import { useDispatch } from "react-redux";

import chatIcon from "../../assets/img/svg/chat-bubble-icon.svg";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions";

function ChatBubble() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(sidePopupOpen({ component: 11 }));
  };

  return (
    <button id="chat-bubble" className="unset-btn" onClick={handleClick}>
      <img src={chatIcon} alt="Chat with us" />
    </button>
  );
}

export default ChatBubble;
