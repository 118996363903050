const appointmentState = {
  upcomingAppointments: [],
  pastAppointments: [],
  appointmentSlots: [],
  isAppSlotsRequested: false,
};

export const appointmentsReducer = (state = appointmentState, action) => {
  if (action.type === "UPCOMING_APPOINTMENTS_REQUEST") {
    return {
      ...state,
      isUpcomingApptLoading: true,
      isUpcomingApptError: false,
    };
  }

  if (action.type === "UPCOMING_APPOINTMENTS_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isUpcomingApptLoading: false,
      isUpcomingApptError: false,
      upcomingAppointments: [...payload],
    };
  }

  if (action.type === "UPCOMING_APPOINTMENTS_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isUpcomingApptLoading: false,
      isUpcomingApptError: true,
      is404Error: payload.code,
    };
  }

  if (action.type === "PAST_APPOINTMENTS_REQUEST") {
    return {
      ...state,
      isLoading: true,
      isDashboadError: false,
      is404Error: "",
      isPastApptLoading: true,
      isPastApptError: false,
    };
  }

  if (action.type === "PAST_APPOINTMENTS_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      isLoginError: false,
      is404Error: "",
      isPastApptLoading: false,
      isPastApptError: false,
      pastAppointments: [...payload],
    };
  }

  if (action.type === "MARK_NOTE_READ_PAST") {
    const { appointmentID } = action;
    let pastAppointmentsTemp = [...state.pastAppointments];
    return {
      ...state,
      pastAppointments: pastAppointmentsTemp.map((a)=>{
        if(a.appointmentID === appointmentID){
          return {...a, isSummaryNoteRead: 1 }
        }
        else{
          return a;
        }
      }),
    };
  }

  if (action.type === "PAST_APPOINTMENTS_FAIL") {
    const { payload = {} } = action;
    return {
      ...state,
      isLoading: false,
      isLoginError: true,
      is404Error: payload.code,
      isPastApptLoading: false,
      isPastApptError: true,
    };
  }

  if (action.type === "APPOINTMENTS_SLOT_REQUEST") {
    return {
      ...state,
      isAppSlotLoading: true,
      isDashboadError: false,
      appointmentSlots: [],
      is404Error: "",
      selectedSlotToBookApp: "",
      isAppSlotsRequested: true,
    };
  }

  if (action.type === "APPOINTMENTS_SLOT_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isAppSlotLoading: false,
      isDashboadError: false,
      is404Error: "",
      appointmentSlots: payload,
    };
  }

  if (action.type === "APPOINTMENTS_SLOT_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isAppSlotLoading: false,
      isDashboadError: true,
      is404Error: payload.code,
    };
  }
  if (action.type === "SELECT_SLOT_TO_BOOK") {
    const { payload } = action;
    return {
      ...state,
      isAppBookError: false,
      selectedSlotToBookApp: payload,
    };
  }
  if (action.type === "SIDEPOPUP_OPEN") {
    return {
      ...state,
      isSidePopupClosed: false,
    };
  }
  if (action.type === "SIDEPOPUP_CLOSE") {
    return {
      ...state,
      selectedSlotToBookApp: "",
      isSidePopupClosed: true,
    };
  }

  if (action.type === "BOOK_APP_REQUEST") {
    return {
      ...state,
      isAppBookLoading: true,
      isAppBookError: false,
      appBookErrorMsg: "",
      is404Error: "",
      bookedAppData: "",
    };
  }

  if (action.type === "BOOK_APP_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isAppBookLoading: false,
      isAppBookError: false,
      appBookErrorMsg: "",
      is404Error: "",
      bookedAppData: payload,
    };
  }

  if (action.type === "BOOK_APP_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isAppBookLoading: false,
      isAppBookError: true,
      appBookErrorMsg: payload.errorMessage,
      is404Error: payload.code,
      bookedAppData: "",
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      upcomingAppointments: [],
      pastAppointments: [],
      selectedSlotToBookApp: [],
      appointmentSlots: [],
      is404Error: "",
    };
  }

  if (action.type === "LOGIN_REQUEST") {
    return {
      upcomingAppointments: [],
      pastAppointments: [],
      selectedSlotToBookApp: [],
      appointmentSlots: [],
      is404Error: "",
    };
  }

  if (action.type === "CANCEL_APP_REQUEST") {
    return {
      ...state,
      isCancelAppointmentLoading: true,
      cancelApiErrorMessage: "",
      isCancelAppointmentSuccess: false,
    };
  }

  if (action.type === "CANCEL_APP_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isCancelAppointmentLoading: false,
      cancelApiErrorMessage: "",
      cancelApiSuccessMessage: payload,
      isCancelAppointmentSuccess: true,
    };
  }

  if (action.type === "CANCEL_APP_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isCancelAppointmentSuccess: false,
      isCancelAppointmentLoading: false,
      cancelApiErrorMessage: payload.errorMessage,
    };
  }

  if (action.type === "CANCEL_APP_CLEAR") {
    return {
      ...state,
      cancelApiErrorMessage: "",
      cancelApiSuccessMessage: "",
      isCancelAppointmentSuccess: false,
    };
  }
  if (action.type === "CLEAR_BOOKED_APP") {
    return {
      ...state,
      bookedAppData: {},
      appBookErrorMsg: "",
      isAppBookError: "",
      stampWorkplaceDate: "",
    };
  }

  if (action.type === "STAMP_WORKPLACE_DATE") {
    return {
      ...state,
      stampWorkplaceDate: action.payload,
    };
  }
  return state;
};
