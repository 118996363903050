import { DOCUMENT_REDIRECT_URL } from "./Environment";

function navigateToRoute(link, linkType, myNavigate, props) {
  switch (linkType.toLowerCase()) {
    case "uri":
      handleInternalRouting(link, myNavigate, props);
      break;
    case "url":
      window.open(link);
      break;
    case "urlsamewindow":
      window.location.href = link;
      break;
    default:
      myNavigate("/dashboard", { replace: false });
      break;
  }
}

function handleInternalRouting(route, myNavigate, props) {
  switch (route.toLowerCase()) {
    case "addlabdocument":
      let docParameter = { isAddLabDocument: true };
      if (props.param) {
        if (props.param.documentType) {
          docParameter.documentType = props.param.documentType;
        }
        if (props.param.documentTypeID) {
          docParameter.documentTypeID = props.param.documentTypeID;
        }
      } else if (props.urlParam) {
        if (props.urlParam.get("documenttype")) {
          docParameter.documentType = props.urlParam.get("documenttype");
        }

        if (props.urlParam.get("documenttypeid")) {
          docParameter.documentTypeID = props.urlParam.get("documenttypeid");
        }
      }
      myNavigate("/dashboard/documents", {
        replace: false,
        state: docParameter,
      });
      break;
    case "mylabdocument":
      myNavigate("/dashboard/documents", { replace: false, state: {} });
      break;
    case "healthchecklist":
      myNavigate("/dashboard/health-checklist", { replace: false, state: {} });
      break;
    case "appointments":
      myNavigate("/dashboard/appointments", { replace: false, state: {} });
      break;
    case "explore":
      myNavigate("/dashboard/explore-content", { replace: false, state: {} });
      break;
    case "medicalhistory":
      myNavigate("/dashboard/medical-history", { replace: false, state: {} });
      break;
    case "maintenance":
      myNavigate("/maintenance", { replace: false, state: {} });
      break;
    case "bookappointment":
      let bookAptParam = {};
      if (props.param) {
        if (props.param.appointmentTypeID) {
          bookAptParam.appointmentTypeID = props.param.appointmentTypeID;
        }
        if (props.param.parentKey) {
          bookAptParam.parentKey = props.param.parentKey;
        }
      } else if (props.urlParam) {
        if (props.urlParam.get("parentkey")) {
          bookAptParam.parentKey = props.urlParam.get("parentkey");
        }
      }
      myNavigate("/dashboard/bookappointment", {
        replace: false,
        state: bookAptParam,
      });
      break;
    case "dependent":
    case "profilepage":
    case "insurancepage":
    case "personalinformationpage":
      myNavigate("/dashboard/myprofile", { replace: false });
      break;
    case "myworkpass":
      if (props.urlParam) {
        if (props.urlParam.get("link")) {
          myNavigate("/dashboard/myworkpass", {
            replace: false,
            state: { link: props.urlParam.get("link") },
          });
        } else {
          myNavigate("/dashboard", { replace: false });
        }
      }
      break;
    case "summarynotes":
      let searchParam = new URLSearchParams(props.queryParam);
      let aptId = searchParam.get("aptId");
      myNavigate("/dashboard/appointments", {
        replace: false,
        state: { aptId: aptId },
      });
      break;
    default:
      myNavigate("/dashboard", { replace: false });
      break;
  }
}

export default navigateToRoute;
