import {
  getSliders,
  getServices,
  getDocumentsUnreadCount,
} from "../../requests/requestAPI";

const requestSliders = () => ({
  type: "SLIDERS_REQUEST",
});
const receiveSliders = (payload) => ({
  type: "SLIDERS_RECEIVE",
  payload,
});
const failureSliders = (payload) => ({
  type: "SLIDERS_FAIL",
  payload,
});

export const slidersAction = (token) => {
  return async (dispatch) => {
    dispatch(requestSliders());
    try {
      let data = await getSliders(token);
      if (data.ok) {
        let slidersInfo = await data.json();
        dispatch(receiveSliders(slidersInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureSliders(JSON.parse(ex.message)));
    }
  };
};

const requestServices = () => ({
  type: "SERVICES_REQUEST",
});
const receiveServices = (payload) => ({
  type: "SERVICES_RECEIVE",
  payload,
});
const failureServices = (payload) => ({
  type: "SERVICES_FAIL",
  payload,
});

export const servicesAction = (token) => {
  return async (dispatch) => {
    dispatch(requestServices());
    try {
      let data = await getServices(token);
      if (data.ok) {
        let servicesInfo = await data.json();
        dispatch(receiveServices(servicesInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureServices(JSON.parse(ex.message)));
    }
  };
};

const unreadDocumentCountRequest = () => ({
  type: "UNREAD_DOCUMENT_REQUEST",
});
const unreadDocumentCountReceive = (payload) => ({
  type: "UNREAD_DOCUMENT_RECEIVE",
  payload,
});
const unreadDocumentCountFail = (payload) => ({
  type: "UNREAD_DOCUMENT_FAIL",
  payload,
});

export const unreadDocumentCountAction = (token) => {
  return async (dispatch) => {
    dispatch(unreadDocumentCountRequest());
    try {
      let data = await getDocumentsUnreadCount(token);
      if (data.ok) {
        let documentCountInfo = await data.json();
        dispatch(unreadDocumentCountReceive(documentCountInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(unreadDocumentCountFail(JSON.parse(ex.message)));
    }
  };
};
