import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { doctorsSpecialityAction } from "../../../redux/actions/doctorsActions";
import { doctorsListAction } from "../../../redux/actions/doctorsActions";
import { sidePopupClose } from "../../../redux/actions/sidePopupActions";
import { modalOpen } from "../../../redux/actions/modalAction";
import { updateCareTeam } from "../../../requests/requestAPI";

import Spinner from "../Spinner";

const DoctorRow = ({ doctor, labels = {} }) => {
  const { token } = useSelector((state) => state.user);
  const { userProfile: { state, companyState } = {} } = useSelector(
    (state) => state.userProfile
  );
  const dispatch = useDispatch();

  const onDoctorClick = async (e) => {
    if (!e.target.dataset.noselect) {
      const payload = {
        doctorID: doctor.doctorID,
        specialty: "PRIMARYCARE",
      };
      await updateCareTeam(token, payload);
      dispatch(sidePopupClose());
      dispatch(doctorsListAction(token, state ? state : companyState));
    }
  };

  const openModal = () => {
    dispatch(
      modalOpen({
        component: 6,
        content: {
          modalData: {
            DrName: doctor.fullName,
            title: doctor.title,
            data: doctor.bio,
            img: doctor.profilePicture,
            id: doctor.doctorID,
            speciality: "PRIMARYCARE",
          },
        },
      })
    );
  };

  return (
    <div
      className={`my-care-team-card mb-25 ${
        doctor.isCareTeam ? "primary-provider" : "alternate-provider"
      }`}
      onClick={(e) => onDoctorClick(e)}
    >
      {doctor.isCareTeam && (
        <div className="iconic-label">
          <i className="icon icon-heart"></i>
          <span>{labels.LBL_CARE_TEAM}</span>
        </div>
      )}
      <div className="avatar-info avatar-info-2">
        <div className="avatar-info-in flex-top">
          <figure>
            <img
              src={doctor.profilePicture}
              alt=""
              className="img-responsive"
            />
          </figure>
          <div className="ai-meta">
            <span className="ai-title">{doctor.fullName}</span>
            <span className="doctor-grade">{doctor.title}</span>
            <div
              className="doctor-short-info"
              onClick={openModal}
              data-noselect="1"
            >
              {doctor.bio.slice(0, 90) + "..."}
              <span data-noselect="1">more</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditCareTeam = ({ labels = {} }) => {
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { specialityDoctors, isDoctorsSpecialityLoading } = useSelector(
    (state) => state.doctors
  );
  const { content: { state, specialty } = {} } = useSelector(
    (state) => state.sidePopup
  );

  useEffect(() => {
    const payload = {
      stateCode: state,
      specialty: specialty,
    };
    dispatch(doctorsSpecialityAction(token, payload));
  }, [dispatch, specialty, state, token]);

  return (
    <div className="edit-care-team">
      {!specialityDoctors.length && isDoctorsSpecialityLoading ? (
        <Spinner />
      ) : (
        <>
          <h2 className="app-heading-1 mb-40">
            {labels.LBL_EDIT_YOUR_CARE_TEAM}
          </h2>
          <label>{labels.LBL_CURRENT_PRIMARY_PROVIDER}</label>
          {specialityDoctors.length && (
            <DoctorRow doctor={specialityDoctors[0]} labels={labels} />
          )}
          <label>{labels.LBL_MORE_OPTIONS}</label>
          {specialityDoctors.map(
            (doctor, index) =>
              index !== 0 && (
                <DoctorRow key={index} doctor={doctor} labels={labels} />
              )
          )}
        </>
      )}
    </div>
  );
};

export default EditCareTeam;
