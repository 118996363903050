const dhaState = {
  dhaInfo: {},
  dhaWVInfo: {},
};

export const dhaReducer = (state = dhaState, action) => {
  if (action.type === "GET_DHA_REQUEST") {
    return {
      ...state,
      isDHALoading: true,
    };
  }

  if (action.type === "GET_DHA_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isDHALoading: false,
      dhaInfo: { ...payload },
    };
  }

  if (action.type === "GET_DHA_FAIL") {
    return {
      ...state,
      isDHALoading: false,
    };
  }

  if (action.type === "GET_DHAWV_REQUEST") {
    return {
      ...state,
      isDHAWVLoading: true,
    };
  }

  if (action.type === "GET_DHAWV_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isDHAWVLoading: false,
      dhaWVInfo: { ...payload },
    };
  }

  if (action.type === "GET_DHAWV_FAIL") {
    return {
      ...state,
      isDHAWVLoading: false,
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      dhaInfo: {},
      dhaWVInfo: {},
    };
  }

  return state;
};
