const userState = {
  token: null,
  userID: 0,
  userUID: null,
  patientID: null,
  companyID: null,
  company: null,
  firstName: null,
  lastName: null,
  isCompanyOps: false,
  isRadishOps: false,
  isReportOps: false,
  isSupervisor: false,
  isImpersonation: false,
  versionError: null,
  versionUpdate: false,
  isAccountActive: true,
  features: [],
  is2FA: null,
  success: false,
  error: null,
  isForgotPasswordSent: null,
};

export const userReducer = (state = userState, action) => {
  if (action.type === "FORGOT_PASSWORD_REQUEST") {
    return {
      ...state,
      isLoading: true,
      isLoginError: false,
    };
  }
  if (action.type === "FORGOT_PASSWORD_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      isLoginError: false,
      isForgotPasswordSent: payload,
      emailNOtFoundError: false,
    };
  }
  if (action.type === "FORGOT_PASSWORD_FAIL") {
    return {
      ...state,
      isLoading: false,
      isForgotPasswordSent: false,
      emailNOtFoundError: true,
    };
  }
  if (action.type === "CLEAR_STATE") {
    const newState = { ...state };
    delete newState.selfRegisterData;
    delete newState.preRegData;

    return {
      ...newState,
      isLoading: false,
      loginApiErrorMsg: "",
      isLoginError: false,
      isForgotPasswordSent: false,
      SelfRegisterApiErrorMsg: "",
      emailNOtFoundError: false,
      registerSuccess: false,
      registerApiError: false,
      otpError: false,
      otpData: "",
      otpErrorMsg: "",
      otpPhoneMob: "",
      resetPassData: "",
    };
  }
  if (action.type === "LOGIN_REQUEST") {
    localStorage.clear();
    return {
      ...state,
      isLoading: true,
      isLoginError: false,
      isForgotPasswordSent: false,
      emailNOtFoundError: false,
      loginApiErrorMsg: "",
    };
  }
  if (action.type === "LOGIN_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      isLoginError: false,
      loginApiErrorMsg: "",
      ...payload,
    };
  }

  if (action.type === "LOGIN_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      isLoginError: true,
      loginApiErrorMsg: payload.errorMessage,
    };
  }

  if (action.type === "CONSENT_REQUEST") {
    return {
      ...state,
      isConsentLoading: true,
    };
  }
  if (action.type === "CONSENT_RECEIVE") {

    const retrievedString = localStorage.getItem("loginInfo");
    const parsedObject = JSON.parse(retrievedString);
    parsedObject.isConsentProvided = true;
    const modifiedndstrigifiedForStorage = JSON.stringify(parsedObject);
    localStorage.setItem("loginInfo", modifiedndstrigifiedForStorage);

    return {
      ...state,
      isConsentLoading: false,
      isConsentProvided: true
    };
  }

  if (action.type === "CONSENT_FAIL") {
    return {
      ...state,
      isConsentLoading: false
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      isLoading: false,
      isLoginError: false,
      ...userState,
    };
  }

  if (action.type === "REGISTER_REQUEST") {
    return {
      ...state,
      isLoading: true,
      isLoginError: false,
      isForgotPasswordSent: false,
      emailNOtFoundError: false,
      registerSuccess: false,
      registerApiError: false,
      otpData: "",
    };
  }
  if (action.type === "REGISTER_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      isLoginError: false,
      registerSuccess: payload.success,
      registerApiError: false,
    };
  }

  if (action.type === "REGISTER_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      isLoginError: true,
      registerSuccess: false,
      registerApiError: true,
      registerApiErrorMsg: payload.errorMessage,
    };
  }

  if (action.type === "SET_USER") {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === "SELF_REGISTER_REQUEST") {
    return {
      ...state,
      isLoading: true,
      emailPhoneNotFoundError: false,
      selfRegisterData: "",
      SelfRegisterApiErrorMsg: "",
      otpData: "",
    };
  }
  if (action.type === "SELF_REGISTER_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      emailPhoneNotFoundError: false,
      selfRegisterData: payload,
    };
  }

  if (action.type === "SELF_REGISTER_FAIL") {
    const { payload } = action;
    return {
      ...state,
      selfRegisterData: "",
      isLoading: false,
      emailPhoneNotFoundError: true,
      SelfRegisterApiErrorMsg: payload.errorMessage,
    };
  }

  if (action.type === "ADD_PHONE_EMAIL") {
    const { payload } = action;
    return {
      ...state,
      otpPhoneMob: payload,
    };
  }

  if (action.type === "OTP_AUTH_REQUEST") {
    return {
      ...state,
      isLoading: true,
      otpError: false,
      otpData: "",
      otpErrorMsg: "",
    };
  }
  if (action.type === "OTP_AUTH_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      otpError: false,
      otpData: payload,
    };
  }

  if (action.type === "OTP_AUTH_FAIL") {
    const { payload } = action;
    return {
      ...state,
      otpData: "",
      isLoading: false,
      otpError: true,
      otpErrorMsg: payload.errorMessage,
    };
  }

  if (action.type === "REG_DATA_REQUEST") {
    return {
      ...state,
      preRegData: {
        isRegDataLoading: true,
        isRegDataError: false,
      },
    };
  }
  if (action.type === "REG_DATA_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      preRegData: {
        isRegDataLoading: false,
        ...payload,
        isRegDataError: false,
      },
    };
  }

  if (action.type === "REG_DATA_FAIL") {
    const { payload } = action;
    return {
      ...state,
      preRegData: {
        isRegDataLoading: false,
        isRegDataError: true,
        regDataErrorMessage: payload.errorMessage,
      },
    };
  }

  if (action.type === "RESET_PASS_REQUEST") {
    return {
      ...state,
      resetPassData: {
        isResetPassLoading: true,
        isResetPassError: false,
        isResetPassErrorMsg: "",
      },
    };
  }
  if (action.type === "RESET_PASS_RECEIVE") {
    // const { payload } = action;
    return {
      ...state,
      resetPassData: {
        isResetPassLoading: false,
        resetPassDone: true,
        isResetPassError: false,
        isResetPassErrorMsg: "",
      },
    };
  }

  if (action.type === "RESET_PASS_FAIL") {
    const { payload } = action;
    return {
      ...state,
      resetPassData: {
        isResetPassLoading: false,
        isResetPassError: true,
        isResetPassErrorMsg: payload.errorMessage,
      },
    };
  }
  if (action.type === "CLEAR_OTP") {
    return {
      ...state,
      resetPassData: "",
      preRegData: "",
      selfRegisterData: "",
    };
  }

  if (action.type === "VERIFY_RESET_PASS_REQUEST") {
    return {
      ...state,
      resetPassData: {
        isResetPassLoading: true,
        isResetPassError: false,
        isResetPassErrorMsg: "",
      },
    };
  }
  if (action.type === "VERIFY_RESET_PASS_RECEIVE") {
    // const { payload } = action;
    return {
      ...state,
      resetPassData: {
        isResetPassLoading: false,
        isResetPassError: false,
        isResetPassErrorMsg: "",
      },
    };
  }

  if (action.type === "VERIFY_RESET_PASS_FAIL") {
    const { payload } = action;
    return {
      ...state,
      resetPassData: {
        isResetPassLoading: false,
        isResetPassError: true,
        isResetPassErrorMsg: payload.errorMessage,
      },
    };
  }
  return state;
};
