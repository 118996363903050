import infoIcon from "../../../assets/img/svg/info.svg";
import healthConditionsIcon from "../../../assets/img/svg/medical-history-icons/health-conditions-icon.svg";
import medicationsIcon from "../../../assets/img/svg/medical-history-icons/medication-icon.svg";
import drugAllergyIcon from "../../../assets/img/svg/medical-history-icons/drug-allergy-icon.svg";

const Verified = ({
  userConditionsVerified = [],
  userMedicationsVerified = [],
  userAllergiesVerified = [],
  isUserVerifiedLoading,
}) => {
  if (
    userConditionsVerified.length ||
    userMedicationsVerified.length ||
    userAllergiesVerified.length
  )
    return (
      <div className="mh-verified col-12 col-lg-6">
        <div className="mh-status">
          <span>Verified Medical History</span>
        </div>
        <div className="mh-populated-list">
          <div className="green-stripe"></div>
          {userConditionsVerified.length > 0 && (
            <div
              className={`mh-verified-list ${
                (userMedicationsVerified.length > 0 ||
                  userAllergiesVerified.length > 0) &&
                "mh-border-bottom"
              }`}
            >
              <div className="mh-list-type-header">
                <img src={healthConditionsIcon} alt="" />
                <h6>Health Conditions</h6>
              </div>
              {userConditionsVerified.map((condition, index) => {
                return (
                  <div key={index} className="mh-populated-list-item">
                    <span>{condition.name}</span>
                    <a
                      href={condition.infoUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={infoIcon} alt="Get more info" />
                    </a>
                  </div>
                );
              })}
            </div>
          )}
          {userMedicationsVerified.length > 0 && (
            <div
              className={`mh-verified-list ${
                userAllergiesVerified.length > 0 && "mh-border-bottom"
              }`}
            >
              <div className="mh-list-type-header">
                <img src={medicationsIcon} alt="" />
                <h6>Medications</h6>
              </div>
              {userMedicationsVerified.map((medication, index) => {
                return (
                  <div key={index} className="mh-populated-list-item">
                    <span>{medication.name}</span>
                  </div>
                );
              })}
            </div>
          )}
          {userAllergiesVerified.length > 0 && (
            <div className="mh-verified-list">
              <div className="mh-list-type-header">
                <img src={drugAllergyIcon} alt="" />
                <h6>Drug Allergies</h6>
              </div>
              {userAllergiesVerified.map((allergy, index) => {
                return (
                  <div key={index} className="mh-populated-list-item">
                    <div className="mh-medication-list-left">
                      <span>{allergy.reaction}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );

  return "";
};

export default Verified;
