import React, { Component } from "react";
import { connect } from "react-redux";
import { getLabelsAction } from "../redux/actions/genericActions";

const receiveLogin = (payload) => ({
  type: "LOGIN_RECEIVE",
  payload,
});

function withLabels(HocComponent) {
  class HOC extends Component {
    componentDidMount() {
      this.props.getLabels();
      this.props.setStore(JSON.parse(localStorage.getItem("loginInfo")));
    }
    render() {
      return (
        <div>
          <HocComponent {...this.props}></HocComponent>
        </div>
      );
    }
  }
  const mapStateToProps = (state) => {
    return {
      labels: state.app.labels,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      getLabels: () => {
        dispatch(getLabelsAction());
      },
      setStore: (payload) => {
        dispatch(receiveLogin(payload));
      },
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
}
export default withLabels;
