import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const DashboardLoading = () => {
  let location = useLocation();
  const {
    isSlidersLoading,
    isServicesLoading,
    isAppointmentTypesLoading,
    isMyToDoLoading,
    isPastAPPTLoading,
    isUpcomingAPPTLoading,
  } = useSelector((state) => state.dashboard);
  const { isDailyWorkPassLoading } = useSelector((state) => state.toDoInfo);

  if (
    location.pathname === "/dashboard" &&
    (isServicesLoading ||
      isSlidersLoading ||
      isAppointmentTypesLoading ||
      isMyToDoLoading ||
      isPastAPPTLoading ||
      isUpcomingAPPTLoading ||
      isDailyWorkPassLoading)
  )
    return true;
  else return false;
};

export default DashboardLoading;
