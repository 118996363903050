import pencilIcon from "../../../assets/img/svg/pencil-icon.svg";

const UnderReview = ({
  userConditions = [],
  userMedications = [],
  userAllergies = [],
  openSidePopup,
  isUserHistoryLoading,
}) => {
  const openDeleteSidePopup = (label, type, id) => {
    const content = {
      label: label,
      type: type,
      id: id,
    };
    openSidePopup(10, content);
  };

  if (userConditions.length || userMedications.length || userAllergies.length)
    return (
      <div className="mh-under-review col-12 col-lg-6">
        <div className="mh-status">
          <span>Under Review</span>
        </div>
        <div className="mh-populated-list">
          {userConditions.length > 0 && (
            <div
              className={`mh-under-review-list ${
                (userMedications.length > 0 || userAllergies.length > 0) &&
                "mh-border-bottom"
              }`}
            >
              <div className="mh-list-type-header">
                <h6>Health Condition</h6>
              </div>
              {userConditions.map((condition, index) => {
                return (
                  <div key={index} className="mh-populated-list-item">
                    <span>{condition.consumerName}</span>
                    <img
                      src={pencilIcon}
                      alt="Edit health condition"
                      onClick={() =>
                        openDeleteSidePopup(
                          "health condition",
                          "medicalConditionId",
                          condition.medicalConditionID
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
          {userMedications.length > 0 && (
            <div
              className={`mh-under-review-list ${
                userAllergies.length > 0 && "mh-border-bottom"
              }`}
            >
              <div className="mh-list-type-header">
                <h6>Medications</h6>
              </div>
              {userMedications.map((medication, index) => {
                return (
                  <div key={index} className="mh-populated-list-item">
                    <div className="mh-medication-list-left">
                      <span className="medication-name">
                        {medication.displayName}
                      </span>
                      <span className="medication-dosage">
                        Dosage:{" "}
                        {medication.dosage.length
                          ? medication.dosage
                          : "Unknown"}
                      </span>
                    </div>
                    <img
                      src={pencilIcon}
                      alt="Edit Medication"
                      onClick={() =>
                        openDeleteSidePopup(
                          "medication",
                          "medicationId",
                          medication.medicationID
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
          {userAllergies.length > 0 && (
            <div className="mh-under-review-list">
              <div className="mh-list-type-header">
                <h6>Medications</h6>
              </div>
              {userAllergies.map((allergy, index) => {
                return (
                  <div key={index} className="mh-populated-list-item">
                    <div className="mh-allergy-list-left">
                      <span className="allergy-name">
                        {allergy.displayName}
                      </span>
                      <span className="allergy-reaction">
                        Dosage:{" "}
                        {allergy.reaction.length ? allergy.reaction : "Unknown"}
                      </span>
                    </div>
                    <img
                      src={pencilIcon}
                      alt="Edit allergy"
                      onClick={() => openSidePopup(10, "allergy")}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="add-history-div">
          <button onClick={() => openSidePopup(9)}>
            <span>+ Add Medical History</span>
          </button>
        </div>
      </div>
    );

  return "";
};

export default UnderReview;
