import { useSelector } from "react-redux";
import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DOCUMENT_REDIRECT_URL } from "../../utils/Environment";
import Spinner from "../common/Spinner";

const MyWorkPass = () => {
  const location = useLocation();
  const { token, userUID } = useSelector((state) => state.user);
  const myInputRef = useRef(null);

  useEffect(() => {
    if (location.state && location.state.link) {
      myInputRef.current.submit();
    }
  }, []);

  return location.state && location.state.link ? (
    <>
      <Spinner />
      <form
        ref={myInputRef}
        name={`myform${userUID}`}
        method="post"
        action={`${DOCUMENT_REDIRECT_URL}?app=web&redirectURL=${encodeURIComponent(
          location.state.link
        )}`}
      >
        <input type="hidden" name="token" value={token}></input>
      </form>
    </>
  ) : (
    <Spinner />
  );
};

export default MyWorkPass;
