import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { forgotPasswordAction } from "../../redux/actions/loginUserAction.js";
import Alert from "../common/Alert.js";
import { useDispatch } from "react-redux";

import Spinner from "../common/Spinner.js";
import {
  postResetPassword,
  postVerifyResetPassword,
} from "../../redux/actions/registerUserAction.js";

const ResetPassword = ({ labels = {} }) => {
  let dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const { resetPassData = {} } = userInfo;
  const [isSubmit, setSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [errorObj, setError] = useState({});

  const patientId = new URLSearchParams(useLocation().search).get("p");
  const tokenQuery = new URLSearchParams(useLocation().search).get("token");

  function handleInputChange({ target }) {
    const { value } = target;
    setPassword(value);
  }

  const onPassBlur = () => {
    if (password && password.length < 8) {
      setError({ ...errorObj, passwordError: "Password is invalid" });
      return;
    }
    setError({ ...errorObj, passwordError: "" });
  };

  useEffect(() => {
    const payload = JSON.stringify({
      patientID: patientId,
      token: tokenQuery,
    });
    dispatch(postVerifyResetPassword(payload));
  }, [dispatch, patientId, tokenQuery]);

  async function submitRequest(event) {
    event.preventDefault();
    setSubmitted(true);
    if (password && !errorObj.passwordError && patientId && tokenQuery) {
      const payload = JSON.stringify({
        patientID: patientId,
        token: tokenQuery,
        password: password,
      });
      await dispatch(postResetPassword(payload));
      // console.log('ss', store.getState().user);
    }
  }

  if (resetPassData && resetPassData.isResetPassLoading) {
    return (
      <div className="lg-form-wrap" id="forget-pass-form-main">
        <h3 className="login-heading">Reset Password</h3>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className="lg-form-wrap" id="forget-pass-form-main">
        <h3 className="login-heading">Reset Password</h3>

        {resetPassData && resetPassData.isResetPassErrorMsg ? (
          <Alert
            messageTitle="ERROR:"
            cssClassName={"alert alert-danger mt-20"}
            message={resetPassData.isResetPassErrorMsg}
          ></Alert>
        ) : (
          <>
            {resetPassData && resetPassData.resetPassDone && (
              <Alert
                cssClassName={"alert alert-success mt-20"}
                message={"Your password has been changed successfully!"}
              ></Alert>
            )}

            {/* { hasError && <Error /> }
        { hasSuccess && <Success /> } */}
            {userInfo.isLoading && <Spinner />}
            {!(resetPassData && resetPassData.resetPassDone) && (
              <>
                <div className="form-group mb-15">
                  {/* Need a label that says "Create a new password" */}
                  <label htmlFor="password">
                    {" "}
                    {labels.LBL_REGISTER_PASSWORD}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="password"
                      autoComplete="off"
                      maxLength="25"
                      placeholder="At least 8 characters"
                      className={`form-control form-control-lg ${
                        ((password && errorObj.passwordError) ||
                          (isSubmit && !password)) &&
                        "input-invalid"
                      }`}
                      name="password"
                      disabled={resetPassData.resetPassDone}
                      id="password"
                      onBlur={onPassBlur}
                      onChange={handleInputChange}
                    />
                    {password && errorObj.passwordError && (
                      <span className="error-label">
                        {errorObj.passwordError}
                      </span>
                    )}
                    {isSubmit && !password && (
                      <span className="error-label">Required</span>
                    )}
                  </div>
                  <div className="switch-cta-wrap"></div>
                </div>

                <button
                  type="submit"
                  disabled={resetPassData.resetPassDone}
                  onClick={submitRequest}
                  className="btn btn-primary btn-lg col-12"
                >
                  Update Password
                </button>
              </>
            )}
            <div className="switch-cta-wrap">
              <Link to="../login" className="link-type-1">
                <strong>{labels.LBL_BACK_LOGIN}</strong>
              </Link>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default ResetPassword;
