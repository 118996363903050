import { getDHAStatus, getDHAWebview } from "../../requests/requestAPI";

const requestDHAStatus = () => ({
  type: "GET_DHA_REQUEST",
});
const receiveDHAStatus = (payload) => ({
  type: "GET_DHA_RECEIVE",
  payload,
});
const failDHAStatus = (payload) => ({
  type: "GET_DHA_FAIL",
  payload,
});

export const getDHAAction = (token) => {
  return async (dispatch) => {
    dispatch(requestDHAStatus());
    try {
      let data = await getDHAStatus(token);
      if (data.ok) {
        let dhaInfo = await data.json();
        dispatch(receiveDHAStatus(dhaInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failDHAStatus(JSON.parse(ex.message)));
    }
  };
};

const requestDHAWVStatus = () => ({
  type: "GET_DHAWV_REQUEST",
});
const receiveDHAWVStatus = (payload) => ({
  type: "GET_DHAWV_RECEIVE",
  payload,
});
const failDHAWVStatus = (payload) => ({
  type: "GET_DHAWV_FAIL",
  payload,
});

export const getDHAWVAction = (userUID) => {
  return async (dispatch) => {
    dispatch(requestDHAWVStatus());
    try {
      let data = await getDHAWebview(userUID);
      if (data.ok) {
        let dhaWVInfo = await data.json();
        dispatch(receiveDHAWVStatus(dhaWVInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failDHAWVStatus(JSON.parse(ex.message)));
    }
  };
};
