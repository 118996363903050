import { Link } from "react-router-dom";

import GooglePlay from "../../assets/img/svg/google-play-badge.svg";
import AppStore from "../../assets/img/svg/app-store.svg";

const Footer = () => {
  return (
    <>
      <footer className="footer clearfix">
        <div className="container">
          <div className="footer-1">
            <div className="footer-block" id="footer-location">
              <h3 className="footer-title">Location</h3>
              <div className="fb-title-group">
                <p>
                  <strong>Address: </strong>
                </p>
                <p>
                  Alphabet City Medicine, P.C. | Radish Health Inc. <br />
                  900 Broadway, Suite 903, New York, NY 10003 <br />
                </p>
              </div>
            </div>
            <div className="footer-block" id="footer-contact">
              <h3 className="footer-title">Contact</h3>
              <div className="fb-title-group">
                <div className="row">
                  <div className="col-auto col-md-auto">
                    <p>
                      <strong>Email: </strong>
                    </p>
                    <p>
                      <a href="mailto:help@radish.health">help@radish.health</a>
                    </p>
                  </div>
                  <div className="col-auto col-md-auto">
                    <p>
                      <strong>Phone: </strong>
                    </p>
                    <p>
                      <a href="tel:8334723474">(833)-4-RADISH</a>
                    </p>
                  </div>
                  <div className="col-auto col-md-auto">
                    <p>
                      <strong>Fax: </strong>
                    </p>
                    <p>
                      <a href="fax:833-372-3329">(833)-372-3329</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-block" id="footer-guide">
              <h3 className="footer-title">Guide</h3>
              <div className="fb-title-group">
                <a
                  href="https://radish-health.freshdesk.com/support/home"
                  target="_blank"
                  rel="noreferrer"
                >
                  Help Center
                </a>
              </div>
            </div>
            <div className="footer-block" id="footer-download-apps">
              <h3 className="footer-title">Download App</h3>
              <div className="row g-2" id="download-apps-row">
                <div className="col-auto col-md-auto">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.radish.health"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={GooglePlay}
                      className="store-badge"
                      alt="Download Radish Health from Google Play Store"
                    />
                  </a>
                </div>
                <div className="col-auto col-md-auto">
                  <a
                    href="https://apps.apple.com/us/app/radish-health/id1556054938"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={AppStore}
                      className="store-badge"
                      alt="Download Radish Health from Apple App Store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-2">
            <div className="row g-0">
              <div className="col-md-12 col-lg-8 footer-2-left">
                <p>&copy; 2023 Radish Health Inc</p>
                <div className="footer-nav">
                  <ul>
                    <li>
                      <Link to="/policies/npp">Privacy Practices</Link>
                    </li>
                    <li>
                      <Link to="/policies/pp">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/policies/consent">Consent</Link>
                    </li>
                    <li>
                      <Link to="/policies/tou">Terms of Use</Link>
                    </li>
                    {/* <li>
                      <Link to="/policies/refund-policy">Refund Policy</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="social-media">
                  <a
                    href="https://www.instagram.com/radishhealth/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon icon-instagram"></i>
                  </a>
                  <a
                    href="https://twitter.com/radishhealth"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon icon-twitter"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/radish-health"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon icon-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
