import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withCookies } from "react-cookie";
import {
  patchCancelAppointment,
  upcomingAppointmentsAction,
} from "../../../redux/actions/appointmentsActions";
import Spinner from "../../common/Spinner";
import moment from "moment";
import { UpcomingAppointmentAction } from "../../../redux/actions/dashboardSidebarAction";
import {
  sidePopupClose,
  sidePopupLoading,
  sidePopupConfirmed,
  sidePopupFailed,
} from "../../../redux/actions/sidePopupActions.js";
import { logoutUser } from "../../../redux/actions/logoutUser";
import Logo from "../../../assets/img/svg/radish-icon.svg";

const AppointmentInfo = ({ cookies }) => {
  const dispatch = useDispatch();
  const appointmentInfo = useSelector((state) => state.sidePopup.content);
  const cancelAppointmentInfo = useSelector((state) => state.appointments);
  const { is404Error } = useSelector((state) => state.dashboard);
  const [prompted, setPrompted] = useState(false);
  const { token } = useSelector((state) => state.user);
  const labels = useSelector((state) => state.app.labels);

  let appointmentDay,
    appointmentDate,
    appointmentMonth,
    appointmentYear,
    appointmentDateSuffix;
  if (appointmentInfo.localDate) {
    const scheduleDate = appointmentInfo.localDate.split(",");
    if (scheduleDate.length > 0) {
      let dateYear = scheduleDate[1].split(" ");
      appointmentDay = scheduleDate[0];
      appointmentDate = dateYear[2];
      appointmentMonth = dateYear[1];
      appointmentYear = dateYear[3];
    }

    switch (appointmentDate) {
      case "1":
        appointmentDateSuffix = "st";
        break;
      case "2":
        appointmentDateSuffix = "nd";
        break;
      case "3":
        appointmentDateSuffix = "rd";
        break;
      case "21":
        appointmentDateSuffix = "st";
        break;
      case "22":
        appointmentDateSuffix = "nd";
        break;
      case "23":
        appointmentDateSuffix = "rd";
        break;
      case "31":
        appointmentDateSuffix = "st";
        break;
      default:
        appointmentDateSuffix = "th";
    }

    // const d = new Date();
    // let today = d.getDay();
    // let tomorrow = d.getDay() + 1;

    // function getWeekday(day) {
    //   switch (day) {
    //     case 1:
    //       return "Mon";
    //     case 2:
    //       return "Tue";
    //     case 3:
    //       return "Wed";
    //     case 4:
    //       return "Thu";
    //     case 5:
    //       return "Fri";
    //     case 6:
    //       return "Sat";
    //     case 7:
    //       return "Sun";
    //     default:
    //       return "Mon";
    //   }
    // }

    const d1 = moment().format("YYYY-MM-DD");
    const date1 = new Date(d1);

    const momentDate = moment(appointmentInfo.localDate).format("YYYY-MM-DD");
    const date2 = new Date(momentDate);
    if (
      date1.getDate() === date2.getDate() &&
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    ) {
      appointmentDay = "Today";
    } else {
      date1.setDate(date1.getDate() + 1);
      if (
        date1.getDate() === date2.getDate() &&
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth()
      ) {
        appointmentDay = "Tomorrow";
      }
    }

    // if (appointmentDay === getWeekday(today)) appointmentDay = 'Today'
    // else if (appointmentDay === getWeekday(tomorrow)) appointmentDay = 'Tomorrow'
  }

  useEffect(() => {
    if (cancelAppointmentInfo.isCancelAppointmentSuccess) {
      dispatch(sidePopupConfirmed());
      setTimeout(() => {
        dispatch(sidePopupClose());
      }, 3000);
    }
  }, [dispatch, cancelAppointmentInfo.isCancelAppointmentSuccess]);

  useEffect(() => {
    if (cancelAppointmentInfo.cancelApiErrorMessage) {
      dispatch(sidePopupFailed());
      setTimeout(() => {
        dispatch(sidePopupClose());
      }, 3000);
    }
  }, [dispatch, cancelAppointmentInfo.cancelApiErrorMessage]);

  useEffect(() => {
    if (is404Error === 401) {
      cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [is404Error, dispatch, cookies]);

  const cancelAppointment = async () => {
    setPrompted(false);
    dispatch(sidePopupLoading());
    await dispatch(
      patchCancelAppointment(token, appointmentInfo.appointmentID)
    );
    dispatch(UpcomingAppointmentAction(1, token));
    dispatch(upcomingAppointmentsAction(token));
  };

  // const clearError = () => dispatch({ type: "CANCEL_APP_CLEAR" });

  return (
    <>
      {cancelAppointmentInfo.cancelApiErrorMessage && (
        <div className="app-heading mb-40">
          {cancelAppointmentInfo.cancelApiErrorMessage}
        </div>
      )}
      {cancelAppointmentInfo.isCancelAppointmentSuccess && (
        <div className="app-heading mb-40">
          {cancelAppointmentInfo.cancelApiSuccessMessage}
        </div>
      )}
      {!cancelAppointmentInfo.cancelApiErrorMessage &&
        !cancelAppointmentInfo.isCancelAppointmentSuccess && (
          <>
            <h2 className="app-heading-1 mb-65">
              {labels.LBL_AAPTINFO_HEADER}
            </h2>
            <div className="appt-info-header mb-10">
              <div className="appt-info-type">
                {appointmentInfo.appointmentTypeDisplayText}
              </div>
              <div className="appt-info-location">
                {appointmentInfo.examRoomName}
              </div>
            </div>
            <div className="avatar-info avatar-info-2 mb-25">
              <div className="avatar-info-in">
                <figure>
                  <img
                    src={appointmentInfo.profilePicture || Logo}
                    alt=""
                    className="img-responsive"
                  />
                </figure>
                <div className="ai-meta">
                  <span className="ai-title">{appointmentInfo.doctor}</span>
                  {appointmentInfo.IsShowDoctorOnUI && (
                    <span className="ai-position font-regular">
                      {appointmentInfo.title}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="datetime-cart mb-30">
              <span className="date">
                {appointmentDay} {appointmentMonth} {appointmentDate}
                {appointmentDateSuffix}, {appointmentYear}
              </span>
              <span className="time">
                {appointmentInfo.localTime} {appointmentInfo.timeZone}
              </span>
            </div>

            {appointmentInfo.additionalDetails && (
              <div className="appt-add-details">
                <p>
                  {appointmentInfo.additionalDetails ||
                    labels.MSG_AAPTINFO_NONOTE}
                </p>
              </div>
            )}

            {appointmentInfo.isShowCancelAppointment && !prompted && (
              <button
                type="button"
                className={`btn btn-primary col-12 btn-lg btn-cancel ${
                  cancelAppointmentInfo.isCancelAppointmentLoading &&
                  "loading-cancel"
                }`}
                onClick={() => setPrompted(true)}
              >
                {cancelAppointmentInfo.isCancelAppointmentLoading ? (
                  <Spinner />
                ) : (
                  labels.LBL_AAPTINFO_BTNTXT
                )}
              </button>
            )}

            {prompted && (
              <div className="div-cancel-prompt">
                <p>{labels.LBL_AAPTINFO_CONFIRMTXT}</p>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-cancel"
                    onClick={cancelAppointment}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-cancel"
                    onClick={() => setPrompted(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            )}
          </>
        )}
    </>
  );
};

export default withCookies(AppointmentInfo);
