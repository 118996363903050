import reportDoc from "../../assets/img/svg/report-doc-icon.svg";
import vaccineDoc from "../../assets/img/svg/vaccine-doc-icon.svg";
import labInsightsDoc from "../../assets/img/svg/appt-type-icons/blood-work-icon.svg";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import {
  documentsListAction,
  documentsListSortedAction,
  documentsTypesAction,
  documentsUploadFailedClearAction,
  ddhLabStatusAction,
} from "../../redux/actions/documentsActions.js";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions.js";
import Spinner from "../common/Spinner.js";
import Alert from "../common/Alert.js";
import { DOCUMENT_REDIRECT_URL } from "../../utils/Environment";
import { logoutUser } from "../../redux/actions/logoutUser";

const DocumentRow = ({ document, index }) => {
  const myInputRef = useRef(null);

  const { token } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function renderIcon(metaTags, documentType) {
    if (Object.keys(metaTags).length) {
      if (metaTags.includes("vaccine_record")) return vaccineDoc;
      else return reportDoc;
    } else {
      if (documentType === "ELABORATE") return labInsightsDoc;
    }
  }

  function renderAlt(metaTags) {
    if (metaTags.includes("vaccine_record")) return "vaccine";
    else return "report";
  }

  function renderStatus(status) {
    switch (status) {
      case "Under Review":
        return <span className="document-status orange-text">{status}</span>;
      // case 'Verified':
      //     return (<span className="document-status green-text">{status}</span>)
      default:
        return null;
    }
  }

  const openDocument = (doc) => {
    if (doc.documentType === "ELABORATE") {
      dispatch({ type: "UPDATE_ELABORATE_URL", payload: doc.document });
      navigate("/dashboard/interactivelabinsights");
    } else {
      myInputRef.current.submit();
    }
  };

  return (
    <form
      ref={myInputRef}
      name={`myform${document.id}`}
      method="post"
      target="_blank"
      action={`${DOCUMENT_REDIRECT_URL}?redirectURL=${encodeURIComponent(
        `/dldocv2?id=${document.id}`
      )}`}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <input type="hidden" name="token" value={token}></input>
      <button onClick={() => openDocument(document)} className="unset-btn">
        <div
          key={index}
          className={`document-row ${document.isNew && "document-row-unread"}`}
        >
          <div className="document-row-left">
            <div className="dr-icon">
              <img
                src={renderIcon(document.metaTags, document.documentType)}
                alt={renderAlt(document.metaTags)}
                className="img-responsive"
              />
            </div>
            <div className="dr-info">
              <div className="document-type">{document.description}</div>
              <span className="document-name">
                {moment(document.date).format("MMM Do YYYY")}
              </span>
            </div>
          </div>
          <div className="document-row-right">
            {renderStatus(document.status)}
          </div>
        </div>
      </button>
    </form>
  );
};

const Documents = ({ labels = {}, cookies }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    userDocuments,
    isDocumentsListLoading,
    // labResultsInfo,
    // isLabResultsInfoLoading,
    documentsTypes,
    isDocumentsTypesLoading,
    isDocumentsUploadError,
    documentsErrorMessage,
    isDDHLabStatusLoading,
    ddhLabStatusInfo,
    is404Error,
  } = useSelector((state) => state.documents);
  const { token, patientID } = useSelector((state) => state.user);
  const [sortBy, setSortBy] = useState("DESC");
  const [showFilter, setShowFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const clearError = () => dispatch(documentsUploadFailedClearAction());

  useEffect(() => {
    if (is404Error === 401) {
      cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [is404Error, dispatch, cookies]);

  useEffect(() => {
    dispatch(documentsListAction(token));
    dispatch(documentsTypesAction(token));
    dispatch(ddhLabStatusAction(token));
    // dispatch(labResultsInfoAction(token));
    if (location.state && location.state.isAddLabDocument) {
      //if (location.state.documentTypeID && location.state.documentType) {
      dispatch(
        sidePopupOpen({
          component: 1,
          content: {
            documentTypeID: location.state.documentTypeID,
            documentType: location.state.documentType,
          },
        })
      );
      //}
    }
  }, [dispatch, token, location.state]);

  function getSortedDocumentList(tags) {
    sortBy === "DESC" ? setSortBy("ASC") : setSortBy("DESC");
    const payload = {
      type: tags === "none" ? "" : !tags ? currentFilter : tags,
      patient: patientID,
      sortOrder: sortBy,
    };
    dispatch(documentsListSortedAction(token, payload));
    if (showFilter) setShowFilter(false);
  }

  function getFilters() {
    let filterArray = [];
    documentsTypes.forEach((documentType) => {
      let filter = {
        name: documentType.documentName,
        tags: documentType.tags[0],
      };
      filterArray.push(filter);
    });
    return filterArray.map((filter, index) => {
      return (
        <li
          key={index}
          onClick={() => {
            getSortedDocumentList(filter.tags);
            setCurrentFilter(filter.tags);
            setIsFiltered(true);
          }}
        >
          {filter.name}
        </li>
      );
    });
  }

  const interactiveLabClick = () => {
    if (!isDDHLabStatusLoading) {
      navigate("/dashboard/interactivelabinsights");
    }
  };

  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left">
          <button
            onClick={() => navigate(-1)}
            className="back-t-screen-cta unset-btn"
          >
            Back
          </button>
          <h2>{labels.LBL_RESULT_DOCUMENT}</h2>
        </div>
        <div className="app-screen-title-right">
          <button
            onClick={interactiveLabClick}
            disabled={
              isDDHLabStatusLoading || !ddhLabStatusInfo.isLabStatusAvailable
            }
            className="btn btn-primary btn-pad-40 ucase"
          >
            {isDDHLabStatusLoading ? <Spinner /> : "Interactive Lab Insights"}
          </button>
        </div>
      </div>

      <div className="filters-row">
        <div className="filter-left">
          <div className="filter-holder">
            <div
              className={`filter-header lined-icon-btn filter-icon-btn ${
                isFiltered && "filtered"
              }`}
              onClick={() => setShowFilter(!showFilter)}
            >
              {labels.LBL_DOCUMENT_FILTER}
            </div>
            <ul
              className={`lined-icon-btn filter-dropdown ${
                showFilter && "show-filter-dropdown"
              }`}
            >
              <li
                onClick={() => {
                  getSortedDocumentList("none");
                  setCurrentFilter("");
                  setIsFiltered(false);
                }}
              >
                {labels.LBL_DOCUMENT_VIEWALL}
              </li>
              {!isDocumentsTypesLoading && documentsTypes[0] && getFilters()}
            </ul>
          </div>
          <div
            className="lined-icon-btn sort-icon-btn"
            onClick={() => getSortedDocumentList()}
          >
            {labels.LBL_DOCUMENT_DATESORT}
          </div>
          <div
            className="btn btn-outline-primary btn-thin ucase"
            onClick={() =>
              dispatch(sidePopupOpen({ component: 1, content: {} }))
            }
          >
            {labels.LBL_ADD_NEW_DOCUMENT}
          </div>
        </div>
      </div>
      {isDocumentsUploadError && (
        <Alert
          messageTitle="ERROR:"
          message={documentsErrorMessage}
          cssClassName={"alert alert-danger mt-20"}
          clearError={clearError}
        />
      )}
      {isDocumentsListLoading && <Spinner />}
      {!isDocumentsListLoading && userDocuments[0] && (
        <div className="documents-table">
          {userDocuments.map(
            (document, index) =>
              !document.archived && (
                <DocumentRow document={document} index={index} key={index} />
              )
          )}
        </div>
      )}
      {!isDocumentsListLoading && !userDocuments[0] && (
        <p>No uploaded documents</p>
      )}
    </>
  );
};

export default Documents;
