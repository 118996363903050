import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { forgotPasswordAction } from "../../redux/actions/loginUserAction.js";
import Alert from "../common/Alert.js";
import { useDispatch } from "react-redux";
import Spinner from "../common/Spinner.js";

const requestLogin = () => ({
  type: "CLEAR_STATE",
});

const ForgotPassword = ({ labels = {} }) => {
  let dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [isSubmit, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);

  function handleInputChange({ target }) {
    const { value } = target;
    setEmail(value);
  }

  const onEmailBlur = () => {
    var re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      setEmailError("Email is invalid");
      return;
    }
    setEmailError("");
  };

  useEffect(() => {
    return () => {
      dispatch(requestLogin());
    };
  }, [dispatch]);

  function submitRequest(event) {
    event.preventDefault();
    setSubmitted(true);
    if (email && !emailError) {
      dispatch(forgotPasswordAction(email));
    }
  }

  return (
    <div className="lg-form-wrap" id="forget-pass-form-main">
      <h3 className="login-heading">Forgot Password</h3>
      {userInfo.isForgotPasswordSent && (
        <Alert
          cssClassName={"alert alert-success mt-20"}
          message={labels.MSG_FORGOT_PWD_SUCCESS}
        />
      )}
      {userInfo.emailNOtFoundError && (
        <Alert
          messageTitle="ERROR:"
          cssClassName={"alert alert-danger mt-20"}
          message={labels.MSG_FORGOT_PWD_ERROR}
        ></Alert>
      )}
      <form>
        <div className="form-group">
          <label htmlFor="email">{labels.LBL_REGISTER_EMAIL}</label>
          <div className="inputwrap">
            <input
              type="email"
              className={`form-control form-control-lg ${
                isSubmit && !email && "input-invalid"
              }`}
              id="email"
              onChange={handleInputChange}
              name="email"
              value={email}
              onBlur={onEmailBlur}
              required
              disabled={userInfo.isLoading || userInfo.isForgotPasswordSent}
              maxLength="250"
            />
            {email && emailError && (
              <span className="error-label">{emailError}</span>
            )}
            {isSubmit && !email && (
              <span className="error-label">Required</span>
            )}
          </div>
          <div className="switch-cta-wrap"></div>
        </div>

        <button
          type="submit"
          disabled={userInfo.isLoading || userInfo.isForgotPasswordSent}
          onClick={submitRequest}
          className="btn btn-primary btn-lg col-12"
        >
          {userInfo.isLoading ? <Spinner /> : "Submit"}
        </button>
        <div className="switch-cta-wrap">
          <Link to="../login" className="link-type-1">
            <strong>Back to login</strong>
          </Link>
        </div>
      </form>
    </div>
  );
};
export default ForgotPassword;
