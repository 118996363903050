import { useSelector, useDispatch } from "react-redux";
import { sidePopupClose } from "../../../../redux/actions/sidePopupActions";
import {
  deleteUserCondition,
  deleteUserMedication,
} from "../../../../requests/requestAPI";
import { userHistoryAction } from "../../../../redux/actions/medicalHistoryActions";

const EditMedicalHistory = () => {
  const dispatch = useDispatch();
  const { label, type, id } = useSelector((state) => state.sidePopup.content);
  const { token } = useSelector((state) => state.user);

  const closeSidePopup = () => {
    dispatch(sidePopupClose());
  };

  const deleteHealthCondition = async () => {
    await deleteUserCondition(token, id);
    saveAndClose();
  };

  const deleteMedication = async () => {
    await deleteUserMedication(token, id);
    saveAndClose();
  };

  const deleteRecord = () => {
    switch (type) {
      case "medicalConditionId":
        deleteHealthCondition();
        break;
      case "medicationId":
        deleteMedication();
        break;
      default:
        return;
    }
  };

  const saveAndClose = () => {
    dispatch(userHistoryAction(token));
    dispatch(sidePopupClose());
  };

  return (
    <div className="edit-medical-history-items">
      <h3 className="app-heading-1 mb-40">Edit Medical Record</h3>
      <p>
        To edit this {label}, remove the record and select a new {label}.
      </p>
      <button
        type="button"
        className="btn btn-primary col-12 btn-lg btn-cancel"
        onClick={deleteRecord}
      >
        Remove {label}
      </button>
    </div>
  );
};

export default EditMedicalHistory;
