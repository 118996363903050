import {
  getDocumentsList,
  getDocumentsListSorted,
  getLabResultsInfo,
  getDocumentsTypes,
  getVaccineTypes,
  getVaccineManufacturers,
  getLabTypes,
  getDDHLabStatus,
} from "../../requests/requestAPI";

const requestDocumentsList = () => ({
  type: "DOCUMENTS_LIST_REQUEST",
});
const receiveDocumentsList = (payload) => ({
  type: "DOCUMENTS_LIST_RECEIVE",
  payload,
});
const failureDocumentsList = (payload) => ({
  type: "DOCUMENTS_LIST_FAIL",
});

export const documentsListAction = (token) => {
  return async (dispatch) => {
    dispatch(requestDocumentsList());
    try {
      let data = await getDocumentsList(token);
      if (data.ok) {
        let documentsInfo = await data.json();
        dispatch(receiveDocumentsList(documentsInfo.value));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failureDocumentsList());
    }
  };
};

export const documentsListSortedAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestDocumentsList());
    try {
      let data = await getDocumentsListSorted(token, payload);
      if (data.ok) {
        let documentsInfo = await data.json();
        dispatch(receiveDocumentsList(documentsInfo.value));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failureDocumentsList());
    }
  };
};

const requestDocumentsTypes = () => ({
  type: "DOCUMENTS_TYPES_REQUEST",
});
const receiveDocumentsTypes = (payload) => ({
  type: "DOCUMENTS_TYPES_RECEIVE",
  payload,
});
const failureDocumentsTypes = (payload) => ({
  type: "DOCUMENTS_TYPES_FAIL",
});

export const documentsTypesAction = (token) => {
  return async (dispatch) => {
    dispatch(requestDocumentsTypes());
    try {
      let data = await getDocumentsTypes(token);
      if (data.ok) {
        let documentsInfo = await data.json();
        dispatch(receiveDocumentsTypes(documentsInfo.value));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failureDocumentsTypes());
    }
  };
};

const requestVaccineTypes = () => ({
  type: "VACCINE_TYPES_REQUEST",
});
const receiveVaccineTypes = (payload) => ({
  type: "VACCINE_TYPES_RECEIVE",
  payload,
});
const failureVaccineTypes = (payload) => ({
  type: "VACCINE_TYPES_FAIL",
});

export const vaccineTypesAction = (token) => {
  return async (dispatch) => {
    dispatch(requestVaccineTypes());
    try {
      let data = await getVaccineTypes(token);
      if (data.ok) {
        let documentsInfo = await data.json();
        dispatch(receiveVaccineTypes(documentsInfo.value));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failureVaccineTypes());
    }
  };
};

const requestVaccineManufacturers = () => ({
  type: "VACCINE_MANUFACTURERS_REQUEST",
});
const receiveVaccineManufacturers = (payload) => ({
  type: "VACCINE_MANUFACTURERS_RECEIVE",
  payload,
});
const failureVaccineManufacturers = (payload) => ({
  type: "VACCINE_MANUFACTURERS_FAIL",
});

export const vaccineManufacturersAction = (token) => {
  return async (dispatch) => {
    dispatch(requestVaccineManufacturers());
    try {
      let data = await getVaccineManufacturers(token);
      if (data.ok) {
        let documentsInfo = await data.json();
        dispatch(receiveVaccineManufacturers(documentsInfo.value));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failureVaccineManufacturers());
    }
  };
};

const requestLabTypes = () => ({
  type: "LAB_TYPES_REQUEST",
});
const receiveLabTypes = (payload) => ({
  type: "LAB_TYPES_RECEIVE",
  payload,
});
const failureLabTypes = () => ({
  type: "LAB_TYPES_FAIL",
});

export const labTypesAction = (token) => {
  return async (dispatch) => {
    dispatch(requestLabTypes());
    try {
      let data = await getLabTypes(token);
      if (data.ok) {
        let documentsInfo = await data.json();
        dispatch(receiveLabTypes(documentsInfo.value));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failureLabTypes());
    }
  };
};

const requestLabResultsInfo = () => ({
  type: "LAB_RESULTS_REQUEST",
});
const receiveLabResultsInfo = (payload) => ({
  type: "LAB_RESULTS_RECEIVE",
  payload,
});
const failureLabResultsInfo = () => ({
  type: "LAB_RESULTS_FAIL",
});

export const labResultsInfoAction = (token) => {
  return async (dispatch) => {
    dispatch(requestLabResultsInfo());
    try {
      let data = await getLabResultsInfo(token);
      if (data.ok) {
        let documentsInfo = await data.json();
        dispatch(receiveLabResultsInfo(documentsInfo.value));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failureLabResultsInfo());
    }
  };
};

export const documentsUploadFailedAction = (payload) => ({
  type: "DOCUMENTS_UPLOAD_FAILED",
  payload: payload,
});

export const documentsUploadFailedClearAction = () => ({
  type: "DOCUMENTS_UPLOAD_FAILED_CLEAR",
});

const requestDDHLabStatus = () => ({
  type: "DDH_LABSTATUS_REQUEST",
});
const receiveDDHLabStatus = (payload) => ({
  type: "DDH_LABSTATUS_RECEIVE",
  payload,
});
const failureDDHLabStatus = (payload) => ({
  type: "DDH_LABSTATUS_FAIL",
  payload,
});

export const ddhLabStatusAction = (token) => {
  return async (dispatch) => {
    dispatch(requestDDHLabStatus());
    try {
      let data = await getDDHLabStatus(token);
      if (data.ok) {
        let documentsInfo = await data.json();
        dispatch(receiveDDHLabStatus(documentsInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureDDHLabStatus(JSON.parse(ex.message)));
    }
  };
};
