import {
  postRegisterUser,
  postSelfRegisterUser,
  postOtpAuth,
  getRegData,
  postResetPass,
  postVerifyResetPass,
} from "../../requests/requestAPI";
import { loginAction } from "../../redux/actions/loginUserAction";
const requestRegister = () => ({
  type: "REGISTER_REQUEST",
});
const receiveRegister = (payload) => ({
  type: "REGISTER_RECEIVE",
  payload,
});
const failureRegister = (payload) => ({
  type: "REGISTER_FAIL",
  payload,
});

export const registerAction = (values) => {
  const payload = JSON.stringify({
    firstName: values.firstName,
    lastName: values.lastName,
    dateOfBirth: values.dob,
    mobile: values.mobileNumber,
    regCode: values.registerCode,
    email: values.email,
    gender: values.gender,
    password: values.password,
    address1: values.street,
    address2: values.registerApt,
    city: values.city,
    state: values.state,
    zip: values.zip,
  });
  return async (dispatch) => {
    dispatch(requestRegister());
    try {
      let data = await postRegisterUser(payload);
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(receiveRegister(loginInfo.value));
        await dispatch(loginAction(values.email, values.password));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureRegister(JSON.parse(ex.message)));
    }
  };
};

const requestSelfRegister = () => ({
  type: "SELF_REGISTER_REQUEST",
});
const receiveSelfRegister = (payload) => ({
  type: "SELF_REGISTER_RECEIVE",
  payload,
});
const failureSelfRegister = (payload) => ({
  type: "SELF_REGISTER_FAIL",
  payload,
});
const addPhoneEmail = (payload) => ({
  type: "ADD_PHONE_EMAIL",
  payload,
});

export const selfRegisterAction = (values, resendFlag) => {
  return async (dispatch) => {
    dispatch(requestSelfRegister());
    try {
      let data = await postSelfRegisterUser(values);
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(receiveSelfRegister({ ...loginInfo.value, resendFlag }));
        dispatch(addPhoneEmail(values));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureSelfRegister(JSON.parse(ex.message)));
    }
  };
};

const requestOtpAuth = () => ({
  type: "OTP_AUTH_REQUEST",
});
const receiveOtpAuth = (payload) => ({
  type: "OTP_AUTH_RECEIVE",
  payload,
});
const failureOtpAuth = (payload) => ({
  type: "OTP_AUTH_FAIL",
  payload,
});

export const otpAuthAction = (regOTP, regCode) => {
  const payload = JSON.stringify({
    regCodeID: regCode,
    regOTP: regOTP,
  });
  return async (dispatch) => {
    dispatch(requestOtpAuth());
    try {
      let data = await postOtpAuth(payload);
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(receiveOtpAuth(loginInfo.value));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureOtpAuth(JSON.parse(ex.message)));
    }
  };
};

const reqSelfRegData = () => ({
  type: "REG_DATA_REQUEST",
});
const recSelfRegData = (payload) => ({
  type: "REG_DATA_RECEIVE",
  payload,
});
const failSelfRegData = (payload) => ({
  type: "REG_DATA_FAIL",
  payload,
});

export const getRegistrationData = (code) => {
  return async (dispatch) => {
    dispatch(reqSelfRegData());
    try {
      let data = await getRegData(code);
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(recSelfRegData(loginInfo.value));
      } else {
        if (data.status === 400) {
          let ab = await data.json();
          throw new Error(JSON.stringify(ab));
        }
      }
    } catch (ex) {
      dispatch(failSelfRegData(JSON.parse(ex.message)));
    }
  };
};

const reqResetPass = () => ({
  type: "RESET_PASS_REQUEST",
});
const recResetPass = (payload) => ({
  type: "RESET_PASS_RECEIVE",
  payload,
});
const failResetPass = (payload) => ({
  type: "RESET_PASS_FAIL",
  payload,
});

export const postResetPassword = (payload) => {
  return async (dispatch) => {
    dispatch(reqResetPass());
    try {
      let data = await postResetPass(payload);
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(recResetPass(loginInfo.value));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failResetPass(JSON.parse(ex.message)));
    }
  };
};

const reqVerifyResetPass = () => ({
  type: "VERIFY_RESET_PASS_REQUEST",
});
const recVerifyResetPass = (payload) => ({
  type: "VERIFY_RESET_PASS_RECEIVE",
  payload,
});
const failVerifyResetPass = (payload) => ({
  type: "VERIFY_RESET_PASS_FAIL",
  payload,
});

export const postVerifyResetPassword = (payload) => {
  return async (dispatch) => {
    dispatch(reqVerifyResetPass());
    try {
      let data = await postVerifyResetPass(payload);
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(recVerifyResetPass(loginInfo.value));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failVerifyResetPass(JSON.parse(ex.message)));
    }
  };
};
