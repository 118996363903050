import { useState } from "react";
import PlacesAutoComplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

import googlePowered from "../../assets/img/png/powered-by-google.png";

const AutoComplete = ({ values, setvalues, isSubmit }) => {
  const [address, setAddress] = useState("");

  const handleSelect = async (value) => {
    const place = (await geocodeByAddress(value))[0];
    setAddress(value);

    let route;
    let street_number;
    let postal_code_suffix;
    let administrative_area_level_1_short;
    let sublocality_level_1;
    let locality;
    let neighborhood;
    let postal_code;

    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] === "street_number") {
          //console.log("street_number:" + place.address_components[i].long_name);
          street_number = place.address_components[i].long_name;
        }
        if (place.address_components[i].types[j] === "route") {
          //console.log("route:" + place.address_components[i].long_name);
          route = place.address_components[i].long_name;
        }
        if (place.address_components[i].types[j] === "postal_code") {
          //console.log("postal_code:" + place.address_components[i].long_name);
          postal_code = place.address_components[i].long_name;
        }
        if (place.address_components[i].types[j] === "locality") {
          //console.log("locality:" + place.address_components[i].long_name);
          locality = place.address_components[i].long_name;
        }
        if (
          place.address_components[i].types[j] === "administrative_area_level_1"
        ) {
          //console.log("administrative_area_level_1:" + place.address_components[i].long_name);
          administrative_area_level_1_short =
            place.address_components[i].short_name;
        }
        if (place.address_components[i].types[j] === "neighborhood") {
          //console.log("neighborhood:" + place.address_components[i].long_name);
          neighborhood = place.address_components[i].long_name;
        }
        if (place.address_components[i].types[j] === "sublocality_level_1") {
          //console.log("sublocality_level_1:" + place.address_components[i].long_name);
          sublocality_level_1 = place.address_components[i].long_name;
        }
        if (place.address_components[i].types[j] === "postal_code_suffix") {
          //console.log("postal_code_suffix:" + place.address_components[i].long_name);
          postal_code_suffix = place.address_components[i].long_name;
        }
      }
    }

    let addressValues = {
      address: "",
      street: "",
      city: "",
      state: "",
      zip: "",
    };
    const Strings = {};
    Strings.orEmpty = function (entity) {
      return entity || "";
    };
    addressValues.address = value;
    const stringStreet =
      Strings.orEmpty(street_number) + " " + Strings.orEmpty(route);
    addressValues.street = stringStreet === " " ? "" : stringStreet;
    addressValues.state = administrative_area_level_1_short;

    if (postal_code_suffix)
      addressValues.zip = postal_code + "-" + postal_code_suffix;
    else addressValues.zip = postal_code;

    addressValues.city = locality;

    if (!locality) {
      if (sublocality_level_1) {
        addressValues.city = sublocality_level_1;
      }
    }

    if (neighborhood && sublocality_level_1) {
      addressValues.city = sublocality_level_1;
    }

    if (neighborhood && locality) {
      addressValues.city = neighborhood;
    }

    if (locality && sublocality_level_1) {
      addressValues.city = locality;
    }

    setvalues({
      ...values,
      address: addressValues.address,
      street: addressValues.street,
      city: addressValues.city,
      state: addressValues.state,
      zip: addressValues.zip,
    });
  };

  const searchOptions = {
    componentRestrictions: { country: ["us"] },
  };

  return (
    <>
      <PlacesAutoComplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="autocomplete-wrapper">
            <input
              {...getInputProps({
                type: "text",
                maxLength: "250",
                placeholder: "Start typing your home address",
                className: `form-control form-control-lg`,
              })}
            />

            <div
              className={`autocomplete-dropdown ${
                suggestions[0] ? "dropped" : null
              }`}
            >
              {loading ? <div className="loader">...loading</div> : null}

              {suggestions.map((suggestion, index) => {
                return (
                  <div
                    key={index}
                    className={`suggestion ${
                      suggestion.active ? "suggestion-active" : null
                    }`}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
              <div
                className={`suggestion-image ${
                  suggestions[0] ? "suggestion-image-active" : null
                }`}
              >
                <img src={googlePowered} alt="powered by Google" />
              </div>
            </div>
          </div>
        )}
      </PlacesAutoComplete>
    </>
  );
};

export default AutoComplete;
