import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getExploreList } from "../../redux/actions/exploreActions.js";
import Spinner from "../common/Spinner.js";
import Explore from "./Explore.js";
import parse from "html-react-parser";

const Post = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageNumber, sliderId } = useParams();
  const { slidersList, isExploreListLoading } = useSelector(
    (state) => state.explore
  );
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    const payload = {
      isActive: true,
      pageSize: 9,
      pageNumber: pageNumber,
    };
    if (!slidersList[0] && !isExploreListLoading)
      dispatch(getExploreList(token, payload));
  }, [token, dispatch, slidersList, isExploreListLoading, pageNumber]);

  let index;

  if (slidersList[0]) {
    for (let i = 0; i < slidersList.length; i++) {
      if (slidersList[i]["sliderId"].toString() === sliderId) {
        index = i;
        break;
      }
    }
  }

  return (
    <div className="app-blog-detail">
      {isExploreListLoading && <Spinner />}
      {slidersList[0] && !isExploreListLoading && (
        <>
          <div className="abd-featured-img">
            <figure>
              <img
                src={slidersList[index].image}
                alt=""
                className="img-responsive"
              />
            </figure>
            <button
              onClick={() => navigate(-1)}
              className="blog-back unset-btn"
            >
              Back
            </button>
            {/* <a href="#" className="blog-forward">Forward</a> */}
          </div>

          <div className="postdata">
            <h1>{slidersList[index].title}</h1>
            {slidersList[index].author && slidersList[index].publishedOn ? (
              <span class="byline">
                {slidersList[index].author}
                <span class="middot">&middot;</span>
                {slidersList[index].publishedOn}
              </span>
            ) : (
              ""
            )}
            {parse(slidersList[index].webDescription)}
          </div>
          <Explore />
        </>
      )}
    </div>
  );
};

export default Post;
