import ChecklistCheckIcon from "../../../assets/img/svg/health-checklist-icons/checklist-check-icon.svg";
import ChecklistCrossIcon from "../../../assets/img/svg/health-checklist-icons/checklist-cross-icon.svg";
import ChecklistQuestionIcon from "../../../assets/img/svg/health-checklist-icons/checklist-question-icon.svg";
import ChecklistCheckIconFilled from "../../../assets/img/svg/health-checklist-icons/checklist-check-icon-filled.svg";
import ChecklistCrossIconFilled from "../../../assets/img/svg/health-checklist-icons/checklist-cross-icon-filled.svg";
import ChecklistQuestionIconFilled from "../../../assets/img/svg/health-checklist-icons/checklist-question-icon-filled.svg";
import InfoIcon from "../../../assets/img/svg/info.svg";

const ChecklistRow = ({ recommendation, handleClick }) => {
  const { myHFTitle, userResponse, accessibleVersion } = recommendation;

  return (
    <div className="checklist-row">
      <div className="checklist-row-left">
        <a href={accessibleVersion} target="_blank" rel="noreferrer">
          <img src={InfoIcon} alt="Get more info" />
        </a>
        <span>{myHFTitle}</span>
      </div>
      <div className="checklist-row-right">
        <img
          data-type={"COMPLETED"}
          src={
            userResponse === "COMPLETED"
              ? ChecklistCheckIconFilled
              : ChecklistCheckIcon
          }
          alt="I have done this"
          onClick={(e) => handleClick(e, recommendation)}
        />
        <img
          data-type={"NOTCOMPLETED"}
          src={
            userResponse === "NOTCOMPLETED"
              ? ChecklistCrossIconFilled
              : ChecklistCrossIcon
          }
          alt="I have not done this"
          onClick={(e) => handleClick(e, recommendation)}
        />
        <img
          data-type={"NOTTOANSWER"}
          src={
            userResponse === "NOTTOANSWER"
              ? ChecklistQuestionIconFilled
              : ChecklistQuestionIcon
          }
          alt="This is not applicable"
          onClick={(e) => handleClick(e, recommendation)}
        />
      </div>
    </div>
  );
};

export default ChecklistRow;
