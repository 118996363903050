import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PersonalInformation from "./PersonalInformation";
import InsuranceProvider from "./InsuranceProvider";
import {
  getFamilyMemberAction,
  getUserProfileAction,
  getUserPharmacyAction,
  getStripeAction,
  getProfileServiceAction
} from "../../redux/actions/profileAction";
import { useNavigate } from "react-router-dom";
import helpIcon from "../../assets/img/svg/help-center-icon.svg";
import logoutIcon from "../../assets/img/svg/logout-icon.svg";
import membership from "../../assets/img/svg/membership.svg";
import passwordIcon from "../../assets/img/svg/profile-icons/change-password-icon.svg";
import { getInsuranceProviderAction } from "../../redux/actions/insuranceProviderAction";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions.js";
import Dependants from "./Dependants";
import Pharmacy from "./Pharmacy";
import { logoutUser } from "../../redux/actions/logoutUser";
import careCostIcon from "../../assets/img/svg/profile-icons/carecost-icon.svg";
import logoIcon from "../../assets/img/png/processing.png"

const MyProfile = ({ labels = {}, handleLogout, cookies }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfileInfo = useSelector((state) => state.userProfile);
  const {
    token,
    isDependent,
    features: accessLevel,
  } = useSelector((state) => state.user);

  function isShow(moduleKey) {
    const selectModule = accessLevel.find(
      (next) => next.type === "MODULE" && next.key === moduleKey
    );
    if (
      (selectModule.action === "ENABLE" || selectModule.action === "SHOW") &&
      !isDependent
    ) {
      return true;
    }
    return false;
  }

  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "EXPECTEDSERVICECOST"
  );
  const isEnable = selectModule.action === "ENABLE";

  useEffect(() => {
    if (userProfileInfo.is404Error === 401) {
      cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [userProfileInfo.is404Error, dispatch, cookies]);


  useEffect(() => {
    if (token) {
      dispatch(getUserProfileAction(token));
      //dispatch(getInsuranceProviderAction(token));
      dispatch(getFamilyMemberAction(token));
      dispatch(getStripeAction(token));
      //dispatch(getUserPharmacyAction(token));
      dispatch(getProfileServiceAction(token));
    }
  }, [dispatch, token]);

  const openSideBarPassword = () => {
    const payload = {
      component: 7,
    };
    dispatch(sidePopupOpen(payload));
  };
  const openSideBarCareCost = () => {
    const payload = {
      component: 14,
    };
    dispatch(sidePopupOpen(payload));
  };

  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left">
          <button
            className="back-t-screen-cta unset-btn"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <h2>{labels.LBL_MYPROFILE}</h2>
        </div>
        <div className="app-screen-title-right"></div>
      </div>

      <div className="row gt-4">
        <PersonalInformation
          userProfile={userProfileInfo.userProfile}
          labels={labels}
        />
        <InsuranceProvider labels={labels} />
      </div>
      <div className="row gt-4 reverse-row">
        {isShow("DEPENDENT") &&
          userProfileInfo.familyData &&
          userProfileInfo.familyData.length ? (
          <Dependants labels={labels} />
        ) : (
          ""
        )}
        <Pharmacy userProfile={userProfileInfo.userProfile} labels={labels} />
      </div>
      <div className="row gt-4 mb-40">
        <div className="col-12 col-xl-8">
          <div className="profile-in-menu">
            <ul>
              {(userProfileInfo.stripeURL && userProfileInfo.stripeURL.length > 0) &&
                < li >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={userProfileInfo.stripeURL}
                  >
                    <figure>
                      <img
                        src={membership}
                        alt="membership & billing"
                        className="img-responsive"
                      />
                    </figure>
                    <span>{labels.LBL_PROFILE_STRIPE}</span>
                  </a>
                </li>
              }
              {(isEnable) &&
                <li>
                  <button className="unset-btn" onClick={openSideBarCareCost}>
                    <figure>
                      <img
                        src={careCostIcon}
                        alt="Expected care cost"
                        className="img-responsive"
                      />
                    </figure>
                    <span>{labels.LBL_TITLE_EXPECTED_CARE_COST}</span>
                  </button>
                </li>}
              <li>
                <button className="unset-btn" onClick={openSideBarPassword}>
                  <figure>
                    <img
                      src={passwordIcon}
                      alt="Change password"
                      className="img-responsive"
                    />
                  </figure>
                  <span>{labels.LBL_PROFILE_CHANGE_PWD}</span>
                </button>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://help.radish.health/">
                  <figure>
                    <img
                      src={helpIcon}
                      alt="Go to Radish Help Center"
                      className="img-responsive"
                    />
                  </figure>
                  <span>{labels.LBL_PROFILE_HELP_CENTER}</span>
                </a>
              </li>
              {(userProfileInfo.profileService) &&
                userProfileInfo.profileService.map((item) => {                  
                  if (item.linkType.toLowerCase() === "uri") {
                    const obj = JSON.parse(item.deeplink)
                    return (<li>
                      <a rel="noreferrer"
                        href={obj.details.web.route}>
                        <figure>
                          <img
                            src={logoIcon}
                            alt={item.title}
                            className="img-responsive"
                            style={{ height: 24, width: 24 }}
                          />
                        </figure>
                        <span>{item.title}</span>
                      </a>
                    </li>);
                  } else if (item.linkType.toLowerCase() === "url") {
                    const obj = item.link
                    return (<li>
                      <a rel="noreferrer"
                        target="_blank"
                        href={obj}>
                        <figure>
                          <img
                            src={logoIcon}
                            alt={item.title}
                            className="img-responsive"
                            style={{ height: 24, width: 24 }}
                          />
                        </figure>
                        <span>{item.title}</span>
                      </a>
                    </li>);
                  }
                }
                )
              }
              <li>
                <button className="unset-btn" onClick={handleLogout}>
                  <figure>
                    <img
                      src={logoutIcon}
                      alt="Log out"
                      className="img-responsive"
                    />
                  </figure>
                  <span>{labels.LBL_PROFILE_LOGOUT}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyProfile;
