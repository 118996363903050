import { Link } from "react-router-dom";
import PrivacyPractices from "./PrivacyPractices.js";

const Consent = () => {
  return (
    <>
      <div className="col-12">
        <div className="policy-heading">
          <h4>
            <strong>
              CONSENT TO MEDICAL AND TELEHEALTH CARE FOR INDIVIDUALS OVER 18
            </strong>
          </h4>
          <h4>
            <strong>ACKNOWLEDGEMENT OF NOTICE OF PRIVACY PRACTICES</strong>
          </h4>
          <p>
            Radish Health Medical Group, PLLC<br />
            Alphabet City Medicine, P.C. (NY)<br />
            Alphabet City Medicine, P.C. (NJ)<br />
            Alphabet City Medicine of California P.C. (CA)
          </p>
          <p class="small">For medical license information{" "}
            <strong>
              <a
                href="https://go.radish.health/providerlicenses"
                target="_blank"
                rel="noreferrer nofollow"
              >click here</a>
            </strong>
            .</p>
          <br />
        </div>
        <div className="policy-wrap">
          <p>The purpose of this form is to provide you with information about telehealth and to obtain
            your informed consent for you to participate in telehealth as part of your care and treatment.
            If you so consent, Radish Health Medical Group, PLLC  (including its related and/or affiliated
            entities listed above, “the Practice”) will use telehealth technology in connection with your visits.
            Your physician or other healthcare provider will be introduced to you at the beginning
            of your scheduled telehealth visit.</p>
          <ul className="policy-bulleted-list">
            <li>
              I understand that I can obtain health services from the Practice that may include onsite, in-person physical examinations, tests, vaccinations, and prescriptions for medications by a physician or other healthcare personnel under such physician’s supervision.
            </li>
            <li>
              I understand that I can also obtain medical consultations from the Practice via telehealth through use of the Radish Health Inc. (“Radish”) technology platform. I understand that a telehealth consultation will not be the same as an in-person visit because I will not be in the same physical location as my care provider. Telehealth allows the providers to establish a provider-patient relationship much as they would during a traditional face-to-face appointment.
            </li>
            <li>
              I understand that the delivery of health care services through telehealth services is an evolving field and that the use of telehealth in my medical care and treatment may include interaction through and with the use of the internet, audio communications, review of images and medical history submitted by me, as well as uses of technology not specifically described in this consent form.
            </li>
            <li>
              I understand there are potential risks to using telehealth technology, including interruptions, unauthorized access, delays in medical evaluation and treatment arising from technical difficulties.
            </li>
            <li>
              INDEMNIFICATION. I AGREE TO INDEMNIFY AND HOLD HARMLESS THE PRACTICE, ITS EMPLOYEES, AGENTS, DIRECTORS, AFFILIATES, MEMBERS, MANAGERS, SHAREHOLDERS, OFFICERS, REPRESENTATIVES, ASSIGNS, PARENTS, PREDECESSORS, AND SUCCESSORS FROM AND AGAINST ANY AND ALL LOSS, DAMAGE, EXPENSE, LIABILITY, CLAIM, OR DEMAND WHATSOEVER, ARISING OUT OF OR RELATED TO ANY FAILURE OF TECHNOLOGY OR EQUIPMENT IN CONNECTION WITH THE PROVISION OF TELEMEDICINE OR TELEHEALTH SERVICES, WHETHER OR NOT ANY SUCH LOSS, DAMAGE, EXPENSE, LIABILITY, CLAIM, OR DEMAND ARISES FROM OR RELATES TO THE PRACTICE’S NEGLIGENCE.
            </li>
            <li>
              I understand that my care provider may determine in his or her sole discretion that my condition is not suitable for treatment using telehealth, based on the condition being diagnosed and/or treated. and that I may need to seek medical care and treatment from an alternative source. My provider may refer me to a local healthcare provider if they determine an in-person visit will be in my best interest. I also understand that I can choose to discontinue the telehealth consultation at any time.
            </li>
            <li>
              The benefits of telehealth may include increased efficiency in evaluations, diagnoses, consultations, and treatment. I understand that while telehealth services may provide potential benefits to me, as with any medical care service, no such benefits or specific results can be guaranteed.
            </li>
            <li>
              <strong>EMERGENCY SITUATIONS.</strong> If there is an urgent situation involving, if I have an adverse reaction to treatment, or if a technical failure prevents me from communication with my providers through the telehealth platform, I understand I may contact the Practice using the contact information below. If the contacts listed below are unavailable, I understand I will need to seek urgent or emergency care.
              <br /><br />
              <p>
                Email: <strong>
                  <a href="mailto:help@radish.health">help@radish.health</a>
                </strong>
                <br />
                Phone: <strong>
                  <a href="tel:+18334723474">833-472-3474</a>
                </strong> (Monday-Friday, 7am-6pm EST)
              </p>
            </li>
            <li>
              I understand that in the event of an emergency, I should not contact the Practice but should immediately call “911” and request emergency care assistance.
            </li>
            <li>
              I understand that in addition to my care provider other staff members of the Practice may be present during my consultations. I further understand that I will be informed of their presence, and I have a right to exclude anyone from being present, if I so choose.
            </li>
            <li>
              The Practice uses network and software security protocols to protect the confidentiality of my personally identifiable information (including, for example, personal financial data) and my medical information.
              These protocols are designed to safeguard the data and to ensure its integrity against corruption. Personal information that identifies me will not be disclosed to any third party without my consent,
              except as authorized by law for the purposes of consultation, treatment, payment/billing, and certain administrative purposes, or as otherwise stated in the Practice’s Notice of Privacy Practices set forth in Exhibit A below and also available at{" "}
              <Link to="../npp">
                <strong>https://patient.radish.health/policies/npp</strong>
              </Link>
            </li>
            <li>
              I understand that I will be informed of the cost of my telehealth visit and may be billed separately for my medical treatment and use of the Radish platform (if I receive a telehealth consultation).
            </li>
            <li>
              I understand that I may withhold or withdraw this consent at any time by providing the Practice with such notice. Otherwise, this consent will be considered renewed and ongoing upon each onsite visit or telemedicine consultation provided by the Practice and its affiliated professional entities and their health care providers.
            </li>
            <li>
              BOARD COMPLAINTS. I understand that if I believe a licensed physician or other healthcare provider, or unlicensed person’s behavior or activities may cause harm to me or another patient or may be illegal, I can file a complaint with the applicable licensing board in my state. Contact information for the state medical boards is available here:{" "}
              <strong>
                <a
                  href="https://www.fsmb.org/contact-a-state-medical-board/"
                  target="_blank"
                  rel="noreferrer nofollow"
                >https://www.fsmb.org/contact-a-state-medical-board</a>
              </strong>.
            </li>
          </ul>
          <p>
            I have had a direct conversation with my care provider, during which I had an opportunity to ask questions regarding my medical treatment, including physical examinations and telehealth consultations, as applicable. My questions have been answered and the risks, benefits and any practical alternatives have been discussed with me in a language which I understand.
          </p>
          <p>
            <strong>
              By signing this Consent to Medical and Telehealth Care, I certify
              that:
            </strong>
          </p>
          <ul className="policy-bulleted-list">
            <li>
              <strong>I am at least eighteen (18) years of age or older</strong>
            </li>
            <li>
              <strong>
                I am a resident of the state I have listed on my patient intake forms and/or patient profile. I understand that I am obligated to notify the Practice in writing in the event I have relocated to another state so that my professional care may be transferred to a healthcare provider licensed in my new state.
              </strong>
            </li>
            <li>
              <strong>
                I understand that the Practice and its providers have a financial relationship with Radish Health, Inc., which provides management services to the Practice, and that I am free to obtain an evaluation and/or treatment elsewhere.
              </strong>
            </li>
            <li>
              <strong>
                I have carefully read and acknowledge the terms and conditions stated in the Notice of Privacy Practices set forth in Exhibit A below and also available at{" "}
                <Link to="../npp">
                https://patient.radish.health/policies/npp
              </Link>.
              </strong>
            </li>
            <li>
              <strong>
              I agree that the Practice may communicate with me, including about my personally identifiable information and/or medical information about me, 
              using the following methods and contact information I have associated with my user account for the website and/or mobile application operated by 
              Radish: (i) by sending me an email; (ii) by leaving me a voicemail message; and (iii) by texting me. I understand that if I would like to opt-out of 
              being contacted by any of these methods, I may send an email to <a href="mailto:help@radish.health">help@radish.health</a> requesting the opt-out. I agree to the above methods of unencrypted 
              communication for my own convenience, and I accept all risks associated with them (including, without limitation, risks of improper exposure of my 
              personally identifiable information and/or medical information about me).
              </strong>
            </li>
            <li>
              <strong>
                I understand that I am free to obtain prescriptions from any pharmacy of my choice.
              </strong>
            </li>
            <li>
              <strong>
                I have been provided the opportunity to ask questions in regard to my medical treatment and that my questions have been satisfactorily answered.
              </strong>
            </li>
          </ul>
          <br />
          <p>
            FOR PURPOSES OF THIS INFORMED CONSENT, I UNDERSTAND AND AGREE THAT MY ACT OF CLICKING “ACCEPT” SHALL CONSTITUTE AND IS MY ELECTRONIC SIGNATURE.
          </p>
          <br />
          <h4>
            <strong>FOR INDIVIDUALS UNDER 18:</strong>
          </h4>
          <p>
            To the extent that I am under 18 years of age and do not otherwise have the legal authority
            to consent to receive medical treatment, I acknowledge and agree that the above consent does
            not apply to me. I further acknowledge and agree that The Practice will separately provide the
            appropriate consent to treatment for individuals under 18, which I agree to share with my parent/legal
            guardian for his/her/their review. I further acknowledge and agree that The Practice may schedule my
            appointment but will not provide any medical services to me prior to obtaining a completed
            consent that is signed by my parent/legal guardian.
          </p>
          <p className="small">
            Effective as of 12/20/2018 - updated 03/01/2023
          </p>
        </div>
        <hr className="divider" />
      </div>
      <PrivacyPractices />
    </>
  );
};

export default Consent;
