import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import parse from "html-react-parser";

import { getDHAWVAction } from "../redux/actions/dhaActions.js";
import Spinner from "../components/common/Spinner.js";
// import AnnualBloodWorkWebview from "./DHA/AnnualBloodWorkWebview";
import TalkToPsychologistWebview from "./DHA/TalkToPsychologistWebview";
// import MensHealthMonthWebview from "./DHA/MensHealthMonthWebview";

import ThumbsUpIcon from "../assets/img/svg/thumbs-up.svg";
import RadishIcon from "../assets/img/svg/radish-icon.svg";

const DHAWebView = () => {
  let dispatch = useDispatch();
  let location = useLocation();
  const { dhaWVInfo } = useSelector((state) => state.dha);

  useEffect(() => {
    let index = location.search.indexOf("=") + 1;
    let userUID = location.search.slice(index, location.search.length);
    dispatch(getDHAWVAction(userUID));
    console.log(location);
  }, [dispatch, location.search]);

  function renderDHA() {
    switch (dhaWVInfo.InternalStatusCode) {
      case 0:
        return <Navigate to="/dashboard" />;
      case 1:
        return <ClearedForWork dhaWVInfo={dhaWVInfo} />;
      case 2:
        return <Navigate to="/dashboard" />;
      default:
        return <CareRecommended dhaWVInfo={dhaWVInfo} />;
    }
  }

  return <>{dhaWVInfo.UserUID ? renderDHA() : <Spinner />}</>;
};

const ClearedForWork = ({ dhaWVInfo }) => {
  return (
    <div className="cleared-for-work" style={{ paddingTop: 0 }}>
      <div className="cfw-card" style={{ borderRadius: 0, marginBottom: 0 }}>
        <div className="cfw-card-top greenbg" style={{ borderRadius: 0 }}>
          <figure>
            <imgb
              src={ThumbsUpIcon}
              alt="thumbs-up"
              className="img-responsive"
            />
          </figure>
          <h3>{dhaWVInfo.PublicStatus}</h3>
          <span className="cfw-span-13">{dhaWVInfo.dateFriendly}</span>
        </div>
        {/* <AnnualBloodWorkWebview dhaWVInfo={dhaWVInfo} /> */}
        <TalkToPsychologistWebview dhaWVInfo={dhaWVInfo} />
        {/* <MensHealthMonthWebview dhaWVInfo={dhaWVInfo} /> */}
        <div className="cfw-card-bottom">
          <figure>
            <img
              src={dhaWVInfo.qrcode}
              alt="barcode"
              className="img-responsive"
            />
          </figure>
          <span className="number">{dhaWVInfo.qrid}</span>
          <h3>{dhaWVInfo.FirstName + " " + dhaWVInfo.LastName}</h3>
          <span className="cfw-span-13">({dhaWVInfo.Company})</span>
        </div>
      </div>

      {/* <div className="cfw-footer flex-center">
                <div className="appointment-btn-wrap">
                    <button className="btn btn-primary" onClick={handleClick}>{dhaWVInfo.button}</button>
                </div>
                {parse(dhaWVInfo.msg)}
            </div> */}
    </div>
  );
};

const CareRecommended = ({ dhaWVInfo }) => {
  return (
    <>
      <div className="cleared-for-work" style={{ paddingTop: 0 }}>
        <div className="cfw-card" style={{ borderRadius: 0, marginBottom: 0 }}>
          <div className="cfw-card-top pinkbg" style={{ borderRadius: 0 }}>
            <figure>
              <img
                src={RadishIcon}
                alt="radish-icon"
                className="img-responsive"
              />
            </figure>
            <h3>{dhaWVInfo.PublicStatus}</h3>
            <span className="cfw-span-13">{dhaWVInfo.dateFriendly}</span>
          </div>
          <div className="cfw-card-bottom">
            <h3>{dhaWVInfo.FirstName + " " + dhaWVInfo.LastName}</h3>
            <span className="cfw-span-13 mb-40">({dhaWVInfo.Company})</span>
            <div className="cfw-card-info flex-center">
              <div className="appointment-btn-wrap mb-40">
                {dhaWVInfo.buttonLinkDetails.AppointmentTypeID &&
                  dhaWVInfo.buttonLinkDetails.ParentKey && (
                    <a
                      href={`radishhealth://?pageName=${dhaWVInfo.buttonLink}&AppointmentTypeID=${dhaWVInfo.buttonLinkDetails.AppointmentTypeID}&ParentKey=${dhaWVInfo.buttonLinkDetails.ParentKey}`}
                      className="btn btn-primary"
                    >
                      {dhaWVInfo.button}
                    </a>
                  )}
                {dhaWVInfo.buttonLinkDetails.documentTypeID && (
                  <a
                    href={`radishhealth://?pageName=${dhaWVInfo.buttonLink}&documentTypeID=${dhaWVInfo.buttonLinkDetails.documentTypeID}`}
                    className="btn btn-primary"
                  >
                    {dhaWVInfo.button}
                  </a>
                )}
                {Object.keys(dhaWVInfo.buttonLinkDetails).length === 0 && (
                  <a
                    href={`radishhealth://?pageName=${dhaWVInfo.buttonLink}`}
                    className="btn btn-primary"
                  >
                    {dhaWVInfo.button}
                  </a>
                )}
              </div>
              {dhaWVInfo.msg && parse(dhaWVInfo.msg)}
            </div>
          </div>
        </div>

        {/* <div className="cfw-footer flex-left">
                    <p>Please enter/select the full name of everyone you have been in contact with (both colleagues and non-colleagues) since two (2) days before<br />
                        the onset of your first symptom. This data will stay private, we only use anonymized data to ensure your workplace and colleagues are safe and healthy.<br />
                        Based on the information you provided, we believe that you may be experiencing or are likely to develop an illness, which may be communicable and should contact a medical provider for<br />
                        medical assistance. You can use Radish Health's contact-tracing feature to help prevent the spread of such illness and direct others to seek medical attention if necessary.<br />
                        As explained in our Terms of Use, the Radish Health services are not medical advice, and are not intended to be a substitute for professional diagnosis, treatment or public health information.<br />
                        Please contact a healthcare professional if you have any concerns about your health. To the extent you receive telehealth services through our affiliates, you acknowledge and agree that we<br />
                        have the right to share de-identified information back to your employer.</p>

                    <div className="form-check mb-25">
                        <input className="form-check-input" type="checkbox" value="" id="i-consent" />
                        <label className="form-check-label" htmlFor="i-consent">
                            Check this box to anonymously to inform other Radish Health users who we identify as having been in recent proximity to you that they may have been exposed to an illness. This notification will not include any identifiable information.
                        </label>
                    </div>

                    <div className="care-inputs-wrap">
                        <div className="inputwrap">
                            <input type="text" name="" id="" className="form-control" placeholder="Select/Enter one or more name" />
                        </div>
                        <div className="btnwrap">
                            <button type="button" name="" className="btn btn-primary btn-lg col-12">Save</button>
                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default DHAWebView;
