import SpouseIcon from "../../assets/img/svg/profile-icons/spouse-icon.svg";
import ChildIcon from "../../assets/img/svg/profile-icons/child-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions";

const Dependants = ({ labels = {} }) => {
  const userProfileInfo = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  function openSidePopup(pass) {
    const payload = {
      component: 8,
      content: {
        title: "EDIT",
        isEdit: true,
      },
    };
    dispatch({ type: "SET_FAMILY_EDIT", payload: pass });
    dispatch(sidePopupOpen(payload));
  }

  function renderEmail(email) {
    if (email.length > 25) return email.slice(0, 25) + "...";
    else return email;
  }

  return (
    <div className="col-12 col-xl-8 mb-40">
      <h2 className="app-heading-1">{labels.LBL_DEPENDANT_FAMILY_INFO}</h2>

      <div className="row gt-4">
        {userProfileInfo.familyData.map((pass, index) => {
          return (
            <div
              style={{ marginBottom: "24px" }}
              key={index}
              className="col-12 col-lg-6 col-xl-6 family-card-outer"
            >
              <div className="family-card">
                <div className="family-card-top">
                  <div className="fct-inner">
                    <span className="name">{`${pass.firstName} ${pass.lastName}`}</span>
                    <span
                      className={`status ${
                        pass.registrationText === "Registered" &&
                        "register-margin"
                      }`}
                    >
                      {pass.registrationText}
                    </span>
                    {pass.registrationText !== "Registered" ? (
                      <button
                        onClick={() => openSidePopup(pass, true)}
                        className="edit-dependant-cta"
                      >
                        {labels.LBL_DEPENDANT_EDIT}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="name-ucase">
                    {pass.firstName[0] + pass.lastName[0]}
                  </div>
                </div>
                <div className="family-card-bottom">
                  <div className="register-info">
                    <span className="email">{renderEmail(pass.email)}</span>
                    <span className="register-date">
                      {pass.registrationText === "Registered"
                        ? `Registered ${pass.registrationDate}`
                        : `Invited ${pass.invitationDate}`}
                    </span>
                  </div>
                  <div className="relation">
                    <span className="relation-title">{pass.relation}</span>
                    <figure>
                      <img
                        src={
                          pass.relation === "Spouse" ? SpouseIcon : ChildIcon
                        }
                        alt="Spouse"
                        className="img-responsive"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dependants;
