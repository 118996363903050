const myTodoState = {};

export const myToDoReducer = (state = myTodoState, action) => {
  if (action.type === "GET_MY_TODO_REQUEST") {
    return {
      ...state,
      isMyToDoLoading: true,
    };
  }

  if (action.type === "GET_MY_TODO_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isMyToDoLoading: false,
      myToDoInfo: { ...payload },
    };
  }

  if (action.type === "GET_MY_TODO_FAIL") {
    return {
      ...state,
      isMyToDoLoading: false,
    };
  }

  if (action.type === "GET_DAILYWORKPASS_REQUEST") {
    return {
      ...state,
      isDailyWorkPassLoading: true,
    };
  }

  if (action.type === "GET_DAILYWORKPASS_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isDailyWorkPassLoading: false,
      dailyWorkPassInfo: { ...payload },
    };
  }

  if (action.type === "GET_DAILYWORKPASS_FAIL") {
    return {
      ...state,
      isDailyWorkPassLoading: false,
    };
  }

  return state;
};
