import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import RadSlider from "./RadSlider.js";
import Spinner from "../common/Spinner.js";
import DashboardLoading from "./DashboardLoading.js";

import { getBookAppointmentTypeData } from "../../redux/actions/bookAppointmentActions";
import { logoutUser } from "../../redux/actions/logoutUser";
import { modalOpen } from "../../redux/actions/modalAction";

import GeneralConcernIcon from "../../assets/img/svg/appt-type-icons/general-concern-icon.svg";
import FluShotIcon from "../../assets/img/svg/appt-type-icons/flu-shot-icon.svg";
import AnnualPhysicalIcon from "../../assets/img/svg/appt-type-icons/annual-physical-icon.svg";
import CovidTestIcon from "../../assets/img/svg/appt-type-icons/covid-test-icon.svg";
import CovidBoosterShotIcon from "../../assets/img/svg/appt-type-icons/covid-booster-shot-icon.svg";
import MentalHealthIcon from "../../assets/img/svg/appt-type-icons/mental-health-icon.svg";
import NutritionCounselingIcon from "../../assets/img/svg/appt-type-icons/nutrition-counseling-icon.svg";
import FollowUpIcon from "../../assets/img/svg/appt-type-icons/follow-up-icon.svg";
import BloodWork from "../../assets/img/svg/appt-type-icons/blood-work-icon.svg";
import DefaultApptIcon from "../../assets/img/svg/appt-type-icons/default-appt-icon.svg";
import InfoIcon from "../../assets/img/svg/info.svg";
import { postEventTrack } from "../../requests/requestAPI";

const imgData = {
  "general-concern-icon.svg": GeneralConcernIcon,
  "annual-physical-icon.svg": AnnualPhysicalIcon,
  "covid-test-icon.svg": CovidTestIcon,
  "covid-booster-shot-icon.svg": CovidBoosterShotIcon,
  "mental-health-icon.svg": MentalHealthIcon,
  "nutrition-counseling-icon.svg": NutritionCounselingIcon,
  "flu-shot-icon.svg": FluShotIcon,
  "follow-up-icon.svg": FollowUpIcon,
  "BloodWorks.svg": BloodWork,
  "default-appt-icon.svg": DefaultApptIcon,
};

const MyHealthSlide = ({ state, customClick, onIconClick }) => {
  let dispatch = useDispatch();
  let location = useLocation();
  const accessLevel = useSelector((state) => state.user.features);
  const { token } = useSelector((state) => state.user);

  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "MODULE-MEDICAL-SERVICE"
  );
  const isDisableAll = selectModule.action === "DISABLE";

  const onLinkClick = (e, date) => {
    if (date && location.pathname === "/dashboard/bookworkplace") {
      dispatch({ type: "STAMP_WORKPLACE_DATE", payload: date });
    }
    if (!isDisableAll) {
      if (state.action == "ENABLE") {
        dispatch({ type: "SELECT_APP_TYPE", payload: state.parentKey });
      }
      else {
        e.preventDefault();
        dispatch(
          modalOpen({
            component: 3,
            content: {
              moduleLevelMsg: state.actionMessage,
              title: "BOOKING NOT AVAILABLE"
            },
          })
        );
        var eventJSON = { 'ParentKey': state.parentKey }
        postEventTrack(token, {
          eventName: "DISABLEAPPOINTMENTCLICK",
          eventDetails: JSON.stringify(eventJSON),
          source: "WEB",
        });
      }
    }
     else {
      e.preventDefault();
      customClick();
    }
  }

return (
  <div className="appointment-col">
    <figure className="fig-app-type">
      <img
        onClick={() => onIconClick(state)}
        src={InfoIcon}
        alt={state.shortDescription}
      />
    </figure>
    <Link
      className="appointment-item"
      onClick={(e) => onLinkClick(e, state.onsiteDate)}
      to={"../bookappointment"}
    >
      <figure>
        <img
          src={imgData[state.img]}
          alt="general-concern-icon"
          className="img-responsive"
        />
      </figure>
      <span>{state.title}</span>
    </Link>
  </div>
);
};

const AppointmentTypes = ({ date, labels = {}, ...props }) => {
  let location = useLocation();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const {
    appointmentData,
    appointmentWorkplaceData,
    isAppointmentTypesLoading,
    is404Error,
  } = useSelector((state) => state.dashboard);
  const [oppData, setData] = useState([]);
  const { token } = userInfo || {};
  const accessLevel = useSelector((state) => state.user.features);
  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "MODULE-MEDICAL-SERVICE"
  );
  const isDisableAll = selectModule.action === "DISABLE";
  const moduleLevelMsg = selectModule.featureMessage;

  const settings = {
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    touchThreshold: 10,
    speed: 300,
    arrows: true,
    dots: false,
    className: "appointment-row appointment-ctas-slider",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  useEffect(() => {
    if (token) {
      if (
        !appointmentData.length &&
        !isAppointmentTypesLoading &&
        location.pathname !== "/dashboard/bookworkplace"
      ) {
        dispatch(getBookAppointmentTypeData(token));
      }
      if (
        !appointmentWorkplaceData.length &&
        !isAppointmentTypesLoading &&
        location.pathname === "/dashboard/bookworkplace"
      ) {
        dispatch(getBookAppointmentTypeData(token, "onsite"));
      }
    }
  }, [
    dispatch,
    token,
    location.pathname,
    isAppointmentTypesLoading,
    appointmentData.length,
    appointmentWorkplaceData.length,
  ]);

  useEffect(() => {
    if (is404Error && is404Error === 401) {
      props.cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [is404Error, dispatch, props]);

  useEffect(() => {
    if (appointmentData && location.pathname !== "/dashboard/bookworkplace") {
      const imageData = appointmentData
        .filter(({ isShowOnHomePage }) => isShowOnHomePage === "True")
        .map((data) => {
          return {
            title: data.parentDisplayText,
            img: data.webCssClass,
            parentKey: data.parentKey,
            onsiteDate: data.onsiteDate,
            description: data.description,
            action: data.action,
            actionMessage: data.actionMessage
          };
        });
      setData(imageData);
    }
  }, [appointmentData, location.pathname]);

  useEffect(() => {
    if (
      appointmentWorkplaceData &&
      location.pathname === "/dashboard/bookworkplace"
    ) {
      const imageData = appointmentWorkplaceData
        .filter(({ isShowOnHomePage }) => isShowOnHomePage === "True")
        .map((data) => {
          return {
            title: data.parentDisplayText,
            img: data.webCssClass,
            parentKey: data.parentKey,
            onsiteDate: data.onsiteDate,
            description: data.description,
            action: data.action,
            actionMessage: data.actionMessage
          };
        });
      setData(imageData);
    }
  }, [appointmentWorkplaceData, location.pathname]);

  const customClick = () => {
    if (isDisableAll)
      dispatch(
        modalOpen({
          component: 3,
          content: {
            moduleLevelMsg: moduleLevelMsg,
          },
        })
      );
  };

  const onIconClick = (state) => {
    dispatch(
      modalOpen({
        component: 2,
        content: {
          title: labels.LBL_APP_TYPE_POPUP_HEADER,
          appModalData: state,
          onLinkClickFromPopup: onLinkClickFromPopup,
        },
      })
    );
  };

  const onLinkClickFromPopup = (e, date, data) => {
    if (date && location.pathname === "/dashboard/bookworkplace") {
      dispatch({ type: "STAMP_WORKPLACE_DATE", payload: date });
    }
    if (!isDisableAll) {
      dispatch({ type: "SELECT_APP_TYPE", payload: data.parentKey });
      navigate("/dashboard/bookappointment");
    } else {
      e.preventDefault();
      customClick();
    }
  };

  if (DashboardLoading()) return null;

  if (location.pathname !== "/dashboard" && isAppointmentTypesLoading)
    return <Spinner />;

  return (
    <>
      {location.pathname !== "/dashboard/bookworkplace" && (
        <h2 className="app-heading-1">{labels.LBL_BOOK_APP_HEADER}</h2>
      )}

      <RadSlider
        customClick={customClick}
        stateToMap={oppData}
        CustomSlide={MyHealthSlide}
        settings={settings}
        onIconClick={onIconClick}
      />
    </>
  );
};

export default AppointmentTypes;
