import { Navigate } from "react-router-dom";

const VerifyEmail = () => {
  function redirectDevice() {
    const ua = window.navigator.userAgent;
    if (
      ua.toLowerCase().includes("iphone") &&
      ua.toLowerCase().includes("safari")
    )
      window.location.href =
        "https://apps.apple.com/us/app/radish-health/id1556054938";
    else if (ua.toLowerCase().includes("android"))
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.radish.health";
    else return <Navigate to="../login" replace={true} />;
  }

  return (
    <>
      <p>You will be redirected shortly...</p>
      {redirectDevice()}
    </>
  );
};

export default VerifyEmail;
