import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCareCostAction } from "../../../redux/actions/profileAction";
import { postEventTrack } from "../../../requests/requestAPI";

function CareCost() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const labels = useSelector((state) => state.app.labels);
  const userProfileInfo = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (token) {
      dispatch(getCareCostAction(token));

      postEventTrack(token, {
        eventName: "CARECOSTVIEW",
        eventDetails: null,
        source: "WEB",
      });
    }
  }, [dispatch, token]);


  return (
    <>
      <div className="app-heading-1">{labels.LBL_TITLE_EXPECTED_CARE_COST}</div>
      <div className="popup-form-wrap">
        <div className="row g-3 mb-20">
          <div className="careCostDescription">
            {labels.LBL_EXPECTED_CARE_COST}
          </div>
        </div>
        <div className="row g-3">
          <div className="col-12 mb-20">
            <button
              type="submit"
              onClick={()=>{
                postEventTrack(token, {
                  eventName: "CARECOSTCHATINTENT",
                  eventDetails: null,
                  source: "WEB",
                });
              }}
              className="btn btn-primary col-12">
              <a href="https://go.radish.health/reginvite" target="_blank">CHAT WITH YOUR CARE COORDINATOR</a>
            </button>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <div className="careCost">
                {userProfileInfo.careCostData.map((value, index) => (
                  <>
                    <div className="careCostLabel">{value.title}</div>
                    {userProfileInfo.careCostData.length == (index + 1) ?
                    <div className="careCostInfoNoBorder">{value.patientNotes}</div> :
                    <div className="careCostInfo">{value.patientNotes}</div>
                    }
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default CareCost;
