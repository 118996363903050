import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "./AutoComplete.js";
import { Link, useLocation } from "react-router-dom";
import {
  getRegistrationData,
  registerAction,
} from "../../redux/actions/registerUserAction.js";
import moment from "moment";

import Alert from "../common/Alert.js";
import Spinner from "../common/Spinner.js";

function getAge(DOB) {
  var today = new Date();
  var birthDate = new Date(DOB);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
const clearState = () => ({
  type: "CLEAR_STATE",
});

const CreateAccount = ({ labels = {} }) => {
  const key = new URLSearchParams(useLocation().search).get("c");
  let dispatch = useDispatch();
  const [isSubmit, setSubmitted] = useState(false);
  const userInfo = useSelector((state) => state.user);
  const [isAgreed, setIsAgreed] = useState(false);
  const [errorObj, setError] = useState({});
  const [values, setvalues] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    mobileNumber: "",
    email: "",
    registerCode: "",
    gender: "",
    password: "",
    address: "",
    street: "",
    registerApt: "",
    city: "",
    state: "",
    zip: "",
  });

  const checkFormError = () => {
    const {
      firstName,
      lastName,
      dob,
      mobileNumber,
      email,
      registerCode,
      gender,
      password,
      street,
      city,
      state,
      zip,
    } = values;
    const keyRegisterCode = key ? key : registerCode;
    if (
      !isAgreed ||
      !firstName ||
      !lastName ||
      !dob ||
      !mobileNumber ||
      !email ||
      !keyRegisterCode ||
      !gender ||
      !password ||
      !street ||
      !city ||
      !state ||
      !zip
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (key) {
      dispatch(getRegistrationData(key));
    }
  }, [dispatch, key]);

  useEffect(() => {
    if (userInfo.preRegData) {
      const {
        firstName,
        lastName,
        state,
        city,
        zip,
        address1,
        mobile,
        address2,
        emailTo,
        gender,
        dateOfBirth,
      } = userInfo.preRegData;
      let formattedMob = mobile;
      if (mobile) {
        formattedMob = mobile.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      }
      const dob = dateOfBirth ? moment(dateOfBirth).format("MM/DD/YYYY") : "";
      setvalues({
        ...values,
        firstName,
        lastName,
        mobileNumber: formattedMob,
        state,
        email: emailTo,
        city,
        zip,
        street: address1,
        registerApt: address2,
        gender,
        dob,
      });
      checkDob(dob);
    }
  }, [userInfo.preRegData]);

  const onCreateAccountClick = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (!checkFormError() && Object.keys(errorObj).length !== 0) {
      const payload = { ...values };
      if (key) {
        payload.registerCode = key;
      }
      await dispatch(registerAction(payload));
    }
  };

  const checkMob = () => {
    const { mobileNumber } = values;
    if (mobileNumber && mobileNumber.length < 12) {
      setError({
        ...errorObj,
        mobError: `Mobile is invalid \xa0\xa0\xa0\xa0\xa0 (xxx-xxx-xxxx)`,
      });
      return;
    }
    setError({ ...errorObj, mobError: "" });
  };

  const onEmailBlur = () => {
    const { email } = values;
    var re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      setError({ ...errorObj, emailError: labels.MSG_EMAIL_REQ });
      return;
    }
    setError({ ...errorObj, emailError: "" });
  };

  const onZipBlur = () => {
    const { zip } = values;
    var re = /\d{5}(-\d{4})?/;
    if (!re.test(zip)) {
      setError({ ...errorObj, zipError: labels.MSG_ZIP_REQ });
      return;
    }
    setError({ ...errorObj, zipError: "" });
  };

  const onPassBlur = () => {
    const { password } = values;
    if (password && password.length < 8) {
      setError({ ...errorObj, passwordError: labels.MSG_PASSWORD });
      return;
    }
    setError({ ...errorObj, passwordError: "" });
  };

  const checkDob = (...props) => {
    let dob;
    if (typeof props[0] === "string") {
      dob = props[0];
    } else {
      dob = values.dob;
    }
    var d = new Date(dob);
    if ((dob && dob.length < 10) || !d.getTime()) {
      setError({ ...errorObj, ageError: labels.MSG_AGE_ERROR });
      return;
    }
    if (dob && getAge(dob) < 18) {
      setError({ ...errorObj, ageError: labels.MSG_AGE_LIMIT });
      return;
    }
    setError({ ...errorObj, ageError: "" });
  };

  function handleInputChange({ target }) {
    if (userInfo.registerApiError) {
      dispatch(clearState());
    }
    const { name, value } = target;

    if (target.dataset.stateAddress) {
      if (/^[a-zA-Z]+$/.test(value) || value === "")
        setvalues({ ...values, [name]: value.toUpperCase() });
    } else setvalues({ ...values, [name]: value });
  }

  function handleCheckbox() {
    setIsAgreed(!isAgreed);
  }

  if (userInfo.preRegData && userInfo.preRegData.isRegDataLoading && key) {
    return <Spinner />;
  }

  return (
    <div className="lg-form-wrap" id="set-pass-form-main">
      <h3 className="login-heading">{labels.LBL_CREATE_ACC}</h3>
      {userInfo.registerApiError && (
        <Alert
          messageTitle="ERROR:"
          message={userInfo.registerApiErrorMsg}
          cssClassName={"alert alert-danger mt-20"}
        />
      )}
      {userInfo.preRegData &&
        userInfo.preRegData.isRegDataError &&
        userInfo.preRegData.regDataErrorMessage && (
          <Alert
            messageTitle="WARNING:"
            message={userInfo.preRegData.regDataErrorMessage}
            cssClassName={"alert alert-danger mt-20"}
          />
        )}
      <div className="row g-3">
        <div className="col-6">
          <div className="form-group mb-15">
            <label htmlFor="firstName">{labels.LBL_REGISTER_FNAME}</label>
            <div className="inputwrap">
              <input
                type="text"
                autoComplete="off"
                maxLength="50"
                className={`form-control form-control-lg ${
                  isSubmit && !values.firstName && "input-invalid"
                }`}
                name="firstName"
                value={values.firstName}
                id="firstName"
                onChange={handleInputChange}
              />
              {isSubmit && !values.firstName && (
                <span className="error-label">{labels.MSG_REQ}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group mb-15">
            <label htmlFor="lastName">{labels.LBL_REGISTER_LNAME}</label>
            <div className="inputwrap">
              <input
                type="text"
                autoComplete="off"
                maxLength="50"
                value={values.lastName}
                className={`form-control form-control-lg ${
                  isSubmit && !values.lastName && "input-invalid"
                }`}
                name="lastName"
                id="lastName"
                onChange={handleInputChange}
              />
              {isSubmit && !values.lastName && (
                <span className="error-label">{labels.MSG_REQ}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-6">
          <div className="form-group mb-15">
            <label htmlFor="dob">{labels.LBL_REGISTER_DOB}</label>
            <div className="inputwrap">
              <InputMask
                type="text"
                autoComplete="off"
                placeholder="mm/dd/yyyy"
                inputMode="numeric"
                value={values.dob}
                className={`form-control form-control-lg ${
                  ((values.dob && errorObj.ageError) ||
                    (isSubmit && !values.dob)) &&
                  "input-invalid"
                }`}
                name="dob"
                id="dob"
                onBlur={checkDob}
                onChange={handleInputChange}
                mask="99/99/9999"
                maskChar=""
              />
              {values.dob && errorObj.ageError && (
                <span className="error-label">{errorObj.ageError}</span>
              )}
              {isSubmit && !values.dob && (
                <span className="error-label">{labels.MSG_REQ}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group mb-15">
            <label htmlFor="mobileNumber">{labels.LBL_REGISTER_MOBILE}</label>
            <div className="inputwrap">
              <InputMask
                type="text"
                autoComplete="off"
                placeholder="xxx-xxx-xxxx"
                inputMode="tel"
                value={values.mobileNumber}
                className={`form-control form-control-lg ${
                  ((values.mobileNumber && errorObj.mobError) ||
                    (isSubmit && !values.mobileNumber)) &&
                  "input-invalid"
                }`}
                name="mobileNumber"
                id="mobileNumber"
                onBlur={checkMob}
                onChange={handleInputChange}
                mask="999-999-9999"
                maskChar=""
              />
              {values.mobileNumber && errorObj.mobError && (
                <span className="error-label">{errorObj.mobError}</span>
              )}
              {isSubmit && !values.mobileNumber && (
                <span className="error-label">{labels.MSG_REQ}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      {(!key ||
        (userInfo.preRegData &&
          userInfo.preRegData.isRegDataError &&
          userInfo.preRegData.regDataErrorMessage)) && (
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="registerCode">
                {labels.LBL_REGISTER_REGISTRATION_CODE}
              </label>
              <div className="inputwrap">
                <input
                  type="text"
                  autoComplete="off"
                  maxLength="50"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.registerCode && "input-invalid"
                  }`}
                  name="registerCode"
                  id="registerCode"
                  onChange={handleInputChange}
                />
                {isSubmit && !values.registerCode && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
              <div className="switch-cta-wrap-left">
                {labels.LBL_REGISTER_REGISTRATION_CODE_INFO} {""}
                <a
                  href="https://www.radish.health/request-a-demo"
                  className="link-type-1"
                >
                  <strong>{labels.LBL_REGISTER_CHAT}</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row g-3">
        <div className="col-12">
          <div className="form-group mb-15">
            <label htmlFor="email">{labels.LBL_REGISTER_EMAIL}</label>
            <div className="inputwrap">
              <input
                type="text"
                autoComplete="off"
                maxLength="250"
                className={`form-control form-control-lg ${
                  ((values.email && errorObj.emailError) ||
                    (isSubmit && !values.email)) &&
                  "input-invalid"
                }`}
                name="email"
                id="email"
                value={values.email}
                onBlur={onEmailBlur}
                onChange={handleInputChange}
              />
              {values.email && errorObj.emailError && (
                <span className="error-label">{errorObj.emailError}</span>
              )}
              {isSubmit && !values.email && (
                <span className="error-label">{labels.MSG_REQ}</span>
              )}
            </div>
            <div className="switch-cta-wrap-left">
              {labels.LBL_REGISTER_EMAIL_INFO}
            </div>
          </div>
        </div>
      </div>

      <div className="form-group mb-15">
        <label> {labels.LBL_REGISTER_SEX}</label>
        <div className="inputwrap">
          <div className="row g-0 r-radio-group">
            <div className="col-4 r-radio-group-item">
              <input
                type="radio"
                className="btn-check"
                name="gender"
                id="option1"
                autoComplete="off"
                value={"Male"}
                checked={values.gender === "Male"}
                onChange={handleInputChange}
              />
              <label className="btn btn-primary col-12 mb-0" htmlFor="option1">
                {labels.LBL_MALE}
              </label>
            </div>
            <div className="col-4 r-radio-group-item">
              <input
                type="radio"
                className="btn-check"
                name="gender"
                id="option2"
                value="Female"
                checked={values.gender === "Female"}
                autoComplete="off"
                onChange={handleInputChange}
              />
              <label className="btn btn-primary col-12 mb-0" htmlFor="option2">
                {labels.LBL_FEMALE}
              </label>
            </div>
            <div className="col-4 r-radio-group-item">
              <input
                type="radio"
                className="btn-check"
                name="gender"
                id="option3"
                value="Other"
                autoComplete="off"
                checked={values.gender === "Other"}
                onChange={handleInputChange}
              />
              <label className="btn btn-primary col-12 mb-0" htmlFor="option3">
                {labels.LBL_GEN_OTHER}
              </label>
            </div>
          </div>
          {isSubmit && !values.gender && (
            <span className="error-label">{labels.MSG_REQ}</span>
          )}
        </div>
      </div>

      <div className="row g-3">
        <div className="col-12">
          <div className="form-group mb-15">
            <label htmlFor="password"> {labels.LBL_REGISTER_PASSWORD}</label>
            <div className="inputwrap">
              <input
                type="password"
                autoComplete="off"
                maxLength="25"
                placeholder="At least 8 characters"
                className={`form-control form-control-lg ${
                  ((values.password && errorObj.passwordError) ||
                    (isSubmit && !values.password)) &&
                  "input-invalid"
                }`}
                name="password"
                id="password"
                onBlur={onPassBlur}
                onChange={handleInputChange}
              />
              {values.password && errorObj.passwordError && (
                <span className="error-label">{errorObj.passwordError}</span>
              )}
              {isSubmit && !values.password && (
                <span className="error-label">{labels.MSG_REQ}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3">
        <div className="col-12">
          <div className="form-group mb-15">
            <label htmlFor="address">{labels.LBL_REGISTER_ADDRESS}</label>
            <div className="inputwrap">
              <AutoComplete
                values={values}
                setvalues={setvalues}
                isSubmit={isSubmit}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3">
        <div className="col-6">
          <div className="form-group mb-15">
            <label htmlFor="">{labels.LBL_REGISTER_STREET}</label>
            <div className="inputwrap">
              <input
                type="text"
                maxLength="250"
                className={`form-control form-control-lg ${
                  isSubmit && !values.street && "input-invalid"
                }`}
                name="street"
                value={values.street}
                id="street"
                onChange={handleInputChange}
              />
              {isSubmit && !values.street && (
                <span className="error-label">{labels.MSG_REQ}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group mb-15">
            <label htmlFor="registerApt">{labels.LBL_REGISTER_APT}</label>
            <div className="inputwrap">
              <input
                type="text"
                autoComplete="off"
                value={values.registerApt}
                maxLength="250"
                className={`form-control form-control-lg'}`}
                name="registerApt"
                id="registerApt"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-5">
        <div className="col-6">
          <div className="form-group mb-15">
            <label htmlFor="city">{labels.LBL_REGISTER_CITY}</label>
            <div className="inputwrap">
              <input
                type="text"
                autoComplete="off"
                maxLength="50"
                className={`form-control form-control-lg ${
                  isSubmit && !values.city && "input-invalid"
                }`}
                name="city"
                value={values.city}
                id="city"
                onChange={handleInputChange}
              />
              {isSubmit && !values.city && (
                <span className="error-label">{labels.MSG_REQ}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row g-2">
            <div className="col-6">
              <div className="form-group mb-15">
                <label htmlFor="state">{labels.LBL_REGISTER_STATE}</label>
                <div className="inputwrap">
                  <input
                    type="text"
                    autoComplete="off"
                    maxLength="2"
                    className={`form-control form-control-lg ${
                      isSubmit && !values.state && "input-invalid"
                    }`}
                    name="state"
                    value={values.state}
                    id="state"
                    data-state-address={true}
                    onChange={handleInputChange}
                  />
                  {isSubmit && !values.state && (
                    <span className="error-label">{labels.MSG_REQ}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-15">
                <label htmlFor="zip">{labels.LBL_REGISTER_ZIP}</label>
                <div className="inputwrap">
                  <input
                    type="text"
                    autoComplete="off"
                    maxLength="50"
                    className={`form-control form-control-lg ${
                      isSubmit && !values.zip && "input-invalid"
                    }`}
                    name="zip"
                    value={values.zip}
                    id="zip"
                    onBlur={onZipBlur}
                    onChange={handleInputChange}
                  />
                  {values.zip && errorObj.zipError && (
                    <span className="error-label">{errorObj.zipError}</span>
                  )}
                  {isSubmit && !values.zip && (
                    <span className="error-label">{labels.MSG_REQ}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mb-20">
          <label className="check-label">
            <input
              type="checkbox"
              name="set-pass-agree"
              id="set-pass-agree"
              className="form-check-input set-pass-agree"
              value={isAgreed}
              onChange={handleCheckbox}
            />
            <div>
              I consent to{" "}
              <Link to="/policies/consent" target="_blank">
                medical and telehealth care
              </Link>
              , and I agree to the{" "}
              <Link to="/policies/pp" target="_blank">
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link to="/policies/tou" target="_blank">
                Terms of Use
              </Link>
              .
            </div>
          </label>
          {isSubmit && !isAgreed && (
            <span className="error-label">{labels.MSG_REQ}</span>
          )}
        </div>
      </div>

      <button
        type="submit"
        onClick={onCreateAccountClick}
        className="btn btn-primary btn-lg col-12"
      >
        {userInfo.isLoading ? <Spinner /> : labels.LBL_REGISTER_ACC}
      </button>
    </div>
  );
};

export default CreateAccount;
