const exploreState = {
  isExploreListLoading: false,
  isExploreListMoreLoading: false,
  isExploreListError: false,
  exploreListErrorMessage: null,
  explorePageCount: 0,
  slidersList: [],
};

export const exploreReducer = (state = exploreState, action) => {
  if (action.type === "EXPLORE_LIST_REQ") {
    return {
      ...state,
      isExploreListLoading: true,
      isExploreListError: false,
      exploreListErrorMessage: null,
    };
  }

  if (action.type === "EXPLORE_LIST_REC") {
    const { payload } = action;

    return {
      ...state,
      isExploreListLoading: false,
      isExploreListMoreLoading: false,
      isExploreListError: false,
      exploreListErrorMessage: null,
      explorePageCount: payload.totalPageCount,
      slidersList: payload.value,
    };
  }

  if (action.type === "EXPLORE_LIST_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isExploreListLoading: false,
      isExploreListError: true,
      exploreListErrorMessage: payload.value,
    };
  }

  if (action.type === "EXPLORE_LIST_MORE_REQ") {
    return {
      ...state,
      isExploreListMoreLoading: true,
      isExploreListError: false,
      exploreListErrorMessage: null,
    };
  }

  if (action.type === "EXPLORE_LIST_MORE_REC") {
    const { payload } = action;

    const addNewData = () => {
      let tempData = state.slidersList;
      payload.value.forEach((item) => tempData.push(item));
      return tempData;
    };

    return {
      ...state,
      isExploreListMoreLoading: false,
      isExploreListMoreError: false,
      exploreListErrorMessage: null,
      slidersList: addNewData(),
    };
  }

  if (action.type === "EXPLORE_LIST_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isExploreListMoreLoading: false,
      isExploreListError: true,
      exploreListErrorMessage: payload,
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      isExploreListLoading: false,
      isExploreListMoreLoading: false,
      isExploreListError: false,
      exploreListErrorMessage: null,
      explorePageCount: 0,
      slidersList: [],
    };
  }

  return state;
};
