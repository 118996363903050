import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";

import { doctorsListAction } from "../../../redux/actions/doctorsActions";
import { sidePopupClose } from "../../../redux/actions/sidePopupActions";
import { modalClose } from "../../../redux/actions/modalAction";
import { updateCareTeam } from "../../../requests/requestAPI";

const DoctorsBioModal = ({ labels, content }) => {
  const { token } = useSelector((state) => state.user);
  const { userProfile: { state, companyState } = {} } = useSelector(
    (state) => state.userProfile
  );
  const dispatch = useDispatch();
  let { modalData } = content;

  const handleButtonClick = async (e) => {
    const payload = {
      doctorID: modalData.id,
      specialty: modalData.speciality,
    };
    await updateCareTeam(token, payload);
    dispatch(sidePopupClose());
    dispatch(modalClose());
    dispatch(doctorsListAction(token, state ? state : companyState));
  };

  return (
    <>
      <div className="avatar-info avatar-info-2 mb-25">
        <div className="avatar-info-in">
          <figure>
            <img
              src={modalData.img}
              alt={`${modalData.DrName}`}
              className="img-responsive"
            />
          </figure>
          <div className="ai-meta">
            <span className="ai-title">{modalData.DrName}</span>
            <span className="ai-position font-regular">{modalData.title}</span>
          </div>
        </div>
      </div>

      <div className="center-popup-data mb-40">
        <p>{parse(modalData.data)}</p>
      </div>

      <div className="center-popup-data p-bottom">
        <button className="btn btn-primary ucase" onClick={handleButtonClick}>
          {labels.LBL_UPDATE_PROVIDER}
        </button>
      </div>
    </>
  );
};

export default DoctorsBioModal;
