const TalkToPsychologistWebview = ({ dhaWVInfo }) => {
  return (
    <div className="cfw-card-middle greenbg dha-megan">
      <div className="card-middle-inner-right">
        <h3>
          Anxiety is more common than you think - let's manage it together
        </h3>
        <a
          href={`radishhealth://?pageName=${dhaWVInfo.buttonLink}&AppointmentTypeID=${dhaWVInfo.buttonLinkDetails.AppointmentTypeID}&ParentKey=${dhaWVInfo.buttonLinkDetails.ParentKey}`}
          className="btn btn-primary"
        >
          Talk to a Psychologist
        </a>
      </div>
    </div>
  );
};

export default TalkToPsychologistWebview;
