import { useEffect, useState, useRef } from "react";
import { otpAuthAction } from "../../redux/actions/registerUserAction";
import { useDispatch } from "react-redux";
import Alert from "../common/Alert";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Spinner from "../common/Spinner";
import withLabels from "../../HOC/withLabels";

const clearOtp = () => ({
  type: "CLEAR_OTP",
});

const OTP = (props) => {
  const { labels = {}, otpData } = props;
  const [values, setvalues] = useState({ otp: "" });
  const [isSubmit, setSubmitted] = useState(false);
  const submitOtp = useRef();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const regCodeID = new URLSearchParams(useLocation().search).get("regCodeID");

  useEffect(() => {
    if (otpData && otpData.code) {
      navigate(`/register?c=${otpData.code}`, { replace: true });
    }
  }, [otpData, navigate]);

  function handleInputChange({ target }) {
    const { name, value } = target;
    setvalues({ ...values, [name]: value });
    setSubmitted(false);
  }

  const resendOtp = async (event) => {
    console.log("hit");
    event.preventDefault();
    await dispatch(clearOtp());
    navigate(`/retrieve-registration`, { replace: true, props });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (values.otp) await dispatch(otpAuthAction(values.otp, regCodeID));
  };

  return (
    <div className="lg-form-wrap" id="otp-form-main">
      <p className="otp-top-heading">
        Your one time password was sent to your mobile number via text message
      </p>
      <form>
        <div className="form-group">
          <label>Please enter your OTP below</label>
          {props.otpErrorMsg && (
            <Alert
              messageTitle="ERROR:"
              message={props.otpErrorMsg}
              cssClassName={"alert alert-danger mt-20"}
            />
          )}

          <div className="otp-input-group">
            <div className="otp-input-wrap">
              <input
                type="text"
                name="otp"
                className="otp-input"
                tabIndex="1"
                maxLength="6"
                inputMode="numeric"
                onChange={handleInputChange}
              />
            </div>
          </div>
          {isSubmit && !values.otp && (
            <span className="error-label">{labels.MSG_REQ}</span>
          )}
          <div className="switch-cta-wrap">
            <span className="link-type-1">Didn't receive your code? </span>
            <button
              type="button"
              onClick={resendOtp}
              className="link-type-1 unset-btn"
            >
              <strong>Try again</strong>
            </button>
          </div>
        </div>

        <button
          ref={submitOtp}
          onClick={onSubmit}
          type="submit"
          className="btn btn-primary btn-lg col-12"
          disabled={props.isLoading}
        >
          {props.isLoading ? <Spinner /> : labels.LBL_REGISTER_ACC}
        </button>
        <div className="switch-cta-wrap">
          <Link to="../login" className="link-type-1">
            <strong>Back to login</strong>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default withLabels(OTP);
