const DisabledAppointmentsModal = ({ content }) => {
  const { moduleLevelMsg } = content;

  return (
    <div className="center-popup-data">
      <p>{moduleLevelMsg}</p>
    </div>
  );
};

export default DisabledAppointmentsModal;
