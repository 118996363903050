import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  upcomingAppointmentsAction,
  pastAppointmentsAction,
} from "../../redux/actions/appointmentsActions.js";
import UpcomingAppointmentCard from "./UpcomingAppointmentCard.js";
import PastAppointmentCard from "./PastAppointmentCard.js";
import { logoutUser } from "../../redux/actions/logoutUser.js";
import Spinner from "../common/Spinner.js";
import AppointmentTypes from "./AppointmentTypes";
import CanAccess from "../helpers/CanAccess.js";

const Appointments = ({ labels = {}, cookies }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    is404Error,
    upcomingAppointments,
    pastAppointments,
    isUpcomingApptLoading,
    isPastApptLoading,
  } = useSelector((state) => state.appointments);
  const { isAppointmentLoading } = useSelector((state) => state.dashboard);
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(upcomingAppointmentsAction(token));
    dispatch(pastAppointmentsAction(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (is404Error === 401) {
      cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [is404Error, dispatch, cookies]);

  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left">
          <button
            className="back-t-screen-cta unset-btn"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <h2>{labels.LBL_APPOINTMENTS}</h2>
        </div>
        <div className="app-screen-title-right"></div>
      </div>

      {!1 ? (
        <Spinner />
      ) : (
        <CanAccess moduleKey="MODULE-MEDICAL-SERVICE">
          <AppointmentTypes cookies={cookies} labels={labels} />
        </CanAccess>
      )}

      <h2 className="app-heading-1">{labels.LBL_UPCOMING_APPOINTMENTS}</h2>
      {isUpcomingApptLoading && <Spinner />}
      <div className="row gt-4 mb-30">
        {!isUpcomingApptLoading &&
          upcomingAppointments[0] &&
          upcomingAppointments.map((appointment, index) => {
            return (
              <div
                className="col-12 col-lg-6 col-xl-4 appt-card-col"
                key={index}
              >
                <UpcomingAppointmentCard
                  appointmentInfo={appointment}
                  labels={labels}
                />
              </div>
            );
          })}
        {!isUpcomingApptLoading && !upcomingAppointments[0] && (
          <span className="no-upcoming-appointments">
            {labels.LBL_NOUP_APPOINTMENTS}
          </span>
        )}
      </div>

      <h2 className="app-heading-1">{labels.LBL_PAST_APPOINTMENTS}</h2>
      {isPastApptLoading && <Spinner />}
      <div className="row gt-4 mb-20">
        {!isPastApptLoading &&
          pastAppointments[0] &&
          pastAppointments.map((appointment, index) => {
            return (
              <div
                className="col-12 col-lg-6 col-xl-4 appt-card-col"
                key={index}
              >
                <PastAppointmentCard
                  appointmentInfo={appointment}
                  labels={labels}
                />
              </div>
            );
          })}
        {!isPastApptLoading && !pastAppointments[0] && (
          <span className="no-past-appointments">
            {labels.LBL_NOPAST_APPOINTMENTS}
          </span>
        )}
      </div>
    </>
  );
};

export default Appointments;
