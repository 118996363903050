import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import { getDHAAction } from "../../redux/actions/dhaActions.js";
import navigateToRoute from "../../utils/InternalRouting";
import Spinner from "../common/Spinner.js";

import ThumbsUpIcon from "../../assets/img/svg/thumbs-up.svg";
import RadishIcon from "../../assets/img/svg/radish-icon.svg";
// import AnnualBloodWork from "./DHA/AnnualBloodWork";
import TalkToPsychologist from "./DHA/TalkToPsychologist";
// import MensHealthMonth from "./DHA/MensHealthMonth";

const DHAResult = ({ labels = {} }) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { token } = useSelector((state) => state.user);
  const { dhaInfo } = useSelector((state) => state.dha);

  useEffect(() => {
    dispatch(getDHAAction(token));
  }, [dispatch, token]);

  function renderDHA() {
    switch (dhaInfo.InternalStatusCode) {
      case 0:
        return <Navigate to="/dashboard" />;
      case 1:
        return <ClearedForWork labels={labels} dhaInfo={dhaInfo} />;
      case 2:
        return <Navigate to="/dashboard" />;
      default:
        return <CareRecommended dhaInfo={dhaInfo} />;
    }
  }

  return (
    <>
      <div className="app-screen-title mb-0">
        <div className="app-screen-title-left">
          <button
            className="back-t-screen-cta unset-btn"
            onClick={() => navigate("/dashboard")}
          >
            Back
          </button>
          <h2>{labels.LBL_DAILY_HELTH_ASS}</h2>
        </div>
        <div className="app-screen-title-right"></div>
      </div>
      {dhaInfo.UserUID ? renderDHA() : <Spinner />}
    </>
  );
};

const ClearedForWork = ({ dhaInfo, labels = {} }) => {
  let navigate = useNavigate();

  function handleClick(e) {
    e.preventDefault();
    let parameter = {
      appointmentTypeID: dhaInfo.buttonLinkDetails.AppointmentTypeID,
      parentKey: dhaInfo.buttonLinkDetails.ParentKey,
    };
    navigateToRoute(dhaInfo.buttonLink, "URI", navigate, { param: parameter });
  }

  return (
    <div className="cleared-for-work">
      <div className="cfw-card">
        <div className="cfw-card-top greenbg">
          <figure>
            <img
              src={ThumbsUpIcon}
              alt="thumbs-up"
              className="img-responsive"
            />
          </figure>
          <h3>{dhaInfo.PublicStatus}</h3>
          <span className="cfw-span-13">{dhaInfo.dateFriendly}</span>
        </div>
        {/* <AnnualBloodWork labels={labels} handleClick={handleClick} /> */}
        <TalkToPsychologist labels={labels} handleClick={handleClick} />
        {/* <MensHealthMonth labels={labels} handleClick={handleClick} /> */}
        <div className="cfw-card-bottom">
          <figure>
            <img
              src={dhaInfo.qrcode}
              alt="barcode"
              className="img-responsive"
            />
          </figure>
          <span className="number">{dhaInfo.qrid}</span>
          <h3>{dhaInfo.FirstName + " " + dhaInfo.LastName}</h3>
          <span className="cfw-span-13">({dhaInfo.Company})</span>
        </div>
      </div>

      {/* <div className="cfw-footer flex-center">
                <div className="appointment-btn-wrap">
                    <button className="btn btn-primary" onClick={handleClick}>{dhaInfo.button}</button>
                </div>
                {parse(dhaInfo.msg)}
            </div> */}
    </div>
  );
};

const CareRecommended = ({ dhaInfo }) => {
  let navigate = useNavigate();

  function handleClick(e) {
    e.preventDefault();
    let parameter = {
      documentType: dhaInfo.buttonLinkDetails.documentType,
      documentTypeID: dhaInfo.buttonLinkDetails.documentTypeID,
      appointmentTypeID: dhaInfo.buttonLinkDetails.AppointmentTypeID,
      parentKey: dhaInfo.buttonLinkDetails.ParentKey,
    };
    navigateToRoute(dhaInfo.buttonLink, "URI", navigate, { param: parameter });
  }

  return (
    <>
      <div className="cleared-for-work pt-40">
        <div className="cfw-card">
          <div className="cfw-card-top pinkbg">
            <figure>
              <img
                src={RadishIcon}
                alt="radish-icon"
                className="img-responsive"
              />
            </figure>
            <h3>{dhaInfo.PublicStatus}</h3>
            <span className="cfw-span-13">{dhaInfo.dateFriendly}</span>
          </div>
          <div className="cfw-card-bottom">
            <h3>{dhaInfo.FirstName + " " + dhaInfo.LastName}</h3>
            <span className="cfw-span-13 mb-40">({dhaInfo.Company})</span>
            <div className="cfw-card-info flex-center">
              <div className="appointment-btn-wrap mb-40">
                <button className="btn btn-primary" onClick={handleClick}>
                  {dhaInfo.button}
                </button>
              </div>
              {dhaInfo.msg && parse(dhaInfo.msg)}
            </div>
          </div>
        </div>

        {/* <div className="cfw-footer flex-left">
                    <p>Please enter/select the full name of everyone you have been in contact with (both colleagues and non-colleagues) since two (2) days before<br />
                        the onset of your first symptom. This data will stay private, we only use anonymized data to ensure your workplace and colleagues are safe and healthy.<br />
                        Based on the information you provided, we believe that you may be experiencing or are likely to develop an illness, which may be communicable and should contact a medical provider for<br />
                        medical assistance. You can use Radish Health's contact-tracing feature to help prevent the spread of such illness and direct others to seek medical attention if necessary.<br />
                        As explained in our Terms of Use, the Radish Health services are not medical advice, and are not intended to be a substitute for professional diagnosis, treatment or public health information.<br />
                        Please contact a healthcare professional if you have any concerns about your health. To the extent you receive telehealth services through our affiliates, you acknowledge and agree that we<br />
                        have the right to share de-identified information back to your employer.</p>

                    <div className="form-check mb-25">
                        <input className="form-check-input" type="checkbox" value="" id="i-consent" />
                        <label className="form-check-label" htmlFor="i-consent">
                            Check this box to anonymously to inform other Radish Health users who we identify as having been in recent proximity to you that they may have been exposed to an illness. This notification will not include any identifiable information.
                        </label>
                    </div>

                    <div className="care-inputs-wrap">
                        <div className="inputwrap">
                            <input type="text" name="" id="" className="form-control" placeholder="Select/Enter one or more name" />
                        </div>
                        <div className="btnwrap">
                            <button type="button" name="" className="btn btn-primary btn-lg col-12">Save</button>
                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default DHAResult;
