import chatWithDoctorPhone from "../../../assets/img/png/chat-with-doctor-phone.png";
import qrCode from "../../../assets/img/png/download-app-qr.png";
import googlePlay from "../../../assets/img/svg/google-play-badge.svg";
import appStore from "../../../assets/img/svg/app-store.svg";
import { postEventTrack } from "../../../requests/requestAPI";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const DownloadApp = () => {
  const { token } = useSelector((state) => state.user);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (token) {
      postEventTrack(token, {
        eventName: "CHATPOPUP",
        eventDetails: null,
        source: "WEB",
      });
    }
  }, [token]);

  useEffect(() => {
    const screenWidth = window.screen.width;
    if (screenWidth > 768) setIsDesktop(true);
  }, []);

  return (
    <div className="download-app-sidepopup">
      <h3>Chat with a Doctor</h3>
      <img
        className="mobile-phone-chat-img"
        src={chatWithDoctorPhone}
        alt="A mobile phone chat thread between doctor and patient"
      />
      <p>
        Download the Radish Health app to chat with your doctor anytime,
        anywhere. Ask questions directly and get advice in minutes - no
        appointment needed.
      </p>
      {isDesktop ? (
        <>
          <a
            href="https://go.radish.health/reginvite"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={qrCode}
              className="store-badge"
              alt="Download the Radish Health app"
            />
          </a>
        </>
      ) : (
        <>
          <a
            href="https://play.google.com/store/apps/details?id=com.radish.health"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={googlePlay}
              className="store-badge"
              alt="Download Radish Health from Google Play Store"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/radish-health/id1556054938"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={appStore}
              className="store-badge"
              alt="Download Radish Health from Apple App Store"
            />
          </a>
        </>
      )}
    </div>
  );
};

export default DownloadApp;
