import { combineReducers } from "redux";
import { userReducer } from "./reducers/userReducer.js";
import { appReducer } from "./reducers/appReducer.js";
import { dashboardReducer } from "./reducers/dashboardReducer.js";
import { documentsReducer } from "./reducers/documentsReducer.js";
import { appointmentsReducer } from "./reducers/appointmentsReducer.js";
import { sidePopupReducer } from "./reducers/sidePopupReducer.js";
import { modalReducer } from "./reducers/modalReducer.js";
import { profileReducer } from "./reducers/profileReducer.js";
import { insuranceReducer } from "./reducers/insuranceProviderReducer.js";
import { myToDoReducer } from "./reducers/myToDoReducer.js";
import { doctorsReducer } from "./reducers/doctorsReducer.js";
import { dhaReducer } from "./reducers/dhaReducer.js";
import { healthCheckReducer } from "./reducers/healthCheckReducer";
import { exploreReducer } from "./reducers/exploreReducer";
import { medicalHistoryReducer } from "./reducers/medicalHistoryReducer";

export const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  dashboard: dashboardReducer,
  documents: documentsReducer,
  appointments: appointmentsReducer,
  sidePopup: sidePopupReducer,
  modal: modalReducer,
  userProfile: profileReducer,
  insuranceInfo: insuranceReducer,
  toDoInfo: myToDoReducer,
  doctors: doctorsReducer,
  dha: dhaReducer,
  healthCheck: healthCheckReducer,
  explore: exploreReducer,
  medicalHistory: medicalHistoryReducer,
});
