import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { sidePopupOpen } from "../../../redux/actions/sidePopupActions";
import {
  userHistoryAction,
  userVerifiedHistoryAction,
} from "../../../redux/actions/medicalHistoryActions";

import UnderReview from "./UnderReview";
import Verified from "./Verified";
import Spinner from "../../common/Spinner";

import medicalHistoryIcon from "../../../assets/img/svg/medical-history-icon.svg";

const MedicalHistory = ({ labels = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    userConditions,
    userMedications,
    userAllergies,
    userConditionsVerified,
    userMedicationsVerified,
    userAllergiesVerified,
    isUserHistoryLoading,
    isUserVerifiedLoading,
  } = useSelector((state) => state.medicalHistory);

  const { token } = useSelector((state) => state.user);
  const [showBookMessage, setShowBookMessage] = useState(false);

  const openSidePopup = (popupComponent, popupContent) => {
    dispatch(
      sidePopupOpen({
        component: popupComponent,
        content: popupContent,
      })
    );
  };

  useEffect(() => {
    dispatch(userHistoryAction(token));
    dispatch(userVerifiedHistoryAction(token));
  }, [dispatch, token]);

  const navigateBackward = () => {
    if (showBookMessage) setShowBookMessage(false);
    else navigate(-1);
  };

  const handleSave = () => {
    if (
      userConditions.length > 0 ||
      userMedications.length > 0 ||
      userAllergies.length > 0
    )
      setShowBookMessage(true);
    else openSidePopup(9);
  };

  const handleNavigateBooking = () => {
    dispatch({
      type: "SELECT_APP_TYPE",
      payload: "VIRTUALCARE",
    });
    navigate("../bookappointment");
  };

  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left">
          <button
            onClick={navigateBackward}
            className="back-t-screen-cta unset-btn"
          >
            Back
          </button>
          <h2>{labels.LBL_MEDICAL_HISTORY}</h2>
        </div>
        <div className="app-screen-title-right">
          {(userConditions.length > 0 ||
            userMedications.length > 0 ||
            userMedications.length > 0 ||
            userConditionsVerified.length > 0 ||
            userMedicationsVerified.length > 0 ||
            userMedicationsVerified.length > 0) &&
            !showBookMessage && (
              <button
                className="btn btn-primary btn-pad-40 ucase"
                onClick={handleSave}
              >
                {userConditions.length > 0 ||
                userMedications.length > 0 ||
                userAllergies.length > 0
                  ? labels.LBL_MEDICAL_HISTORY_SAVE
                  : labels.LBL_MEDICAL_HISTORY_ADD}
              </button>
            )}
        </div>
      </div>

      {isUserHistoryLoading || isUserVerifiedLoading ? (
        <Spinner />
      ) : showBookMessage ? (
        <div className="medical-history-message">
          <figure>
            <img src={medicalHistoryIcon} alt="" />
          </figure>
          <h5>{labels.LBL_MEDICAL_HISTORY_THANKS}</h5>
          <p>{labels.LBL_MEDICAL_HISTORY_BOOK_MSG}</p>
          <button
            className="btn btn-primary btn-pad-40 ucase"
            onClick={handleNavigateBooking}
          >
            {labels.LBL_MEDICAL_HISTORY_BOOK}
          </button>
        </div>
      ) : (
        <div className="medical-history-container">
          {!userConditions.length &&
          !userMedications.length &&
          !userAllergies.length &&
          !userConditionsVerified.length &&
          !userMedicationsVerified.length &&
          !userMedicationsVerified.length ? (
            <>
              <div className="medical-history-empty">
                <figure>
                  <img src={medicalHistoryIcon} alt="" />
                </figure>
                <p>{labels.LBL_MEDICAL_HISTORY_INFO}</p>
                <button
                  className="btn btn-primary btn-pad-40 ucase"
                  onClick={() => openSidePopup(9)}
                >
                  {labels.LBL_MEDICAL_HISTORY_ADD}
                </button>
              </div>
            </>
          ) : (
            <div className="medical-history-populated row">
              <Verified
                userConditionsVerified={userConditionsVerified}
                userMedicationsVerified={userMedicationsVerified}
                userAllergiesVerified={userAllergiesVerified}
                isUserVerifiedLoading={isUserVerifiedLoading}
              />
              <UnderReview
                userConditions={userConditions}
                userMedications={userMedications}
                userAllergies={userAllergies}
                openSidePopup={openSidePopup}
                isUserHistoryLoading={isUserHistoryLoading}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MedicalHistory;
