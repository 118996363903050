import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions.js";
import AddToCalendar from "@culturehq/add-to-calendar";
import moment from "moment";
import Logo from "../../assets/img/svg/radish-icon.svg";

const UpcomingAppointmentCard = ({ appointmentInfo = {}, labels = {} }) => {
  const dispatch = useDispatch();

  let appointmentDay, appointmentDate, appointmentMonth;
  if (appointmentInfo.localDate) {
    const scheduleDate = appointmentInfo.localDate.split(",");
    if (scheduleDate.length > 0) {
      let dateYear = scheduleDate[1].split(" ");
      appointmentDay = scheduleDate[0];
      appointmentDate = dateYear[2];
      appointmentMonth = dateYear[1];
    }
  }

  const [minutes, setMinutes] = useState(appointmentInfo.diffMinutes);
  const [isActive, setIsActive] = useState(true);

  const openSidePopup = () => {
    const payload = {
      component: 2,
      content: appointmentInfo,
    };
    dispatch(sidePopupOpen(payload));
    dispatch({ type: "CANCEL_APP_CLEAR" });
  };

  function getCalendarEvent() {
    const toDate = moment(
      appointmentInfo.localDate + " " + appointmentInfo.localTime,
      "ddd, MMM D YYYY hh:mm A"
    );
    const endDate = moment(toDate).add(60, "minutes");
    return {
      name:
        appointmentInfo.isOnsite === 0
          ? "Radish Health Virtual Appointment"
          : "Radish Health In-Person Appointment",
      details:
        appointmentInfo.isOnsite === 0 && !appointmentInfo.additionalDetails
          ? `For your upcoming virtual appointment, please have a smartphone, computer, or tablet ready with the camera and microphone permissions enabled. You can enter the virtual waiting room 10 minutes before your appointment at the link provided or through your Patient Portal. If you need to cancel your appointment, please do so in the Radish Health mobile app, at https://patient.radish.health, or email help@radish.health. Link to join appointment: ${appointmentInfo.vrurl}`
          : appointmentInfo.additionalDetails +
            " " +
            "If you need to cancel your appointment, please do so in the Radish Health mobile app, at https://patient.radish.health, or email help@radish.health.",
      location: appointmentInfo.isOnsite
        ? appointmentInfo.location
        : appointmentInfo.vrurl,
      startsAt: toDate,
      endsAt: endDate,
    };
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setMinutes((minutes) => minutes - 1);
      }, 60000);
    } else if (!isActive && minutes !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, minutes]);

  return (
    <>
      <div className="appointment-card">
        <div className="appointment-card-top">
          <div className="act-time-title">
            <div className="act-left">
              <span className="act-flag">
                {appointmentInfo.appointmentTypeDisplayText}
              </span>
              <span className="act-title">{appointmentInfo.examRoomName}</span>
            </div>
            <div className="act-right">
              <span className="act-time">
                {appointmentInfo.localTime} {appointmentInfo.timeZone}
              </span>
            </div>
          </div>
          <div className="act-middle">
            <div className="act-middle-left">
              <div className="avatar-info">
                <div className="avatar-info-in">
                  <figure>
                    <img
                      src={appointmentInfo.profilePicture || Logo}
                      alt=""
                      className="img-responsive"
                    />
                  </figure>
                  <div className="ai-meta">
                    <span
                      className={`ai-title ${
                        appointmentInfo.doctor.length > 20 && "title-long"
                      }`}
                    >
                      {appointmentInfo.doctor}
                    </span>
                    {appointmentInfo.careTeam === 1 ? (
                      <span className="ai-cta">
                        <i className="icon icon-heart"></i>
                        {labels.LBL_YOUR_CARE_TEAM}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="act-middle-right">
              <span className="act-day">{appointmentDay}</span>
              <span className="act-day-num">{appointmentDate}</span>
              <span className="act-month">{appointmentMonth}</span>
            </div>
          </div>
        </div>
        {minutes <= 10 && appointmentInfo.isOnsite === 0 ? (
          <div className="appointment-card-bottom coloredbg">
            <div className="acb-ctas">
              <a
                href={appointmentInfo.vrurl}
                target="_blank"
                rel="noreferrer"
                className="join-call-now"
              >
                {labels.LBL_UPCOMING_JOIN_NOW}
              </a>
            </div>
          </div>
        ) : (
          <div className="appointment-card-bottom">
            <div className="acb-top">
              {appointmentInfo.isOnsite === 0
                ? labels.LBL_JOIN_CALL
                : appointmentInfo.location}
            </div>
            <div className="acb-ctas">
              <AddToCalendar
                event={getCalendarEvent()}
                children={"+ Add to calendar"}
              />

              <button
                className="cta-circle unset-btn"
                onClick={openSidePopup}
              ></button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UpcomingAppointmentCard;
