import SendBird from "sendbird";
// Login & Register
export const LOCALIZATION_API = "/general/generic/v2/localization";
export const LOGINAPI = "/identity/v2/web-login";
export const FORGOTPASSWORD_API = "/identity/v2/forgot-password";
export const REGISTER_API = "/registration/v2/register";
export const SELF_REGISTRATION = "/registration/v2/self-registration";
export const OTP_AUTH = "/registration/v2/self-registration/otp-auth";
export const GET_SELF_DATA = "/registration/v2/self-registration/";
export const RESET_PASSWORD = "/identity/v2/reset-password";
export const VERIFY_RESET_PASSWORD =
  "/identity/v2/reset-password/validate/token";

// Appointments
export const GETAPPOINTMENT_API = "/appointment/v2/list";
export const GETPASTAPPOINTMENT_API = "/appointment/v2/history";
export const GETPASTAPPOINTMENTSUMMARY_API = "/appointment/v2/past/summary";
export const POSTUPDATESUMMARYNOTESREAD =
  "/appointment/v2/updatesummarynotestatus";
export const GETAPPOINTMENT_SLOT_API = "/appointment/v2/doctor/slots/available";
export const POSTAPPOINTMENT_BOOK_API = "/appointment/v2/schedule";
export const CANCEL_APPOINTMENT_API = "/appointment/v2/cancel";

// Dashboard Sliders
export const GETAPPOINTMENTTYPE_API = "/appointment/v2/appointmenttype";
export const SLIDER_API = "/promotion/v2/sliders";
export const SLIDER_API_LIST = "/promotion/v2/sliders/list";
export const GETSERVICES_API = "/promotion/v2/services";

// Documents
export const DOCUMENTS_LIST_API = "/document/v2/list";
export const DOCUMENTS_LAB_RESULTS_INFO = "/externalagent/v2/ddh/labstatus";
export const DOCUMENTS_TYPES_API = "/document/documenttype/v2/type";
export const DOCUMENTS_VACCINE_TYPES_API = "/document/vaccine/v2/type";
export const DOCUMENTS_VACCINE_MANUFACTURER_API =
  "/document/vaccine/v2/manufacturer";
export const DOCUMENTS_LAB_TYPES_API = "/document/labtype/v2/type";
export const DOCUMENTS_LAB_RESULTS_API = "/document/labresult/v2/result/";
export const DOCUMENTS_UPLOAD_VACCINE = "/document/v2/vaccine";
export const DOCUMENTS_UPLOAD_LAB = "/document/v2/lab";
export const DOCUMENTS_UPLOAD_OTHER = "/document/v2/other";
export const DRIP_DROP_LAB_STATUS = "/externalagent/v2/ddh/labstatus";
export const DOCUMENTS_UNREAD_COUNT = "/externalagent/v2/unreaddoccount";

// Profile
export const GETUSERPROFILE = "/user/v2/profile";
export const SAVEUSERPROFILE = "/user/v2/profile";
export const SAVEUSERPHOTO = "/user/v2/profile/photo";
export const ADDFAMILY = "/user/v2/dependent";
export const GETFAMILY = "/user/v2/dependent";
export const REMOVEINVITE = "/user/v2/dependent/";
export const USER_CONSENT = "/user/v2/consent";
export const GETPHARMACY = "/user/v2/pharmacy";
export const SAVEPHARMACY = "/user/v2/pharmacy";

// Identity
export const CHANGEPASSWORD = "/identity/v2/change-password";

// Insurance Provider
export const GETINSURANCEPROVIDER = "/insurance/v2/details";
export const SAVEINSURANCEPROVIDER = "/insurance/v2/details";
export const SAVECARDDETAILS = "/insurance/v2/card";

// MyTodo
export const GETMYTODO = "/externalagent/v2/todo";
export const GETWORKPASS = "/externalagent/v2/dailyworkpass";

// Doctors
export const GETDOCTORSLIST = "/doctor/v2/state";
export const GETDOCTORSSPECIALITY = "/doctor/v2/speciality";
export const UPDATECARETEAM = "/doctor/v2/careteam";

// DHA
export const DHASTATUS = "/externalagent/v2/dhastatus";
export const DHAWEBVIEW = "/externalagent/v2/dhastatusmobile";

// Health Checklist
export const HEALTHCHECK_LIST = "/v2/patient/healthcheck/list";
export const HEALTHCHECK = "/v2/patient/healthcheck";

// Medical History
export const MEDICAL_CONDITION_SEARCH =
  "https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?maxList=500,sf=primary_name,consumer_name,synonyms&ef=primary_name,consumer_name,key_id,icd10cm_codes,icd10cm,term_icd9_code,term_icd9_text,word_synonyms,synonyms,info_link_data&authenticity_token=&terms=";
export const MEDICATION_SEARCH =
  "https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?sf=DISPLAY_NAME,DISPLAY_NAME_SYNONYM&ef=DISPLAY_NAME,STRENGTHS_AND_FORMS,RXCUIS,SXDG_RXCUI,DISPLAY_NAME_SYNONYM&authenticity_token=&terms=";
export const USER_MEDICAL_CONDITIONS = "/v2/patient/medicalcondition";
export const USER_MEDICATIONS = "/v2/patient/medication";
export const USER_MEDICAL_HISTORY = "/v2/patient/medicalhistory";
export const USER_MEDICAL_HISTORY_VERIFIED =
  "/v2/patient/medicalhistory/verified";

// Sendbird
const SENDBIRD_APP_ID_DEV = "64E89025-0E4D-4019-9E3B-0B0C800DFE70";
const SENDBIRD_APP_ID_PROD = "373E78AA-AC12-464C-8A4C-87ED981E884A";
const appId =
  process.env.REACT_APP_ENV === "staging"
    ? SENDBIRD_APP_ID_DEV
    : SENDBIRD_APP_ID_PROD;
export const sendbird = new SendBird({ appId });

// Misc
export const POSTEVENTTRACK = "/general/generic/v2/event/track";

//Stripe
export const STRIPEAPI ="/externalagent/v2/stripe/customerportal"
//Care Cost

export const CARECOSTAPI="/general/generic/v2/servicecost/notes"
export const PROFILESERVICEAPI="/promotion/v2/company/profile";
