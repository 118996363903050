import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "./containers/Login.js";
import Policies from "./containers/Policies.js";
import NotFound from "./components/common/NotFound.js";
import DHAWebView from "./utils/DHAWebView.js";
import renderPageTitle from "./utils/RenderPageTitle.js";

import Dashboard from "./containers/Dashboard.js";
import RequireAuth from "./utils/RequireAuth.js";
import Footer from "./components/footer/Footer.js";
import HeaderMessage from "./components/common/HeaderMessage";
import { withCookies } from "react-cookie";
import withLabels from "./HOC/withLabels";
import { compose } from "redux";

import { VR_URL } from "./utils/Environment";
import redirectToRoute from "./utils/InternalRouting";

function App({ cookies, persistor, ...props }) {
  const abc = localStorage.getItem("loginInfo");
  const location = useLocation();
  let navigate = useNavigate();
  const { slidersList } = useSelector((state) => state.explore);

  const { token } = abc ? JSON.parse(abc) : { token: "dummuy" };
  let key = token;
  if (token) {
    key = token.split("=&")[1];
  }
  if (!cookies.get(key)) {
    localStorage.clear();
  }

  if (Boolean(location.search)) {
    let searchParam = new URLSearchParams(location.search);
    if (
      searchParam.get("pageName") &&
      searchParam.get("pageName").toLocaleLowerCase() === "vr"
    ) {
      redirectToRoute(
        `${VR_URL}?${location.search}`,
        "URLSAMEWINDOW",
        navigate
      );
    } else if (searchParam.get("pageName") && searchParam.get("pageName").toLocaleLowerCase() === "redirect" ) {
      // debugger;
      // let test = `${location.search}`;
      let rurl = searchParam.get("RURL");
      redirectToRoute(`${rurl}`,"URLSAMEWINDOW",navigate);
    }
    else {
      sessionStorage.setItem("queryParam", location.search);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {props.labels && props.labels.HEADER_MSG && (
        <HeaderMessage message={props.labels.HEADER_MSG} />
      )}
      {renderPageTitle(location, slidersList)}
      <Routes>
        <Route path="/*" element={<Login />} />
        <Route
          path="/dashboard/*"
          element={
            <RequireAuth cookies={cookies}>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/maintenance"
          element={
            <NotFound
              type={"maintenance"}
              message={props.labels && props.labels.LBL_MAINTENANCE}
            />
          }
        />
        <Route path="/policies/*" element={<Policies />} />
        <Route path="/dha-webview/" element={<DHAWebView />} />
        <Route path="/404" element={<NotFound type={"404"} />} />
      </Routes>
      {location.pathname !== "/dha-webview" && <Footer />}
    </>
  );
}

export default compose(withLabels, withCookies)(App);
