const doctorsState = {
  doctors: [],
  specialityDoctors: [],
  isDoctorsListLoading: false,
  isDoctorsListError: false,
  isDoctorsListRequested: false,
  isDoctorsSpecialityLoading: false,
  isDoctorsSpecialityError: false,
};

export const doctorsReducer = (state = doctorsState, action) => {
  if (action.type === "DOCTORS_LIST_REQUEST") {
    return {
      ...state,
      isDoctorsListLoading: true,
      isDoctorsListError: false,
      isDoctorsListRequested: true,
      doctors: [],
    };
  }

  if (action.type === "DOCTORS_LIST_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isDoctorsListLoading: false,
      isDoctorsListError: false,
      doctors: [...payload],
    };
  }

  if (action.type === "DOCTORS_LIST_FAIL") {
    return {
      ...state,
      isDoctorsListLoading: false,
      isDoctorsListError: true,
    };
  }

  if (action.type === "DOCTORS_SPECIALITY_REQUEST") {
    return {
      ...state,
      isDoctorsSpecialityLoading: true,
      isDoctorsSpecialityError: false,
      specialityDoctors: [],
    };
  }

  if (action.type === "DOCTORS_SPECIALITY_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isDoctorsSpecialityLoading: false,
      isDoctorsSpecialityError: false,
      specialityDoctors: [...payload],
    };
  }

  if (action.type === "DOCTORS_SPECIALITY_FAIL") {
    return {
      ...state,
      isDoctorsSpecialityLoading: false,
      isDoctorsSpecialityError: true,
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      doctors: [],
      specialityDoctors: [],
      isDoctorsListLoading: false,
      isDoctorsListError: false,
      isDoctorsListRequested: true,
    };
  }

  return state;
};
