const insuranceProviderState = {};

export const insuranceReducer = (state = insuranceProviderState, action) => {
  if (action.type === "GET_INSURANCEPROVIDER_REQUEST") {
    return {
      ...state,
      isInsuranceProviderLoading: true,
    };
  }

  if (action.type === "GET_INSURANCEPROVIDER_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isInsuranceProviderLoading: false,
      insuranceProviderInfo: { ...payload },
    };
  }

  if (action.type === "GET_INSURANCEPROVIDER_FAIL") {
    return {
      ...state,
      isInsuranceProviderLoading: false,
    };
  }

  if (action.type === "SAVE_INSURANCE_REQUEST") {
    return {
      ...state,
      isSaveInsuranceProviderLoading: true,
    };
  }

  if (action.type === "SAVE_INSURANCE_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isSaveInsuranceProviderLoading: false,
      saveInsuranceProvider: { ...payload },
    };
  }

  if (action.type === "SAVE_INSURANCE_FAIL") {
    return {
      ...state,
      isSaveInsuranceProviderLoading: false,
    };
  }

  if (action.type === "SAVE_CARDDETAILS_REQUEST") {
    return {
      ...state,
      isSaveInsuranceCardLoading: true,
    };
  }

  if (action.type === "SAVE_CARDDETAILS_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isSaveInsuranceCardLoading: false,
      saveInsuranceCard: { ...payload },
    };
  }

  if (action.type === "SAVE_CARDDETAILS_FAIL") {
    return {
      ...state,
      isSaveInsuranceCardLoading: false,
    };
  }

  if (action.type === "SAVE_INSURANCE_CLEAR") {
    return {
      ...state,
      isSaveInsuranceProviderLoading: false,
      saveInsuranceProvider: null,
      isInvalidCardPhoto: false,
    };
  }

  if (action.type === "INVALID_CARDPHOTO") {
    return {
      ...state,
      isInvalidCardPhoto: true,
    };
  }

  if (action.type === "INVALID_CARDPHOTO_CLEAR") {
    return {
      ...state,
      isInvalidCardPhoto: false,
    };
  }

  return state;
};
