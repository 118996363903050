import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { forgotPasswordAction } from "../../redux/actions/loginUserAction.js";
import Alert from "../common/Alert.js";
import { useDispatch } from "react-redux";
import Spinner from "../common/Spinner.js";

const SetYourPassword = ({ labels = {} }) => {
  let dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [isSubmit, setSubmitted] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [termsCondition, setTermsCondition] = useState(false);

  function passwordInputChange({ target }) {
    const { value } = target;
    setPassword(value);
  }

  function rePasswordInputChange({ target }) {
    const { value } = target;
    setRePassword(value);
  }

  function termsConditionInputChange() {
    setTermsCondition(!termsCondition);
  }

  function submitRequest(event) {
    event.preventDefault();
    if (password == repassword) {
      setPasswordError("");
      setSubmitted(true);
      if (password && repassword && !passwordError) {
        // dispatch(forgotPasswordAction(email));
      }
    } else {
      setPasswordError("Password does not match");
    }
  }

  function createMarkup(text) {
    return { __html: text };
  }

  return (
    <div className="lg-form-wrap" id="Set-pass-form-main">
      <h3 className="login-heading">{labels.LBL_SET_PASS}</h3>
      {userInfo.isLoading && <Spinner />}
      <form>
        <div className="form-group mb-20">
          <label htmlFor="email">{labels.LBL_LOGIN_PASSWORD}</label>
          <div className="inputwrap">
            <input
              type="password"
              className="form-control form-control-lg"
              id="password"
              name="password"
              maxLength="50"
              onChange={passwordInputChange}
              value={password}
            />
            {isSubmit && !password && (
              <span className="error-label">{labels.MSG_REQ}</span>
            )}
          </div>
        </div>
        <div className="form-group mb-20">
          <label htmlFor="email">{labels.LBL_LOGIN_RE_PASSWORD}</label>
          <div className="inputwrap">
            <input
              type="password"
              className="form-control form-control-lg"
              id="retype-password"
              name="retype-password"
              maxLength="50"
              onChange={rePasswordInputChange}
              value={repassword}
            />
            {passwordError && (
              <span className="error-label">{passwordError}</span>
            )}
            {isSubmit && !repassword && (
              <span className="error-label">{labels.MSG_REQ}</span>
            )}
          </div>
        </div>
        <div className="form-group mb-20">
          <label className="check-label">
            <input
              type="checkbox"
              name="set-pass-agree"
              id="set-pass-agree"
              className="form-check-input set-pass-agree"
              defaultChecked={termsCondition}
              onChange={termsConditionInputChange}
            />
            <div
              dangerouslySetInnerHTML={createMarkup(labels.LBL_PRIVACY_POLICY)}
            ></div>
          </label>
          {isSubmit && !termsCondition && (
            <span className="error-label">{labels.MSG_REQ}</span>
          )}
        </div>
        <button
          type="submit"
          onClick={submitRequest}
          className="btn btn-primary btn-lg col-12"
        >
          {labels.LBL_REGISTER_ACC}
        </button>
        <div className="switch-cta-wrap">
          <Link to="../login" className="link-type-1">
            <strong>{labels.LBL_BACK_LOGIN}</strong>
          </Link>
        </div>
      </form>
    </div>
  );
};
export default SetYourPassword;
