import { useDispatch, useSelector } from "react-redux";
import { NotesReadAction } from "../../redux/actions/dashboardSidebarAction.js";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions.js";

const PastAppointmentNotesCard = ({ appointmentInfo = {}, labels = {} }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);


  const payload = {
    component: 3,
    content: appointmentInfo,
  };

  
  const onSidePopupOpenClick = () => {
    const { isSummaryNoteRead, summaryNotes, appointmentID } = appointmentInfo;
    if (isSummaryNoteRead === 0 && summaryNotes) {
      dispatch({type:"MARK_NOTE_READ", appointmentID});
      dispatch(NotesReadAction(token, appointmentID));
    }
    dispatch(sidePopupOpen(payload))
  };



  return (
    <div className="radish-white-card-1">
      <div className="avatar-info">
        <div className="avatar-info-in">
          <figure>
            <img
              src={appointmentInfo.profilePicture}
              style={{ height: 40, width: 40 }}
              alt=""
            />
          </figure>
          <div className="ai-meta">
            <span className="ai-title">{appointmentInfo.doctor}</span>
            {appointmentInfo.careTeam === 1 ? (
              <span className="ai-cta">
                <i className="icon icon-heart"></i>
                {labels.LBL_YOUR_CARE_TEAM}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <span className="rwc-para">
        {appointmentInfo.summaryNotes.slice(0, 75)}...
      </span>
      <span
        className="cta-circle"
        onClick={onSidePopupOpenClick}
      ></span>
    </div>
  );
};

export default PastAppointmentNotesCard;
