import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import { SaveUserProfileAction } from "../../../redux/actions/profileAction";
import ProfilePhoto from "../../profile/ProfilePhoto";
import Spinner from "../../common/Spinner";
import AutoComplete from "../../login/AutoComplete";
import Alert from "../../common/Alert.js";
import {
  sidePopupClose,
  sidePopupLoading,
  sidePopupConfirmed,
  sidePopupNotLoading,
} from "../../../redux/actions/sidePopupActions.js";
import { withCookies } from "react-cookie";
import { logoutUser } from "../../../redux/actions/logoutUser";

const clearProfileError = () => ({
  type: "CLEAR_PROFILE_ERROR",
});

const AddUserProfile = ({ cookies }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const userProfile = useSelector((state) => state.userProfile);

  const [values, setvalues] = useState(userProfile.userProfile);
  const [errorObj, setError] = useState({});
  const [isSubmit, setSubmitted] = useState(false);
  const labels = useSelector((state) => state.app.labels);

  useEffect(() => {
    setvalues(userProfile.userProfile);
    setError({});
    setSubmitted(false);
  }, [userProfile.userProfile]);

  useEffect(() => {
    if (userProfile.is404Error === 401) {
      cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [userProfile.is404Error, dispatch, cookies]);

  function handleInputChange({ target }) {
    const { name, value } = target;
    if (userProfile.saveProfileError) {
      dispatch(clearProfileError());
    }
    if (target.dataset.stateAddress) {
      if (/^[a-zA-Z]+$/.test(value) || value === "")
        setvalues({ ...values, [name]: value.toUpperCase() });
    } else setvalues({ ...values, [name]: value });
  }

  const saveUserProfile = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (!checkFormError() && validationError()) {
      dispatch(sidePopupLoading());
      await dispatch(SaveUserProfileAction(userInfo.token, values));
      dispatch(sidePopupNotLoading());
    }
  };

  useEffect(() => {
    const { profileSaved } = userProfile;
    if (profileSaved) {
      dispatch(sidePopupConfirmed());
      setTimeout(() => {
        dispatch(sidePopupClose());
      }, 3000);
    }
  }, [userProfile, dispatch]);

  const checkFormError = () => {
    const { phoneNumber, email, street, city, state, zip } = values;

    if (!phoneNumber || !email || !street || !city || !state || !zip) {
      return true;
    }
    return false;
  };

  const validationError = () => {
    if (Object.keys(errorObj).length > 0) {
      if (errorObj.mobError) {
        return false;
      }
      if (errorObj.emailError) {
        return false;
      }
      if (errorObj.zipError) {
        return false;
      }
    }
    return true;
  };

  const onEmailBlur = () => {
    const { email } = values;
    var re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      setError({ ...errorObj, emailError: labels.MSG_EMAIL_REQ });
      return;
    }
    setError({ ...errorObj, emailError: "" });
  };

  const checkMob = () => {
    const { phoneNumber } = values;
    if (phoneNumber && phoneNumber.length < 12) {
      setError({ ...errorObj, mobError: labels.MSG_INVALID_PHONE });
      return;
    }
    setError({ ...errorObj, mobError: "" });
  };

  const onZipBlur = () => {
    const { zip } = values;
    var re = /\d{5}(-\d{4})?/;
    if (!re.test(zip)) {
      setError({ ...errorObj, zipError: labels.MSG_ZIP_REQ });
      return;
    }
    setError({ ...errorObj, zipError: "" });
  };

  const clearError = () => dispatch({ type: "SAVE_USERPROFILE_CLEAR" });
  return userProfile.saveUserProfile ? (
    <h2 className="app-heading-1 mb-40">{labels.MSG_ADDPROFILE_UPDATE}</h2>
  ) : (
    <>
      <h3 className="app-heading-1 mb-40">{labels.LBL_ADDPROFILE_HEADER}</h3>
      <div className="avatar-slide-top">
        {userProfile.isUserProfileLoading ||
        userProfile.isSaveUserProfilePhotoLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <ProfilePhoto
            profilePhoto={values.photo}
            firstName={values.firstName}
            lastName={values.lastName}
          />
        )}
      </div>
      {userProfile.isInvalidProfilePhoto && (
        <Alert
          messageTitle="ERROR:"
          message="Invalid file format"
          cssClassName={"alert alert-danger mt-20"}
          clearError={clearError}
        />
      )}
      <div className="popup-mandatory-note">Required fields*</div>
      <div className="popup-form-wrap">
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="email">{labels.LBL_REGISTER_EMAIL} *</label>
              <div className="inputwrap">
                <input
                  type="email"
                  maxLength="250"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.email && "input-invalid"
                  } ${
                    userProfile.saveProfileError.includes("Email") &&
                    "input-invalid"
                  }`}
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleInputChange}
                  onBlur={onEmailBlur}
                />
                {values.email && errorObj.emailError && (
                  <span className="error-label">{errorObj.emailError}</span>
                )}
                {isSubmit && !values.email && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
                {userProfile.saveProfileError ? (
                  <span className="error-label">
                    {userProfile.saveProfileError.includes("Email") &&
                      userProfile.saveProfileError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="mobile-number">
                {labels.LBL_REGISTER_MOBILE} *
              </label>
              <div className="inputwrap">
                <InputMask
                  type="text"
                  autoComplete="off"
                  placeholder="xxx-xxx-xxxx"
                  inputMode="tel"
                  className={`form-control form-control-lg ${
                    ((values.phoneNumber && errorObj.mobError) ||
                      (isSubmit && !values.phoneNumber)) &&
                    "input-invalid"
                  }`}
                  name="phoneNumber"
                  id="phoneNumber"
                  mask="999-999-9999"
                  maskChar=""
                  onBlur={checkMob}
                  value={values.phoneNumber}
                  onChange={handleInputChange}
                />
                {values.phoneNumber && errorObj.mobError && (
                  <span className="error-label">{errorObj.mobError}</span>
                )}
                {isSubmit && !values.phoneNumber && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="nickname">{labels.LBL_REGISTER_NICKNAME}</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  maxLength="50"
                  autoComplete="off"
                  name="nickName"
                  id="nickname"
                  value={values.nickName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="address">{labels.LBL_REGISTER_ADDRESS}</label>
              <div className="inputwrap">
                <AutoComplete
                  values={values}
                  setvalues={setvalues}
                  isSubmit={isSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="address-1">{labels.LBL_REGISTER_STREET} *</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.street && "input-invalid"
                  }`}
                  maxLength="50"
                  name="street"
                  id="street"
                  value={values.street}
                  onChange={handleInputChange}
                />
                {isSubmit && !values.street && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="address-2">{labels.LBL_REGISTER_APT}</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  maxLength="50"
                  name="street2"
                  id="street2"
                  value={values.street2}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row g-3 mb-5">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="city">{labels.LBL_REGISTER_CITY} *</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.city && "input-invalid"
                  }`}
                  maxLength="50"
                  autoComplete="off"
                  name="city"
                  id="city"
                  value={values.city}
                  onChange={handleInputChange}
                />
                {isSubmit && !values.city && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-5">
          <div className="col-6">
            <div className="form-group mb-15">
              <label htmlFor="state">{labels.LBL_REGISTER_STATE} *</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.state && "input-invalid"
                  }`}
                  maxLength="2"
                  autoComplete="off"
                  name="state"
                  id="state"
                  data-state-address={true}
                  value={values.state}
                  onChange={handleInputChange}
                />
                {isSubmit && !values.state && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group mb-15">
              <label htmlFor="zip">{labels.LBL_REGISTER_ZIP} *</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.zip && "input-invalid"
                  }`}
                  maxLength="50"
                  autoComplete="off"
                  name="zip"
                  id="zip"
                  value={values.zip}
                  onBlur={onZipBlur}
                  onChange={handleInputChange}
                />
                {values.zip && errorObj.zipError && (
                  <span className="error-label">{errorObj.zipError}</span>
                )}
                {isSubmit && !values.zip && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg col-12"
          disabled={userProfile.isSaveUserProfileLoading}
          onClick={saveUserProfile}
        >
          {userProfile.isSaveUserProfileLoading ? <Spinner /> : "Save Profile"}
        </button>
      </div>
    </>
  );
};

export default withCookies(AddUserProfile);
