import { useSelector, useDispatch } from "react-redux";
import { sidePopupClose } from "../../redux/actions/sidePopupActions";
import AddNewDocument from "./sidepopup/AddNewDocument";
import AppointmentInfo from "./sidepopup/AppointmentInfo";
import BookAppPopup from "./sidepopup/BookAppPopup";
import SummaryNotes from "./sidepopup/SummaryNotes";
import AddUserProfile from "./sidepopup/AddUserProfile";
import AddInsuranceProvider from "./sidepopup/AddInsuranceProvider";
import ChangePassword from "./sidepopup/ChangePassword";
import AddFamilyMemberPopup from "./sidepopup/AddFamilyMemberPopup";
import AddMedicalHistory from "./sidepopup/MedicalHistory/AddMedicalHistory";
import EditMedicalHistory from "./sidepopup/MedicalHistory/EditMedicalHistory";
import DownloadApp from "./sidepopup/DownloadApp";
import EditCareTeam from "./sidepopup/EditCareTeam";
import AddPharmacyInfo from "./sidepopup/AddPharmacyInfo";
import CareCost from "./sidepopup/CareCost";

const Content = ({ ...props }) => {
  const contentValue = useSelector((state) => state.sidePopup.component);

  const values = {
    0: null,
    1: <AddNewDocument />,
    2: <AppointmentInfo />,
    3: <SummaryNotes />,
    4: <BookAppPopup />,
    5: <AddUserProfile />,
    6: <AddInsuranceProvider />,
    7: <ChangePassword />,
    8: <AddFamilyMemberPopup />,
    9: <AddMedicalHistory />,
    10: <EditMedicalHistory />,
    11: <DownloadApp />,
    12: <EditCareTeam {...props} />,
    13: <AddPharmacyInfo />,
    14: <CareCost />
  };

  return values[contentValue];
};

const SidePopup = ({ ...props }) => {
  const dispatch = useDispatch();
  const contentValue = useSelector((state) => state.sidePopup.component);
  const { isLoading, isConfirmed, isFailed } = useSelector(
    (state) => state.sidePopup
  );

  return (
    <>
      <div
        className={`side-popup ${isConfirmed && "confirmed"} ${
          isFailed && "failed"
        }`}
      >
        {!isLoading && (
          <button
            className="close-popup unset-btn"
            onClick={() => {
              dispatch(sidePopupClose());
            }}
          >
            Close
          </button>
        )}
        <div className={`side-popup-inner ${contentValue === 3 && "less-pad"}`}>
          <Content {...props} />
        </div>
      </div>
      <div
        className="popup-overlay"
        onClick={() => {
          if (!isLoading) dispatch(sidePopupClose());
        }}
      ></div>
    </>
  );
};

export default SidePopup;
