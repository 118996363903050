import parse from "html-react-parser";

const CareTeamAttentionModal = ({ labels, content }) => {
  let { appointmentData, selectedAppType } = content;
  return (
    <div className="center-popup-data">
      <p>
        {appointmentData.filter((app) => app.parentKey === selectedAppType)
          .length > 0
          ? parse(
              appointmentData.filter(
                (app) => app.parentKey === selectedAppType
              )[0].attentionMessage
            )
          : ""}
      </p>
    </div>
  );
};

export default CareTeamAttentionModal;
