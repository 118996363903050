const doctorsState = {
  healthChecklist: [],
};

export const healthCheckReducer = (state = doctorsState, action) => {
  if (action.type === "GET_HEALTHCHECK_REQ") {
    return {
      ...state,
      isGetHealthcheckLoading: true,
      isGetHealthcheckError: false,
    };
  }

  if (action.type === "GET_HEALTHCHECK_REC") {
    const { payload } = action;
    return {
      ...state,
      isGetHealthcheckLoading: false,
      isGetHealthcheckError: false,
      healthChecklist: { ...payload },
    };
  }

  if (action.type === "GET_HEALTHCHECK_FAIL") {
    return {
      ...state,
      isGetHealthcheckLoading: false,
      isGetHealthcheckError: true,
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      healthChecklist: [],
    };
  }

  return state;
};
