import { Link } from "react-router-dom";
import qrCode from "../../assets/img/png/reg-download-app-qr.png";

const RegistrationDownload = (props) => {
  const { labels = {}, selfRegisterData = {} } = props;

  return (
    <div className="lg-form-wrap registration-download" id="login-form-main">
      <h3 className="login-heading">Download the Mobile App</h3>
      <div className="form-group">
        <label htmlFor="phone-number-email">
          For the best experience, scan the QR code to download the app and
          complete registration.
        </label>
        <a href="https://go.radish.health/reginvite">
          <img src={qrCode} alt="Download the Radish Health App" />
        </a>
        <div className="switch-cta-wrap"></div>
      </div>

      <div className="switch-cta-wrap">
        <span className="link-type-1">Alternatively,</span>
        <Link to="../retrieve-registration" className="link-type-1">
          <strong> continue registering on the web</strong>
        </Link>
      </div>
    </div>
  );
};

export default RegistrationDownload;
