import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import Spinner from "../../Spinner";

const AddHealthCondition = ({
  currentSearchTerm,
  setCurrentSearchTerm,
  searchResults,
  setSearchResults,
  selectedResult,
  setSelectedResult,
  onInputChange,
  isLongEnough,
}) => {
  const handleSelect = (input) => {
    const { target } = input;
    setSelectedResult(target.dataset.index);
  };

  const handleSearch = ({ target }) => {
    setCurrentSearchTerm(target.value);
    onInputChange(target.value, 1);
    if (!target.value) setSelectedResult(-1);
  };

  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  }, []);

  const { isSearchFetching } = useSelector((state) => state.medicalHistory);

  return (
    <>
      <input
        type="text"
        className="form-control form-control-lg medical-history-search mb-20"
        placeholder="Search Health Conditions"
        value={currentSearchTerm}
        onChange={handleSearch}
        ref={inputEl}
      />
      <div className="history-results-container">
        {currentSearchTerm && searchResults.length > 0 && (
          <>
            <p>
              Search Results (
              {searchResults.length === 500
                ? `${searchResults.length}+`
                : searchResults.length}
              )
            </p>
            <ul className="history-results-list">
              {searchResults.map((result, index) => {
                return (
                  <li
                    key={index}
                    data-index={index}
                    className={`${
                      index === parseInt(selectedResult) && "selected"
                    }`}
                    onClick={handleSelect}
                  >
                    {result}
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {isSearchFetching && <Spinner />}
        {currentSearchTerm &&
          !searchResults.length &&
          !isSearchFetching &&
          isLongEnough && <p>No results found...</p>}
      </div>
    </>
  );
};

export default AddHealthCondition;
