import { useNavigate } from "react-router-dom";

import caveman404 from "../../assets/img/gif/caveman404.gif";

const NotFound = ({ type, message }) => {
  let navigate = useNavigate();

  return (
    <section className="app-main">
      <div className="container">
        {type === "404" && (
          <div className="app-screen-title">
            <div className="app-screen-title-left">
              <button
                className="back-t-screen-cta unset-btn"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <h2>404 Error</h2>
            </div>
            <div className="app-screen-title-right"></div>
          </div>
        )}
        <div className="div-not-found">
          <figure>
            <img
              src={caveman404}
              alt="A caveman trying to fix a broken wire and shocking himself"
            />
          </figure>
          {type === "404" && (
            <div class="div-404">
              <h3>Looks like you're lost</h3>
              <p>
                We couldn't find the page you were looking for. This is either
                because:
              </p>
              <ul>
                <li>
                  There is an error in the URL entered into your web browser.
                  Please check the URL and try again.
                </li>
                <li>The page you are looking for has been moved or deleted.</li>
              </ul>
            </div>
          )}
          {type === "maintenance" && (
            <div class="div-maintenance">
              <h3>{message}</h3>
              <p>Please try again later.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default NotFound;
