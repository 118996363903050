import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getExploreList,
  getExploreListMore,
} from "../../redux/actions/exploreActions.js";

import Spinner from "../common/Spinner";

const ExploreContent = ({ labels = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    slidersList,
    isExploreListLoading,
    isExploreListMoreLoading,
    explorePageCount,
  } = useSelector((state) => state.explore);
  const { token } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);

  const parseTitle = (slider) => {
    return slider.title.replace(/[^a-zA-Z ]/g, "").replace(/ /g, "-");
  };

  useEffect(() => {
    const payload = {
      isActive: true,
      pageSize: 9,
      pageNumber: currentPage,
    };
    dispatch(getExploreList(token, payload));
  }, []);

  const loadMore = () => {
    const nextPage = currentPage + 1;
    const payload = {
      isActive: true,
      pageSize: 9,
      pageNumber: nextPage,
    };
    dispatch(getExploreListMore(token, payload));
    setCurrentPage(nextPage);
  };

  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left care-team-title">
          <div>
            <button
              onClick={() => navigate(-1)}
              className="back-t-screen-cta unset-btn"
            >
              Back
            </button>
            <h2>{labels.LBL_EXPLORE_CONTENT}</h2>
          </div>
        </div>
      </div>

      <div className="explore-content-container">
        {isExploreListLoading ? (
          <Spinner />
        ) : (
          <div className="row gt-4 explore-content-row">
            {slidersList.length > 0 &&
              slidersList.map((slider, index) => {
                if (slider.link)
                  return (
                    <div
                      key={index}
                      className="explore-content-card col-12 col-lg-4 col-md-6"
                    >
                      <a
                        href={slider.link}
                        rel="noreferrer"
                        target="_blank"
                        className="explore-item"
                      >
                        <figure>
                          <img
                            src={slider.image}
                            alt=""
                            className="img-responsive"
                          />
                        </figure>
                        <span>{slider.title}</span>
                      </a>
                    </div>
                  );

                return (
                  <div
                    key={index}
                    className="explore-content-card col-12 col-lg-4 col-md-6"
                  >
                    <Link
                      to={`../explore/${parseTitle(slider)}/${Math.ceil(
                        (index + 1) / 9
                      )}/${slider.sliderId}`}
                      state={{ slider: slider }}
                      className="explore-item"
                    >
                      <figure>
                        <img
                          src={slider.image}
                          alt=""
                          className="img-responsive"
                        />
                      </figure>
                      <span>{slider.title}</span>
                    </Link>
                  </div>
                );
              })}
            {isExploreListMoreLoading && (
              <div>
                <Spinner />
              </div>
            )}
          </div>
        )}
        {explorePageCount !== currentPage && !isExploreListLoading ? (
          <button
            className="btn btn-primary load-more ucase"
            onClick={loadMore}
          >
            Load more
          </button>
        ) : null}
      </div>
    </>
  );
};

export default ExploreContent;
