import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/actions/logoutUser.js";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { withCookies } from "react-cookie";
import { compose } from "redux";

import AppointmentTypes from "../components/dashboard/AppointmentTypes";
import MyHealth from "../components/dashboard/MyHealth";
import Explore from "../components/dashboard/Explore";
import ExploreContent from "../components/dashboard/ExploreContent";
import Post from "../components/dashboard/Post";
import Documents from "../components/dashboard/Documents";
import Appointments from "../components/dashboard/Appointments";
import CareTeam from "../components/dashboard/CareTeam";
import TeamHealth from "../components/dashboard/TeamHealth";
import InviteFamily from "../components/dashboard/InviteFamily";
import BookWorkplace from "../components/dashboard/appointment/BookWorkplace";
import DHAResult from "../components/dashboard/DHAResult";
import BookAppointments from "../components/dashboard/appointment/BookAppointments";
import MyProfile from "../components/profile/MyProfile";
import HealthChecklist from "../components/dashboard/HealthChecklist";
import MedicalHistory from "../components/dashboard/medicalHistory/MedicalHistory";
import CanAccess from "../components/helpers/CanAccess";
import MyWorkPass from "../components/dashboard/MyWorkPass";
import Nav from "../components/common/Nav";
import Sidebar from "../components/dashboard/Sidebar";
import SidePopup from "../components/common/SidePopup";
import Modal from "../components/common/Modal";
import ChatBubble from "../components/common/ChatBubble";

import withLabels from "../HOC/withLabels";
import {
  modalClose,
  modalOpen as modalOpenAction,
} from "../redux/actions/modalAction";
import redirectToRoute from "../utils/InternalRouting";

import Labels from "../mock/Labels.json";

import InteractiveLabInsights from "../components/dashboard/InteractiveLabInsights.js";
import { ConsentAction } from "../redux/actions/profileAction.js";

const Dashboard = ({ ...props }) => {
  let navigate = useNavigate();
  const isSidePopupOpen = useSelector(
    (state) => state.sidePopup.isSidePopupOpen
  );
  const isModalOpen = useSelector((state) => state.modal.isModalOpen);

  const userObj = useSelector((state) => state.user);

  const componentValue = useSelector((state) => state.modal.component);

  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(true);
  let location = useLocation();

  const handleLogout = () => {
    props.cookies.remove("isAuthorised");
    localStorage.clear();
    dispatch(logoutUser());
  };

  const onAcceptClick = () => {
    dispatch(ConsentAction(userObj.token));
  };

  useEffect(() => {
    if (userObj.isConsentProvided && componentValue === 7) {
      dispatch(modalClose());
    }
  }, [userObj.isConsentProvided, componentValue, dispatch]);

  useEffect(() => {
    if (props.labels && props.labels.ISMAINTENANCE)
      redirectToRoute("maintenance", "URI", navigate);
  });

  useEffect(() => {
    if (!userObj.isConsentProvided) {
      dispatch(
        modalOpenAction({
          component: 7,
          content: {
            onAcceptClick: onAcceptClick,
            labels: props.labels ? props.labels : Labels,
            title: "UPDATED TERMS",
            noClose: true,
          },
        })
      );
    }
  }, [userObj.isConsentProvided, dispatch]);

  const abc = localStorage.getItem("loginInfo");
  const { token } = abc ? JSON.parse(abc) : { token: "dummuy" };
  let key = token;
  if (token) {
    key = token.split("=&")[1];
  }

  if (!props.cookies.get(key)) {
    localStorage.clear();
  } else {
    const localObj = localStorage.getItem("loginInfo");
    if (localObj && !userObj.token) {
      dispatch({ type: "SET_USER", payload: JSON.parse(localObj) });
    }
  }

  return (
    <>
      {location.pathname !== "/dashboard/dha-webview" && (
        <Nav {...props} handleLogout={handleLogout} />
      )}
      <section className={`app-main ${isSidePopupOpen && "side-popup-active"}`}>
        <div className="container">
          <div
            className={`row gx-4 ${
              (location.pathname === "/dashboard/dha-result" || !showSidebar) &&
              "row-no-sidebar"
            }`}
          >
            {location.pathname !== "/dashboard/dha-result" && showSidebar && (
              <Sidebar {...props} setShowSidebar={setShowSidebar} />
            )}
            <div className="col-9 app-container-main">
              <div className="app-container">
                <Routes>
                  <Route
                    path=""
                    element={
                      <>
                        <CanAccess moduleKey="MODULE-MEDICAL-SERVICE">
                          <AppointmentTypes {...props} />
                        </CanAccess>
                        <MyHealth {...props} />
                        <Explore {...props} />
                      </>
                    }
                  />
                  <Route
                    path="/explore/:title/:pageNumber/:sliderId"
                    element={<Post />}
                  />
                  <Route
                    path="/explore-content"
                    element={<ExploreContent {...props} />}
                  />
                  <Route
                    path="/bookappointment"
                    element={<BookAppointments {...props} />}
                  />
                  <Route
                    path="/bookworkplace"
                    element={<BookWorkplace {...props} />}
                  />
                  <Route path="/documents" element={<Documents {...props} />} />
                  <Route
                    path="/appointments"
                    element={<Appointments {...props} />}
                  />
                  <Route
                    path="/your-care-team"
                    element={<CareTeam {...props} />}
                  />
                  <Route path="/TeamHealth" element={<TeamHealth />} />
                  <Route path="/invite-family" element={<InviteFamily />} />
                  <Route
                    path="/myprofile"
                    element={
                      <MyProfile {...props} handleLogout={handleLogout} />
                    }
                  />
                  <Route
                    path="/health-checklist"
                    element={<HealthChecklist {...props} />}
                  />
                  <Route
                    path="/dha-result"
                    element={<DHAResult {...props} />}
                  />
                  <Route
                    path="/interactivelabinsights"
                    element={<InteractiveLabInsights {...props} />}
                  />
                  <Route
                    path="/myworkpass"
                    element={<MyWorkPass {...props} />}
                  />
                  <Route
                    path="/medical-history"
                    element={<MedicalHistory {...props} />}
                  />
                  <Route
                    path="/*"
                    element={<Navigate to="../../404" replace="true" />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
        <ChatBubble />
        <SidePopup {...props} />
        {isModalOpen && <Modal {...props} />}
      </section>
    </>
  );
};

export default compose(withLabels, withCookies)(Dashboard);
