import { postLogin, postForgotPassword } from "../../requests/requestAPI";

const requestLogin = () => ({
  type: "LOGIN_REQUEST",
});
const receiveLogin = (payload) => ({
  type: "LOGIN_RECEIVE",
  payload,
});
const failureLogin = (payload) => ({
  type: "LOGIN_FAIL",
  payload,
});

export const loginAction = (username, password, IPURL) => {
  const payload = {
    username: username,
    password: password,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };
  return async (dispatch) => {
    dispatch(requestLogin());
    try {
      let data = await postLogin(payload,IPURL);
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(receiveLogin(loginInfo.value));
        localStorage.setItem("loginInfo", JSON.stringify(loginInfo.value));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureLogin(JSON.parse(ex.message)));
    }
  };
};

const requestForgotPassword = () => ({
  type: "FORGOT_PASSWORD_REQUEST",
});
const receiveForgotPassword = (payload) => ({
  type: "FORGOT_PASSWORD_RECEIVE",
  payload,
});
const failureForgotPassword = (payload) => ({
  type: "FORGOT_PASSWORD_FAIL",
});

export const forgotPasswordAction = (email) => {
  return async (dispatch) => {
    dispatch(requestForgotPassword());
    try {
      let data = await postForgotPassword(email);
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(receiveForgotPassword(loginInfo.value));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failureForgotPassword());
    }
  };
};
