import { getInsuranceProviderDetails } from "../../requests/requestAPI";
import { SaveInsuranceProvider } from "../../requests/requestAPI";
import { SaveInsuranceCardDetails } from "../../requests/requestAPI";

const requestInsuranceProvider = () => ({
  type: "GET_INSURANCEPROVIDER_REQUEST",
});
const receiveInsuranceProvider = (payload) => ({
  type: "GET_INSURANCEPROVIDER_RECEIVE",
  payload,
});
const failureInsuranceProvider = (payload) => ({
  type: "GET_INSURANCEPROVIDER_FAIL",
  payload,
});

export const getInsuranceProviderAction = (token) => {
  return async (dispatch) => {
    dispatch(requestInsuranceProvider());
    try {
      let data = await getInsuranceProviderDetails(token);
      if (data.ok) {
        let insuranceInfo = await data.json();
        dispatch(receiveInsuranceProvider(insuranceInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureInsuranceProvider(JSON.parse(ex.message)));
    }
  };
};

const requestSaveInsuranceProvider = () => ({
  type: "SAVE_INSURANCE_REQUEST",
});
const receiveSaveInsuranceProvider = (payload) => ({
  type: "SAVE_INSURANCE_RECEIVE",
  payload,
});
const failureSaveInsurencProvider = (payload) => ({
  type: "SAVE_INSURANCE_FAIL",
  payload,
});

export const SaveInsuranceProviderAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestSaveInsuranceProvider());
    try {
      let data = await SaveInsuranceProvider(token, payload);
      if (data.ok) {
        let insuranceInfo = await data.json();
        dispatch(receiveSaveInsuranceProvider(insuranceInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureSaveInsurencProvider(JSON.parse(ex.message)));
    }
  };
};

const requestSaveCardDetails = () => ({
  type: "SAVE_CARDDETAILS_REQUEST",
});
const receiveSaveCardDetails = (payload) => ({
  type: "SAVE_CARDDETAILS_RECEIVE",
  payload,
});
const failureSaveCardDetails = (payload) => ({
  type: "SAVE_CARDDETAILS_FAIL",
  payload,
});

export const SaveCardDetailsAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestSaveCardDetails());
    try {
      let data = await SaveInsuranceCardDetails(token, payload);
      if (data.ok) {
        let insuranceInfo = await data.json();
        dispatch(receiveSaveCardDetails(insuranceInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureSaveCardDetails(JSON.parse(ex.message)));
    }
  };
};
