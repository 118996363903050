// const API_URLS = {
//   prod: "https://api-prod.patch.health",
//   staging: "https://api-stage.patch.health",
// };

// const VR_URLS = {
//   prod: "https://video.radish.health/app/patient",
//   staging: "https://api-qa.radish.health/app/patient",
// };

// const DOCUMENT_REDIRECT_URLS = {
//   prod: "https://book.radish.health/MobileApp/Login",
//   staging: "https://dev001.radish.health/MobileApp/Login",
// };

// const PROD_INTERNALS = {
//   UPLOAD_VACCINE: "https://api2-prod.radish.health/document/v2/vaccine",
//   UPLOAD_LAB: "https://api2-prod.radish.health/document/v2/lab",
//   UPLOAD_OTHER: "https://api2-prod.radish.health/document/v2/otherdocument",
//   UPLOAD_PROFILE_IMAGE:
//     "https://api2-prod.radish.health/user/api/v2/user/update/photo",
//   UPLOAD_INSURANCE_IMAGE: "https://api2-prod.radish.health/insurance/v2/card",
// };

// const STAGE_INTERNALS = {
//   UPLOAD_VACCINE: "https://api2-stage.radish.health/document/v2/vaccine",
//   UPLOAD_LAB: "https://api2-stage.radish.health/document/v2/lab",
//   UPLOAD_OTHER: "https://api2-stage.radish.health/document/v2/otherdocument",
//   UPLOAD_PROFILE_IMAGE:
//     "https://api2-stage.radish.health/user/api/v2/user/update/photo",
//   UPLOAD_INSURANCE_IMAGE: "https://api2-stage.radish.health/insurance/v2/card",
// };

// const getEnv = () => {
//   if (process.env.NODE_ENV === "production") {
//     return "prod";
//   } else {
//     return "staging";
//   }
// };

// const getInternalURLS = () => {
//   if (process.env.NODE_ENV === "production") {
//     return PROD_INTERNALS;
//   } else {
//     return STAGE_INTERNALS;
//   }
// };

export const LANG = process.env.REACT_APP_LANGUAGE;
export const ENV = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const DOCUMENT_REDIRECT_URL =
  process.env.REACT_APP_DOCUMENT_REDIRECT_URL;
export const VR_URL = process.env.REACT_APP_VR_URL;
export const GET_IP_URL = process.env.REACT_APP_GET_IP_URL;
export const INTERNAL_URL = {
  UPLOAD_VACCINE: process.env.REACT_APP_UPLOAD_VACCINE,
  UPLOAD_LAB: process.env.REACT_APP_UPLOAD_LAB,
  UPLOAD_OTHER: process.env.REACT_APP_UPLOAD_OTHER,
  UPLOAD_PROFILE_IMAGE: process.env.REACT_APP_UPLOAD_PROFILE_IMAGE,
  UPLOAD_INSURANCE_IMAGE: process.env.REACT_APP_UPLOAD_INSURANCE_IMAGE,
};
export const STRIPE_URL = process.env.REACT_APP_STRIPE_URL;
