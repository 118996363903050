import { useDispatch, useSelector } from "react-redux";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions.js";

function Pharmacy({ userProfile, labels = {} }) {
  const dispatch = useDispatch();
  //const { userPharmacy } = useSelector((state) => state.userProfile);
  //const { userProfile } = useSelector((state) => state.userProfile);

  const openSideBar = () => {
    dispatch(sidePopupOpen(payload));
    dispatch({ type: "SAVE_PHARMACY_CLEAR" });
  };

  const payload = {
    component: 13,
    content: "",
  };

  return (
    <div id="pharmacy-outer" className="col-12 col-xl-4 mb-40">
      <h2 className="app-heading-1">{labels.LBL_PHARMACY_INFO}</h2>

      <div
        className={`pharmacy-block ${!userProfile?.pharmacyName && "ph-block-max"
          }`}
      >
        {!userProfile?.pharmacyName ? (
          <button onClick={openSideBar} className="add-pharmacy-cta unset-btn">
            + Add your <br />
            preferred pharmacy
          </button>
        ) : (
          <>
            <button onClick={openSideBar} className="pharmacy-edit unset-btn">
              {labels.LBL_INSURANCE_EDIT}
            </button>
            <div className="pharmacy-info">
              <span class="pharmacy-name">{userProfile?.pharmacyName}</span>
              <span>{userProfile?.pharmacyAddress}</span>
              <span>
                {userProfile?.pharmacyCity}, {userProfile?.pharmacyState}, {userProfile?.pharmacyZip}
              </span>
              <span>{userProfile?.pharmacyPhone}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Pharmacy;
