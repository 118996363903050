import { sidePopupOpen } from "../../redux/actions/sidePopupActions.js";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../common/Spinner.js";

const InsuranceProvider = ({ labels = {} }) => {
  const dispatch = useDispatch();
  const insuranceInfo = useSelector((state) => state.insuranceInfo);
  //const { userProfile } = useSelector((state) => state.userProfile);
  const userProfileInfo = useSelector((state) => state.userProfile);


  const openSideBar = () => {
    dispatch(sidePopupOpen(payload));
    dispatch({ type: "SAVE_INSURANCE_CLEAR" });
  };

  const payload = {
    component: 6,
    content: "",
  };

  return (
    <div className="col-12 col-xl-4 mb-40">
      <h2 className="app-heading-1">{labels.LBL_INSURANCE_PROVIDER}</h2>
      <div className="insurance-block">
        {userProfileInfo.isUserProfileLoading ? (
          <div>
            <Spinner />
          </div>
        ) : userProfileInfo.userProfile &&
          userProfileInfo.userProfile.insuranceCompany &&
          userProfileInfo.userProfile.insuranceCompany !== "" ? (
          <>
            <button onClick={openSideBar} className="insurance-edit unset-btn">
              {labels.LBL_INSURANCE_EDIT}
            </button>
            <div className="insurance-items-list">
              <div className="insurance-item">
                <span className="title">
                  {userProfileInfo.userProfile.insuranceCompany}
                </span>
                <span className="insurance-id mb-10">
                  ID number :{" "}
                  {userProfileInfo.userProfile.insuranceIdNumber}
                </span>
                {userProfileInfo.userProfile.photoFront && (
                  <div className="upload-insurance-top mb-5">
                    {/* <span className="insurance-card-title mb-10">Card front</span> */}
                    <div className="uit-row">
                      <figure>
                        <img
                          src={userProfileInfo.userProfile.photoFront}
                          alt="Front of your insurance card"
                          className="img-responsive img-insurance"
                        />
                      </figure>
                    </div>
                  </div>
                )}
                {/* <div className="upload-insurance-top mb-5">
                      <span className="insurance-card-title mb-10">Card back</span>
                      <div className="uit-row">
                          <figure>
                              <img src={userProfileInfo.userProfile.photoBack} alt className="img-responsive img-insurance" />
                          </figure>
                      </div>
                  </div> */}
              </div>
            </div>
          </>
        ) : (
          <button onClick={openSideBar} className="add-insurance-cta unset-btn">
            + Add your <br />
            insurance provider
          </button>
        )}
      </div>
    </div>
  );
};
export default InsuranceProvider;
