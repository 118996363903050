import { getMyTodo, getDailyUserWorkPass } from "../../requests/requestAPI";

const requestMyToDo = () => ({
  type: "GET_MY_TODO_REQUEST",
});
const receiveMyToDo = (payload) => ({
  type: "GET_MY_TODO_RECEIVE",
  payload,
});
const failureMyToDo = (payload) => ({
  type: "GET_MY_TODO_FAIL",
  payload,
});

export const getMyToDoAction = (token) => {
  return async (dispatch) => {
    dispatch(requestMyToDo());
    try {
      let data = await getMyTodo(token);

      if (data.ok) {
        let myToDoInfo = await data.json();
        dispatch(receiveMyToDo(myToDoInfo.value));
      } else {
        dispatch(failureMyToDo({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureMyToDo(ex.message));
    }
  };
};

const requestDailyWorkPass = () => ({
  type: "GET_DAILYWORKPASS_REQUEST",
});
const receiveDailyWorkPass = (payload) => ({
  type: "GET_DAILYWORKPASS_RECEIVE",
  payload,
});
const failureDailyWorkPass = (payload) => ({
  type: "GET_DAILYWORKPASS_FAIL",
  payload,
});

export const getDailyWorkPassAction = (token) => {
  return async (dispatch) => {
    dispatch(requestDailyWorkPass());
    try {
      let data = await getDailyUserWorkPass(token);

      if (data.ok) {
        let myToDoInfo = await data.json();
        dispatch(receiveDailyWorkPass(myToDoInfo.value));
      } else {
        dispatch(failureDailyWorkPass({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureDailyWorkPass(ex.message));
    }
  };
};
