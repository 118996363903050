import { useEffect, useState } from "react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import {
  vaccineTypesAction,
  vaccineManufacturersAction,
  documentsListAction,
  labTypesAction,
  documentsUploadFailedAction,
} from "../../../redux/actions/documentsActions.js";
import {
  uploadDocumentOther,
  uploadDocumentLab,
  uploadDocumentVaccine,
} from "../../../requests/requestAPI.js";
import {
  sidePopupClose,
  sidePopupLoading,
  sidePopupConfirmed,
  sidePopupFailed,
} from "../../../redux/actions/sidePopupActions.js";
import Spinner from "../Spinner.js";

const AddNewDocument = () => {
  const dispatch = useDispatch();
  const {
    documentsTypes,
    vaccineManufacturers,
    vaccineTypes,
    labTypes,
    isDocumentsTypesLoading,
  } = useSelector((state) => state.documents);
  const { isLoading, isConfirmed, isFailed, content } = useSelector(
    (state) => state.sidePopup
  );
  const { token } = useSelector((state) => state.user);
  const labels = useSelector((state) => state.app.labels);

  //Form values
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(-1);
  const [currentDocumentObject, setCurrentDocumentObject] = useState({});
  const [currentDocumentTypeId, setCurrentDocumentTypeId] = useState(0);
  const [currentLabResult, setCurrentLabResult] = useState(0);
  const [currentDocumentDescription, setCurrentDocumentDescription] =
    useState("");
  const [currentFirstDate, setCurrentFirstDate] = useState("");
  const [currentSecondDate, setCurrentSecondDate] = useState("");
  const [currentVaccineIndex, setCurrentVaccineIndex] = useState(0);
  const [currentVaccineManufacturer, setCurrentVaccineManufacturer] =
    useState("");
  const [currentVaccineTypeId, setCurrentVaccineTypeId] = useState(0);
  const [currentFile, setCurrentFile] = useState("");
  const [currentFilename, setCurrentFilename] = useState("");
  const [currentFileSize, setCurrentFileSize] = useState("");
  const [currentLabTypeId, setCurrentLabTypeId] = useState(0);
  const [currentLabTypeKey, setCurrentLabTypeKey] = useState("");

  //Validation
  const [isLabResultMissing, setIsLabResultMissing] = useState(false);
  const [isDescriptionMissing, setIsDescriptionMissing] = useState(false);
  const [isFirstDateMissing, setIsFirstDateMissing] = useState(false);
  const [isFirstDateInvalid, setIsFirstDateInvalid] = useState(false);
  const [isSecondDateMissing, setIsSecondDateMissing] = useState(false);
  const [isSecondDateInvalid, setIsSecondDateInvalid] = useState(false);
  const [isVaccineManufacturerMissing, setIsVaccineManufacturerMissing] =
    useState(false);
  const [isFileMissing, setIsFileMissing] = useState(false);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [isDateRangeInvalid, setIsDateRangeInvalid] = useState(false);
  const [dateMessage, setDateMessage] = useState("");
  const [isConsented, setIsConsented] = useState(false);
  const [isDocTypeChanged, setIsDocTypeChanged] = useState(false);

  useEffect(() => {
    dispatch(vaccineTypesAction(token));
    dispatch(vaccineManufacturersAction(token));
    dispatch(labTypesAction(token));
  }, [token, dispatch]);

  useEffect(() => {
    if (
      Object.keys(content).length > 0 &&
      documentsTypes.length > 0 &&
      vaccineManufacturers.length > 0 &&
      vaccineTypes.length > 0
    ) {
      let index = documentsTypes.findIndex(
        (type) =>
          type.documentType === content.documentType &&
          type.documentTypeID == content.documentTypeID
      );
      if (index > -1) {
        setCurrentDocumentIndex(index);
        setCurrentDocumentObject(documentsTypes[index]);
        setCurrentDocumentTypeId(documentsTypes[index].documentTypeID);
        setCurrentVaccineIndex(vaccineManufacturers.length);
        let key = documentsTypes[index].documentKey;
        vaccineTypes.forEach((vaccineType) => {
          if (vaccineType.documentKey === key)
            setCurrentVaccineTypeId(vaccineType.vaccineTypeID);
        });
      }
    }
  }, [documentsTypes, content, vaccineManufacturers.length, vaccineTypes]);

  useEffect(() => {
    setCurrentVaccineIndex(vaccineManufacturers.length);
  }, [vaccineManufacturers.length]);

  function handleDocumentIndexChange({ target }) {
    setIsDocTypeChanged(true);
    let index = target.value;
    setCurrentDocumentIndex(index);
    setCurrentDocumentObject(documentsTypes[index]);
    setCurrentDocumentTypeId(documentsTypes[index].documentTypeID);
    setCurrentVaccineManufacturer("");
    setCurrentVaccineIndex(vaccineManufacturers.length);
    setCurrentDocumentDescription("");
    setCurrentLabResult("");
    setCurrentFirstDate("");
    setCurrentSecondDate("");
    setCurrentLabResult("");
    setIsConsented(false);
    setIsFileMissing(false);
    setIsDescriptionMissing(false);
    setIsLabResultMissing(false);
    setIsFirstDateMissing(false);
    setIsFirstDateInvalid(false);
    setIsSecondDateMissing(false);
    setIsSecondDateInvalid(false);
    setIsVaccineManufacturerMissing(false);
    setIsDateRangeInvalid(false);
    documentsTypes[index].documentType === "VACCINE"
      ? handleVaccineTypeIdChange(index)
      : setCurrentVaccineTypeId(0);
    documentsTypes[index].documentType === "LAB"
      ? handleLabTypeIdChange(index)
      : setCurrentLabTypeId(0);
  }

  function handleLabResultChange({ target }) {
    setCurrentLabResult(parseInt(target.value));
    setIsLabResultMissing(false);
  }

  function handleFirstDateChange(date) {
    if (currentSecondDate) {
      let firstDate = moment(date);
      let secondDate = moment(currentSecondDate);
      const check = checkDateRange(firstDate, secondDate);
      if (!check.valid) {
        setIsDateRangeInvalid(true);
        setDateMessage(check.message);
        setCurrentFirstDate("");
        return;
      }
    }
    setIsFirstDateInvalid(false);
    setIsFirstDateMissing(false);
    setIsDateRangeInvalid(false);
    setCurrentFirstDate(date);
  }

  function handleSecondDateChange(date) {
    if (currentFirstDate) {
      let firstDate = moment(currentFirstDate);
      let secondDate = moment(date);
      const check = checkDateRange(firstDate, secondDate);
      if (!check.valid) {
        setIsDateRangeInvalid(true);
        setDateMessage(check.message);
        setCurrentSecondDate("");
        return;
      }
    }
    setIsSecondDateInvalid(false);
    setIsSecondDateMissing(false);
    setIsDateRangeInvalid(false);
    setCurrentSecondDate(date);
  }

  function checkDateRange(firstDate, secondDate) {
    const difference = firstDate.diff(secondDate, "days") * -1;
    if (difference < 0)
      return { valid: false, message: "First date must be before second date" };
    if (difference < 14)
      return { valid: false, message: "Dates must be 14 days apart" };
    else return { valid: true };
  }

  function handleVaccineManufacturerChange({ target }) {
    let index = target.value;
    setCurrentVaccineIndex(index);
    setCurrentVaccineManufacturer(vaccineManufacturers[index].manufacturerID);
    setCurrentFirstDate("");
    setCurrentSecondDate("");
    setIsFirstDateMissing(false);
    setIsFirstDateInvalid(false);
    setIsSecondDateMissing(false);
    setIsSecondDateInvalid(false);
    setIsVaccineManufacturerMissing(false);
    setIsDateRangeInvalid(false);
  }

  function handleFileChange({ target }) {
    const file = target.files[0];
    const fileTypes = [
      "image/jpeg",
      "application/pdf",
      "image/png",
      "image/gif",
      "image/bmp",
    ];
    if (!fileTypes.includes(file.type)) {
      setIsWrongFileType(true);
      setCurrentFile("");
    } else {
      setIsWrongFileType(false);
      setCurrentFileSize((file.size / (1024 * 1024)).toFixed(2));
      setCurrentFilename(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        setCurrentFile(base64String);
      };
      reader.readAsDataURL(file);
      setIsFileMissing(false);
    }
  }

  function handleVaccineTypeIdChange(index) {
    let key = documentsTypes[index].documentKey;
    vaccineTypes.forEach((vaccineType) => {
      if (vaccineType.documentKey === key)
        setCurrentVaccineTypeId(vaccineType.vaccineTypeID);
    });
  }

  function handleLabTypeIdChange(index) {
    let key = documentsTypes[index].documentKey;
    labTypes.forEach((labType) => {
      if (labType.documentKey === key) {
        setCurrentLabTypeId(labType.labTypeID);
        setCurrentLabTypeKey(labType.documentKey);
      }
    });
  }

  function handleSubmit() {
    switch (documentsTypes[currentDocumentIndex].documentType) {
      case "LAB":
        handleUploadDocumentLab();
        break;
      case "VACCINE":
        handleUploadDocumentVaccine();
        break;
      case "OTHER":
        handleUploadDocumentOther();
        break;
      default:
        console.log("No such document type");
    }
  }

  async function handleUpload(func, payload) {
    try {
      dispatch(sidePopupLoading());
      let data = await func(token, payload);
      if (data.ok) {
        handleUploadComplete();
      } else {
        handleUploadFailed();
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(
        documentsUploadFailedAction(
          documentsTypes[currentDocumentIndex].documentName +
            ": " +
            JSON.parse(ex.message).errorMessage
        )
      );
    }
  }

  async function handleUploadDocumentLab() {
    const payload = {
      documentType: currentLabTypeId,
      date: moment(currentFirstDate).format(),
      document: currentFile,
    };
    if (documentsTypes[currentDocumentIndex].documentKey === "COVID19") {
      payload.result = currentLabResult;
      payload.description = "COVID-19 Lab Result";
    } else if (
      documentsTypes[currentDocumentIndex].documentKey === "OTHERLAB"
    ) {
      payload.result = "0";
      payload.description = currentDocumentDescription;
    }
    if (validateLabDocumentUpload()) handleUpload(uploadDocumentLab, payload);
  }

  function validateLabDocumentUpload() {
    if (!currentFile) setIsFileMissing(true);
    else setIsFileMissing(false);
    if (!currentFirstDate) setIsFirstDateMissing(true);
    else setIsFirstDateMissing(false);
    if (documentsTypes[currentDocumentIndex].documentKey === "OTHERLAB") {
      if (!currentDocumentDescription) setIsDescriptionMissing(true);
      else setIsDescriptionMissing(false);
      if (currentFile && currentFirstDate && currentDocumentDescription)
        return true;
      else return false;
    } else {
      if (!currentLabResult) setIsLabResultMissing(true);
      else setIsLabResultMissing(false);
      if (currentFile && currentLabResult && currentFirstDate) return true;
      else return false;
    }
  }

  async function handleUploadDocumentVaccine() {
    let dates = [];
    if (currentFirstDate) dates.push(moment(currentFirstDate).format());
    if (currentSecondDate) dates.push(moment(currentSecondDate).format());
    const payload = {
      documentType: currentVaccineTypeId,
      vaccineManufacture: currentVaccineManufacturer,
      dates: dates,
      document: currentFile,
    };
    if (validateVaccinebDocumentUpload())
      handleUpload(uploadDocumentVaccine, payload);
  }

  function validateVaccinebDocumentUpload() {
    if (!currentFile) setIsFileMissing(true);
    else setIsFileMissing(false);
    if (!currentVaccineManufacturer) setIsVaccineManufacturerMissing(true);
    else setIsVaccineManufacturerMissing(false);
    if (!currentFirstDate) setIsFirstDateMissing(true);
    else setIsFirstDateMissing(false);
    if (
      vaccineManufacturers[currentVaccineIndex].noOfDates === 2 &&
      currentVaccineTypeId === 1
    ) {
      if (!currentSecondDate) setIsSecondDateMissing(true);
      else setIsSecondDateMissing(false);
      if (
        currentFile &&
        currentVaccineManufacturer &&
        currentFirstDate &&
        currentSecondDate
      )
        return true;
      else return false;
    } else {
      if (currentFile && currentVaccineManufacturer && currentFirstDate)
        return true;
      else return false;
    }
  }

  async function handleUploadDocumentOther() {
    const payload = {
      documentType: currentDocumentTypeId,
      document: currentFile,
      description: currentDocumentDescription,
    };
    if (validateOtherDocumentUpload())
      handleUpload(uploadDocumentOther, payload);
  }

  function validateOtherDocumentUpload() {
    if (!currentFile) setIsFileMissing(true);
    else setIsFileMissing(false);
    if (!currentDocumentDescription) setIsDescriptionMissing(true);
    else setIsDescriptionMissing(false);
    if (currentDocumentDescription && currentFile) return true;
    else return false;
  }

  function handleUploadComplete() {
    dispatch(sidePopupConfirmed());
    setTimeout(() => {
      dispatch(sidePopupClose());
      dispatch(documentsListAction(token));
    }, 3000);
  }

  function handleUploadFailed() {
    dispatch(sidePopupFailed());
    setTimeout(() => {
      dispatch(sidePopupClose());
    }, 3000);
  }

  function renderLab() {
    let renderedInput = [];
    if (currentLabTypeKey === "COVID19")
      renderedInput.push(
        <LabResultInput
          key="lab-result-input"
          isLabResultMissing={isLabResultMissing}
          currentLabResult={currentLabResult}
          handleLabResultChange={handleLabResultChange}
          setIsLabResultMissing={setIsLabResultMissing}
          isLoading={isLoading}
        />
      );
    renderedInput.push(
      <FirstDateInput
        key="first-date-input"
        currentFirstDate={currentFirstDate}
        handleFirstDateChange={handleFirstDateChange}
        isFirstDateInvalid={isFirstDateInvalid}
        isFirstDateMissing={isFirstDateMissing}
        placeholder={"Lab Date:"}
        cssClassName={`form-group ${
          currentLabTypeKey === "COVID19" && "mb-30"
        } ${currentLabTypeKey === "OTHERLAB" && "mb-15"}`}
        isLoading={isLoading}
      />
    );
    if (currentLabTypeKey === "OTHERLAB")
      renderedInput.push(
        <DescriptionInput
          key="description-input"
          currentDocumentDescription={currentDocumentDescription}
          setCurrentDocumentDescription={setCurrentDocumentDescription}
          isDescriptionMissing={isDescriptionMissing}
          isLoading={isLoading}
        />
      );
    return renderedInput.map((input) => input);
  }

  function renderVaccine() {
    let renderedInput = [];
    const currentVaccineObject = vaccineManufacturers[currentVaccineIndex];
    renderedInput.push(
      <VaccineManufacturerInput
        key="vaccine-manufacturer-input"
        vaccineManufacturers={vaccineManufacturers}
        currentVaccineIndex={currentVaccineIndex}
        setCurrentVaccineIndex={setCurrentVaccineIndex}
        handleVaccineManufacturerChange={handleVaccineManufacturerChange}
        isVaccineManufacturerMissing={isVaccineManufacturerMissing}
        setIsVaccineManufacturerMissing={setIsVaccineManufacturerMissing}
        currentVaccineManufacturer={currentVaccineManufacturer}
        isLoading={isLoading}
      />
    );
    if (currentVaccineObject) {
      if (currentVaccineObject.noOfDates === 2 && currentVaccineTypeId === 1) {
        renderedInput.push(
          <FirstDateInput
            key="first-date-input"
            currentFirstDate={currentFirstDate}
            handleFirstDateChange={handleFirstDateChange}
            isFirstDateInvalid={isFirstDateInvalid}
            isFirstDateMissing={isFirstDateMissing}
            placeholder={"First Dose:"}
            cssClassName={`form-group mb-15`}
            isLoading={isLoading}
          />,
          <SecondDateInput
            key="second-date-input"
            currentSecondDate={currentSecondDate}
            handleSecondDateChange={handleSecondDateChange}
            isSecondDateInvalid={isSecondDateInvalid}
            isSecondDateMissing={isSecondDateMissing}
            placeholder={"Second Dose:"}
            cssClassName={"form-group mb-30"}
            isDateRangeInvalid={isDateRangeInvalid}
            dateMessage={dateMessage}
            isLoading={isLoading}
          />
        );
      } else {
        renderedInput.push(
          <FirstDateInput
            key="first-date-input"
            currentFirstDate={currentFirstDate}
            handleFirstDateChange={handleFirstDateChange}
            isFirstDateInvalid={isFirstDateInvalid}
            isFirstDateMissing={isFirstDateMissing}
            placeholder={"First Dose:"}
            cssClassName={`form-group mb-30`}
            isLoading={isLoading}
          />
        );
      }
    }
    return renderedInput.map((input) => input);
  }

  function renderOther() {
    return (
      <DescriptionInput
        key="description-input"
        currentDocumentDescription={currentDocumentDescription}
        setCurrentDocumentDescription={setCurrentDocumentDescription}
        isDescriptionMissing={isDescriptionMissing}
        isLoading={isLoading}
      />
    );
  }

  if (isConfirmed)
    return (
      <h2 className="app-heading-1 mb-65">
        {labels.MSG_ADDLAB_UPLOAD_SUCCESS}
      </h2>
    );

  if (isFailed)
    return (
      <h2 className="app-heading-1 mb-65">{labels.MSG_ADDLAB_UPLOAD_FAIL}</h2>
    );

  if (isDocumentsTypesLoading && !documentsTypes.length) return <Spinner />;

  if (!isDocumentsTypesLoading && documentsTypes.length > 0)
    return (
      <div className="document-steps-stack">
        <div className="document-step" id="document-step-2">
          <h3 className="app-heading-1 mb-40">{labels.LBL_ADDLAB_HEADER}</h3>

          <div className="document-form-wrap">
            <DocumentSelectInput
              documentsTypes={documentsTypes}
              currentDocumentIndex={currentDocumentIndex}
              handleDocumentIndexChange={handleDocumentIndexChange}
              isLoading={isLoading}
              isDocTypeChanged={isDocTypeChanged}
            />

            {currentDocumentObject.id && (
              <>
                {currentDocumentObject.documentType === "LAB" && renderLab()}
                {currentDocumentObject.documentType === "VACCINE" &&
                  renderVaccine()}
                {currentDocumentObject.documentType === "OTHER" &&
                  renderOther()}

                <FileInput
                  isFileMissing={isFileMissing}
                  handleFileChange={handleFileChange}
                  currentFile={currentFile}
                  currentFilename={currentFilename}
                  currentFileSize={currentFileSize}
                  isWrongFileType={isWrongFileType}
                  isLoading={isLoading}
                />

                {currentDocumentObject.isConsentRequired ? (
                  <ConsentInput
                    setIsConsented={setIsConsented}
                    isConsented={isConsented}
                    consentText={currentDocumentObject.consentText}
                    isLoading={isLoading}
                  />
                ) : null}

                <SubmitInput
                  isConsentRequired={currentDocumentObject.isConsentRequired}
                  isConsented={isConsented}
                  handleSubmit={handleSubmit}
                  isLoading={isLoading}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );

  return <Spinner />;
};

const DocumentSelectInput = ({
  documentsTypes,
  currentDocumentIndex,
  handleDocumentIndexChange,
  isLoading,
  isDocTypeChanged,
}) => {
  return (
    <div className="form-group mb-15">
      <div className="inputwrap">
        <select
          className={`form-select col-12 ${
            currentDocumentIndex === documentsTypes.length &&
            "doc-upload-unfilled"
          }`}
          name="document-type-select"
          aria-label="Document Type"
          value={currentDocumentIndex}
          onChange={handleDocumentIndexChange}
          disabled={isLoading}
        >
          <option
            hidden
            disabled={isDocTypeChanged}
            value={documentsTypes.length}
          >
            Select document type...
          </option>
          {documentsTypes.map((documentType, index) => {
            if (documentType.isActive)
              return (
                <option value={index} key={index}>
                  {documentType.documentName}
                </option>
              );
            else return null;
          })}
        </select>
      </div>
    </div>
  );
};

const LabResultInput = ({
  isLabResultMissing,
  currentLabResult,
  handleLabResultChange,
  setIsLabResultMissing,
  isLoading,
}) => {
  return (
    <div className="form-group mb-15">
      <div className="inputwrap">
        <select
          className={`form-select col-12 ${
            isLabResultMissing && "input-invalid"
          } ${!currentLabResult && "doc-upload-unfilled"}`}
          name="lab-result"
          aria-label="Result Type"
          value={currentLabResult}
          onChange={handleLabResultChange}
          onBlur={({ target }) =>
            target.value === "0" && setIsLabResultMissing(true)
          }
          disabled={isLoading}
        >
          <option value="0" hidden>
            Result
          </option>
          <option value="1">Positive</option>
          <option value="2">Negative</option>
        </select>
        {isLabResultMissing && <span className="error-label">Required</span>}
      </div>
    </div>
  );
};

const DescriptionInput = ({
  currentDocumentDescription,
  setCurrentDocumentDescription,
  isDescriptionMissing,
  isLoading,
}) => {
  return (
    <div className="form-group mb-30">
      <div className="inputwrap">
        <input
          type="text"
          className={`form-control form-control-lg ${
            isDescriptionMissing && "input-invalid"
          }`}
          name="other-document-name"
          id="other-document-name"
          value={currentDocumentDescription}
          placeholder="Document Name"
          onChange={({ target }) => {
            setCurrentDocumentDescription(target.value);
          }}
          disabled={isLoading}
        />
        {isDescriptionMissing && <span className="error-label">Required</span>}
      </div>
    </div>
  );
};

const FirstDateInput = ({
  currentFirstDate,
  handleFirstDateChange,
  isFirstDateInvalid,
  isFirstDateMissing,
  placeholder,
  cssClassName,
  isLoading,
}) => {
  return (
    <div className={cssClassName}>
      <div className="inputwrap">
        <ReactDatePicker
          placeholderText={placeholder}
          className={`form-control form-control-lg r-date-control lab-date-input ${
            isFirstDateMissing && "input-invalid"
          }`}
          selected={currentFirstDate}
          onChange={(date) => handleFirstDateChange(date)}
          maxDate={new Date()}
          disabled={isLoading}
        />
        {isFirstDateInvalid && (
          <span className="error-label">Date cannot be in the future</span>
        )}
        {isFirstDateMissing && <span className="error-label">Required</span>}
      </div>
    </div>
  );
};

const SecondDateInput = ({
  currentSecondDate,
  handleSecondDateChange,
  isSecondDateInvalid,
  isSecondDateMissing,
  placeholder,
  cssClassName,
  isDateRangeInvalid,
  dateMessage,
  isLoading,
}) => {
  return (
    <div className={cssClassName}>
      <div className="inputwrap">
        <ReactDatePicker
          placeholderText={placeholder}
          className={`form-control form-control-lg r-date-control lab-date-input ${
            isSecondDateMissing && "input-invalid"
          }`}
          selected={currentSecondDate}
          onChange={(date) => handleSecondDateChange(date)}
          maxDate={new Date()}
          disabled={isLoading}
        />
        {isSecondDateInvalid && (
          <span className="error-label">Date cannot be in the future</span>
        )}
        {isDateRangeInvalid && (
          <span className="error-label">{dateMessage}</span>
        )}
        {isSecondDateMissing && <span className="error-label">Required</span>}
      </div>
    </div>
  );
};

const VaccineManufacturerInput = ({
  vaccineManufacturers,
  currentVaccineIndex,
  setCurrentVaccineIndex,
  handleVaccineManufacturerChange,
  isVaccineManufacturerMissing,
  setIsVaccineManufacturerMissing,
  currentVaccineManufacturer,
  isLoading,
}) => {
  return (
    <div className="form-group mb-15">
      <div className="inputwrap">
        <select
          className={`form-select col-12 ${
            isVaccineManufacturerMissing && "input-invalid"
          } ${
            currentVaccineIndex === vaccineManufacturers.length &&
            "doc-upload-unfilled"
          }`}
          name="document-type-select"
          aria-label="Document Type"
          value={currentVaccineIndex}
          onChange={handleVaccineManufacturerChange}
          onBlur={() =>
            !currentVaccineManufacturer && setIsVaccineManufacturerMissing(true)
          }
          disabled={isLoading}
        >
          <option value={vaccineManufacturers.length} hidden>
            Vaccine type
          </option>
          {vaccineManufacturers.map((vaccineType, index) => {
            if (vaccineType.isActive)
              return (
                <option value={index} key={index}>
                  {vaccineType.name}
                </option>
              );
            else return null;
          })}
        </select>
        {isVaccineManufacturerMissing && (
          <span className="error-label">Required</span>
        )}
      </div>
    </div>
  );
};

const FileInput = ({
  isFileMissing,
  handleFileChange,
  currentFile,
  currentFilename,
  currentFileSize,
  isWrongFileType,
  isLoading,
}) => {
  return (
    <div className="form-group mb-30">
      <div className="inputwrap file-field">
        <div
          className={`btn btn-outline-primary col-12 ${
            isFileMissing && "input-invalid"
          }`}
        >
          <span>Upload</span>
          <input
            type="file"
            onChange={handleFileChange}
            accept=".jpg, .jpeg, .png, .gif, .pdf, .bmp"
            disabled={isLoading}
          />
        </div>
        {isFileMissing && <span className="error-label">Required</span>}
        {isWrongFileType && (
          <span className="error-label">
            File should only be of types .pdf, .png, .jpg, .gif, .bmp
          </span>
        )}
        {currentFile && (
          <span className="file-label">
            File: {currentFilename} ({currentFileSize} mb)
          </span>
        )}
      </div>
    </div>
  );
};

const ConsentInput = ({
  currentDocumentObject,
  setIsConsented,
  isConsented,
  consentText,
  isLoading,
}) => {
  return (
    <div className="form-check mb-25">
      <input
        className="form-check-input"
        type="checkbox"
        id="i-consent"
        checked={isConsented}
        onChange={() => setIsConsented(!isConsented)}
        disabled={isLoading}
      />
      <label className="form-check-label" htmlFor="i-consent">
        {consentText}
      </label>
    </div>
  );
};

const SubmitInput = ({
  isConsentRequired,
  isConsented,
  handleSubmit,
  isLoading,
}) => {
  return (
    <button
      type="button"
      className="btn btn-primary col-12 btn-lg"
      disabled={(isConsentRequired && !isConsented) || isLoading}
      onClick={handleSubmit}
    >
      {isLoading ? <Spinner /> : "Submit"}
    </button>
  );
};

export default AddNewDocument;
