import GooglePlay from "../../assets/img/svg/google-play-badge.svg";
import AppStore from "../../assets/img/svg/app-store.svg";
import DownloadAppPhone from "../../assets/img/png/download-app-phone.png";

const DownloadAppCard = () => {
  return (
    <div className="download-app-card container">
      <div className="row">
        <div className="col-6">
          <h2>
            Download <br />
            the app
          </h2>
          <a
            href="https://play.google.com/store/apps/details?id=com.radish.health"
            target="_blank"
          >
            <img
              src={GooglePlay}
              className="store-badge"
              alt="Download Radish Health from Google Play Store"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/radish-health/id1556054938"
            target="_blank"
          >
            <img
              src={AppStore}
              className="store-badge"
              alt="Download Radish Health from Apple App Store"
            />
          </a>
        </div>

        <div className="col-6 col-img">
          <figure>
            <img
              className="img-responsive"
              src={DownloadAppPhone}
              alt="Download the radish app today"
            />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppCard;
