import parse from "html-react-parser";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";

const UserConsentModal = ({ content }) => {
  //alert(data);
  const userObj = useSelector((state) => state.user);

  return (
    <div className="center-popup-data mb-5">
      <p>{parse(content.labels.LBL_CONSENT_NOT_GIVEN)}</p>
      <p>
        By accepting, you agree to our updated terms and policies. Please take a
        few minutes to read and understand them.
      </p>
      {
        <button
          onClick={content.onAcceptClick}
          className="btn btn-primary ucase"
        >
          {userObj.isConsentLoading ? <Spinner /> : "ACCEPT"}
        </button>
      }
    </div>
  );
};

export default UserConsentModal;
