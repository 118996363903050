import {
  getAllUpcomingAppointments,
  getAllPastAppointments,
  getAppointmentSlots,
  cancelAppointment,
} from "../../requests/requestAPI";

const requestUpcomingAppointnments = () => ({
  type: "UPCOMING_APPOINTMENTS_REQUEST",
});
const receiveUpcomingAppointments = (payload) => ({
  type: "UPCOMING_APPOINTMENTS_RECEIVE",
  payload,
});
const failureUpcomingAppointments = (payload) => ({
  type: "UPCOMING_APPOINTMENTS_FAIL",
  payload,
});

export const upcomingAppointmentsAction = (token) => {
  return async (dispatch) => {
    dispatch(requestUpcomingAppointnments());
    try {
      let data = await getAllUpcomingAppointments(token);
      if (data.ok) {
        let appointmentsInfo = await data.json();
        dispatch(receiveUpcomingAppointments(appointmentsInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureUpcomingAppointments(JSON.parse(ex.message)));
    }
  };
};

const requestPastAppointnments = () => ({
  type: "PAST_APPOINTMENTS_REQUEST",
});
const receivePastAppointments = (payload) => ({
  type: "PAST_APPOINTMENTS_RECEIVE",
  payload,
});
const failurePastAppointments = (payload) => ({
  type: "PAST_APPOINTMENTS_FAIL",
});

export const pastAppointmentsAction = (token) => {
  return async (dispatch) => {
    dispatch(requestPastAppointnments());
    try {
      let data = await getAllPastAppointments(token);
      if (data.ok) {
        let appointmentsInfo = await data.json();
        dispatch(receivePastAppointments(appointmentsInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failurePastAppointments(JSON.parse(ex.message)));
    }
  };
};

const requestAppointnmentSlots = () => ({
  type: "APPOINTMENTS_SLOT_REQUEST",
});
const receiveAppointnmentSlots = (payload) => ({
  type: "APPOINTMENTS_SLOT_RECEIVE",
  payload,
});
const failureAppointnmentSlots = (payload) => ({
  type: "APPOINTMENTS_SLOT_FAIL",
  payload,
});

export const getAppointnmentSlotAction = (
  token,
  type,
  date,
  onsiteDate,
  state
) => {
  const payload = {
    ParentKey: type,
    AppointmentDate: date,
    onSiteAppointmentDate: onsiteDate,
    stateCode: state,
  };
  return async (dispatch) => {
    dispatch(requestAppointnmentSlots());
    try {
      let data = await getAppointmentSlots(token, payload);
      if (data.ok) {
        let appointmentsInfo = await data.json();
        dispatch(receiveAppointnmentSlots(appointmentsInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureAppointnmentSlots(JSON.parse(ex.message)));
    }
  };
};

const requestCancelAppointnment = () => ({
  type: "CANCEL_APP_REQUEST",
});
const receiveCancelAppointnment = (payload) => ({
  type: "CANCEL_APP_RECEIVE",
  payload,
});
const failureCancelAppointnment = (payload) => ({
  type: "CANCEL_APP_FAIL",
  payload,
});

export const patchCancelAppointment = (token, appointmentID) => {
  return async (dispatch) => {
    dispatch(requestCancelAppointnment());
    try {
      let data = await cancelAppointment(token, appointmentID);
      if (data.ok) {
        let appointmentsInfo = await data.json();
        dispatch(receiveCancelAppointnment(appointmentsInfo.value));
      } else {
        let appointmentsInfo = await data.json();
        throw new Error(JSON.stringify(appointmentsInfo));
      }
    } catch (ex) {
      dispatch(failureCancelAppointnment(JSON.parse(ex.message)));
    }
  };
};
