import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchHealthCheckList,
  saveHealthCheckList,
} from "../../redux/actions/healthCheckActions";
import { servicesAction } from "../../redux/actions/dashboardActions";

import ChecklistCheckIcon from "../../assets/img/svg/health-checklist-icons/checklist-check-icon.svg";
import ChecklistCrossIcon from "../../assets/img/svg/health-checklist-icons/checklist-cross-icon.svg";
import ChecklistQuestionIcon from "../../assets/img/svg/health-checklist-icons/checklist-question-icon.svg";
import HealthFinderIcon from "../../assets/img/svg/health-checklist-icons/my-health-finder-icon.svg";
import Chevron from "../../assets/img/svg/health-checklist-icons/chevron.svg";
import ChecklistCompleteIcon from "../../assets/img/svg/health-checklist-icons/checklist-complete-icon.svg";

import Spinner from "../common/Spinner";
import ChecklistCategory from "./healthChecklist/ChecklistCategory";

import { modalOpen as modalOpenAction } from "../../redux/actions/modalAction";

const HealthChecklist = ({ labels = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, features: accessLevel } = useSelector((state) => state.user);
  const { isGetHealthcheckLoading } = useSelector((state) => state.healthCheck);
  const { healthChecklist } = useSelector((state) => state.healthCheck);

  const [selectedTab, setSelectedTab] = useState(1);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);

  const [checklistToDo, setChecklistToDo] = useState([]);
  const [checklistComplete, setChecklistComplete] = useState([]);
  // const [checklistNotApplicable, setChecklistNotApplicable] = useState([]);
  const [currentChecklist, setCurrentChecklist] = useState([]);

  useEffect(() => {
    dispatch(fetchHealthCheckList(token));
  }, [dispatch, token]);

  useEffect(() => {
    let toDo = [];
    let complete = [];
    // let notApplicable = [];

    if (healthChecklist.categoryWiseRecommendations) {
      healthChecklist.categoryWiseRecommendations.forEach((categoryRec) => {
        const { recommendations, myHFCategoryHeading } = categoryRec;

        toDo.push({
          [myHFCategoryHeading]: recommendations.filter(
            ({ userResponse }) =>
              !userResponse || userResponse === "NOTCOMPLETED"
          ),
        });

        complete.push({
          [myHFCategoryHeading]: recommendations.filter(
            ({ userResponse }) =>
              userResponse === "COMPLETED" || userResponse === "NOTTOANSWER"
          ),
        });

        // notApplicable.push({
        //   [myHFCategoryHeading]: recommendations.filter(
        //     ({ userResponse }) => userResponse === "NOTTOANSWER"
        //   ),
        // });
      });
    }

    setChecklistToDo(toDo);
    setChecklistComplete(complete);
    // setChecklistNotApplicable(notApplicable);

    if (selectedTab === 1) {
      setCurrentChecklist(toDo);
    }
  }, [healthChecklist, selectedTab]);

  useEffect(() => {
    if (selectedTab === 1) setCurrentChecklist(checklistToDo);
    if (selectedTab === 2) setCurrentChecklist(checklistComplete);
  }, [checklistComplete, checklistToDo, selectedTab]);

  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "MODULE-MEDICAL-SERVICE"
  );
  const isDisableAll =
    selectModule.action === "DISABLE" || selectModule.action === "HIDE";
  const moduleLevelMsg = selectModule.featureMessage;

  const onBookClick = () => {
    if (isDisableAll) {
      dispatch(
        modalOpenAction({
          component: 3,
          content: {
            moduleLevelMsg: moduleLevelMsg,
          },
        })
      );
    } else {
      navigate(`/dashboard/bookappointment`, { replace: true });
    }
  };

  const handleClick = async (e, item) => {
    const payload = { id: item.id, userResponse: e.target.dataset.type };
    await dispatch(saveHealthCheckList(token, payload));
    dispatch(fetchHealthCheckList(token));
    dispatch(servicesAction(token));
  };

  const getCount = (checklist) => {
    let count = 0;
    for (let i = 0; i < checklist.length; i++) {
      count = count + checklist[i][Object.keys(checklist[i])[0]].length;
    }
    return count;
  };

  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left">
          <button
            onClick={() => navigate(-1)}
            className="back-t-screen-cta unset-btn"
          >
            Back
          </button>
          <h2>{labels.LBL_HEALTH_CHECKLIST}</h2>
        </div>
      </div>

      {isGetHealthcheckLoading &&
      !checklistToDo.length &&
      !checklistComplete.length ? (
        <Spinner />
      ) : (
        <div className="checklist-outer">
          <div className="checklist-row-top">
            <div className="checklist-heading-row">
              <h2 onClick={() => setDisclaimerOpen(!disclaimerOpen)}>
                {healthChecklist.myHFHeading}
              </h2>
              <img
                className={`${disclaimerOpen && "disclaimer-open"}`}
                src={Chevron}
                alt="Read the health checklist disclaimer"
                onClick={() => setDisclaimerOpen(!disclaimerOpen)}
              />
            </div>
            {disclaimerOpen && (
              <div className="checklist-disclaimer">
                <p>{labels.LBL_CHECKLIST_INFO_1}</p>
                <p>
                  <strong>{labels.LBL_PLEASE_NOTE} </strong>
                  {labels.LBL_CHECKLIST_INFO_2}
                </p>
                <a
                  href="https://health.gov/myhealthfinder"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={HealthFinderIcon} alt="My Health Finder Logo" />
                </a>
              </div>
            )}
            <ul>
              <li>
                <img src={ChecklistCheckIcon} alt="I have done this" />
                {labels.LBL_CHECKLIST_HAVE_DONE}
              </li>
              <li>
                <img src={ChecklistCrossIcon} alt="I have not done this" />
                {labels.LBL_CHECKLIST_HAVE_NOT_DONE}
              </li>
              <li>
                <img src={ChecklistQuestionIcon} alt="This is not applicable" />
                {labels.LBL_CHECKLIST_DOES_NOT_APPLY}
              </li>
            </ul>
          </div>
          <div className="checklist-tab-select">
            <span
              className={`checklist-tab ${
                selectedTab === 1 && "checklist-tab-active"
              }`}
              onClick={() => setSelectedTab(1)}
            >
              To Do ({getCount(checklistToDo)})
            </span>
            <span
              className={`checklist-tab ${
                selectedTab === 2 && "checklist-tab-active"
              }`}
              onClick={() => setSelectedTab(2)}
            >
              Completed ({getCount(checklistComplete)})
            </span>
          </div>
          <div className="checklist-categories-container">
            {currentChecklist.length > 0 &&
              currentChecklist.map((category, index) => (
                <ChecklistCategory
                  key={index}
                  category={category}
                  handleClick={handleClick}
                />
              ))}
            {selectedTab === 1 && !getCount(checklistToDo) && (
              <>
                <div className="checklist-complete-message">
                  <p>{labels.LBL_UP_TO_DATE}</p>
                  <img src={ChecklistCompleteIcon} alt="" />
                </div>
              </>
            )}
            {/* {selectedTab === 2 && getCount(checklistNotApplicable) ? (
              <>
                <p className="label-not-applicable ">
                  {labels.LBL_NOT_APPLICABLE}
                </p>
                {checklistNotApplicable.map((category, index) => (
                  <ChecklistCategory
                    key={index}
                    category={category}
                    handleClick={handleClick}
                  />
                ))}
              </>
            ) : null} */}
          </div>
          <div className="checklist-row-bottom checklist-book-btn">
            <button onClick={onBookClick} className="btn btn-primary ucase">
              {labels.LBL_BOOKAPPT_BTNTXT}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HealthChecklist;
