import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changePassword } from "../../../requests/requestAPI.js";
import {
  sidePopupClose,
  sidePopupLoading,
  sidePopupConfirmed,
} from "../../../redux/actions/sidePopupActions.js";
import Spinner from "../Spinner.js";

const ChangePassword = () => {
  let dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);
  const { isLoading, isConfirmed } = useSelector((state) => state.sidePopup);
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  function validatePassword() {
    if (!newPassword) {
      setIsPasswordInvalid(true);
      setPasswordErrorMessage("Required");
      return false;
    } else if (newPassword.length < 8) {
      setIsPasswordInvalid(true);
      setPasswordErrorMessage("Password must be longer than 8 characters");
      return false;
    } else {
      return true;
    }
  }

  function handlePasswordChanged() {
    dispatch(sidePopupConfirmed());
    setTimeout(() => {
      dispatch(sidePopupClose());
    }, 3000);
  }

  async function submitNewPassword() {
    if (validatePassword()) {
      setIsPasswordInvalid(false);
      const payload = {
        password: newPassword,
      };
      try {
        dispatch(sidePopupLoading());
        let data = await changePassword(token, payload);
        if (data.ok) {
          handlePasswordChanged();
        } else {
          let ab = await data.json();
          throw new Error(JSON.stringify(ab));
        }
      } catch (ex) {
        alert(ex);
      }
    }
  }

  if (isConfirmed)
    return <h2 className="app-heading-1 mb-65">PASSWORD CHANGE SUCCESSFUL</h2>;

  return (
    <>
      <h2 className="app-heading-1 mb-40">CHANGE PASSWORD</h2>
      <div className="form-group mb-15">
        <input
          className={`form-control form-control-lg ${
            isPasswordInvalid && "input-invalid"
          }`}
          type="password"
          value={newPassword}
          onChange={({ target }) => setNewPassword(target.value)}
          placeholder="At least 8 characters"
        />
        {isPasswordInvalid && (
          <span className="error-label">{passwordErrorMessage}</span>
        )}
      </div>
      <div className="form-group mb-15">
        <button
          type="button"
          className="btn btn-primary col-12 btn-lg"
          onClick={submitNewPassword}
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : "Change Password"}
        </button>
      </div>
    </>
  );
};

export default ChangePassword;
