import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../common/Spinner";

const InteractiveLabInsights = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ddhLabStatusInfo = useSelector((state) => state.documents);

  const source = ddhLabStatusInfo.doc_iframe_url ? ddhLabStatusInfo.doc_iframe_url : ddhLabStatusInfo.ddhLabStatusInfo ? ddhLabStatusInfo.ddhLabStatusInfo.url : "";

  useEffect(() => {
    return () => {
      if (ddhLabStatusInfo.doc_iframe_url) {
        dispatch({ type: "UPDATE_ELABORATE_URL", payload: "" })
      }
    }
  })


  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left">
          <button
            onClick={() => navigate("/dashboard/documents")}
            className="back-t-screen-cta unset-btn"
          >
            Back
          </button>
          <h2>Interactive Lab Insights</h2>
        </div>
      </div>

      {source ? (
        <div className="iframe-labs-wrapper">
          <iframe
            title="Interactive Lab Insights"
            className="iframe-labs"
            src={source}
            frameBorder="0"
            allowFullScreen
            id="ddhIframe"
          />
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default InteractiveLabInsights;
