import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selfRegisterAction } from "../../redux/actions/registerUserAction";
import Alert from "../common/Alert";
import Spinner from "../common/Spinner";
import { STRIPE_URL } from "../../utils/Environment";

const RetrieveRegistration = (props) => {
  const { labels = {}, selfRegisterData = {} } = props;
  const { isEmailSent } = selfRegisterData;
  let userInfo = useSelector((state) => state.user);
  const [values, setvalues] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [isSubmit, setSubmitted] = useState(false);

  function handleInputChange({ target }) {
    const { name, value } = target;
    setvalues({ ...values, [name]: value });
    setSubmitted(false);
  }

  let dispatch = useDispatch();

  const onPhoneEmailBlur = () => {
    const { phoneEmail } = values;
    var regex = new RegExp(
      "^([a-zA-Z0-9_\\.\\-])+\\@(([a-zA-Z0-9\\-])+\\.)+([a-zA-Z0-9]{2,4})|(^[0-9]{10})+$"
    );
    if (!regex.test(phoneEmail)) {
      setError("Please enter valid email address or phone number.");
      return;
    }
    setError("");
  };

  const onSubmit = async (event) => {
    const { phoneEmail } = values;
    event.preventDefault();
    setSubmitted(true);
    if (phoneEmail) {
      // if (!error && phoneEmail) {
      await dispatch(selfRegisterAction(phoneEmail, false));
    }
  };
  return (
    <div className="lg-form-wrap" id="login-form-main">
      <h3 className="login-heading">Register Account</h3>
      {/* {props.SelfRegisterApiErrorMsg && <Alert messageTitle="ERROR:" message={labels.MSG_PHONEEMAIL} cssClassName={"alert alert-danger mt-20"} />} */}
      {userInfo.SelfRegisterApiErrorMsg && (
        <Alert
          message={userInfo.SelfRegisterApiErrorMsg}
          cssClassName={"alert alert-danger mt-20"}
        />
      )}
      {/* {error && isSubmit && values.phoneEmail && <Alert messageTitle="ERROR:" message={labels.MSG_PHONEEMAIL_ERROR} cssClassName={"alert alert-danger mt-20"} />}
       */}
      {isEmailSent && (
        <Alert
          cssClassName={"alert alert-success mt-20"}
          message={labels.MSG_EMAILSENT}
        />
      )}
      <div className="form-group">
      <label className="infoLabel" htmlFor="phone-number-email">{labels.LBL_SELF_ONBOARDING}</label>
        <label htmlFor="phone-number-email">{labels.LBL_PHONEEMAIL}</label>
        <div className="inputwrap">
          <input
            type="text"
            className={`form-control form-control-lg ${isSubmit && !values.phoneEmail && "input-invalid"
              }`}
            id="phoneEmail"
            // placeholder='mobile number (xxx-xxx-xxx) or email'
            name="phoneEmail"
            disabled={isEmailSent}
            onChange={handleInputChange}
            onBlur={onPhoneEmailBlur}
          />
          {isSubmit && !values.phoneEmail && (
            <span className="error-label">{labels.MSG_REQ}</span>
          )}
          {/* <div className="switch-cta-wrap-left">
            Enter your details to retrieve your registration invite.
          </div> */}
        </div>
        <div className="switch-cta-wrap">
          {/* <a onClick={handleFormChange} className="link-type-1">
              Use Email Address
            </a> */}
        </div>
      </div>

      <button
        type="submit"
        disabled={props.isLoading || isEmailSent}
        onClick={onSubmit}
        className="btn btn-primary btn-lg col-12"
      >
        {props.isLoading ? <Spinner /> : labels.LBL_SUBMIt}
      </button>
      <div className="switch-cta-wrap">
        <span className="link-type-1">Already have an account? </span>
        <Link to="../login" className="link-type-1">
          <strong>Log in here</strong>
        </Link>
      </div>
      <div className="switch-cta-wrap">
        <span className="link-type-1">Interested in Radish for yourself?</span><br />
        <a href={STRIPE_URL} target="_blank" className="link-type-1">
          <strong>Sign up for an account</strong>
        </a>
      </div>
    </div>
  );
};

export default RetrieveRegistration;
