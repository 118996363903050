import { getSlidersList } from "../../requests/requestAPI";

const requestExploreList = () => ({
  type: "EXPLORE_LIST_REQ",
});

const receiveExploreList = (payload) => ({
  type: "EXPLORE_LIST_REC",
  payload,
});

const failExploreList = (payload) => ({
  type: "EXPLORE_LIST_FAIL",
  payload,
});

export const getExploreList = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestExploreList());
    try {
      let data = await getSlidersList(token, payload);
      if (data.ok) {
        let exploreSliders = await data.json();
        dispatch(receiveExploreList(exploreSliders));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failExploreList(JSON.parse(ex.message)));
    }
  };
};

const requestExploreListMore = () => ({
  type: "EXPLORE_LIST_MORE_REQ",
});

const receiveExploreListMore = (payload) => ({
  type: "EXPLORE_LIST_MORE_REC",
  payload,
});

const failExploreListMore = (payload) => ({
  type: "EXPLORE_LIST_MORE_FAIL",
  payload,
});

export const getExploreListMore = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestExploreListMore());
    try {
      let data = await getSlidersList(token, payload);
      if (data.ok) {
        let exploreSliders = await data.json();
        dispatch(receiveExploreListMore(exploreSliders));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failExploreListMore(JSON.parse(ex.message)));
    }
  };
};
