const dashboardState = {
  appointmentData: [],
  appointmentWorkplaceData: [],
  services: [],
  sliders: [],
  pastAppointmentSidebar: null,
  upcomingAppointmentSidebar: null,
  unreadDocumentsCount: null,
};

export const dashboardReducer = (state = dashboardState, action) => {
  if (action.type === "SLIDERS_REQUEST") {
    return {
      ...state,
      isSlidersLoading: true,
      isDashboadError: false,
      is404Error: "",
    };
  }

  if (action.type === "SLIDERS_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isSlidersLoading: false,
      isLoginError: false,
      sliders: [...payload],
      is404Error: "",
    };
  }

  if (action.type === "SLIDERS_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isSlidersLoading: false,
      isLoginError: true,
      is404Error: payload.code,
    };
  }
  if (action.type === "PAST_APPOINTMENT_REQUEST") {
    return {
      ...state,
      isPastAPPTLoading: true,
      is404Error: "",
    };
  }
  if (action.type === "PAST_APPOINTMENT_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      pastAppointmentSidebar: payload,
      isPastAPPTLoading: false,
      is404Error: "",
    };
  } 
  
  if (action.type === "MARK_NOTE_READ") {
    const { appointmentID } = action;
    let pastAppointmentSidebarTemp = [...state.pastAppointmentSidebar];
    return {
      ...state,
      pastAppointmentSidebar: pastAppointmentSidebarTemp.map((a)=>{
        if(a.appointmentID === appointmentID){
          return {...a, isSummaryNoteRead: 1 }
        }
        else{
          return a;
        }
      }),
    };
  }
  if (action.type === "PAST_NOTESREAD_REQUEST") {
    return {
      ...state,
      isSaveReadNotesLoading: true,
      is404Error: "",
    };
  }
  if (action.type === "PAST_NOTESREAD_RECEIVE") {
    return {
      ...state,
      isSaveReadNotesLoading: false,
      is404Error: "",
    };
  }
  if (action.type === "PAST_APPOINTMENT_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isPastAPPTLoading: false,
      is404Error: payload.code,
    };
  }
  if (action.type === "UPCOMING_APPOINTMENT_REQUEST") {
    return {
      ...state,
      isUpcomingAPPTLoading: true,
      is404Error: "",
    };
  }
  if (action.type === "UPCOMING_APPOINTMENT_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      upcomingAppointmentSidebar: { ...payload },
      isUpcomingAPPTLoading: false,
      is404Error: "",
    };
  }
  if (action.type === "UPCOMING_APPOINTMENT_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isUpcomingAPPTLoading: false,
      is404Error: payload.code,
    };
  }

  if (action.type === "APPOINTMENT_TYPE_REQUEST") {
    return {
      ...state,
      isAppointmentTypesLoading: true,
      isAppointmentError: false,
      is404Error: "",
    };
  }
  if (action.type === "APPOINTMENT_TYPE_RECEIVE") {
    const { payload, typeData } = action;
    return typeData
      ? {
          ...state,
          appointmentWorkplaceData: payload,
          isAppointmentTypesLoading: false,
          isAppointmentError: false,
          is404Error: "",
        }
      : {
          ...state,
          appointmentData: payload,
          isAppointmentTypesLoading: false,
          isAppointmentError: false,
          is404Error: "",
        };
  }
  if (action.type === "APPOINTMENT_TYPE_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isAppointmentTypesLoading: false,
      isAppointmentError: true,
      is404Error: payload.code,
    };
  }
  if (action.type === "SELECT_APP_TYPE") {
    const { payload } = action;
    return {
      ...state,
      selectedAppType: payload,
    };
  }

  if (action.type === "SERVICES_REQUEST") {
    return {
      ...state,
      isServicesLoading: true,
      isDashboadError: false,
      is404Error: "",
    };
  }

  if (action.type === "SERVICES_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLoginError: false,
      services: [...payload],
      is404Error: "",
      isServicesLoading: false,
    };
  }

  if (action.type === "SERVICES_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isLoginError: true,
      is404Error: payload.code,
      isServicesLoading: false,
    };
  }

  if (action.type === "UNREAD_DOCUMENT_REQUEST") {
    return {
      ...state,
      isUnreadDocumentsLoading: true,
      isUnreadDocumentsFail: false,
      is404Error: "",
    };
  }

  if (action.type === "UNREAD_DOCUMENT_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      unreadDocumentsCount: payload.MyToDos[0].unreadDocCount,
      isUnreadDocumentsLoading: false,
      isUnreadDocumentsFail: false,
      is404Error: "",
    };
  }

  if (action.type === "UNREAD_DOCUMENT_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isUnreadDocumentsLoading: false,
      isUnreadDocumentsFail: true,
      is404Error: payload.code,
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      appointmentData: [],
      appointmentWorkplaceData: [],
      services: [],
      sliders: [],
      pastAppointmentSidebar: null,
      upcomingAppointmentSidebar: null,
      appointmentSlots: [],
      is404Error: "",
    };
  }

  if (action.type === "LOGIN_REQUEST") {
    return {
      appointmentData: [],
      appointmentWorkplaceData: [],
      services: [],
      sliders: [],
      pastAppointmentSidebar: null,
      upcomingAppointmentSidebar: null,
      appointmentSlots: [],
      is404Error: "",
    };
  }

  return state;
};
