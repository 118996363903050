import {
  getHealthCheckList,
  postHealthCheckList,
} from "../../requests/requestAPI";

const requestHealthCheckList = () => ({
  type: "GET_HEALTHCHECK_REQ",
});
const receiveHealthCheckList = (payload) => ({
  type: "GET_HEALTHCHECK_REC",
  payload,
});
const failHealthCheckList = (payload) => ({
  type: "GET_HEALTHCHECK_FAIL",
  payload,
});

export const fetchHealthCheckList = (token) => {
  return async (dispatch) => {
    dispatch(requestHealthCheckList());
    try {
      let data = await getHealthCheckList(token);
      if (data.ok) {
        let hcInfo = await data.json();
        dispatch(receiveHealthCheckList(hcInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failHealthCheckList(JSON.parse(ex.message)));
    }
  };
};

const requestPostHealthCheck = () => ({
  type: "POST_HEALTHCHECK_REQ",
});
const receivePostHealthCheck = (payload) => ({
  type: "POST_HEALTHCHECK_REC",
  payload,
});
const failPostHealthCheck = (payload) => ({
  type: "POST_HEALTHCHECK_FAIL",
  payload,
});

export const saveHealthCheckList = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestPostHealthCheck());
    try {
      let data = await postHealthCheckList(token, payload);
      if (data.ok) {
        let hcInfo = await data.json();
        dispatch(receivePostHealthCheck(hcInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failPostHealthCheck(JSON.parse(ex.message)));
    }
  };
};
