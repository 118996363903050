import {
  getTopPastAppointment,
  getTopUpcomingAppointment,
  postSummeryNoteReadApi,
} from "../../requests/requestAPI";

const requestPastAppointment = () => ({
  type: "PAST_APPOINTMENT_REQUEST",
});
const receivePastAppointment = (payload) => ({
  type: "PAST_APPOINTMENT_RECEIVE",
  payload,
});
const failurePastAppointment = (payload) => ({
  type: "PAST_APPOINTMENT_FAIL",
  payload,
});

export const pastAppointmentAction = (topOffset, token) => {
  return async (dispatch) => {
    dispatch(requestPastAppointment());
    try {
      let data = await getTopPastAppointment(topOffset, token);
      if (data.ok) {
        let pastAppointmentInfo = await data.json();
        dispatch(receivePastAppointment(pastAppointmentInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failurePastAppointment(JSON.parse(ex.message)));
    }
  };
};


const requestNotesRead = () => ({
  type: "PAST_NOTESREAD_REQUEST",
});
const receiveNotesRead = (payload) => ({
  type: "PAST_NOTESREAD_RECEIVE",
  payload,
});
const failureNotesRead = (payload) => ({
  type: "PAST_NOTESREAD_FAIL",
  payload,
});

export const NotesReadAction = (token, a) => {
  return async (dispatch) => {
    dispatch(requestNotesRead());
    try {
      let data = await postSummeryNoteReadApi(token, a);
      if (data.ok) {
        let NotesReadInfo = await data.json();
        dispatch(receiveNotesRead(NotesReadInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureNotesRead(JSON.parse(ex.message)));
    }
  };
};
const requestUpcomingAppointment = () => ({
  type: "UPCOMING_APPOINTMENT_REQUEST",
});
const receiveUpcomingAppointment = (payload) => ({
  type: "UPCOMING_APPOINTMENT_RECEIVE",
  payload,
});
const failureUpcomingAppointment = (payload) => ({
  type: "UPCOMING_APPOINTMENT_FAIL",
  payload,
});

export const UpcomingAppointmentAction = (topOffset, token) => {
  return async (dispatch) => {
    dispatch(requestUpcomingAppointment());
    try {
      let data = await getTopUpcomingAppointment(topOffset, token);
      if (data.ok) {
        let upcomingAppointmentInfo = await data.json();
        dispatch(receiveUpcomingAppointment(upcomingAppointmentInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureUpcomingAppointment(JSON.parse(ex.message)));
    }
  };
};
