import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { modalClose } from "../../redux/actions/modalAction";

import ChecklistItemDescription from "./modal/ChecklistItemDescription";
import ApptTypesDesciptionModal from "./modal/ApptTypesDesciptionModal";
import DoctorsBioModal from "./modal/DoctorsBioModal";
import DisabledAppointmentsModal from "./modal/DisabledAppointmentsModal";
import CareTeamAttentionModal from "./modal/CareTeamAttentionModal";
import EditCareTeamModal from "./modal/EditCareTeamModal";
import UserConsentModal from "./modal/UserConsentModal";

const ModalContent = ({ labels, content, closeModal }) => {
  const componentValue = useSelector((state) => state.modal.component);

  const values = {
    0: null,
    1: <ChecklistItemDescription labels={labels} content={content} />,
    2: (
      <ApptTypesDesciptionModal
        labels={labels}
        content={content}
        closeModal={closeModal}
      />
    ),
    3: <DisabledAppointmentsModal content={content} />,
    4: <DoctorsBioModal content={content} />,
    5: <CareTeamAttentionModal labels={labels} content={content} />,
    6: <EditCareTeamModal labels={labels} content={content} />,
    7: <UserConsentModal labels={labels} content={content} />,
  };

  return values[componentValue];
};

const Modal = ({ labels }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { content, component } = useSelector((state) => state.modal);

  const closeModal = () => {
    if (component === 3) navigate("/dashboard");
    dispatch(modalClose(component));
  };

  return (
    <>
      <div
        className="modal-overlay"
        onClick={content.noClose ? () => {} : closeModal}
      ></div>
      <div className="center-popup-active center-popup confirmed appt-type-modal">
        {content && <div className="modal-header">{content.title}</div>}
        {content.noClose ? (
          ""
        ) : (
          <button onClick={closeModal} className="close-popup unset-btn">
            Close
          </button>
        )}

        <div className="center-popup-inner">
          <ModalContent
            labels={labels}
            content={content}
            closeModal={closeModal}
          />
        </div>
      </div>
    </>
  );
};

export default Modal;
