import { Navigate } from "react-router-dom";

const RequireAuth = ({ children, cookies }) => {
  const abc = localStorage.getItem("loginInfo");
  const { token } = abc ? JSON.parse(abc) : { token: "dummuy" };
  let key = token;
  if (token) {
    key = token.split("=&")[1];
  }
  if (!(cookies.get(key) && localStorage.getItem("loginInfo"))) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default RequireAuth;
