import {
  bookAppointmentSlot,
  getBookAppointmentType,
} from "../../requests/requestAPI";

const requestBookAppointment = () => ({
  type: "APPOINTMENT_TYPE_REQUEST",
});
const receiveBookAppointment = (payload, typeData) => ({
  type: "APPOINTMENT_TYPE_RECEIVE",
  payload,
  typeData,
});
const failureBookAppointment = (payload) => ({
  type: "APPOINTMENT_TYPE_FAIL",
  payload,
});

export const getBookAppointmentTypeData = (token, type) => {
  const payload = {
    appointmentCategory: type ? type : "",
  };
  return async (dispatch) => {
    dispatch(requestBookAppointment());
    try {
      let data = await getBookAppointmentType(token, JSON.stringify(payload));
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(receiveBookAppointment(loginInfo.value, type));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureBookAppointment(JSON.parse(ex.message)));
    }
  };
};

const requestBookAppointmentSlot = () => ({
  type: "BOOK_APP_REQUEST",
});
const receiveBookAppointmentSlot = (payload) => ({
  type: "BOOK_APP_RECEIVE",
  payload,
});
const failureBookAppointmentSlot = (payload) => ({
  type: "BOOK_APP_FAIL",
  payload,
});

export const postBookAppointmentSlot = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestBookAppointmentSlot());
    try {
      let data = await bookAppointmentSlot(token, JSON.stringify(payload));
      if (data.ok) {
        let loginInfo = await data.json();
        dispatch(receiveBookAppointmentSlot(loginInfo.value));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureBookAppointmentSlot(JSON.parse(ex.message)));
    }
  };
};
