import { Link } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <div className="container">
      <div className="policy-heading">
        <h4>
          <strong>Terms of Use</strong>
        </h4>
        <p>
          <strong>Version 2.0</strong>
        </p>
        <p>
          <strong>
            Last Updated: March 1, 2023
            <br />
          </strong>
          <strong>Effective Date: December 27, 2018</strong>
        </p>
      </div>
      <div className="policy-wrap">
        <p>
          PLEASE READ THESE TERMS OF USE (THE “<strong>TERMS</strong>”)
          CAREFULLY. THESE TERMS APPLY TO THIS WEBSITE, AS WELL AS ALL RELATED
          WEBSITES, APPLICATIONS, NETWORKS AND OTHER SERVICES PROVIDED BY RADISH
          HEALTH INC. (“
          <strong>RADISH</strong>”), ITS AFFILIATES OR AGENTS and on which a
          link to THE Terms is displayed (COLLECTIVELY, THE “
          <strong>SERVICES</strong>”). BY CLICKING ON THE BOX TO INDICATE YOUR
          ACCEPTANCE OF THE TERMS, OR USING THE SERVICES, YOU REPRESENT THAT (1)
          YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS, (2) YOU
          ARE AT LEAST SIXTEEN (16) YEARS OLD, (3) YOU HAVE THE AUTHORITY TO
          ENTER INTO THE TERMS, AND (4) IF YOU ARE UNDER EIGHTEEN (18) YEARS OLD
          (OR UNDER THE AGE OF MAJORITY WHERE YOU LIVE), YOU HAVE REVIEWED THE
          TERMS WITH YOUR PARENT OR LEGAL GUARDIAN AND HE OR SHE AGREES TO THE
          TERMS ON YOUR BEHALF AND TAKES FULL RESPONSIBILITY FOR YOUR COMPLIANCE
          WITH THE TERMS. If you are under 18 years of age (or under the age of
          majority where you live), you understand that you cannot later void
          the Terms with Radish as a minor without losing access to your
          Services, and your parents/legal guardians are responsible for your
          actions and any obligations you have incurred while enjoying the
          benefits of access to Services as if you were an adult.{" "}
          <strong>
            IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS, YOU MAY NOT ACCESS OR
            USE THE SERVICES.
          </strong>
        </p>
        <p>
          <strong>
            PLEASE BE AWARE THAT SECTION 12 OF THE TERMS, BELOW, CONTAINS
            PROVISIONS GOVERNING HOW CLAIMS THAT YOU AND WE HAVE AGAINST EACH
            OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT
            AROSE OR WERE ASSERTED PRIOR TO THE EFFECTIVE DATE OF THE TERMS. IN
            PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH
            LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO
            BINDING AND FINAL ARBITRATION.&nbsp; UNLESS YOU OPT OUT OF THE
            ARBITRATION AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE
            CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
            PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A
            COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS.
          </strong>
        </p>
        <p>
          <strong>
            THE SERVICES PROVIDED THROUGH RADISH ARE INTENDED FOR ONLY RESIDENTS
            OF THE STATE OF NEW YORK AND THE COMMONWEALTH OF PENNSYLVANIA WHO
            ARE 16 YEARS OR OLDER. IF YOU ARE NOT A RESIDENT OF NEW YORK OR
            PENNSYLVANIA AND/OR YOU ARE UNDER THE AGE OF 16 YOU WILL NOT BE
            PERMITTED TO USE THE SERVICES PROVIDED THROUGH THIS SITE.
          </strong>
        </p>
        <p>
          Your use of, and participation in, certain Services may be subject to
          additional terms (<strong>“Supplemental Terms”</strong>) and such
          Supplemental Terms will either be linked to from the Terms or will be
          presented to you for your acceptance when you sign up to use the
          supplemental features or Service.&nbsp; All Supplemental Terms are
          incorporated into the Terms by reference.&nbsp; If the Terms are
          inconsistent with the Supplemental Terms, the Supplemental Terms
          control with respect to such Service.
        </p>
        <p>
          PLEASE NOTE THAT THE TERMS ARE SUBJECT TO CHANGE BY RADISH IN ITS SOLE
          DISCRETION AT ANY TIME.&nbsp; When changes are made, Radish will make
          a new copy of the Terms available through the Services.&nbsp; We will
          also update the “Last Updated” date at the top of the Terms.&nbsp; If
          we make any material changes, and you have previously used the
          Services, we may also send an e-mail to you at the last e-mail address
          you provided to us pursuant to the Terms.&nbsp; Any changes to the
          Terms will be effective 30 days after posting notice of such changes
          on the Services for existing users. Radish may require you to provide
          consent to the updated Terms in a specified manner before further use
          of the Services is permitted.&nbsp; If you do not agree to any
          change(s) after receiving a notice of such change(s), you must stop
          using the Services.&nbsp; Otherwise, your continued use of the
          Services constitutes your acceptance of such change(s).&nbsp; PLEASE
          REGULARLY CHECK THE SERVICES TO VIEW THE THEN-CURRENT TERMS.
        </p>
        <ol>
          <li>
            <strong>
              Overview of the Services.
              <br />
              <br />
              1.1
            </strong>{" "}
            <strong>
              <u>Medical and Telehealth Services</u>
            </strong>
            <strong>.</strong>&nbsp;Radish does not provide any medical services; rather Radish provides a technology platform 
            for you to access a Radish Health Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); 
            Alphabet City Medicine, P.C. (NJ); and/or Alphabet City Medicine of California P.C. (CA) 
            health care provider to obtain certain medical services, 
            including prescription refills through the Services (“
            <strong>Telehealth Services</strong>”).&nbsp; No medical services
            other than the Telehealth Services are available from Radish Health Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); 
            Alphabet City Medicine, P.C. (NJ); and/or Alphabet City Medicine of California P.C. (CA) 
            through the Radish platform other than scheduling
            appointments and paying for onsite visits. A health care provider of
            Telehealth Services interacting with you through the Services may
            not have the benefit of information that would be obtained by
            examining you in person and observing your physical condition, in
            each instance.&nbsp; Therefore, the health care provider may not be
            aware of facts or information that may affect his or her opinion
            regarding a potential diagnosis or treatment recommendation. To
            reduce the risk to you of this limitation, Radish strongly
            encourages you to provide all relevant information and discuss any
            and all diagnosis and treatment options with a health care
            provider.&nbsp; Moreover, a health care provider utilizing Radish
            may be limited by state law in prescribing certain medications to
            you without first conducting an in-person physical examination. By
            deciding to engage the Services, including the Telehealth Services,
            you acknowledge and agree that you are aware of these limitations
            and agree to assume the risk of these limitations. Furthermore, you
            agree and accept that: (i) the Telehealth&nbsp;Services are not
            intended, in all cases, to replace a full medical evaluation or an
            in-person visit with a health care provider; (ii) a health care
            provider acting through the Services may not have important
            information that is usually obtained through a “hands-on” physical
            examination; and (iii) the absence of a physical examination may
            affect the health care provider’s ability to determine whether a
            particular drug is safe for you to take.{" "}
            <strong>
              IN THE EVENT OF A MEDICAL EMERGENCY, USERS SHOULD CALL 911 OR
              CONTACT THEIR PRIMARY HEALTH CARE PRACTITIONER.
            </strong>{" "}
            For more information regarding the Telehealth Services offered
            through the Services, please visit{" "}
            <strong>www.radish.health</strong>.<br />
            <br />
            <strong>
              1.2 <u>S</u>
            </strong>
            <strong>
              <u>pecial Consent to Telehealth Services</u>
            </strong>
            . Telemedicine involves the delivery of health care services using
            electronic communications, information technology, or other means
            between a health care provider and a patient who are not in the same
            physical location.&nbsp; Telemedicine may be used for prescription
            refills and/or related patient education, and may include, but is
            not limited to:
            <br />
            <br />
            <ol className="policy-alpha-list">
              <li>
                Electronic transmission of medical records, photo images,
                personal health information, or other data between a patient and
                health care provider;
                <br />
                <br />
              </li>
              <li>
                Interactions between a patient and health care provider via
                audio, video, and/or data communications; and
                <br />
                <br />
              </li>
              <li>
                The electronic systems used in the Services will incorporate
                network and software security protocols to protect the privacy
                and security of health information and imaging data, and will
                include measures to safeguard the data to ensure its integrity
                against intentional or unintentional corruption.
                <br />
                <br />
              </li>
              <li>
                Please see the Consent to Medical and Telehealth Care available
                at{" "}
                <strong>
                  <Link to="../consent">
                    https://patient.radish.health/policies/consent
                  </Link>
                </strong>{" "}
                for a description of the risks and benefits of medical services,
                including telemedicine.&nbsp; If you do not accept and agree to
                be bound by the Consent to Medical and Telehealth Care, you are
                not authorized to access or otherwise receive the Telehealth
                Services.
                <br />
                <br />
              </li>
            </ol>
            <p>
              <strong>
                1.3 <u>Your Relationship with Radish</u>
              </strong>
              . You understand that by coordinating with an Radish Health Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); 
              Alphabet City Medicine, P.C. (NJ); and/or Alphabet City Medicine of California P.C. (CA) 
              health care provider you are not entering into a provider-patient relationship with Radish. By accepting the Terms, you agree 
              and consent to Radish, Radish affiliates, or Radish Health Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); 
              Alphabet City Medicine, P.C. (NJ); and/or Alphabet City Medicine of California P.C. (CA) 
              health care providers sending you disclosures, notices, messages, reports, and other communications. It is your 
              responsibility to monitor these communications. You acknowledge and agree that you will not hold Radish or any Radish 
              affiliate liable for any loss, injury, or claim of any kind resulting from your failure to read these communications 
              or for your failure to comply with any treatment recommendations contained in communications from Radish Health 
              Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); Alphabet City Medicine, P.C. (NJ); and/or Alphabet City 
              Medicine of California P.C. (CA) or its health care providers. Except for specific 
              communications received from Radish Health Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); 
              Alphabet City Medicine, P.C. (NJ); and/or Alphabet City Medicine of California P.C. (CA) 
              or its affiliated professional entities and their health care providers, none of the content you receive through 
              the Services should be considered medical advice.
            </p>
            <strong>
              1.4{" "}
              <u>
                Necessary Equipment and Software; Consent to Receive Calls and
                Text Messages
              </u>
            </strong>
            . You are solely responsible for any fees, including Internet
            connection or mobile fees, that you incur when accessing the
            Services.&nbsp; By providing your cellphone number and using the
            Services, you hereby affirmatively consent to our use of your
            cellphone number for calls and texts in order to provide the
            Services. Radish will not assess and charge for any calls or texts,
            but standard message charges or other charged from your wireless
            carrier may apply.&nbsp; You may opt out of receiving text messages
            from us by emailing{" "}
            <strong>
              <a href="mailto:help@radish.health">help@radish.health</a>.<br />
              <br />
            </strong>
            <strong>
              1.5 <u>Limited License</u>
            </strong>
            . Subject to your compliance with the Terms, Radish grants you
            permission to access and use our Services solely for your personal
            use.
            <br />
            <br />
            <strong>
              1.6 <u>Certain Restrictions</u>
            </strong>
            <strong>.</strong> As a condition of your use of the Services, you
            agree not to use the Services for any purpose that is prohibited by
            the Terms or by applicable law. The rights granted to you in the
            Terms are subject to the following restrictions: you may not (a)
            license, sell, rent, lease, transfer, assign, reproduce, distribute,
            host or otherwise commercially exploit the Services or any portion
            thereof ,&nbsp; (b) frame or utilize framing techniques to enclose
            any trademark, logo, or other portions of the Services (including
            images, text, page layout or form); (c) use any metatags or other
            “hidden text” using Radish’s name or trademarks; (d) modify,
            translate, adapt, merge, make derivative works of, disassemble,
            decompile, reverse compile or reverse engineer any part of Services
            except to the extent the foregoing restrictions are expressly
            prohibited by applicable law; (e) use any manual or automated
            software, devices or other processes (including but not limited to
            spiders, robots, scrapers, crawlers, avatars, data mining tools or
            the like) to “scrape” or download data from the Services; (f) access
            Services in order to build a similar or competitive website,
            application or service; (g) except as expressly stated herein, copy,
            reproduce, distribute, republish, download, display, post or
            transmit any part of the Services in any form or by any means; and
            (h) remove or destroy any copyright notices or other proprietary
            markings contained on or in Services. In addition, you may not (and
            may not permit any third party) to either (y) take any action or (z)
            make available any User Content (as defined in Section 3) on or
            through the Services that: (i) infringes any patent, trademark,
            trade secret, copyright, right of publicity or other right of any
            person or entity; (ii) is unlawful, threatening, abusive, harassing,
            defamatory, libelous, deceptive, fraudulent, invasive of another’s
            privacy, tortious, obscene, offensive, or profane; (iii) constitutes
            unauthorized or unsolicited advertising, junk or bulk e-mail; (iv)
            involves commercial activities and/or sales without Radish’s prior
            written consent, such as contests, sweepstakes, barter, advertising,
            or pyramid schemes; (v) impersonates any person or entity, including
            any employee or representative of Radish and other users; (vi)
            interferes with or attempt to interfere with the proper functioning
            of the Services or uses the Services in any way not expressly
            permitted by the Terms; or (vii) attempts to engage in or engage in,
            any potentially harmful acts that are directed against the Services,
            including but not limited to violating or attempting to violate any
            security features of the Services, introducing viruses, worms, or
            similar harmful code into the Services, or interfering or attempting
            to interfere with use of the Services by any other user, host or
            network, including by means of overloading, “flooding,” “spamming,”
            “mail bombing,” or “crashing” the Services. Any future release,
            update or other addition to Services is subject to the Terms, unless
            otherwise stated by Radish at the time such release, updated or
            addition is made available to you.&nbsp; Radish, its suppliers and
            service providers reserve all rights not granted in the Terms.&nbsp;
            Any unauthorized use of the Services terminates the licenses granted
            by Radish pursuant to the Terms.
            <br />
            <strong>
              <br />
              1.7 <u>Third-Party Materials</u>
            </strong>
            <strong>.</strong> As a part of the Services, you may have access to
            materials that are hosted by another party.&nbsp; You agree that it
            is impossible for Radish to monitor such materials and that you
            access these materials at your own risk.
            <br />
            <br />
            <strong>
              1.8 <u>Ownership</u>.
            </strong> Excluding any User Content that you may provide (defined in
            Section <a href="#Ref531438414">1</a> below), you acknowledge that
            all the intellectual property rights, including copyrights, patents,
            trademarks, and trade secrets, in the Services and its content are
            owned by Radish or Radish’s suppliers.&nbsp; Neither these Terms
            (nor your access to the Service) transfers to you or any third party
            any rights, title or interest in or to such intellectual property
            rights, except for the limited access rights expressly set forth in
            Section 1.5. Radish and its suppliers reserve all rights not granted
            in these Terms.&nbsp; There are no implied licenses granted under
            these Terms. For clarity, any statistics and information regarding
            your use of the Services is separate from, and will not include or
            be deemed, User Content.
            <br />
            <br />
            <strong>
              1.9 <u>Not an Insurance Product</u>.
            </strong> Radish's services are not insurance coverage, and should not be considered a substitute 
            for health insurance. Users should still maintain traditional health insurance 
            coverage to ensure they have access to all necessary medical services.
            <br />
            <br />
          </li>
          <li>
            <strong>PRIVACY.</strong> Our Privacy Policy located here:{" "}
            <Link to="../pp">
              <strong>https://patient.radish.health/policies/pp</strong>
            </Link>
            . Please review the Privacy Policy to learn&nbsp;about:
            <br />
            <br />
            <ul className="policy-disc-list">
              <li>What information we may collect about&nbsp;you;</li>
              <li>What we use that information for;&nbsp;and</li>
              <li>With whom we share that&nbsp;information.</li>
            </ul>
            <br />
            <p>
              Additionally, please review the Notice of Privacy Practices located at{" "}
              <Link to="../npp">
                <strong>https://patient.radish.health/policies/npp</strong>
              </Link>
              {" "}to learn about how
              it uses and discloses your medical information and how you may
              access that information.
              <br/>
            </p>
          </li>
          <li>
            <strong>
              User Content.
              <br />
              <br />
              3.1. <span className="policy-underlined-span">User Content</span>.
            </strong>
            &nbsp; <strong>“User Content”</strong> means any and all information
            and content that a user submits to, or uses in connection with, the
            Services.&nbsp; You are solely responsible for your User
            Content.&nbsp; You assume all risks associated with use of your User
            Content, including any reliance on its accuracy, completeness or
            usefulness by others.&nbsp; Except as may be required by the HIPAA
            or related state-specific privacy laws and regulations), Radish is
            not obligated to backup any User Content, and your User Content may
            be deleted at any time without prior notice.&nbsp; You are solely
            responsible for creating and maintaining your own backup copies of
            your User Content if you desire.
            <br />
            <br />
            <strong>
              3.2. <span className="policy-underlined-span">License</span>
              .&nbsp;
            </strong>{" "}
            You grant (and you represent and warrant that you have the right to
            grant) to Radish an irrevocable, nonexclusive, royalty-free and
            fully paid, worldwide license to reproduce, distribute, publicly
            display and perform, prepare derivative works of, and otherwise use
            your User Content, and to grant sublicenses of the foregoing rights,
            for the purposes of operating and providing the Services to
            you.&nbsp; You irrevocably waive any claims and assertions of moral
            rights or attribution with respect to your User Content.
            <br />
            <br />
            <strong>
              3.3. <span className="policy-underlined-span">Feedback</span>
              .&nbsp;
            </strong>{" "}
            Your submission of any ideas, suggestions, documents, and/or
            proposals to Radish through its suggestion, feedback, wiki, forum or
            similar pages (“Feedback”) is at your own risk and Radish has no
            obligations (including without limitation obligations of
            confidentiality) with respect to such Feedback.&nbsp; You represent
            and warrant that you have all rights necessary to submit the
            Feedback.&nbsp; You grant to Radish a fully paid, royalty-free,
            perpetual, irrevocable, worldwide, non-exclusive, and fully
            sublicensable right and license to use, reproduce, perform, display,
            distribute, adapt, modify, re-format, create derivative works of,
            and otherwise commercially or non-commercially exploit in any
            manner, any and all Feedback, and to sublicense the foregoing
            rights, in connection with the operation and maintenance of the
            Services.
            <br />
            <br />
            <strong>
              3.4.{" "}
              <span className="policy-underlined-span">Investigations</span>
              .&nbsp;
            </strong>{" "}
            Radish may, but is not obligated to, monitor or review the Services
            and User Content at any time.&nbsp; Without limiting the foregoing,
            Radish may, in its sole discretion, to remove any of your User
            Content for any reason (or no reason), including if such content
            violates the Terms or any applicable law.&nbsp; Although Radish does
            not generally monitor user activity occurring in connection with the
            Services or User Content, if Radish becomes aware of any possible
            violations by you of any provision of the Terms, Radish reserves the
            right to investigate such violations, and Radish may, at its sole
            discretion, immediately terminate your license to use the Services,
            or change, alter or remove your User Content, in whole or in part,
            without prior notice to you.
            <br />
            <strong>
              <br />
            </strong>
          </li>
          <li>
            <strong>Third-Party Services. </strong>The Services may contain
            links and integrations to third-party websites, applications,
            services and application programming interfaces (collectively,{" "}
            <strong>“Third-Party Services”</strong>), such as Radish’s digital
            health partners, and are subject to the terms and conditions
            (including privacy policies) of each such Third-Party Service.&nbsp;
            Such Third-Party Services are not under the control of Radish, and
            Radish is not responsible for any Third-Party Services.&nbsp; Radish
            provides these Third-Party Services only as a convenience and does
            not review, approve, monitor, endorse, warrant, or make any
            representations with respect to Third-Party Services, or their
            products or services.&nbsp; Your use of the Third-Party Services is
            at your own risk.&nbsp; You should review applicable terms and
            policies, including privacy and data gathering practices, of any
            Third-Party Services, and should make whatever investigation you
            feel necessary or appropriate before proceeding with any transaction
            with any third party.
            <br />
            <br />
          </li>
          <li>
            <strong>Fees and Purchase Terms.</strong>
            <br />
            <br />
            <strong>
              5.1. <span className="policy-underlined-span">Payment</span>.
            </strong>
            &nbsp; You will pay all fees or charges for use of the Radish
            platform and medical services, including Telehealth Services
            provided by Radish Health Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); 
            Alphabet City Medicine, P.C. (NJ); and/or Alphabet City Medicine of California P.C. (CA) and its health care
            providers through the Radish platform in accordance with the fees,
            charges and billing terms in effect at the time a fee or charge is
            due and payable.&nbsp; You must provide Radish with a valid credit
            card (Visa, MasterCard, or any other issuer accepted by us) or
            PayPal account (“<strong>Payment Provider</strong>”), or purchase
            order information as a condition to obtaining Telehealth
            Services.&nbsp; Your Payment Provider agreement governs your use of
            the designated credit card or PayPal account, and you must refer to
            that agreement and not the Terms to determine your rights and
            liabilities.&nbsp; By providing Radish with your credit card number
            or PayPal account and associated payment information, you agree that
            Radish is authorized to immediately charge you for all fees and
            charges due and payable to Radish and that no additional notice or
            consent is required.&nbsp; You will immediately notify Radish of any
            change in your billing address or the credit card or PayPal account
            used for payment hereunder.&nbsp; Radish reserves the right at any
            time to change its prices and billing methods, either immediately
            upon posting on the Website or by e-mail delivery to you.
            <br />
            <br />
            <strong>
              5.2. <span className="policy-underlined-span">Taxes</span>.
            </strong>
            &nbsp; The payments required under Section 5.1 of the Terms do not
            include any Sales Tax that may be due in connection with the
            Services provided under the Terms.&nbsp; If Radish determines it has
            a legal obligation to collect a Sales Tax from you in connection
            with the Terms, Radish will collect such Sales Tax in addition to
            the payments required under Section 5.1 of the Terms.&nbsp; If any
            Services, or payments for any Services, under the Terms are subject
            to any Sales Tax in any jurisdiction and you have not remitted the
            applicable Sales Tax to Radish, you will be responsible for the
            payment of such Sales Tax and any related penalties or interest to
            the relevant tax authority, and you will indemnify Radish for any
            liability or expense Radish may incur in connection with such Sales
            Taxes.&nbsp; Upon Radish’s request, you will provide it with
            official receipts issued by the appropriate taxing authority, or
            other such evidence that you have paid all applicable taxes.&nbsp;
            For purposes of this section, “Sales Tax” means any sales or use tax
            and any other tax measured by sales proceeds that is the functional
            equivalent of a sales tax where the applicable taxing jurisdiction
            does not otherwise impose a sales or use tax.
            <br />
            <br />
            <strong>
              5.3. <span className="policy-underlined-span">Disputes</span>
              .&nbsp;
            </strong>{" "}
            Unless otherwise provided by the applicable payment processor or
            payment platform used in connection with your payment for Services,
            you must notify Radish in writing within 7 days after receiving your
            credit card statement if you dispute any of our charges on that
            statement or such dispute will be deemed waived.&nbsp; Billing
            disputes should be notified to the following address:{" "}
            <strong>
              <a href="mailto:help@radish.health">help@radish.health</a>
            </strong>
            .<br />
            <br />
          </li>
          <li>
            <strong>Indemnification.&nbsp;&nbsp;</strong>To the fullest extent
            permitted by law, you will indemnify and hold Radish, its parents,
            subsidiaries, affiliates (including without limitation Radish Health Medical Group, PLLC; 
            Alphabet City Medicine, P.C. (NY); Alphabet City Medicine, P.C. (NJ); 
            and/or Alphabet City Medicine of California P.C. (CA) and all other affiliated professional entities and
            their health care providers), officers, employees, agents, partners
            and licensors (collectively, the <strong>“Radish Parties”</strong>)
            harmless from any losses, costs, liabilities and expenses (including
            reasonable attorneys’ fees) relating to or arising out of: (a) your
            User Content; (b) your violation of the Terms; (c) your violation of
            any rights of another party, including any users of the Services; or
            (d) your violation of any applicable laws, rules or
            regulations.&nbsp; Radish reserves the right, at its own cost, to
            assume the exclusive defense and control of any matter otherwise
            subject to indemnification by you, in which event you will fully
            cooperate with Radish in asserting any available defenses.&nbsp;
            This provision does not require you to indemnify any of the Radish
            Parties for any unconscionable commercial practice by such party or
            for such party’s fraud, deception, false promise, misrepresentation
            or concealment, suppression or omission of any material fact in
            connection with any Services provided hereunder. You agree that the
            provisions in this section will survive any termination of the Terms
            or your access to the Services.
            <br />
            <br />
          </li>
          <li>
            <strong>
              Disclaimer of Warranties and Conditions.
              <br />
              <br />
              7.1. <span className="policy-underlined-span">As Is</span>.
            </strong>{" "}
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY
            LAW, (A) YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK, AND THE
            SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH
            ALL FAULTS AND (B) THE RADISH PARTIES EXPRESSLY DISCLAIM ALL
            WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
            WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF THE
            SERVICES.
            <br />
            <strong>
              <br />
            </strong>
            <ol className="policy-alpha-list">
              <li>
                THE RADISH PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION
                THAT: (1) THE SERVICES WILL MEET YOUR REQUIREMENTS; (2) YOUR USE
                OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                ERROR-FREE; OR (3) THE RESULTS THAT MAY BE OBTAINED FROM USE OF
                THE SERVICES WILL BE ACCURATE OR RELIABLE.
                <br />
                <br />
              </li>
              <li>
                ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH THE
                SERVICES IS ACCESSED AT YOUR OWN RISK, AND YOU ARE SOLELY
                RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY, INCLUDING, BUT NOT
                LIMITED TO, YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO
                ACCESS THE SERVICES, OR ANY OTHER LOSS THAT RESULTS FROM
                ACCESSING SUCH CONTENT.
                <br />
                <br />
              </li>
              <li>
                THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER
                DISRUPTIONS. RADISH MAKES NO WARRANTY, REPRESENTATION OR
                CONDITION WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED
                TO, THE QUALITY, EFFECTIVENESS, REPUTATION AND OTHER
                CHARACTERISTICS OF SERVICES.
                <br />
                <br />
              </li>
              <li>
                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
                RADISH OR THROUGH THE SERVICES WILL CREATE ANY WARRANTY NOT
                EXPRESSLY MADE HEREIN.
                <br />
                <br />
              </li>
              <li>
                From time to time, Radish may offer new “beta” features or tools
                with which its users may experiment. Such features or tools are
                offered solely for experimental purposes and without any
                warranty of any kind, and may be modified or discontinued at
                Radish’s sole discretion.&nbsp; The provisions of this section
                apply with full force to such features or tools.
                <br />
                <br />
              </li>
            </ol>
            <p>
              <strong>
                7.2 <u>No Liability for Conduct of Third Parties</u>
              </strong>
              <strong>.</strong> YOU ACKNOWLEDGE AND AGREE THAT THE RADISH
              PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD THE
              RADISH PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES ON THE
              SERVICES, USER CONTENT, OR THIRD PARTY SERVICES PROVIDED BY SUCH
              THIRD PARTIES, INCLUDING OPERATORS OF EXTERNAL SITES, AND THAT THE
              RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH YOU.
              <br />
              <br />
              <strong>
                7.3 <u>Limitations. </u>
              </strong>
              Notwithstanding the forgoing THE RADISH PARTIES do not disclaim
              any warranty or other right UNDER THIS SECTION 7 that THE RADISH
              PARTIES ARE prohibited from disclaiming under law.
            </p>
          </li>
          <li>
            <strong>
              Limitation of Liability.
              <br />
              <br />
            </strong>
            <strong>
              8.1 <u>Disclaimer of Certain Damages</u>
            </strong>
            <strong>.</strong> YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST
            EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE RADISH
            PARTIES BE LIABLE FOR ANY LOSS OF PROFITS, REVENUE OR DATA,
            INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT
            OF OR IN CONNECTION WITH THE SERVICES, OR DAMAGES OR COSTS DUE TO
            LOSS OF PRODUCTION OR USE, BUSINESS INTERRUPTION, PROCUREMENT OF
            SUBSTITUTE GOODS OR SERVICES, WHETHER OR NOT RADISH HAS BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN CONNECTION
            WITH THE TERMS, OR FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS
            WITH OTHER USERS OF THE SERVICES, ON ANY THEORY OF LIABILITY,
            RESULTING FROM: (1) THE USE OR INABILITY TO USE THE SERVICES; (2)
            THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES RESULTING
            FROM ANY GOODS, DATA, CONTENT, INFORMATION OR SERVICES PURCHASED OR
            OBTAINED OR MESSAGES RECEIVED FOR TRANSACTIONS ENTERED INTO THROUGH
            THE SERVICES; (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
            TRANSMISSIONS OR DATA; (4) STATEMENTS OR CONDUCT OF ANY THIRD PARTY
            ON THE SERVICES; OR (5) ANY OTHER MATTER RELATED TOTHE SERVICES,
            WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT (INCLUDING
            NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY.&nbsp; THE
            FOREGOING CAP ON LIABILITY DOES NOT APPLY TO LIABILITY OF AN RADISH
            PARTY FOR (A) DEATH OR PERSONAL INJURY CAUSED BY AN RADISH PARTY’S
            NEGLIGENCE; OR FOR (B) ANY INJURY CAUSED BY A RADISH PARTY’S FRAUD
            OR FRAUDULENT MISREPRESENTATION.
            <br />
            <br />
            <strong>
              8.2.{" "}
              <span className="policy-underlined-span">Cap on Liability</span>.
            </strong>
            &nbsp; UNDER NO CIRCUMSTANCES WILL THE RADISH PARTIES BE LIABLE TO
            YOU FOR MORE THAN THE GREATER OF (A) THE TOTAL AMOUNT PAID TO RADISH
            BY YOU DURING THE ONE-MONTH PERIOD PRIOR TO THE ACT, OMISSION OR
            OCCURRENCE GIVING RISE TO SUCH LIABILITY AND (B) ONE HUNDRED DOLLARS
            ($100).&nbsp; THE FOREGOING CAP ON LIABILITY DOES NOT APPLY TO
            LIABILITY OF AN RADISH PARTY FOR (A) DEATH OR PERSONAL INJURY CAUSED
            BY AN RADISH PARTY’S NEGLIGENCE; OR FOR (B) ANY INJURY CAUSED BY AN
            RADISH PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION.
            <br />
            <br />
            <strong>
              8.3. <span className="policy-underlined-span">User Conten</span>
              t.&nbsp;
            </strong>{" "}
            EXCEPT FOR RADISH’S OBLIGATIONS TO PROTECT YOUR PERSONAL DATA AS SET
            FORTH IN RADISH’S PRIVACY POLICY,&nbsp; RADISH ASSUMES NO
            RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR FAILURE
            TO STORE ANY CONTENT (INCLUDING, BUT NOT LIMITED TO, YOUR USER
            CONTENT), USER COMMUNICATIONS OR PERSONALIZATION SETTINGS.
            <br />
            <br />
            <strong>
              8.4.{" "}
              <span className="policy-underlined-span">
                Basis of the Bargain
              </span>
              .
            </strong>
            &nbsp; THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
            ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN RADISH AND YOU.
            <br />
            <br />
          </li>
          <li>
            <strong>Remedies</strong>
            <br />
            <br />
            <strong>
              9.1. <span className="policy-underlined-span">Violations</span>.
            </strong>
            &nbsp; If Radish becomes aware of any possible violations by you of
            the Terms, Radish reserves the right to investigate such
            violations.&nbsp; If, as a result of the investigation, Radish
            believes that criminal activity has occurred, Radish reserves the
            right to refer the matter to, and to cooperate with, any and all
            applicable legal authorities.&nbsp; Radish is entitled, except to
            the extent prohibited by applicable law, to disclose any information
            or materials on or in the Services, including your User Content, in
            Radish’s possession in connection with your use of the Services, to
            (1) comply with applicable laws, legal process or governmental
            request; (2) enforce the Terms, (3) respond to any claims that your
            User Content violates the rights of third parties, (4) respond to
            your requests for customer service, or (5) protect the rights,
            property or personal safety of Radish, its users or the public, and
            all enforcement or other government officials, as Radish in its sole
            discretion believes to be necessary or appropriate.
            <br />
            <br />
          </li>
          <li>
            <strong>
              Term and Termination.
              <br />
              <br />
              10.1. <span className="policy-underlined-span">Term</span>.&nbsp;
            </strong>{" "}
            The Terms commence on the date when you accept them (as described in
            the preamble above) and remain in full force and effect while you
            use the Services, unless terminated earlier in accordance with the
            Terms.
            <br />
            <br />
            <strong>
              10.2. <span className="policy-underlined-span">Prior Use</span>
              .&nbsp;
            </strong>{" "}
            Notwithstanding the foregoing, if you used the Services prior to the
            date you accepted the Terms, you hereby acknowledge and agree that
            the Terms commenced on the date you first used the Services
            (whichever is earlier) and will remain in full force and effect
            while you use the Services, unless earlier terminated in accordance
            with the Terms.
            <br />
            <br />
            <strong>
              10.3.{" "}
              <span className="policy-underlined-span">
                Termination of Services by Radish
              </span>
              .
            </strong>
            &nbsp; If you have breached any provision of the Terms, or if Radish
            is required to do so by law (e.g., where the provision of the
            Services is, or becomes, unlawful), Radish has the right to,
            immediately and without notice, suspend or terminate any Services
            provided to you. You agree that all terminations for cause are made
            in Radish’s sole discretion and that Radish will not be liable to
            you or any third party for any termination of your access to the
            Services.
            <br />
            <br />
            <strong>
              10.4.{" "}
              <span className="policy-underlined-span">
                Termination of Services by You
              </span>
              .
            </strong>
            &nbsp; If you want to terminate the Services provided by Radish, you
            may do so by notifying Radish at any time. Your notice should be
            sent, in writing, to Radish's address set forth below. <br />
            <br />
            <strong>
              10.5.{" "}
              <span className="policy-underlined-span">
                Effect of Termination
              </span>
              .&nbsp;
            </strong>{" "}
            Termination of any Service includes removal of access to such
            Service and barring of further use of the Service.&nbsp; Termination
            of all Services also includes deletion of your files and content
            associated with you (or any part thereof), including your User
            Content. Upon termination of any Service, your right to use such
            Service will automatically terminate immediately. You understand
            that any termination of Services may involve deletion of your User
            Content associated therewith from our live databases.&nbsp; Radish
            will not have any liability whatsoever to you for any suspension or
            termination, including for deletion of your User Content.&nbsp; All
            provisions of the Terms which by their nature should survive, will
            survive termination of Services, including without limitation,
            ownership provisions, warranty disclaimers, and limitation of
            liability.
            <br />
            <br />
            <strong>
              10.6.{" "}
              <span className="policy-underlined-span">
                No Subsequent Access
              </span>
              .&nbsp;
            </strong>{" "}
            If your ability to access is discontinued by Radish due to your
            violation of any portion of the Terms or for otherwise inappropriate
            conduct, then you agree that you will not attempt to re-access the
            Services, and you acknowledge that you will not be entitled to
            receive a refund for fees related to those Services to which your
            access has been terminated.&nbsp; In the event that you violate the
            immediately preceding sentence, Radish reserves the right, in its
            sole discretion, to immediately take any or all of the actions set
            forth herein without any notice or warning to you.
            <br />
            <br />
          </li>
          <li>
            <strong>International Users. </strong>The Website can be accessed
            from countries around the world and may contain references to
            Services and content that are not available in your country.&nbsp;
            These references do not imply that Radish intends to announce such
            Services or content in your country.&nbsp; The Services are
            controlled and offered by Radish from its facilities in the United
            States of America. Radish makes no representations that the Services
            are appropriate or available for use in other locations.&nbsp; Those
            who access or use the Services from other countries do so at their
            own volition and are responsible for compliance with local law.
            <br />
            <br />
          </li>
          <li>
            <strong>Dispute Resolution</strong>
            <strong>
              <em>.</em>
            </strong>{" "}
            <strong>
              <em>
                Please read the following arbitration agreement in this Section
                (“
              </em>
            </strong>
            <strong>Arbitration Agreement</strong>
            <strong>
              <em>
                ”) carefully.&nbsp; It requires you to arbitrate disputes with
                Radish and limits the manner in which you can seek relief from
                us.
              </em>
            </strong>
            <strong>
              <em>
                &nbsp;
                <br />
                <br />{" "}
              </em>
              12.1.{" "}
            </strong>
            <span className="policy-underlined-span">
              Applicability of Arbitration Agreement
            </span>
            . You agree that any dispute or claim relating in any way to your
            access or use of the Services, to any products sold or distributed
            through the Services, or to any aspect of your relationship with
            Radish, will be resolved by binding arbitration, rather than in
            court, except that (1) you may assert claims in small claims court
            if your claims qualify, so long as the matter remains in such court
            and advances only on an individual (non-class, non-representative)
            basis; and (2) you or Radish may seek equitable relief in court for
            infringement or other misuse of intellectual property rights (such
            as trademarks, trade dress, domain names, trade secrets, copyrights,
            and patents).&nbsp;
            <br />
            <br />
            <p>
              <strong>
                IF YOU AGREE TO ARBITRATION WITH RADISH, YOU ARE AGREEING IN
                ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER
                MONETARY OR OTHER RELIEF IN ANY LAWSUIT FILED AGAINST RADISH
                ALLEGING CLASS, COLLECTIVE, AND/OR REPRESENTATIVE CLAIMS ON YOUR
                BEHALF.&nbsp; INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING
                YOUR CLAIMS AGAINST RADISH IN AN INDIVIDUAL ARBITRATION
                PROCEEDING.&nbsp; IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE
                AWARDED MONEY OR OTHER RELIEF BY AN ARBITRATOR.&nbsp; YOU
                ACKNOWLEDGE THAT YOU HAVE BEEN ADVISED THAT YOU MAY CONSULT WITH
                AN ATTORNEY IN DECIDING WHETHER TO ACCEPT THE TERMS, INCLUDING
                THIS ARBITRATION AGREEMENT.
              </strong>
            </p>
            <strong>12.2. </strong>
            <span className="policy-underlined-span">
              Arbitration Rules and Forum
            </span>
            .&nbsp; The Federal Arbitration Act governs the interpretation and
            enforcement of this Arbitration Agreement.&nbsp; To begin an
            arbitration proceeding, you must send a letter requesting
            arbitration and describing your claim to{" "}
            <strong>
              <a href="mailto:help@radish.health">help@radish.health</a>&nbsp;
            </strong>{" "}
            AND&nbsp;{" "}
            <strong>
              Radish Health, 900 Broadway, Suite 903, New York,
              NY 10003
            </strong>
            . The arbitration will be conducted by JAMS, an established
            alternative dispute resolution provider.&nbsp; &nbsp;Disputes
            involving claims and counterclaims under $250,000, not inclusive of
            attorneys’ fees and interest, are subject to JAMS’s most current
            version of the Streamlined Arbitration Rules and procedures
            available at{" "}
            <strong>
              <a
                href="http://www.jamsadr.com/rules-streamlined-arbitration/"
                target="_blank"
                rel="noreferrer"
              >
                http://www.jamsadr.com/rules-streamlined-arbitration/
              </a>
            </strong>
            ; all other claims are subject to JAMS’s most current version of the
            Comprehensive Arbitration Rules and Procedures, available at{" "}
            <strong>
              <a
                href="http://www.jamsadr.com/rules-comprehensive-arbitration/"
                target="_blank"
                rel="noreferrer"
              >
                http://www.jamsadr.com/rules-comprehensive-arbitration/
              </a>
            </strong>
            .&nbsp; JAMS’s rules are also available at{" "}
            <strong>
              <a href="http://www.jamsadr.com" target="_blank" rel="noreferrer">
                www.jamsadr.com
              </a>
            </strong>{" "}
            or by calling JAMS at 800-352-5267.&nbsp; If JAMS is not available
            to arbitrate, the parties will select an alternative arbitral
            forum.&nbsp; If the arbitrator finds that you cannot afford to pay
            JAMS’s filing, administrative, hearing and/or other fees and cannot
            obtain a waiver from JAMS, Radish will pay them for you.&nbsp; In
            addition, Radish will reimburse all such JAMS’s filing,
            administrative, hearing and/or other fees for claims totaling less
            than $10,000 unless the arbitrator determines the claims are
            frivolous.&nbsp; Likewise, Radish will not seek attorneys’ fees and
            costs in arbitration unless the arbitrator determines the claims are
            frivolous.
            <br />
            <br />
            <p>
              You may choose to have the arbitration conducted by telephone,
              based on written submissions, or in person in the country where
              you live or at another mutually agreed location.&nbsp;&nbsp; Any
              judgment on the award rendered by the arbitrator may be entered in
              any court of competent jurisdiction.
              <br />
              <br />
              <strong>12.3.</strong>{" "}
              <span className="policy-underlined-span">
                Authority of Arbitrator
              </span>
              .&nbsp; The arbitrator, and not any federal, state or local court
              or agency has exclusive authority to (a) determine the scope and
              enforceability of this Arbitration Agreement and (b) resolve any
              dispute related to the interpretation, applicability,
              enforceability or formation of this Arbitration Agreement
              including, but not limited to any claim that all or any part of
              this Arbitration Agreement is void or voidable.&nbsp; The
              arbitration will decide the rights and liabilities, if any, of you
              and Radish.&nbsp; &nbsp;The arbitration proceeding will not be
              consolidated with any other matters or joined with any other cases
              or parties.&nbsp; The arbitrator has the authority to grant
              motions dispositive of all or part of any claim. The arbitrator
              will have the authority to award monetary damages and to grant any
              non-monetary remedy or relief available to an individual under
              applicable law, the arbitral forum’s rules, and the Terms
              (including the Arbitration Agreement). The arbitrator will issue a
              written award and statement of decision describing the essential
              findings and conclusions on which the award is based, including
              the calculation of any damages awarded.&nbsp; The arbitrator has
              the same authority to award relief on an individual basis that a
              judge in a court of law would have.&nbsp; The award of the
              arbitrator is final and binding upon you and us.
              <br />
              <br />
              <strong>12.4.</strong>{" "}
              <span className="policy-underlined-span">
                Waiver of Jury Trial
              </span>
              .&nbsp; YOU AND RADISH HEREBY WAIVE ANY CONSTITUTIONAL AND
              STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A
              JUDGE OR A JURY.&nbsp; You and Radish are instead electing that
              all claims and disputes will be resolved by arbitration under this
              Arbitration Agreement, except as specified in Section 12.1
              above.&nbsp; An arbitrator can award on an individual basis the
              same damages and relief as a court and must follow this Agreement
              as a court would.&nbsp; &nbsp;However, there is no judge or jury
              in arbitration, and court review of an arbitration award is
              subject to very limited review.
              <br />
              <br />
              <strong>12.5.</strong>{" "}
              <span className="policy-underlined-span">
                Waiver of Class or Consolidated Actions
              </span>
              .&nbsp; ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS
              ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS
              AND NOT ON A CLASS BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND
              CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
              CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.&nbsp;
              Notwithstanding anything to the contrary herein, (a)
              representative action for public injunctive relief may be
              arbitrated on a class basis and (b) in the event that the
              foregoing sentence is deemed invalid or unenforceable with respect
              to a particular class or dispute for recovery of damages, neither
              you nor we are entitled to arbitration and instead claims and
              disputes will be resolved in a court as set forth in Section 13.6.
              <br />
              <br />
              <strong>12.6.</strong>{" "}
              <span className="policy-underlined-span">
                30-Day Right to Opt Out
              </span>
              . You have the right to opt out of the provisions of this
              Arbitration Agreement by sending written notice of your decision
              to opt out to the following address:{" "}
              <strong>
                Radish Health, 900 Broadway, Suite 903, New York,
                NY 10003
              </strong>
              , or emailing us at:&nbsp;
              <strong>
                <a href="mailto:help@radish.health">help@radish.health</a>
              </strong>{" "}
              within 30 days after first becoming subject to this Arbitration
              Agreement. Your notice must include your name and address and an
              unequivocal statement that you want to opt out of this Arbitration
              Agreement.&nbsp; &nbsp; If you opt out of this Arbitration
              Agreement, all other parts of this Agreement will continue to
              apply to you.&nbsp; Opting out of this Arbitration Agreement has
              no effect on any other arbitration agreements that you may
              currently have, or may enter in the future, with us.
              <br />
              <br />
              <strong>12.7.</strong>{" "}
              <span className="policy-underlined-span">Severability</span>. If
              any part or parts of this Arbitration Agreement are found under
              the law to be invalid or unenforceable, then such specific part or
              parts will be of no force and effect and will be severed and the
              remainder of the Arbitration Agreement will continue in full force
              and effect.
              <br />
              <br />
              <strong>12.8.</strong>{" "}
              <span className="policy-underlined-span">
                Survival of Agreement
              </span>
              . This Arbitration Agreement will survive the termination of your
              relationship with Radish.
              <br />
              <br />
              <strong>12.9.</strong>{" "}
              <span className="policy-underlined-span">Modification</span>
              .&nbsp; Notwithstanding any provision in this Agreement to the
              contrary, we agree that if Radish makes any future material change
              to this Arbitration Agreement, it will not apply to any individual
              claim(s) that you had already provided notice of to Radish.
            </p>
          </li>
          <li>
            <strong>
              General Provisions.
              <br />
              <br />
            </strong>
            <strong>
              13.1.{" "}
              <span className="policy-underlined-span">
                Electronic Communications
              </span>
              .
            </strong>
            &nbsp; The communications between you and Radish use electronic
            means, whether you visit the Services or send Radish e-mails, or
            whether Radish posts notices on the Services or communicates with
            you via e-mail.&nbsp; For contractual purposes, you (1) consent to
            receive communications from Radish in an electronic form; and (2)
            agree that all terms and conditions, agreements, notices,
            disclosures, and other communications that Radish provides to you
            electronically satisfy any legal requirement that such
            communications would satisfy if it were to be in writing.&nbsp; The
            foregoing does not affect your statutory rights.
            <br />
            <br />
            <strong>
              13.2. <span className="policy-underlined-span">Assignment</span>
            </strong>
            .&nbsp; The Terms, and your rights and obligations hereunder, may
            not be assigned, subcontracted, delegated or otherwise transferred
            by you without Radish’s prior written consent, and any attempted
            assignment, subcontract, delegation, or transfer in violation of the
            foregoing will be null and void.
            <br />
            <br />
            <strong>
              13.3.{" "}
              <span className="policy-underlined-span">Force Majeure</span>.
            </strong>
            &nbsp; Radish is not be liable for any delay or failure to perform
            resulting from causes outside its reasonable control, including, but
            not limited to, acts of God, war, terrorism, riots, embargos, acts
            of civil or military authorities, fire, floods, accidents, strikes
            or shortages of transportation facilities, fuel, energy, labor or
            materials.
            <br />
            <br />
            <strong>
              13.4.{" "}
              <span className="policy-underlined-span">
                Questions, Complaints, Claims
              </span>
              .
            </strong>
            &nbsp; If you have any questions, complaints or claims with respect
            to the Services, please contact us at:{" "}
            <strong>
              <a href="mailto:help@radish.health">help@radish.health</a>
            </strong>
            . We will do our best to address your concerns.&nbsp; If you feel
            that your concerns have been addressed incompletely, we invite you
            to let us know for further investigation.
            <br />
            <br />
            <strong>
              13.5.{" "}
              <span className="policy-underlined-span">Exclusive Venue</span>.
            </strong>
            &nbsp; To the extent the parties are permitted under this
            Arbitration Agreement to initiate litigation in a court, both you
            and Radish agree that all claims and disputes arising out of or
            relating to the Terms will be litigated exclusively in the state or
            federal courts located in New York.
            <br />
            <br />
            <strong>
              13.6.{" "}
              <span className="policy-underlined-span">Governing Law</span>.
            </strong>
            &nbsp; THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND
            INTERPRETED BY AND UNDER THE LAWS OF NEW YORK CONSISTENT WITH THE
            FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY PRINCIPLES
            THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER
            JURISDICTION.&nbsp; THE UNITED NATIONS CONVENTION ON CONTRACTS FOR
            THE INTERNATIONAL SALE OF GOODS DOES NOT APPLY TO THESE TERMS.
            <br />
            <br />
            <strong>
              13.7. <span className="policy-underlined-span">Notice</span>
              .&nbsp;
            </strong>{" "}
            Where Radish requires that you provide an e-mail address, you are
            responsible for providing Radish with your most current e-mail
            address.&nbsp; In the event that the last e-mail address you
            provided to Radish is not valid, or for any reason is not capable of
            delivering to you any notices required/ permitted by the Terms,
            Radish’s dispatch of the e-mail containing such notice will
            nonetheless constitute effective notice.&nbsp; You may give notice
            to Radish at the following address:{" "}
            <strong>
              Radish Health, 900 Broadway, Suite 903, New York,
              NY 10003
            </strong>
            . Such notice is deemed given when received by Radish by letter
            delivered by nationally recognized overnight delivery service or
            first class postage prepaid mail at the above address.
            <br />
            <br />
            <strong>
              13.8. <span className="policy-underlined-span">Waiver</span>
              .&nbsp;
            </strong>{" "}
            Any waiver or failure to enforce any provision of the Terms on one
            occasion will not be deemed a waiver of any other provision or of
            such provision on any other occasion.
            <br />
            <br />
            <strong>
              13.9. <span className="policy-underlined-span">Severability</span>
              .&nbsp;
            </strong>{" "}
            If any portion of the Terms is held invalid or unenforceable, that
            portion will be construed in a manner to reflect, as nearly as
            possible, the original intention of the parties, and the remaining
            portions will remain in full force and effect.
            <br />
            <br />
            <strong>
              13.10.{" "}
              <span className="policy-underlined-span">Export Control</span>.
            </strong>
            &nbsp; The Services may be subject to U.S. export control laws and
            may be subject to export or import regulations in other countries.
            You agree not to export, re-export, or transfer, directly or
            indirectly, any U.S. technical data acquired from Radish, or any
            products utilizing such data, in violation of the United States
            export laws or regulations.
            <br />
            <br />
            <strong>
              13.11.{" "}
              <span className="policy-underlined-span">Entire Agreement</span>.
            </strong>
            &nbsp; The Terms are the final, complete and exclusive agreement of
            the parties with respect to the subject matter hereof and supersedes
            and merges all prior discussions between the parties with respect to
            such subject matter.
            <br />
            <br />
          </li>
          <li>
            <strong>
              Terms of Sale – These Terms of Sale govern any purchases and other transactions made on the Radish platform. 
              If your employer invited you to register for an account, then these Terms of Sale in Section 14 
              may not apply to you, unless you make additional purchases on the Radish platform.
              <br />
              <br />
            </strong>
            <strong>
              14.1.{" "}
              <span className="policy-underlined-span">Payment and Billing Information</span>.
            </strong>
            &nbsp; By providing a credit card or other payment method that we accept, you represent and 
            warrant that you are authorized to use the designated payment method and that you authorize 
            us (or our third-party payment processor) to charge your payment method for the total amount of your 
            purchase (including any applicable taxes and other charges) (each, a “Transaction”). If the payment 
            method cannot be verified, is invalid or is otherwise not acceptable, your Transaction may 
            be suspended or canceled. You must resolve any payment method problems before we proceed with 
            your Transaction. If you want to change or update your payment method information, you can do so 
            at any time by logging into your account. You acknowledge that the amount billed may vary due to 
            promotional offers, preferences you select, or changes in applicable taxes or other charges, and you 
            authorize us (or our third party-payment processor) to charge your payment method for the corresponding amount. 
            You may still be responsible for out-of pocket costs such as copays and other ancillary 
            services (eg. labs, medical equipment, cost of medication).
            <br />
            <br />
            <strong>
              14.2.{" "}
              <span className="policy-underlined-span">Subscription Autorenewal Policy</span>.
            </strong>
            &nbsp; When you register for a subscription (“Subscription”), you expressly acknowledge and agree that 
            (a) your Subscription automatically renews and Radish (or our third-party payment processor) is authorized 
            to charge you on an annual or monthly basis, as applicable, for your Subscription (in addition to any applicable 
            taxes and other charges) for as long as your Subscription continues, and (b) your Subscription continues to 
            automatically renew until you cancel it or we suspend or stop providing access to the Radish platform in 
            accordance with these Terms. Your Subscription type, current price, payment method, and next billing date will 
            be confirmed in an acknowledgement email after you register. You will also receive a payment receipt each time 
            your card on file is charged. Monthly subscriptions can be changed to annual Subscriptions at any time. However, 
            annual subscriptions can only be changed to monthly subscriptions at your next Subscription renewal date, which will 
            be the anniversary of the date on which you first registered for your Subscription, unless otherwise noted in the 
            email acknowledgement you receive upon registering. You may cancel your Subscription by going to the Profile tab 
            in the app. We reserve the right to change Subscription prices or this Subscription Autorenewal Policy at any time 
            in our sole discretion. If prices or material terms of this Subscription Autorenewal Policy are changed, 
            you will be notified by email prior to the change.
            <br />
            <br />
            <strong>
              14.3.{" "}
              <span className="policy-underlined-span">Refunds</span>.
            </strong>
            &nbsp; Radish Health does not offer refunds for subscription fees, except when required by law. Certain refund 
            requests for Subscriptions may be considered on a case-by-case basis and granted at the sole discretion 
            of Radish Health. If a refund is issued, it will be in the form of a credit to the user's account, 
            and not in cash or other forms of payment.
            <br />
            <br />
            <strong>
              14.4.{" "}
              <span className="policy-underlined-span">Cancellation</span>.
            </strong>
            &nbsp; Users may cancel their subscription at any time by contacting our support team. 
            Cancellations will take effect at the end of the billing cycle, and users will continue to 
            have access to our services until that time.
            <br />
            <br />
            <strong>
              14.5.{" "}
              <span className="policy-underlined-span">Promotions</span>.
            </strong>
            &nbsp; Any sweepstakes, contests, raffles, surveys, games, or similar promotions (collectively, “Promotions”) 
            made available through the Service may be governed by rules that are separate from this Agreement.  
            If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy.  
            If the rules for a Promotion conflict with this Agreement, the Promotion rules will govern.
          </li>
        </ol>
        <p className="small" style={{ textAlign: "right" }}>
          193638777 v8
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
