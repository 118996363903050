import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { postBookAppointmentSlot } from "../../../redux/actions/bookAppointmentActions";
import Spinner from "../Spinner";
import {
  sidePopupLoading,
  sidePopupConfirmed,
  sidePopupFailed,
} from "../../../redux/actions/sidePopupActions.js";
import AddToCalendar from "@culturehq/add-to-calendar";
import Logo from "../../../assets/img/svg/radish-icon.svg";

const BookAppPopup = () => {
  // const [termsValue, setTerms] = useState(false);
  const {
    selectedSlotToBookApp = {},
    bookedAppData = {},
    isAppBookLoading = false,
    isAppBookError = false,
    appBookErrorMsg,
  } = useSelector((state) => state.appointments);
  const { token } = useSelector((state) => state.user);
  const { appointmentData } = useSelector((state) => state.dashboard);
  const { slot, subType, time, localTime, timeZone } = selectedSlotToBookApp;
  const labels = useSelector((state) => state.app.labels);

  const dispatch = useDispatch();

  function getAppType(type) {
    return appointmentData.find((next) => next.parentKey === type)
      .parentDisplayText;
  }

  // function getTimeForApp(type) {
  //   return appointmentData.find((next) => next.parentKey === type).duration;
  // }

  // function onConsentChange() {
  //   setTerms(!termsValue);
  // }

  useEffect(() => {
    if (isAppBookError) {
      dispatch(sidePopupFailed());
    }
  }, [dispatch, isAppBookError]);

  async function bookMyAppointment() {
    const payload = {
      appointmentTypeID: subType.appointmentTypeID,
      appointmentDate: `${subType.appointmentDate} ${time}`,
      doctorID: slot.doctorID,
      officeID: subType.officeID,
    };
    dispatch(sidePopupLoading());
    await dispatch(postBookAppointmentSlot(token, payload));
    dispatch(sidePopupConfirmed());
  }

  function getCalendarEvent() {
    const {
      localTime,
      subType: { appointmentDate, additionalDetails, location, isOnSite },
    } = selectedSlotToBookApp;
    const toDate = moment(
      appointmentDate + " " + localTime,
      "YYYY-MM-DD HH:mm A"
    );
    const endDate = moment(toDate).add(60, "minutes");

    return {
      name:
        isOnSite === 0
          ? "Radish Health Virtual Appointment"
          : "Radish Health In-Person Appointment",
      details:
        isOnSite === 0 && !additionalDetails
          ? `For your upcoming virtual appointment, please have a smartphone, computer, or tablet ready with the camera and microphone permissions enabled. You can enter the virtual waiting room 10 minutes before your appointment at the link provided or through your Patient Portal. If you need to cancel your appointment, please do so in the Radish Health mobile app, at https://patient.radish.health, or email help@radish.health. Link to join appointment: ${bookedAppData.vrurl}`
          : additionalDetails +
            " " +
            "If you need to cancel your appointment, please do so in the Radish Health mobile app, at https://patient.radish.health, or email help@radish.health.",
      location: isOnSite ? location : bookedAppData.vrurl,
      startsAt: toDate,
      endsAt: endDate,
    };
  }

  function isToday(date) {
    let d1 = moment().format("YYYY-MM-DD");
    const date1 = new Date(d1);
    const date2 = new Date(date);
    if (
      date1.getDate() === date2.getDate() &&
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    ) {
      return `Today ${moment(date, "YYYY-MM-DD").format("MMM Do")}`;
    }
    return ` ${moment(date).format("dddd")} ${moment(date, "YYYY-MM-DD").format(
      "MMM Do"
    )}`;
  }

  return (
    <>
      {isAppBookError && appBookErrorMsg ? (
        <h2 className="app-heading-1 mb-40">{appBookErrorMsg}</h2>
      ) : (
        <h2 className="app-heading-1 mb-40">
          {bookedAppData.appointmentID
            ? labels.MSG_BOOKAPPT_CONFIRMED
            : labels.MSG_BOOKAPPT_CONFIRM}
        </h2>
      )}
      <div className="appt-info-header mb-10">
        <div className="appt-info-type">{getAppType(slot.selectedAppType)}</div>
        <div className="appt-info-location">
          {subType.appointmentTypeCategoty}
        </div>
      </div>
      <div className="avatar-info avatar-info-2 mb-25">
        <div className="avatar-info-in">
          <figure>
            <img
              src={slot.doctorProfilePicture || Logo}
              alt=""
              className="img-responsive"
            />
          </figure>
          <div className="ai-meta">
            <span className="ai-title">{slot.doctorName}</span>
            <span className="ai-position font-regular">{slot.doctorTitle}</span>
          </div>
        </div>
      </div>
      <div className="datetime-cart mb-30">
        <span className="date">{isToday(subType.appointmentDate)}</span>
        <span className="time">
          {localTime} {timeZone}
        </span>
      </div>
      {/* {!bookedAppData.appointmentID ? (
        <div className="form-check mb-25">
          <input
            className="form-check-input"
            value={termsValue}
            onChange={onConsentChange}
            type="checkbox"
            id="i-consent"
          />
          <label className="form-check-label" htmlFor="i-consent">
            I consent to{" "}
            <Link to="/policies/consent" target="_blank">
              medical and telehealth care
            </Link>
            , and I agree to the{" "}
            <Link to="/policies/pp" target="_blank">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link to="/policies/tou" target="_blank">
              Terms of Use
            </Link>
            .
          </label>
        </div>
      ) : (
        ""
      )} */}
      {bookedAppData.appointmentID ? (
        ""
      ) : (
        <>
          <button
            onClick={bookMyAppointment}
            disabled={isAppBookLoading}
            className="btn btn-primary col-12 btn-lg"
          >
            {isAppBookLoading ? <Spinner /> : labels.LBL_BOOKAPPT_BTNTXT}
          </button>
          {slot.customMessage && (
            <label className="consent-help" htmlFor="i-consent">
              {slot.customMessage}{" "}
            </label>
          )}
          {subType.onsiteDisclaimer && (
            <label className="consent-help">{subType.onsiteDisclaimer} </label>
          )}
        </>
      )}
      {bookedAppData.appointmentID ? (
        <AddToCalendar
          event={getCalendarEvent()}
          children={"ADD TO CALENDAR"}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default BookAppPopup;
