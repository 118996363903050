import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="col-12">
      <div className="policy-heading">
        <h4>
          <strong>Privacy Policy</strong>
        </h4>
        <p>
          <strong>Version 2.0</strong>
        </p>
        <p>
          <strong>
            Last Updated: June 25, 2023
            <br />
          </strong>
          <strong>Effective Date: December 27, 2018</strong>
        </p>
      </div>
      <div className="polic-wrap">
        <p>
          Radish Health Inc. (“<strong>Radish</strong>”, “<strong>we</strong>”,
          “<strong>us</strong>” and/or “<strong>our</strong>”) is committed to
          protecting your privacy.&nbsp; We have prepared this Privacy Policy to
          describe to you our practices regarding the Personal Data (as defined
          below) we collect from users of our website, located at{" "}
          <strong>
            <a
              href="https://www.radish.health"
              target="_blank"
              rel="noreferrer"
            >
              https://www.radish.health
            </a>
          </strong>
          , as well as all related websites, applications, networks and other
          services provided by us an on which a link to this Privacy Policy is
          displayed (collectively, our <strong>“Services”</strong>). This
          Privacy Policy is incorporated into and is subject to the Radish Terms
          of Use found at <strong><Link to="../tou">https://patient.radish.health/policies/tou</Link></strong>. As
          described in the Radish Terms of Use, the Services may allow you to
          connect with a health care provider from Alphabet City group of medical providers (including without limitation Radish Health Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); Alphabet City Medicine, P.C. (NJ); Alphabet City Medicine of California P.C. (CA)) (the “
          <strong>Practice</strong>”) to provide certain medical services,
          including the Telehealth Services (as defined in the Radish Terms of
          Use).
        </p>
        <ol>
          <li>
            <strong>Questions; Contacting Radish; Reporting Violations</strong>.
            If you have any questions or concerns or complaints about our
            Privacy Policy or our data collection or processing practices, or if
            you want to report any security violations to us, please contact us
            at the following address or phone number:
            <br />
            <br />
            Radish Health Inc.
            <br />
            900 Broadway, Suite 903, New York, NY 10003
            <br />
            Phone: (833) 472-3474
            <br />
            <br />
          </li>
          <li>
            <strong>User Consent. </strong>By checking the box to indicate your
            acceptance of this Privacy Policy, you agree to the terms of this
            Privacy Policy and you expressly consent to the collection, use and
            disclosure of your Personal Data in accordance with this Privacy
            Policy.
            <br />
            <br />
          </li>
          <li>
            <strong>A Note About Individuals Under the Age of 16.</strong> The
            Services provided through Radish are not intended for individuals
            under the age of 16. We do not intentionally gather Personal Data
            from visitors who are under the age of 16. If an individual under 16
            submits Personal Data to Radish and we learn that the Personal Data
            is the information of an individual under 16, we will attempt to
            delete the information as soon as possible. If you believe that we
            might have any Personal Data from an individual under 16, please
            contact us at&nbsp;
            <strong>
              <a href="mailto:help@radish.health">help@radish.health</a>
            </strong>
            .<br />
            <br />
          </li>
          <li>
            <strong>Types of Data We Collect. “Personal Data”</strong> means
            data that allows someone to identify or contact you, including, for
            example, your name, address, telephone number, e-mail address, as
            well as any other non-public information about you that is
            associated with or linked to any of the foregoing data.&nbsp;{" "}
            <strong>“Anonymous Data”</strong> means data that is not associated
            with or linked to your Personal Data; Anonymous Data does not, by
            itself, permit the identification of individual persons.&nbsp; We
            collect Personal Data and Anonymous Data, as described below.
            <br />
            <br />
            <ol className="policy-alpha-list">
              <li>
                <strong>
                  Information You Provide to Us.
                  <br />
                </strong>
                <br />
                <ul className="policy-bulleted-list">
                  <li>
                    We may collect Personal Data from you, such as your first
                    and last name, phone number, e-mail and mailing addresses,
                    gender, and date of birth when you use our Services (“
                    <strong>Account</strong>”).
                  </li>
                  <li>
                    If you use our Services on your mobile device, we may
                    collect your phone number and the unique device ID number.
                  </li>
                  <li>
                    You may be asked to provide information necessary to
                    complete transactions via the Services, including your name,
                    credit card, and billing information. We do not store your
                    credit card information directly on our servers, but this
                    information may be collected and stored by our third party
                    payment processor(s), who will help process and fulfill your
                    purchases. When you submit credit card numbers, that
                    information is encrypted using industry standard technology.
                  </li>
                  <li>
                    If you provide us feedback or contact us via e-mail, we will
                    collect your name and e-mail address, as well as any other
                    content included in the e-mail, in order to send you a
                    reply.
                  </li>
                  <li>
                    We also collect other types of Personal Data that you
                    provide to us voluntarily as part of the Services, including
                    information about your medical condition, physician
                    referrals, prescriptions, and other related health
                    information we may collect or store on behalf of the Practice,
                    and other requested information if you contact us via e-mail
                    regarding support for the Services.
                  </li>
                  <li>
                    We may also collect Personal Data at other points in our
                    Services that state that Personal Data is being collected.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  Information Collected via Technology.
                  <br />
                  <br />
                </strong>
                <ul className="policy-bulleted-list">
                  <li>
                    <u>Information Collected by Our Servers</u>. To make our
                    Services more useful to you, our servers (which may be
                    hosted by a third party service provider) collect
                    information from you, including your browser type, operating
                    system, Internet Protocol (<strong>“IP”</strong>) address (a
                    number that is automatically assigned to your computer when
                    you use the Internet, which may vary from session to
                    session), domain name, and/or a date/time stamp for your
                    visit.&nbsp;
                  </li>
                  <li>
                    <u>Log Files.</u> As is true of most websites, we gather
                    certain information automatically and store it in log
                    files.&nbsp; This information includes IP addresses, browser
                    type, Internet service provider (<strong>“ISP”</strong>),
                    referring/exit pages, operating system, date/time stamp, and
                    clickstream data.&nbsp; We use this information to analyze
                    trends, administer the Services, track users’ movements
                    around the Services, gather demographic information about
                    our user base as a whole, and better tailor our Services to
                    our users’ needs.&nbsp; For example, some of the information
                    may be collected so that when you use the Services again, it
                    will recognize you and the information could then be used to
                    serve advertisements and other information appropriate to
                    your interests.&nbsp; Except as noted in this Privacy
                    Policy, we do not link this automatically-collected data to
                    Personal Data.
                  </li>
                  <li>
                    <u>Cookies</u>. Like many online services, we use cookies to
                    collect information.&nbsp; <strong>“Cookies”</strong> are
                    small pieces of information that a website sends to your
                    computer’s hard drive while you are viewing the
                    website.&nbsp; We may use both session Cookies (which expire
                    once you close your web browser) and persistent Cookies
                    (which stay on your computer until you delete them) to
                    provide you with a more personal and interactive experience
                    on our Services.&nbsp;&nbsp;&nbsp; This type of information
                    is collected to make the Services more useful to you and to
                    tailor the experience with us to meet your special interests
                    and needs.&nbsp;
                  </li>
                  <li>
                    <u>Pixel Tag</u> In addition, we use{" "}
                    <strong>“Pixel Tags</strong>” (also referred to as clear
                    Gifs, Web beacons, or Web bugs). Pixel Tags are tiny graphic
                    images with a unique identifier, similar in function to
                    Cookies, which are used to track online movements of Web
                    users.&nbsp; In contrast to Cookies, which are stored on a
                    user’s computer hard drive, Pixel Tags are embedded
                    invisibly in Web pages.&nbsp; Pixel Tags also allow us to
                    send e-mail messages in a format users can read, and they
                    tell us whether e-mails have been opened to ensure that we
                    are sending only messages that are of interest to our
                    users.&nbsp; We may use this information to reduce or
                    eliminate messages sent to a user.&nbsp; We do not tie the
                    information gathered by Pixel Tags to our users’ Personal
                    Data.
                  </li>
                  <li>
                    <strong>
                      <u>How We Respond to Do Not Track Signals</u>. We do not
                      currently respond to “do not track” signals or other
                      mechanisms that might enable users to opt out of tracking
                      on our Services.
                    </strong>
                  </li>
                  <li>
                    <u>Flash LSOs</u>. When we post videos, third parties may
                    use local shared objects, known as “
                    <strong>Flash Cookies</strong>,” to store your preferences
                    for volume control or to personalize certain video
                    features.&nbsp; Flash Cookies are different from browser
                    Cookies because of the amount and type of data and how the
                    data is stored.&nbsp; Cookie management tools provided by
                    your browser will not remove Flash Cookies.&nbsp; To learn
                    how to manage privacy and storage settings for Flash
                    Cookies,{" "}
                    <strong>
                      <a
                        href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        click here
                      </a>
                    </strong>
                    .
                  </li>
                  <li>
                    <u>Mobile Services</u>. We may also collect non-personal
                    information from your mobile device.&nbsp; Examples of
                    information that may be collected and used include your
                    geographic location, and information about the type of
                    device you use.&nbsp;&nbsp; This information is sent to us
                    as aggregated information and is not traceable to any
                    individual and cannot be used to identify an individual.
                  </li>
                  <li>
                    <u>Analytics Services.</u> In addition to the tracking
                    technologies we place, other companies may set their own
                    cookies or similar tools when you visit our Services.&nbsp;
                    This includes third party analytics services (
                    <strong>“Analytics Services”</strong>), that we engage to
                    help analyze how users use the Services.&nbsp; We may
                    receive reports based on these parties’ use of these tools
                    on an individual or aggregate basis.&nbsp; We use the
                    information we get from Analytics Services only to improve
                    our Services.&nbsp; The information generated by the Cookies
                    or other technologies about your use of our Services (the{" "}
                    <strong>“Analytics Information”</strong>) is transmitted to
                    the Analytics Services.&nbsp; The Analytics Services use
                    Analytics Information to compile reports on user
                    activity.&nbsp; The Analytics Services may also transfer
                    information to third parties where required to do so by law,
                    or where such third parties process Analytics Information on
                    their behalf. Each Analytics Services’ ability to use and
                    share Analytics Information is restricted by such Analytics
                    Services’ Terms of Use and Privacy Policy.&nbsp; By using
                    our Services, you consent to the processing of data about
                    you by Analytics Services in the manner and for the purposes
                    set out above.&nbsp; For a full list of Analytics Services
                    we use, please contact us at{" "}
                    <strong>
                      <a href="mailto:help@radish.health">help@radish.health</a>
                    </strong>
                    .<br />
                    <br />
                  </li>
                </ul>
              </li>
              <li>
                <strong>Information Collected from Third Parties.&nbsp;</strong>
                We may receive Personal and/or Anonymous Data about you from
                third parties, such as providers and pharmacies.&nbsp; We may
                add this information to the information we have already
                collected from you via our Services in order to improve the
                Services we provide.
                <br />
                <br />
              </li>
            </ol>
          </li>
          <li>
            <strong>
              Information Collected while Using the Radish Health Mobile
              Application
            </strong>
            <br />
            <br />
            <ol className="policy-alpha-list">
              <li>
                <strong>
                  While using Radish Health Mobile Application, in order to
                  provide features of Radish Health Mobile Application, We may
                  collect/access, with Your prior permission:
                  <br />
                </strong>
                <br />
                <ul className="policy-bulleted-list">
                  <li>
                    Location data for analytics and during the use of our
                    messaging feature while using the app
                  </li>
                  <li>
                    Wi-Fi connection status to ensure you have proper internet
                    connection for a fast and reliable experience
                  </li>
                  <li>
                    Camera, Audio, and Bluetooth to transmit audio/video to
                    enable our telehealth services
                  </li>
                  <li>Calendar to create upcoming appointment reminder</li>
                  <li>
                    Files and Images storage to upload/download health-related
                    records
                  </li>
                  <li>Audio and Video storage to upload/download health-related records and communicate with care team over chat</li>
                  <li>Apple Health/Google Fit to provide you with personalized and comprehensive care</li>
                </ul>
              </li>
            </ol>
            <br />
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or it
            may be simply stored on Your device.
            <br />
            <br />
            You can enable or disable access to this information at any time,
            through Your Device settings.
            <br />
            <br />
          </li>
          <li>
            <strong>Use of Your Personal Data</strong>
            <br />
            <br />
            <ol className="policy-alpha-list">
              <li>
                <strong>General Use.</strong> In general, Personal Data you
                submit to us is used either to respond to requests that you
                make, or to aid us in serving you better. We may use your
                Personal Data in the following ways, depending on the particular
                types of Services received:
                <br />
                <br />
                <ul className="policy-bulleted-list">
                  <li>
                    facilitate the creation of and secure your account in a
                    third party electronic medical record (“<strong>EMR</strong>
                    ”) system;
                  </li>
                  <li>identify you as a user in our system;</li>
                  <li>provide improved administration of our Services;</li>
                  <li>provide the Services you request;</li>
                  <li>
                    improve the quality of experience when you interact with our
                    Services;
                  </li>
                  <li>
                    send e-mails and text messages to you to provide
                    notifications and authorizations in connection with the
                    Services;
                  </li>
                  <li>
                    allow the Practice to communicate with you in connection
                    with the&nbsp;provision of medical services, such as
                    telephonic medical consultations with you for prescription
                    refills or make other telephone calls, send e-mails and text
                    messages to you in connection with the Services;
                  </li>
                  <li>
                    facilitate the transmission of consent forms with the Practice; and
                  </li>
                  <li>
                    facilitate the transmission of electronic prescriptions to
                    pharmacies.
                    <br />
                    <br />
                  </li>
                </ul>
              </li>
              <li>
                <strong>User Testimonials and Feedback</strong>. We often
                receive testimonials and comments from users who have had
                positive experiences with our Services. We occasionally publish
                such content. When we publish this content, we may identify our
                users by their first and last name and may also indicate their
                home city. We obtain the user’s consent prior to posting his or
                her name along with the testimonial. We may post user feedback
                on the Services from time to time. We will share your feedback
                with your first name and last initial only. If we choose to post
                your first and last name along with your feedback, we will
                obtain your consent prior to posting your name with your
                feedback. If you make any comments on a blog or forum associated
                with our Services, you should be aware that any Personal Data
                you submit there can be read, collected, or used by other users
                of these forums, and could be used to send you unsolicited
                messages. We are not responsible for the personally identifiable
                information you choose to submit in these blogs and forums.
                <br />
                <br />
              </li>
              <li>
                <strong>Creation of Anonymous Data.</strong> We may create
                Anonymous Data records from Personal Data by excluding
                information (such as your name) that makes the data personally
                identifiable to you. We use this Anonymous Data to analyze
                request and usage patterns so that we may enhance the content of
                our Services and improve navigation. We reserve the right to use
                Anonymous Data and aggregated and other de-identified
                information for any purpose and disclose Anonymous Data to third
                parties in our sole discretion.
                <br />
                <br />
              </li>
            </ol>
          </li>
          <li>
            <strong>Disclosure of Your Personal Data.</strong>&nbsp;We may
            disclose your Personal Data as described below and as described
            elsewhere in this Privacy Policy, depending on the particular types
            of Services received.
            <br />
            <br />
            <ol className="policy-alpha-list">
              <li>
                <strong>Third Parties Designated by You.</strong> When you use
                the Services, the Personal Data you provide will be shared with
                the third party that you designate to receive such information,
                including the Practice, pharmacies and other health care service
                providers. Even though Radish may not be a “covered entity” as
                defined in the Health Insurance Portability and Accountability
                Act of 1996, as amended, and its implementing regulations
                (“HIPAA”), any provider in the Practice engaging through our Services may be a
                “covered entity” and therefore maybe subject to the provisions
                of HIPAA. If you are using the Services, your acceptance of the
                Terms of Use and this Privacy Policy incorporates your
                acceptance and consent to the Practice's
                Notice of Privacy Practices. This notice describes how the
                Practice uses and discloses your protected health information as
                defined under HIPAA (“PHI”). Radish has agreed that its
                collection, use, and disclosure of your PHI on behalf of the
                Practice will be done consistent with the Practice's Notice of Privacy Practices except to the extent
                you have expressly authorized additional uses and disclosures.
                <br />
                <br />
              </li>
              <li>
                <strong>Third Party Service Providers.</strong> We may share
                your Personal Data with our third party service providers to:
                provide you with our Services, to process payments, to maintain
                your EMR file, maintain databases, to conduct quality assurance
                testing, to provide technical support, to provide messaging
                services, and/or to provide other services to Radish.
                <br />
                <br />
              </li>
              <li>
                <strong>Affiliates.</strong> We may share some or all of your
                Personal Data with our parent company, subsidiaries, joint
                ventures, or other companies under a common control
                (“Affiliates”), in which case we will require our Affiliates to
                honor this Privacy Policy.
                <br />
                <br />
              </li>
              <li>
                <strong>Corporate Restructuring.</strong> We may share some or
                all of your Personal Data in connection with or during
                negotiation of any merger, financing, acquisition or dissolution
                transaction or proceeding involving sale, transfer, divestiture,
                or disclosure of all or a portion of our business or assets. In
                the event of an insolvency, bankruptcy, or receivership,
                Personal Data may also be transferred as a business asset. If
                another company acquires our company, business, or assets, that
                company will possess the Personal Data collected by us and will
                assume the rights and obligations regarding your Personal Data
                as described in this Privacy Policy.
                <br />
                <br />
              </li>
              <li>
                <strong>Other Disclosures.</strong> Regardless of any choices
                you make regarding your Personal Data (as described below),
                Radish may disclose Personal Data if it believes in good faith
                that such disclosure is necessary (a) in connection with any
                legal investigation; (b) to comply with relevant laws or to
                respond to subpoenas or warrants served on Radish; (c) to
                protect or defend the rights or property of Radish or users of
                the Services; and/or (d) to investigate or assist in preventing
                any violation or potential violation of the law, this Privacy
                Policy, or our Terms of Use.
                <br />
                <br />
              </li>
            </ol>
          </li>
          <li><strong>Data Security.</strong> We take extensive measures to protect the security of the information you provide us, employing physical, electronic, and administrative procedures. When using specific features of our Services, we utilize industry-standard SSL encryption to reinforce the security of data transmissions. To ensure your privacy and security, your account information is password-protected. While we make every effort to safeguard your information, it is important to note that the security of the Internet cannot be guaranteed, and we cannot ensure the security of information transmitted through it.
          <br/><br/>
          We want to emphasize that safeguarding your Personal Information is a shared responsibility. We strongly advise you to take necessary precautions when accessing the Internet or communicating with us and others online. It is recommended to regularly change your passwords, use a combination of letters and numbers, and utilize a secure browser. If you suspect any compromise to the security of your account or if you believe someone else is using your account, please notify us immediately at <strong><a href="mailto:help@radish.health">help@radish.health</a></strong>. In case of any loss, theft, or unauthorized use of your information, username, or password, please update your account promptly or inform us, and we will assist you in updating your account details.
          <br/><br/></li>
          <li>
            <strong>T</strong>
            <strong>hird Party Websites.</strong> Our Services may contain links
            to third party websites.&nbsp; When you click on a link to any other
            website or location, you will leave our Services and go to another
            site, and another entity may collect Personal Data or Anonymous Data
            from you.&nbsp; We have no control over, do not review, and cannot
            be responsible for, these outside websites or their content.&nbsp;
            Please be aware that the terms of this Privacy Policy do not apply
            to these outside websites or content, or to any collection of your
            Personal Data after you click on links to such outside
            websites.&nbsp; We encourage you to read the privacy policies of
            every website you visit.&nbsp; The links to third party websites or
            locations are for your convenience and do not signify our
            endorsement of such third parties or their products, content or
            websites.
            <br />
            <br />
          </li>
          <li>
            <strong>Your Choices Regarding Information.</strong> You have
            several choices regarding the use of information on our Service:
            <br />
            <br />
            <ol className="policy-alpha-list">
              <li>
                <strong>Email Communications.</strong> We may periodically send
                you free newsletters and e-mails that directly promote the use
                of our Services.&nbsp; When you receive newsletters or
                promotional communications from us, you may indicate a
                preference to stop receiving further communications from us and
                you will have the opportunity to “opt-out” by following the
                unsubscribe instructions provided in the e-mail you receive or
                by contacting us directly (please see contact information
                below).&nbsp; Despite your indicated e-mail preferences, we may
                send you service related communications, including notices of
                any updates to our Terms of Use or Privacy Policy.
                <br />
                <br />
              </li>
              <li>
                <strong>Cookies.</strong> If you decide at any time that you no
                longer wish to accept Cookies from our Service for any of the
                purposes described above, then you can instruct your browser, by
                changing its settings, to stop accepting Cookies or to prompt
                you before accepting a Cookie from the websites you visit.
                Consult your browser’s technical information. If you do not
                accept Cookies, however, you may not be able to use all portions
                of the Service or all functionality of the Service. If you have
                any questions about how to disable or modify Cookies, please let
                us know at the contact information provided below.
                <br />
                <br />
              </li>
              <li>
                <strong>Deleting Your Personal Data. </strong>We will use
                commercially reasonable efforts to honor your request to delete
                your Personal Data that we maintain.&nbsp; However, we may
                retain an archived copy of your records, including information
                related to your EMR file (which we maintain on behalf of
                the Practice), as required by law or for
                legitimate business purposes.&nbsp;
                <br />
                <br />
              </li>
              <li>
                <p>
                  <strong>Declining to Provide Personal Data.</strong> You may
                  decline to provide certain Personal Data to Radish. Declining
                  to provide Personal Data may disqualify you for features of
                  the Services that require certain Personal Data.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>Changes to This Privacy Policy. </strong>This Privacy Policy
            is subject to occasional revision. When changes are made, we will
            make a new copy of the Privacy Policy available through the
            Services.&nbsp; We will also update the “Last Updated” date at the
            top of the Privacy Policy.&nbsp; If we make any material changes,
            and you have previously used the Services, we may also send an
            e-mail to you at the last e-mail address you provided to us pursuant
            to the Terms.&nbsp; Any changes to the Privacy Policy will be
            effective 30 days after posting notice of such changes on the
            Services for existing users. We may require you to provide consent
            to the updated Privacy Policy in a specified manner before further
            use of the Services is permitted.&nbsp; If you do not wish to permit
            changes in our use of your Personal Data, you must notify us prior
            to the effective date of the changes that you wish to terminate your
            relationship with us and stop using the Services. Otherwise, your
            continued use of the Services constitutes your acceptance of such
            change(s).
          </li>
        </ol>
        <p className="small">193715006 v7</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
