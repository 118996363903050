import Slider from "react-slick";

const RadSlider = ({
  stateToMap,
  CustomSlide,
  settings,
  customClick,
  onIconClick,
}) => {
  return (
    <Slider {...settings}>
      {stateToMap.map(
        (state, index) =>
          state.serviceKey !== "WORKPLACE" &&
          (!state.visibility ||
            state.visibility === "both" ||
            state.visibility === "web") && (
            <CustomSlide
              customClick={customClick}
              onIconClick={onIconClick}
              state={state}
              key={index}
            />
          )
      )}
    </Slider>
  );
};

export default RadSlider;
