import {
  getDoctorsList,
  getDoctorsSpeciality,
} from "../../requests/requestAPI";

const requestDoctorsList = () => ({
  type: "DOCTORS_LIST_REQUEST",
});
const receiveDoctorsList = (payload) => ({
  type: "DOCTORS_LIST_RECEIVE",
  payload,
});
const failureDoctorsList = (payload) => ({
  type: "DOCTORS_LIST_FAIL",
});

export const doctorsListAction = (token, state) => {
  return async (dispatch) => {
    dispatch(requestDoctorsList());
    try {
      let data = await getDoctorsList(token, state);
      if (data.ok) {
        let doctors = await data.json();
        dispatch(receiveDoctorsList(doctors.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureDoctorsList(JSON.parse(ex.message)));
    }
  };
};

const requestDoctorsSpeciality = () => ({
  type: "DOCTORS_SPECIALITY_REQUEST",
});
const receiveDoctorsSpeciality = (payload) => ({
  type: "DOCTORS_SPECIALITY_RECEIVE",
  payload,
});
const failureDoctorsSpeciality = (payload) => ({
  type: "DOCTORS_SPECIALITY_FAIL",
});

export const doctorsSpecialityAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestDoctorsSpeciality());
    try {
      let data = await getDoctorsSpeciality(token, payload);
      if (data.ok) {
        let doctors = await data.json();
        dispatch(receiveDoctorsSpeciality(doctors.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureDoctorsSpeciality(JSON.parse(ex.message)));
    }
  };
};
