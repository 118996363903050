import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Spinner from "../common/Spinner.js";
import {
  pastAppointmentAction,
  UpcomingAppointmentAction,
} from "../../redux/actions/dashboardSidebarAction";
import PastAppointmentNotesCard from "./PastAppointmentNotesCard";
import UpcomingAppointmentCard from "./UpcomingAppointmentCard";
import UpcomingOnsiteCard from "./UpcomingOnsiteCard";
import DownloadAppCard from "./DownloadAppCard";
import DashboardLoading from "./DashboardLoading.js";
import { logoutUser } from "../../redux/actions/logoutUser.js";
import { servicesAction } from "../../redux/actions/dashboardActions.js";
import { getMyToDoAction } from "../../redux/actions/myToDoActions.js";
import { getDailyWorkPassAction } from "../../redux/actions/myToDoActions.js";
import MyToDo from "./MyToDo";
import DailyWorkPass from "./DailyWorkPass";
import CanAccess from "../helpers/CanAccess.js";

const Sidebar = ({ labels = {}, cookies, setShowSidebar }) => {
  let dispatch = useDispatch();
  let location = useLocation();
  const {
    services,
    isServicesLoading,
    is404Error,
    pastAppointmentSidebar,
    upcomingAppointmentSidebar,
  } = useSelector((state) => state.dashboard);
  const { dailyWorkPassInfo, myToDoInfo } = useSelector(
    (state) => state.toDoInfo
  );
  const userInfo = useSelector((state) => state.user);
  const { token } = userInfo;
  const upcomingOnsite = services.filter(
    (item) => item.serviceKey === "WORKPLACE"
  );

  const sidebarRef = useRef(null);

  useEffect(() => {
    if (token) {
      dispatch(pastAppointmentAction(1, token));
      dispatch(UpcomingAppointmentAction(1, token));
      if (!isServicesLoading && !services.length) {
        dispatch(servicesAction(token));
      }
      //dispatch(getMyToDoAction(token));
      dispatch(getDailyWorkPassAction(token));
    }
  }, [isServicesLoading, token, dispatch]);

  useEffect(() => {
    if (is404Error && is404Error === 401) {
      cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [is404Error, dispatch, cookies]);

  useEffect(() => {
    if (sidebarRef.current.children.length === 0) setShowSidebar(false);
  });

  if (DashboardLoading())
    return (
      <div
        ref={sidebarRef}
        className={`col-3 app-sidebar ${
          location.pathname !== "/dashboard" && "hide-sidebar"
        }`}
      >
        <Spinner />
      </div>
    );

  return (
    <div
      ref={sidebarRef}
      className={`col-3 app-sidebar ${
        location.pathname !== "/dashboard" && "hide-sidebar"
      }`}
    >
      {services && upcomingOnsite.length > 0 && (
        <UpcomingOnsiteCard WorkplaceInfo={upcomingOnsite[0]} />
      )}

      <CanAccess moduleKey="DHA">
        {dailyWorkPassInfo && dailyWorkPassInfo.internalStatusCode !== "0" && (
          <DailyWorkPass dailyWorkPassInfo={dailyWorkPassInfo} />
        )}
      </CanAccess>

      <MyToDo toDoInfo={myToDoInfo} labels={labels} />

      {pastAppointmentSidebar && pastAppointmentSidebar.length > 0 && (
        <PastAppointmentNotesCard
          appointmentInfo={pastAppointmentSidebar[0]}
          labels={labels}
        />
      )}

      {upcomingAppointmentSidebar &&
        Object.keys(upcomingAppointmentSidebar).length > 0 && (
          <>
            <h2 className="app-heading-1 mb-10 upcoming-appt-header">
              Upcoming Appointment
            </h2>
            <UpcomingAppointmentCard
              appointmentInfo={upcomingAppointmentSidebar[0]}
              labels={labels}
            />
          </>
        )}

      <DownloadAppCard />
    </div>
  );
};

export default Sidebar;
