const AddAllergy = ({
  currentSearchTerm,
  setCurrentSearchTerm,
  searchResults,
  setSearchResults,
  selectedResult,
  setSelectedResult,
}) => {
  const handleSelect = ({ target }) => {
    setSelectedResult(target.dataset.index);
  };

  const handleSearch = ({ target }) => {
    setCurrentSearchTerm(target.value);
    if (!target.value) setSelectedResult(null);
  };

  return (
    <>
      <input
        type="text"
        className="form-control form-control-lg medical-history-search mb-20"
        placeholder="Search Drug Allergies"
        value={currentSearchTerm}
        onChange={handleSearch}
      />
      {currentSearchTerm && (
        <div className="history-results-container">
          <p>Search Results</p>
          <ul className="history-results-list">
            {searchResults.map((result, index) => {
              return (
                <li
                  key={index}
                  data-index={index}
                  className={index === parseInt(selectedResult) && "selected"}
                  onClick={handleSelect}
                >
                  {result}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default AddAllergy;
