import ChecklistRow from "./ChecklistRow";

const ChecklistCategory = ({ category, handleClick }) => {
  const title = Object.keys(category)[0];
  const recommendations = category[title];

  if (category[title].length)
    return (
      <div className="checklist-category">
        <h6>{title}</h6>
        <div className="checklist-row-container">
          {recommendations.map((recommendation, index) => (
            <ChecklistRow
              key={index}
              recommendation={recommendation}
              handleClick={handleClick}
            />
          ))}
        </div>
      </div>
    );

  return null;
};

export default ChecklistCategory;
