import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { doctorsListAction } from "../../redux/actions/doctorsActions.js";
import { modalOpen } from "../../redux/actions/modalAction";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions";
import Logo from "../../assets/img/svg/radish-icon.svg";

import Spinner from "../common/Spinner.js";

const DoctorRow = ({ doctor, index, onBookClick, isDisableAll }) => {
  const dispatch = useDispatch();
  const { userProfile: { state, companyState } = {} } = useSelector(
    (state) => state.userProfile
  );

  const onDoctorClick = (e) => {
    if (doctor.parentAppointmentKey) {
      dispatch({
        type: "SELECT_APP_TYPE",
        payload: doctor.parentAppointmentKey,
      });
    }
    onBookClick(e);
  };

  return (
    <div className="col-12 col-md-6 col-xl-4">
      <div className="my-care-team-card mb-25">
        {doctor.isEditable && (
          <span
            className="care-team-edit"
            onClick={() =>
              dispatch(
                sidePopupOpen({
                  component: 12,
                  content: {
                    specialty: doctor.specialty,
                    state: state ? state : companyState,
                  },
                })
              )
            }
          >
            Edit
          </span>
        )}
        <div className="avatar-info avatar-info-2">
          <div className="avatar-info-in flex-top">
            <figure>
              <img
                src={doctor.profilePicture || Logo}
                alt=""
                className="img-responsive"
              />
            </figure>
            <div className="ai-meta">
              <span className="ai-title">{doctor.fullName}</span>
              <span className="doctor-grade">{doctor.title}</span>
              <div className="doctor-short-info mb-20">
                {doctor.bio.slice(0, 90) + "..."}
                <span
                  onClick={() =>
                    dispatch(
                      modalOpen({
                        component: 4,
                        content: {
                          modalData: {
                            DrName: doctor.fullName,
                            title: doctor.title,
                            data: doctor.bio,
                            img: doctor.profilePicture,
                          },
                        },
                      })
                    )
                  }
                >
                  more
                </span>
              </div>
              <button onClick={onDoctorClick} className="btn btn-outline-green">
                Book Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CareTeam = ({ labels = {} }) => {
  const { token, features: accessLevel } = useSelector((state) => state.user);
  const { userProfile: { state, companyState } = {} } = useSelector(
    (state) => state.userProfile
  );

  const { doctors, isDoctorsListLoading, isDoctorsListRequested } = useSelector(
    (state) => state.doctors
  );

  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "MODULE-MEDICAL-SERVICE"
  );
  const isDisableAll =
    selectModule.action === "DISABLE" || selectModule.action === "HIDE";
  const moduleLevelMsg = selectModule.featureMessage;

  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SELECT_APP_TYPE", payload: "" });
    if (state || companyState)
      dispatch(doctorsListAction(token, state ? state : companyState));
  }, [dispatch, token, state, companyState]);

  const onBookClick = (e) => {
    if (isDisableAll)
      dispatch(
        modalOpen({
          component: 3,
          content: {
            moduleLevelMsg: moduleLevelMsg,
          },
        })
      );
    else {
      navigate("../bookappointment");
    }
  };

  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left care-team-title">
          <div>
            <button
              onClick={() => navigate(-1)}
              className="back-t-screen-cta unset-btn"
            >
              Back
            </button>
            <h2>
              <i className="icon icon-heart"></i>
              {labels.LBL_MYCARE_TEAM}
            </h2>
          </div>
          <h2 className="care-team-headline">{labels.LBL_MYCARE_TEAM_HELP}</h2>
        </div>
      </div>

      {(isDoctorsListLoading || !isDoctorsListRequested) && <Spinner />}
      <div className="row gt-4">
        {isDoctorsListRequested &&
        !isDoctorsListLoading &&
        (!doctors ||
          !doctors.length ||
          !doctors.filter(({ isCareTeam }) => isCareTeam).length) ? (
          <div className="div-no-doctor-avail">
            <p>{labels.LBL_NO_CARETEAM}</p>
            <a
              href="mailto:help@radish.health"
              className="btn btn-primary ucase"
            >
              {"EMAIL SUPPORT"}
            </a>
          </div>
        ) : (
          doctors.map(
            (doctor, index) =>
              doctor.isCareTeam && (
                <DoctorRow
                  doctor={doctor}
                  index={index}
                  key={index}
                  onBookClick={onBookClick}
                  isDisableAll={isDisableAll}
                />
              )
          )
        )}
      </div>

      {/* {!doctors ||
      !doctors.length ||
      !doctors.filter(({ isCareTeam }) => isCareTeam).length ? (
        ""
      ) : (
        <div className="row gt-4 mb-20">
          <div className="col-12 care-team-button-col">
            <button
              className="btn btn-primary ucase care-team-button"
              onClick={editCareTeamClick}
            >
              {labels.LBL_EDIT_MY_CARE_TEAM}
            </button>
          </div>
        </div>
      )} */}
    </>
  );
};

export default CareTeam;
