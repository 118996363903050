const modalState = {
  isModalOpen: false,
  component: 0,
  content: null,
};

export const modalReducer = (state = modalState, action) => {
  if (action.type === "MODAL_OPEN") {
    return {
      isModalOpen: true,
      component: action.payload.component,
      content: action.payload.content,
      modalClosed: "",
    };
  }

  if (action.type === "MODAL_CLOSE") {
    return {
      isModalOpen: false,
      modalClosed: action.payload,
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      isModalOpen: false,
      modalClosed: "",
    };
  }

  return state;
};
