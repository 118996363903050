const TalkToPsychologist = ({ labels = {}, handleClick }) => {
  return (
    <div className="cfw-card-middle greenbg dha-megan">
      <div className="card-middle-inner-right">
        <h3>{labels.LBL_DHA_PSYCH_TEXT}</h3>
        <button className="btn btn-primary" onClick={handleClick}>
          {labels.LBL_DHA_BOOK_PSYCH}
        </button>
      </div>
    </div>
  );
};

export default TalkToPsychologist;
