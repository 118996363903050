import React from "react";
import { connect } from "react-redux";

class CanAccess extends React.Component {
  render() {
    const { children, moduleKey, accessLevel } = this.props;
    if (moduleKey) {
      const selectModule = accessLevel.find(
        (next) => next.type === "MODULE" && next.key === moduleKey
      );
      return (
        <React.Fragment>
          {selectModule &&
          (selectModule.action === "SHOW" ||
            selectModule.action === "ENABLE" ||
            selectModule.action === "DISABLE") ? (
            children
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    } else return children;
  }
}

const mapStateToProps = (state) => {
  return {
    accessLevel: state.user.features,
  };
};

export default connect(mapStateToProps)(CanAccess);
