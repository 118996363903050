import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import EmilyHeadshot from "../../../assets/img/png/emily-headshot.png";
import AppointmentTypes from "../AppointmentTypes.js";
import CanAccess from "../../helpers/CanAccess";
import { useEffect } from "react";
import { getBookAppointmentTypeData } from "../../../redux/actions/bookAppointmentActions";

const BookWorkplace = ({ labels = {}, cookies }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardInfo = useSelector((state) => state.dashboard);
  const { token } = useSelector((state) => state.user);
  const upcomingOnsite = dashboardInfo.services.filter(
    (item) => item.serviceKey === "WORKPLACE"
  );

  let dt = "";
  if (dashboardInfo.services && upcomingOnsite.length > 0) {
    let a = moment(
      `${upcomingOnsite[0].workplaceDate} ${new Date().getFullYear()}`
    );
    dt = moment(a).format("YYYY-MM-DD");
  }

  useEffect(() => {
    dispatch(getBookAppointmentTypeData(token, "onsite"));
  }, [token, dispatch]);

  return (
    <>
      <div className="app-screen-title">
        <div className="app-screen-title-left">
          <button
            className="back-t-screen-cta unset-btn"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <h2>{labels.LBL_WORKPLACE_HEADER}</h2>
        </div>
      </div>
      <div className="radish-hospitality-card book-workplace-card">
        <span className="rhc-title">
          {dashboardInfo.services &&
            upcomingOnsite.length > 0 &&
            upcomingOnsite[0].workplaceString}
        </span>
        <span className="rhc-date">
          {dashboardInfo.services &&
            upcomingOnsite.length > 0 &&
            upcomingOnsite[0].workplaceDate}
        </span>
        <span className="rhc-time">
          {dashboardInfo.services &&
            upcomingOnsite.length > 0 &&
            upcomingOnsite[0].workplaceTime}
        </span>
        <figure>
          <img
            src={EmilyHeadshot}
            alt=""
            className="img-responsive book-workplace-img"
          />
        </figure>
      </div>
      <CanAccess moduleKey="MODULE-MEDICAL-SERVICE">
        <AppointmentTypes labels={labels} cookies={cookies} date={dt} />
      </CanAccess>
    </>
  );
};

export default BookWorkplace;
