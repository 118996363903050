import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  medicalHistorySearchAction,
  userHistoryAction,
} from "../../../../redux/actions/medicalHistoryActions";
import { sidePopupClose } from "../../../../redux/actions/sidePopupActions";
import {
  postUserMedicalConditions,
  postUserMedications,
} from "../../../../requests/requestAPI";

import AddHealthCondition from "./AddHealthCondition";
import AddMedication from "./AddMedication";
import AddAllergy from "./AddAllergy";

const AddMedicalHistory = () => {
  const dispatch = useDispatch();
  const [currentComponent, setCurrentComponent] = useState(0);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isLongEnough, setIsLongEnough] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [selectedDosage, setSelectedDosage] = useState(-1);

  const { searchTerms } = useSelector((state) => state.medicalHistory);
  const { token } = useSelector((state) => state.user);

  const changeComponent = ({ target }) => {
    if (stepNumber === 2) {
      setStepNumber(1);
      setSelectedDosage(-1);
    } else {
      dispatch({ type: "MEDICAL_CONDITION_REQUEST" });
      setSearchResults([]);
      setSelectedResult(null);
      setCurrentComponent(parseInt(target.dataset.index));
      if (target.dataset.index === "0") setCurrentSearchTerm("");
    }
  };

  const onInputChange = (input, type) => {
    if (input.length >= 3) {
      dispatch(medicalHistorySearchAction(input, type));
      setIsLongEnough(true);
    } else {
      setSearchResults([]);
      setIsLongEnough(false);
    }
  };

  const checkDisabled = () => {
    if (currentComponent === 1) {
      if (!selectedResult) return true;
    } else if (currentComponent === 2) {
      if (stepNumber === 1) {
        if (!selectedResult) return true;
      }
      if (stepNumber === 2) {
        if (selectedDosage === -1) return true;
      }
    }
  };

  useEffect(() => {
    if (searchTerms) {
      let entity = "consumer_name";
      switch (currentComponent) {
        case 1:
          entity = "consumer_name";
          break;
        case 2:
          entity = "DISPLAY_NAME";
          break;
        default:
          entity = "consumer_name";
      }
      const tempData = searchTerms.map((next) => next[entity]);
      setSearchResults(tempData);
    }
  }, [searchTerms, currentComponent]);

  useEffect(() => {
    setStepNumber(1);
  }, []);

  const headerLabels = {
    1: "Health Condition",
    2: "Medication",
    3: "Drug Allergy",
  };

  const onSaveHealth = async () => {
    let payload = searchTerms[selectedResult];

    if (currentComponent === 1) {
      await postUserMedicalConditions(token, payload);
      saveAndClose();
    }
    if (currentComponent === 2) {
      if (stepNumber === 1) setStepNumber(2);
      else {
        payload.SELECTED_STRENGTHS_AND_FORMS =
          searchTerms[selectedResult].STRENGTHS_AND_FORMS[selectedDosage] || "";
        await postUserMedications(token, payload);
        saveAndClose();
      }
    }
  };

  const saveAndClose = () => {
    dispatch(userHistoryAction(token));
    dispatch(sidePopupClose());
  };

  return (
    <div className="add-medical-history-items">
      {!currentComponent ? (
        <>
          <div className="medical-history-back">
            <h3 className="app-heading-1 mb-40">Add Medical History</h3>
          </div>
          <ul className="add-history-type-select">
            <li data-index="1" onClick={changeComponent}>
              Health Conditions
            </li>
            <li data-index="2" onClick={changeComponent}>
              Medications
            </li>
            {/* <li data-index="3" onClick={changeComponent}>
              Drug Allergies
            </li> */}
          </ul>
        </>
      ) : (
        <>
          <div className="medical-history-back">
            <button
              onClick={changeComponent}
              className="back-t-screen-cta unset-btn"
              data-index="0"
            >
              Back
            </button>
            <h3 className="app-heading-1 mb-40">
              Add {headerLabels[currentComponent]}
            </h3>
          </div>
          <div className="add-medical-history-item">
            {currentComponent === 1 && (
              <AddHealthCondition
                currentSearchTerm={currentSearchTerm}
                setCurrentSearchTerm={setCurrentSearchTerm}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                selectedResult={selectedResult}
                setSelectedResult={setSelectedResult}
                onInputChange={onInputChange}
                isLongEnough={isLongEnough}
              />
            )}
            {currentComponent === 2 && (
              <AddMedication
                currentSearchTerm={currentSearchTerm}
                setCurrentSearchTerm={setCurrentSearchTerm}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                selectedResult={selectedResult}
                setSelectedResult={setSelectedResult}
                onInputChange={onInputChange}
                isLongEnough={isLongEnough}
                stepNumber={stepNumber}
                selectedDosage={selectedDosage}
                setSelectedDosage={setSelectedDosage}
              />
            )}
            {currentComponent === 3 && (
              <AddAllergy
                currentSearchTerm={currentSearchTerm}
                setCurrentSearchTerm={setCurrentSearchTerm}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
                selectedResult={selectedResult}
                setSelectedResult={setSelectedResult}
                onInputChange={onInputChange}
                isLongEnough={isLongEnough}
              />
            )}
            <button
              disabled={checkDisabled()}
              className="btn btn-primary ucase"
              onClick={onSaveHealth}
            >
              {currentComponent === 1 && "Save Health Condition"}
              {currentComponent === 2 && "Save Medication"}
              {currentComponent === 3 && "Save Allergy"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddMedicalHistory;
