import { Link } from "react-router-dom";

const PrivacyPractices = () => {
  return (
    <div className="col-12">
      <div className="policy-heading">
        <p>
          <strong>
            <span>EXHIBIT A</span>
          </strong>
        </p>
        <h4>
          <strong>NOTICE OF PRIVACY PRACTICES</strong>
        </h4>
        <p>
          <strong>
            THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
            AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
            REVIEW IT CAREFULLY.
          </strong>
        </p>
      </div>
      <div className="policy-wrap">
        <p>
          Each member of the Alphabet City group of medical providers (including without 
          limitation Radish Health Medical Group, PLLC; Alphabet City Medicine, P.C. (NY); 
          Alphabet City Medicine, P.C. (NJ); Alphabet City Medicine of California P.C. (CA); 
          and collectively, “Company,” “us,” “our,” and “we”)
          is committed to obtaining, maintaining, using
          and disclosing your protected health information (“PHI”) in a manner
          that protects your privacy in providing onsite medical care and/or
          telephonic medical consultations for refills of common prescriptions.
          We urge you to read this Notice of Privacy Practices (this “Notice”),
          carefully in order to understand both our commitment to the privacy of
          your PHI and your rights.
        </p>
        <p>
          All references to “you” or “your” refer to both you and your child, if
          you are a parent or legal guardian of an individual under 18 who is
          receiving Company Services.
        </p>
        <p>
          We are required by law to maintain the privacy of your PHI and to
          provide you with a notice of our legal duties and privacy practices
          with respect to PHI. PHI is information about you, including basic
          demographic information, that may identify you and that relates to
          your past, present or future physical or mental health condition,
          treatment, or payment for health services. This Notice describes how
          we may use and disclose your PHI to carry out treatment, payment or
          health care operations, and for other specified purposes that are
          permitted or required by law. The Notice also describes your rights
          with respect to your PHI.
        </p>
        <p>
          We reserve the right to amend this Notice from time to time. When
          material changes are made, we will promptly post the updated Notice on
          our website at{" "}
          <strong>
            <Link to="../npp">https://patient.radish.health/policies/npp</Link>
          </strong>
          . We are required to abide by the terms of the Notice currently in
          effect.
        </p>
        <p>
          <strong>I. Uses and Disclosures of Your Information</strong>
        </p>
        <p>
          The Company may use or disclose your PHI for the following purposes:
        </p>
        <ul className="policy-bulleted-list">
          <li>
            <span>Treatment</span>. We may use or disclose PHI for purposes of
            providing your medical treatment. For example, we may disclose your
            PHI to pharmacies when ordering prescription refills on your behalf
            or to your primary health care practitioner.
          </li>
          <li>
            <span>Payment</span>. We may use or disclose PHI for purposes of
            billing and collecting payment for our services.
          </li>
          <li>
            <span>Health Care Operations</span>. We may use or disclose PHI to
            facilitate our business’s health care operations. For example, we
            may review your PHI internally as part of an audit to confirm
            quality of our services being delivered to our patients.&nbsp; We
            may use PHI to notify&nbsp;you or remind you of scheduled
            appointments or changes in our medical services.
          </li>
          <li>
            <span>As Required by Law</span>. We may use or disclose PHI if
            required to do so by federal or state law.
          </li>
          <li>
            <span>
              Disclosures to Your Representative and/or Individuals Involved in
              Your Care
            </span>
            . We may disclose your PHI to your friends or family members who are
            involved in your care, including those who are responsible for
            paying for your care. We may also disclose PHI to your personal
            representative, as established under applicable law, or to an
            administrator or authorized individual associated with your estate.
          </li>
          <li>
            <span>Disclosures to Business Associates</span>. We may disclose
            your PHI to certain of our service providers, such as Radish Health
            Inc., that may have access to certain of your PHI – and they will
            also agree to protect its confidentiality.
          </li>
          <li>
            <span>De-Identification of PHI</span>. The Company may de-identify
            your PHI, meaning that we would remove all identifying features as
            determined by law to make it extremely unlikely that the information
            could identify you. De-identified information no longer qualifies as
            PHI, meaning that we may use and disclose it for purposes not set
            forth in this Notice.
          </li>
        </ul>
        <p>
          The Company may also use or disclose your PHI in other ways as
          permitted by law. Generally, these are ways that serve the public
          health and/or research. Specifically:
        </p>
        <ul className="policy-bulleted-list">
          <li>
            We may use or disclose your PHI as needed to assist with public
            health and safety issues and may disclose your PHI to law
            enforcement officials when needed, to health oversight agencies for
            authorized activities, and for special government functions
            including national security needs.
          </li>
          <li>
            We may disclose your PHI as needed to organ procurement
            organizations, medical examiners, and funeral directors in the event
            of an individual’s death.
          </li>
          <li>
            We may use or disclose your PHI to address workers’ compensation
            claims.
          </li>
          <li>
            We may use or disclose your PHI in response to a court or
            administrative order, or in response to a subpoena.
          </li>
        </ul>
        <p>
          Uses and disclosures of PHI for purposes other than those described
          above, including for marketing purposes and disclosures that would
          constitute a sale of PHI, will not be made in the absence of a written
          authorization signed by you or your personal representative. Once you
          sign an authorization, you may revoke it by contacting us at any time
          unless it has already been relied upon to use or disclose PHI.
        </p>
        <p>
          <strong>II. Your Rights Regarding Your PHI</strong>
        </p>
        <p>You have the following rights with respect to your PHI:</p>
        <ul className="policy-bulleted-list">
          <li>
            You have the right to request restrictions on certain uses and
            disclosures of your PHI. We will consider every request to restrict
            uses or disclosures of your PHI and will strive to honor those that
            are reasonable. With respect to any requested restriction, if the
            Company agrees to honor it, we will document such restriction and
            continue to abide by it.
          </li>
          <li>
            You have the right to receive confidential communications of your
            PHI from the Company. Specifically, you may request that we
            communicate with you about your PHI using a specific means, phone
            number, or address. The Company will accommodate reasonable requests
            regarding confidential communications of your PHI.
          </li>
          <li>
            Subject to applicable state law, you have the right to inspect and
            copy your PHI. You also have the right to access and receive your
            PHI electronically if readily producible in such format.
          </li>
          <li>
            You have the right to correct or update your PHI. If you believe
            that there is an error in your PHI, you may request that we update
            it as appropriate.
          </li>
          <li>
            You have the right to receive an accounting of certain disclosures
            of your PHI made by the Company. Upon receipt of such request, we
            will provide you with a list of disclosures made by the Company in
            the prior six (6) years, not including certain types of disclosures
            such as, by way of example only, those made directly to you or
            pursuant to your written authorization.
          </li>
          <li>
            You have the right to obtain a paper copy of this Notice upon
            request.
          </li>
        </ul>
        <p>
          To exercise any of these rights, please send written communication to
          us at{" "}
          <strong>
          Radish Health, 900 Broadway, Suite 903, New York, NY
            10003
          </strong>
          .
        </p>
        <p>
          <strong>III. Breach Notification</strong>
        </p>
        <p>
          The Company is required by law to notify you in the event that your
          PHI is subject to a security breach unless we reasonably determine,
          after fully investigating the situation and assessing the risk
          presented, that there is a low probability that the privacy or
          security of your PHI has been compromised. You will be notified
          without unreasonable delay and in no event later than sixty (60) days
          following our discovery of the security breach. Such notification will
          include information about the security breach, including steps that
          the Company has taken to mitigate potential harm, and a contact person
          to whom you may address additional questions.
        </p>
        <p>
          <strong>IV. Questions, Comments, or Complaints</strong>
        </p>
        <p>
          If you have any questions or comments about this Notice, or if you
          have any complaints about the Company’s privacy practices, please
          contact us at{" "}
          <strong>
            Radish Health, 900 Broadway, Suite 903, New York, NY
            10003
          </strong>
          .&nbsp; You may also file a complaint with the Secretary of the U.S.
          Department of Health and Human Services.&nbsp; The Company will not
          retaliate against you for filing a complaint.
        </p>
        <p className="small">Effective as of 12/20/2018 - updated 03/01/2023</p>
      </div>
    </div>
  );
};

export default PrivacyPractices;
