import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import ReactDatePicker from "react-datepicker";
import parse from "html-react-parser";

import { getAppointnmentSlotAction } from "../../../redux/actions/appointmentsActions";
import { getBookAppointmentTypeData } from "../../../redux/actions/bookAppointmentActions";
import { sidePopupOpen } from "../../../redux/actions/sidePopupActions";
import { UpcomingAppointmentAction } from "../../../redux/actions/dashboardSidebarAction";
import { logoutUser } from "../../../redux/actions/logoutUser";
import { postEventTrack } from "../../../requests/requestAPI";

import Spinner from "../../common/Spinner";
import {
  modalClose,
  modalOpen as modalOpenAction,
} from "../../../redux/actions/modalAction";
import BookAppPopup from "../../common/sidepopup/BookAppPopup";

import InfoIcon from "../../../assets/img/svg/info.svg";

const settings = {
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 4,
  touchThreshold: 10,
  speed: 300,
  arrows: true,
  dots: false,
  touchMove: false,
  className: "my-slot-slider",
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 7,
      },
    },
    {
      breakpoint: 1170,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1060,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 7,
        touchMove: true,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 6,
        touchMove: true,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 7,
        touchMove: true,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 5,
        touchMove: true,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 4,
        touchMove: true,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 3,
        touchMove: true,
        slidesToScroll: 2,
      },
    },
  ],
};

const BookAppointments = ({ labels = {}, cookies }) => {
  const location = useLocation();
  const { token, features: accessLevel } = useSelector((state) => state.user);
  const { userProfile: { state, companyState } = {} } = useSelector(
    (state) => state.userProfile
  );

  const { appointmentData, selectedAppType } = useSelector(
    (state) => state.dashboard
  );
  const navigate = useNavigate();
  // const parentKey = new URLSearchParams(useLocation().search).get('parentKey');
  // const [selectedType, SetSelectType] = useState(parentKey);
  const {
    stampWorkplaceDate,
    isAppSlotLoading,
    isAppBookError,
    bookedAppData = {},
    appointmentSlots = [],
    is404Error = false,
    isSidePopupClosed = false,
    isAppSlotsRequested,
  } = useSelector((state) => state.appointments);
  const dispatch = useDispatch();

  let newD = "";
  if (stampWorkplaceDate) {
    newD = new Date();
    newD.setMonth(stampWorkplaceDate.split("-")[1] - 1);
    newD.setDate(stampWorkplaceDate.split("-")[2]);
  }

  const [startDate, setStartDate] = useState(
    stampWorkplaceDate ? new Date(stampWorkplaceDate) : new Date()
  );
  const [startInPersonDate, setInPersonStartDate] = useState(new Date());
  // const [modalOpen, setModalOpen] = useState(false);
  const [isModuleAccessible, setModuleIsAccessible] = useState(true);

  // CAN ACCESS
  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "MODULE-MEDICAL-SERVICE"
  );
  const isDisableAll =
    selectModule.action === "DISABLE" || selectModule.action === "HIDE";
  const moduleLevelMsg = selectModule.featureMessage;

  useEffect(() => {
    if (isDisableAll) {
      dispatch(
        modalOpenAction({
          component: 3,
          content: {
            moduleLevelMsg: moduleLevelMsg,
          },
        })
      );

      setModuleIsAccessible(false);
    }
  }, [isDisableAll, moduleLevelMsg]);

  useEffect(() => {
    if (
      (isSidePopupClosed && bookedAppData.appointmentID) ||
      (isSidePopupClosed && isAppBookError)
    ) {
      navigate("/dashboard/appointments", { replace: true });
      dispatch({ type: "CLEAR_BOOKED_APP" });
      dispatch(UpcomingAppointmentAction(1, token));
    }
  }, [
    isSidePopupClosed,
    navigate,
    dispatch,
    bookedAppData.appointmentID,
    token,
  ]);

  useEffect(() => {
    if (is404Error === 401) {
      cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [is404Error, dispatch, cookies]);

  // const ExampleCustom = forwardRef(({ value, onClick }, ref) => (
  //   <div className="art-date-icon" onClick={onClick} ref={ref}></div>
  // ));

  const ExampleCustom1 = forwardRef(
    ({ value, onClick, idToda, startDate }, ref) => (
      <div onClick={onClick} ref={ref} className="af-right">
        <div className={` datepicker-box  ${!idToda ? "fhdmmd" : ""}`}>
          <div className="af-date-icon"></div>
          <div className="more-date">
            {!idToda ? getMonthDate(startDate) : "More dates"}
          </div>
        </div>
      </div>
    )
  );

  // const onModalClose = () => {
  //   setModalOpen(!modalOpen);
  //   if (!isModuleAccessible) {
  //     navigate("/dashboard");
  //   }
  // };

  const onModalOpen = (slot) => {
    const data = {
      DrName: slot.doctorName,
      title: slot.doctorTitle,
      data: slot.doctorBio,
      img: slot.doctorProfilePicture,
    };
    dispatch(
      modalOpenAction({
        component: 4,
        content: { modalData: data },
      })
    );
  };

  useEffect(() => {
    dispatch(getBookAppointmentTypeData(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (
      selectedAppType &&
      appointmentData.length > 0 &&
      (state || companyState)
    ) {
      const tempAppType = appointmentData.find(
        ({ parentKey }) => parentKey === selectedAppType
      )
        ? selectedAppType
        : appointmentData[0].parentKey;
      dispatch(
        getAppointnmentSlotAction(
          token,
          tempAppType,
          moment(startDate).format("YYYY-MM-DD"),
          moment(startDate).format("YYYY-MM-DD"),
          state ? state : companyState
        )
      );
    }
    dispatch({ type: "STAMP_WORKPLACE_DATE", payload: "" });
    if (location.state && location.state.parentKey) {
      dispatch({ type: "SELECT_APP_TYPE", payload: location.state.parentKey });
      //delete location.state.parentKey;
    }
  }, [token, dispatch, selectedAppType, appointmentData, state, companyState]);

  useEffect(() => {
    if (!selectedAppType && appointmentData && appointmentData.length > 0) {
      dispatch({
        type: "SELECT_APP_TYPE",
        payload: appointmentData[0].parentKey,
      });
    }
  }, [token, dispatch, appointmentData, selectedAppType]);

  useEffect(() => {
    if (
      Boolean(selectedAppType) &&
      appointmentData &&
      appointmentData.length > 0
    ) {
      let IsShowAttention =
        appointmentData.filter((app) => app.parentKey === selectedAppType)
          .length > 0
          ? appointmentData.filter(
            (app) => app.parentKey === selectedAppType
          )[0].isShowAttention
          : false;
      //setTimeout(() => {
      if (IsShowAttention) {
        dispatch(
          modalOpenAction({
            component: 5,
            content: {
              selectedAppType: selectedAppType,
              appointmentData: appointmentData,
            },
          })
        );
      }
      // setcareModalPopup(IsShowAttention);
      //}, 1000);
    }
  }, [appointmentData, selectedAppType, dispatch]);

  const onTypeChange = (e) => {
    // SetSelectType(e.target.value);

    if (location.state && location.state.parentKey) {
      delete location.state.parentKey;
    }
    dispatch(modalClose());

    // setStartDate(new Date());
    dispatch({ type: "SELECT_APP_TYPE", payload: e.target.value });
  };

  const payload = {
    component: 4,
    content: BookAppPopup,
  };

  const onBookClick = (slot, subType, time, localTime, timeZone) => {
    const NewSlot = { ...slot, selectedAppType };
    dispatch({
      type: "SELECT_SLOT_TO_BOOK",
      payload: { slot: NewSlot, subType, time, localTime, timeZone },
    });
    dispatch(sidePopupOpen(payload));

    var eventJSON = { 'ParentKey': selectedAppType, 'ApptCategory': subType.appointmentTypeCategoty, 'Date': subType.availableDates, 'Slot': time + " EST", 'DoctorId': slot.doctorID }

    postEventTrack(token, {
      'eventName': 'APPTSLOTSELECT',
      'eventDetails': JSON.stringify(eventJSON),
      'source': 'WEB'
    })
  };
  const onDateChange = (date, pass) => {
    setInPersonStartDate(date);
    setStartDate(date);
    dispatch(
      getAppointnmentSlotAction(
        token,
        selectedAppType,
        moment(date).format("YYYY-MM-DD"),
        moment(date).format("YYYY-MM-DD"),
        state ? state : companyState
      )
    );
    // TODO back
    ////Umcomment this for calender old flow
    // if (pass.isOnSite) {
    //     setInPersonStartDate(date);
    //     dispatch(getAppointnmentSlotAction(token, selectedAppType, moment(startDate).format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD")));
    // } else {
    //     setStartDate(date);
    //     dispatch(getAppointnmentSlotAction(token, selectedAppType, moment(date).format("YYYY-MM-DD"), moment(startInPersonDate).format("YYYY-MM-DD")));
    // }
  };

  var is_weekend = function (date1, pass) {
    let returnFlag = true;
    if (pass.isOnSite) {
      const { availableDates } = pass;
      const availableDatesArr = availableDates.split(",") || [];
      const dateFormat = moment(date1).format("YYYY-MM-DD");
      returnFlag = availableDatesArr.indexOf(dateFormat) > -1;
    }
    var dt = new Date(date1);
    if (dt.getDay() === 0) {
      // returnFlag = false;
    }
    return returnFlag;
  };

  const isToday = (date) => {
    let d1 = moment().format("YYYY-MM-DD");
    const date1 = new Date(d1);
    const date2 = new Date(date);
    if (
      date1.getDate() === date2.getDate() &&
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    ) {
      return `Today ${moment(date, "YYYY-MM-DD").format("MMM Do")}`;
    }
    return ` ${moment(date).format("dddd")} ${moment(date, "YYYY-MM-DD").format(
      "MMM Do"
    )}`;
  };

  function getMonthDate(dt) {
    return moment(dt, "YYYY-MM-DD").format("MMMM Do");
  }

  const getBio = (slot) => {
    const { doctorBio } = slot;
    if (!doctorBio) return "";
    if (window.innerWidth > 1320) {
      if (doctorBio.length < 112) return doctorBio;
      return (
        <>
          {parse(doctorBio.substring(0, 112))} ...
          <button
            onClick={() => onModalOpen(slot)}
            className="doctor-more-info"
          >
            more
          </button>
        </>
      );
    }
    if (window.innerWidth < 1320 && window.innerWidth > 990) {
      if (doctorBio.length < 102) return doctorBio;
      return (
        <>
          {parse(doctorBio.substring(0, 102))} ...
          <button
            onClick={() => onModalOpen(slot)}
            className="doctor-more-info"
          >
            more
          </button>
        </>
      );
    }
    if (window.innerWidth < 990) {
      if (doctorBio.length < 155) return doctorBio;
      return (
        <>
          {parse(doctorBio.substring(0, 155))} ...
          <button
            onClick={() => onModalOpen(slot)}
            className="doctor-more-info"
          >
            more
          </button>
        </>
      );
    }
    return doctorBio;
  };

  const getSetting = ({ availableLocalTime }) => {
    let newSetting = JSON.parse(JSON.stringify(settings));
    if (availableLocalTime.length < settings.slidesToShow) {
      newSetting.slidesToShow = availableLocalTime.length;
    }
    const resp = newSetting.responsive.map((pass) => {
      return availableLocalTime.length < pass.settings.slidesToShow
        ? { ...pass, settings: { slidesToShow: availableLocalTime.length } }
        : pass;
    });
    newSetting.responsive = resp;
    return newSetting;
  };

  const getTopSection = () => {
    let isToday = false;
    let isTomm = false;

    const d1 = moment().format("YYYY-MM-DD");
    const date1 = new Date(d1);
    const momentDate = moment(startDate).format("YYYY-MM-DD");
    const date2 = new Date(momentDate);
    if (
      date1.getDate() === date2.getDate() &&
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth()
    ) {
      isToday = true;
    } else {
      date1.setDate(date1.getDate() + 1);
      if (
        date1.getDate() === date2.getDate() &&
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth()
      ) {
        isTomm = true;
      }
    }

    const onTomorrClick = () => {
      const date1 = new Date();
      date1.setDate(date1.getDate() + 1);
      setStartDate(date1);
      // TODO BACK
      // dispatch(getAppointnmentSlotAction(token, selectedAppType, moment(date1).format("YYYY-MM-DD"), moment(startInPersonDate).format("YYYY-MM-DD")));
      dispatch(
        getAppointnmentSlotAction(
          token,
          selectedAppType,
          moment(date1).format("YYYY-MM-DD"),
          moment(date1).format("YYYY-MM-DD"),
          state ? state : companyState
        )
      );
    };

    const removeDateClick = () => {
      const d1 = moment().format("YYYY-MM-DD");
      const date1 = new Date(d1);
      const momentDate = moment(startDate).format("YYYY-MM-DD");
      const date2 = new Date(momentDate);
      if (
        date1.getDate() === date2.getDate() &&
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth()
      ) {
        return;
      } else {
        setStartDate(new Date());
        // TODO BACK
        // dispatch(getAppointnmentSlotAction(token, selectedAppType, moment(new Date()).format("YYYY-MM-DD"), moment(startInPersonDate).format("YYYY-MM-DD")));
        dispatch(
          getAppointnmentSlotAction(
            token,
            selectedAppType,
            moment(new Date()).format("YYYY-MM-DD"),
            moment(new Date()).format("YYYY-MM-DD"),
            state ? state : companyState
          )
        );
      }
    };
    return (
      <div className="appointments-filters">
        <div className="af-inner">
          <div className="af-left">
            <button
              onClick={removeDateClick}
              className={`filter-btn ${isToday ? "btncolor" : ""}`}
            >
              Today
            </button>
            <button
              onClick={!isTomm ? onTomorrClick : () => { }}
              className={`filter-btn ${isTomm ? "btncolor" : ""}`}
            >
              Tomorrow
            </button>
            {/* {(!isToday && !isTomm) ? <button className="filter-btn active"> {getMonthDate(startDate)} <span onClick={removeDateClick} className="filter-remove" title="Remove"></span></button> : ''} */}
          </div>
          {/* <div className={`art-date datepicker-box  ${!isToday ? 'fhdmmd' : ''}`}> */}
          <div className="art-date">
            <ReactDatePicker
              selected={startDate}
              maxDate={moment().add(30, "days").toDate()}
              onChange={(date) => onDateChange(date, {})}
              filterDate={(date) => {
                return is_weekend(date, {});
              }}
              customInput={
                <ExampleCustom1 startDate={startDate} idToda={isToday} />
              }
              minDate={moment().toDate()}
            />
          </div>

          {/* </div> */}
        </div>
      </div>
    );
  };

  const onNextAvailable = (availableDates) => {
    const momentDate = moment(availableDates);
    setStartDate(new Date(momentDate));
    dispatch(
      getAppointnmentSlotAction(
        token,
        selectedAppType,
        moment(availableDates).format("YYYY-MM-DD"),
        moment(availableDates).format("YYYY-MM-DD"),
        state ? state : companyState
      )
    );
  };

  const getMonthDateDay = (date) => {
    // return moment(dt, "YYYY-MM-DD").format("MMMM Do");
    return `${moment(date).format("ddd")}, ${moment(date, "YYYY-MM-DD").format(
      "MMM DD"
    )}`;
  };

  const getNoSlotHtml = ({ isOnSite, availableDates, location }) => {
    if (availableDates) {
      return (
        <div className="frt-ssf">
          <button
            onClick={() => onNextAvailable(availableDates)}
            className="chq-atcss"
          >
            {labels.LBL_NO_INPERSON_APPT}{" "}
            <span style={{ color: "#8ba88e" }}>
              {getMonthDateDay(availableDates)}
            </span>
          </button>
          <button
            onClick={() => onNextAvailable(availableDates)}
            className="cta-circle unset-btn gts-fds"
          ></button>
        </div>
      );
    }
    return (
      <span className="no-available-appointments">
        {labels.LBL_NO_APPOITMENT}
      </span>
    );
  };

  const getSlotUi = (slot, index) => {
    return (
      <div
        key={index}
        style={{ overflow: "inherit" }}
        className="appointment-data-row mb-50"
      >
        {slot.isShowDoctorOnUI !== 0 && (
          <div
            className={`appointment-row-left ${slot.careTeam ? "highlighted" : ""
              }`}
          >
            <div className="appointment-row-left-inner">
              {slot.careTeam ? (
                <div className="iconic-label">
                  <i className="icon icon-heart"></i>
                  <span>{labels.LBL_CARE_TEAM}</span>
                </div>
              ) : (
                ""
              )}
              <div className="avatar-info avatar-info-2">
                <div className="avatar-info-in">
                  <figure>
                    <img
                      src={slot.doctorProfilePicture}
                      alt="Doctor's profile"
                      className="img-responsive"
                    />
                  </figure>
                  <div className="ai-meta">
                    <span className="ai-title">{slot.doctorName}</span>
                    <span className="ai-position">{slot.doctorTitle}</span>
                  </div>
                </div>
              </div>
              <div className="doctor-short-info">{getBio(slot)}</div>
            </div>
          </div>
        )}

        <div
          className={`appointment-row-right ${slot.isShowDoctorOnUI === 0 ? "take-all-width" : ""
            }`}
        >
          <div className="appointment-slot-rows">
            {slot.appointmentDetails.length ? (
              slot.appointmentDetails.map((pass, index) => {
                return (
                  <div key={index} className="appointment-slot-row">
                    <div className="appointment-row-top">
                      <div className="title-block">
                        <div className="title">
                          {pass.appointmentTypeCategoty}
                        </div>
                      </div>
                      <div className="art-date">
                        <span className="art-date-text">
                          {isToday(pass.appointmentDate)}
                        </span>

                        {/* <ReactDatePicker
                                            selected={pass.isOnSite ? new Date(pass.appointmentDate) : startDate}
                                            maxDate={pass.isOnSite ? '' : moment().add(30, 'days').toDate()}
                                            onChange={(date) => onDateChange(date, pass)}
                                            filterDate={(date) => {
                                                return is_weekend(date, pass);
                                            }}
                                            customInput={<ExampleCustom />}
                                            minDate={moment().toDate()}
                                            popperProps={{
                                                positionFixed: true
                                            }}
                                        /> */}
                      </div>
                      <div className="location-block">
                        {pass.location ? (
                          <div className="subtitle">
                            {/* {getadditionalDetails(pass.location)} */}
                            {pass.location}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="appointment-slots">
                      {pass.availableLocalTime.length === 0 ? (
                        getNoSlotHtml(pass)
                      ) : (
                        <Slider {...getSetting(pass)}>
                          {pass.availableLocalTime.map((next, index) => (
                            <button
                              key={index}
                              onClick={() =>
                                onBookClick(
                                  slot,
                                  pass,
                                  pass.availableTime[index],
                                  next,
                                  slot.timeZone
                                )
                              }
                              className="slot-btn appointment-slot"
                            >
                              {next}
                            </button>
                          ))}
                        </Slider>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="appointment-slots">
                <span className="no-available-appointments">
                  {labels.LBL_NO_APPOITMENT}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const onLinkClickFromPopup = (e, date, data) => {
    navigate("/dashboard/bookappointment");
  };

  const onInfoIconClick = (SelectedType) => {
    const data = appointmentData.find(
      ({ parentKey }) => parentKey === SelectedType
    );
    const imgData = {
      title: data.parentDisplayText,
      img: data.webCssClass,
      parentKey: data.parentKey,
      onsiteDate: data.onsiteDate,
      description: data.description,
    };

    dispatch(
      modalOpenAction({
        component: 2,
        content: {
          appModalData: imgData,
          title: labels.LBL_APP_TYPE_POPUP_HEADER,
          onLinkClickFromPopup: onLinkClickFromPopup,
        },
      })
    );
  };

  return (
    <>
      {isModuleAccessible ? (
        <>
          <div className="app-screen-title">
            <div className="app-screen-title-left">
              <button
                className="back-t-screen-cta unset-btn"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <h2>{labels.LBL_BOOK_APP_HEADER}</h2>
            </div>
            <div className="app-screen-title-right appt-app-screen-title-right">
              <figure className="fig-app-type">
                <img
                  onClick={() => onInfoIconClick(selectedAppType)}
                  src={InfoIcon}
                  alt="Read appointment type information"
                />
              </figure>
              <select
                onChange={onTypeChange}
                value={selectedAppType}
                className="form-select width-300"
                name="sick-visit"
                aria-label="Sick visit"
              >
                {appointmentData &&
                  appointmentData.map((next, index) => {
                    return (
                      (next.action == "ENABLE") &&
                      <option key={index} value={next.parentKey}>
                        {next.parentDisplayText}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {getTopSection()}
          {isAppSlotLoading || !isAppSlotsRequested ? (
            <Spinner />
          ) : !appointmentSlots || !appointmentSlots.length ? (
            <div className="div-no-doctor-avail">
              <p>{labels.LBL_NO_PROVIDER}</p>
              <a
                href="mailto:help@radish.health"
                className="btn btn-primary ucase"
              >
                {"EMAIL SUPPORT"}
              </a>
            </div>
          ) : (
            <>
              {appointmentSlots
                .filter((a) => a.isShowOnTop === 1)
                .map((next, index) => {
                  return getSlotUi(next, index);
                })}
              {appointmentSlots.length > 1 ? (
                <div className="app-heading-2">
                  <p>{labels.LBL_MORE_OPTIONS}</p>
                  {selectedAppType === "MENTALHEALTH" && (
                    <span>{labels.LBL_MENTAL_HEALTH_PROVIDER_HELP}</span>
                  )}
                </div>
              ) : (
                ""
              )}
              {appointmentSlots
                .filter((a) => a.isShowOnTop !== 1)
                .map((next, index) => {
                  return getSlotUi(next, index);
                })}
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default BookAppointments;
