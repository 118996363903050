import EmilyHeadshotSmall from "../../assets/img/png/emily-headshot-small.png";
import { useNavigate } from "react-router-dom";

const UpcomingOnsiteCard = ({ WorkplaceInfo = {} }) => {
  const navigate = useNavigate();
  return (
    <div
      className="radish-hospitality-card"
      onClick={() => navigate("./bookworkplace")}
    >
      <span className="rhc-title">{WorkplaceInfo.workplaceString}</span>
      <span className="rhc-date">{WorkplaceInfo.workplaceDate}</span>
      <span className="rhc-time">{WorkplaceInfo.workplaceTime}</span>
      <figure>
        <img
          src={EmilyHeadshotSmall}
          alt=""
          className="img-responsive sidebar-workplace-card-img"
        />
      </figure>
    </div>
  );
};

export default UpcomingOnsiteCard;
