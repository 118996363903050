import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { compose } from "redux";

import LoginForm from "../components/login/LoginForm.js";
import RetrieveRegistration from "../components/login/RetrieveRegistration.js";
import RegistrationDownload from "../components/login/RegistrationDownload.js";
import ForgotPassword from "../components/login/ForgotPassword.js";
import ResetPassword from "../components/login/ResetPassword.js";
import OTP from "../components/login/Otp.js";
import CreateAccount from "../components/login/CreateAccount.js";
import VerifyEmail from "../components/login/VerifyEmail.js";
import withLabels from "../HOC/withLabels.js";

import logo from "../assets/img/svg/radish-text-logo.svg";
import jasonHeadshot from "../assets/img/png/headshots/jason-headshot-login.png";
import stellaHeadshot from "../assets/img/png/headshots/stella-headshot-login.png";
import rishikaHeadshot from "../assets/img/png/headshots/rishika-headshot-login.png";
import meganHeadshot from "../assets/img/png/headshots/megan-headshot-login.png";
import "../assets/styles/scss/login.scss";
import SetYourPassword from "../components/login/SetYourPassword";
import { withCookies } from "react-cookie";
import redirectToRoute from "../utils/InternalRouting";

const Login = ({ ...props }) => {
  let navigate = useNavigate();
  let userInfo = useSelector((state) => state.user);
  let location = useLocation();
  const [featDoctor, setFeatDoctor] = useState({
    headshot: null,
    quote: "",
    byline: "",
  });

  const { cookies } = props;

  useEffect(() => {
    const { pathname } = location;
    const { token, isAccountActive } = userInfo;

    let getQueryParam = sessionStorage.getItem("queryParam");
    let searchParam = new URLSearchParams(getQueryParam);

    const newSearchParams = new URLSearchParams();
    for (const [name, value] of searchParam) {
      newSearchParams.append(name.toLowerCase(), value);
    }

    if (
      token &&
      isAccountActive &&
      (pathname === "/register" ||
        pathname === "/dashboard" ||
        pathname === "/" ||
        pathname === "/login")
    ) {
      if (newSearchParams.get("pagename")) {
        redirectToRoute(newSearchParams.get("pagename"), "URI", navigate, {
          queryParam: getQueryParam,
          urlParam: newSearchParams,
          userInfo,
        });

        sessionStorage.clear();
      } else {
        if (localStorage.getItem("loginInfo"))
          navigate("/dashboard", { replace: true, ...props });
      }

      let key = token;
      if (token) {
        key = token.split("=&")[1];
      }
      if (localStorage.getItem("loginInfo"))
        cookies.set(key, token && isAccountActive, { path: "/" });
    } else if (location.pathname === "/") {
      if (newSearchParams.get("pagename") === "register") {
        let regCode = newSearchParams.get("c");
        if (regCode) {
          navigate(`/register?c=${regCode}`, { replace: true });
        } else navigate("/register", { replace: true });
      } else navigate("/login", { replace: true });
    }
    if (
      pathname === "/retrieve-registration" &&
      userInfo.selfRegisterData &&
      !userInfo.selfRegisterData.isEmailSent
    ) {
      navigate(`otp?regCodeID=${userInfo.selfRegisterData.regCodeID}`, {
        replace: true,
        userInfo,
      });
    }
  }, [userInfo, navigate, location, cookies, props]);

  useEffect(() => {
    if (props.labels && props.labels.ISMAINTENANCE)
      redirectToRoute("maintenance", "URI", navigate);
  });

  useEffect(() => {
    let getQueryParam = sessionStorage.getItem("queryParam");
    let searchParam = new URLSearchParams(getQueryParam);

    const newSearchParams = new URLSearchParams();
    for (const [name, value] of searchParam) {
      newSearchParams.append(name.toLowerCase(), value.toLowerCase());
    }

    if (
      newSearchParams.get("pagename") &&
      newSearchParams.get("pagename") === "message"
    ) {
      redirectToRoute(`https://radish.health/mobile-app/`, "urlsamewindow");
    }
  }, [location.search]);

  useEffect(() => {
    let randomNum = Math.floor(Math.random() * 3);
    switch (randomNum) {
      case 0:
        setFeatDoctor({
          headshot: jasonHeadshot,
          quote:
            "We strive to make it convenient and seamless to discuss health and wellness and help patients live happier and healthier lives.",
          byline: "- Dr. Jason Sellers",
        });
        break;
      case 1:
        setFeatDoctor({
          headshot: stellaHeadshot,
          quote:
            "Radish puts our patients first. We provide easy access to a doctor, evidence-based care, and great follow-up.",
          byline: "- Dr. Stella Gandhi",
        });
        break;
      // case 2:
      //   setFeatDoctor({
      //     headshot: rishikaHeadshot,
      //     quote:
      //       "I believe in using a holistic approach to empower patients to participate in their care.",
      //     byline: "- Rishika Budhrani, NP",
      //   });
      //   break;
      case 2:
        setFeatDoctor({
          headshot: meganHeadshot,
          quote:
            "Focusing on empowerment, self-acceptance, and authenticity is key to helping patients overcome life's challenges.",
          byline: "- Megan McGinn, PsyD",
        });
        break;
      default:
        setFeatDoctor({
          headshot: jasonHeadshot,
          quote:
            "We strive to make it convenient and seamless to discuss health and wellness and help patients live happier and healthier lives.",
          byline: "- Dr. Jason Sellers",
        });
        break;
    }
  }, []);

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="row g-0 login-row">
            <div className="col-md-12 col-lg-4">
              <div className="login-quote">
                <p>
                  {featDoctor.quote}
                  <span>
                    <br></br>
                    {featDoctor.byline}
                  </span>
                </p>
                <figure>
                  <img
                    src={featDoctor.headshot}
                    alt=""
                    className="max-100 login-her-vector"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-12 col-lg-8">
              <div className="login-container">
                {/* <a onClick={handlePreviousForm} className="prev-cta">
                  Back
                </a> */}
                <div className="row g-0">
                  <div className="col-12">
                    <figure
                      className={`mb-60 ${
                        location.pathname === "/register" && "hover-pointer"
                      }`}
                      onClick={() => {
                        if (location.pathname === "/register")
                          navigate("/login");
                      }}
                    >
                      <img src={logo} alt="Radish Logo" />
                    </figure>
                  </div>
                  <div className="col-12">
                    <div className="login-forms-stack">
                      {
                        <Routes>
                          <Route
                            path="*"
                            element={<Navigate to="../404" replace="true" />}
                          />
                          <Route
                            path="login"
                            element={<LoginForm {...props} />}
                          />
                          <Route
                            path="forgot-password"
                            element={<ForgotPassword {...props} />}
                          />
                          <Route
                            path="reset-password"
                            element={<ResetPassword {...props} />}
                          />
                          <Route
                            path="download-radish"
                            element={<RegistrationDownload {...props} />}
                          />
                          <Route
                            path="retrieve-registration"
                            element={
                              <RetrieveRegistration {...props} {...userInfo} />
                            }
                          />
                          <Route
                            path="otp"
                            element={<OTP {...props} {...userInfo} />}
                          />
                          <Route
                            path="register"
                            element={<CreateAccount {...props} />}
                          />
                          <Route
                            path="setpassword"
                            element={<SetYourPassword {...props} />}
                          />
                          <Route
                            path="/verify-email/"
                            element={<VerifyEmail />}
                          />
                        </Routes>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default compose(withLabels, withCookies)(Login);
