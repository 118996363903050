import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions.js";
import { modalOpen as modalOpenAction } from "../../redux/actions/modalAction";
import { useNavigate } from "react-router-dom";
import { NotesReadAction } from "../../redux/actions/dashboardSidebarAction.js";
import Logo from "../../assets/img/svg/radish-icon.svg";
import ChatIcon from "../../assets/img/svg/chat-icon.svg";
import { useLocation } from "react-router-dom";

const PastAppointmentCard = ({ appointmentInfo = {}, labels = {} }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { features: accessLevel } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.user);

  let appointmentDay,
    appointmentDate,
    appointmentMonth,
    summaryExcerpt,
    appointmentYear;
  if (appointmentInfo.localDate) {
    const schduleDate = appointmentInfo.localDate.split(",");
    if (schduleDate.length > 0) {
      let dateYear = schduleDate[1].split(" ");
      appointmentDay = schduleDate[0];
      appointmentDate = dateYear[2];
      appointmentMonth = dateYear[1];
      appointmentYear = dateYear[3];
    }
  }

  const payload = {
    component: 3,
    content: appointmentInfo,
  };

  useEffect(() => {
    if (location.state) {
      if (appointmentInfo.appointmentID === location.state.aptId.toString()) {
        onSidePopupOpenClick();
      }
    }
  }, [location.state, appointmentInfo.appointmentID]);

  const onSidePopupOpenClick = () => {
    const { isSummaryNoteRead, summaryNotes, appointmentID } = appointmentInfo;
    if (isSummaryNoteRead === 0 && summaryNotes) {
      dispatch({ type: "MARK_NOTE_READ_PAST", appointmentID });
      dispatch(NotesReadAction(token, appointmentID));
    }
    dispatch(sidePopupOpen(payload));
  };

  const handleClick = () => {
    dispatch(sidePopupOpen({ component: 11 }));
  };

  // const redirectFollowUp = () => {
  //   dispatch({
  //     type: "SELECT_APP_TYPE",
  //     payload: appointmentInfo.followUpParentKey,
  //   });
  // };

  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "MODULE-MEDICAL-SERVICE"
  );
  const isDisableAll =
    selectModule.action === "DISABLE" || selectModule.action === "HIDE";
  const moduleLevelMsg = selectModule.featureMessage;
  const redirectFollowUp = () => {
    if (isDisableAll) {
      dispatch(
        modalOpenAction({
          component: 3,
          content: {
            moduleLevelMsg: moduleLevelMsg,
          },
        })
      );
    } else {
      dispatch({
        type: "SELECT_APP_TYPE",
        payload: appointmentInfo.followUpParentKey,
      });
      navigate(`/dashboard/bookappointment`, { replace: true });
    }
  };

  if (appointmentInfo.summaryNotes)
    summaryExcerpt = appointmentInfo.summaryNotes.substr(0, 50);

  return (
    <div className="appointment-card">
      <div className="appointment-card-top">
        <div className="act-time-title">
          <div className="act-left">
            <span className="act-flag">
              {appointmentInfo.appointmentTypeDisplayText}
            </span>
            <span className="act-title">{appointmentInfo.examRoomName}</span>
          </div>
          <div className="act-right">
            <span className="act-time">
              {appointmentInfo.localTime} {appointmentInfo.timeZone}
            </span>
          </div>
        </div>
        <div className="act-middle">
          <div className="act-middle-left">
            <div className="avatar-info">
              <div className="avatar-info-in">
                <figure>
                  <img
                    src={appointmentInfo.profilePicture || Logo}
                    alt=""
                    className="img-responsive"
                  />
                </figure>
                <div
                  className={`ai-meta ${
                    !appointmentInfo.careTeam && "ai-meta-center"
                  }`}
                >
                  <span className="ai-title">{appointmentInfo.doctor}</span>
                  {appointmentInfo.careTeam === 1 ? (
                    <span className="ai-cta">
                      <i className="icon icon-heart"></i>
                      {labels.LBL_PAST_APPT_CARE_TEAM}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="act-middle-right">
            {/* <span className="act-day">{appointmentDay}</span> */}
            <span className="act-month">{appointmentMonth}</span>
            <span className="act-day-num">{appointmentDate}</span>
            <span className="act-month">{appointmentYear}</span>
          </div>
        </div>
        <div className="summary-card mt-25">
          <div className="summary-card-left">
            <span className="sc-title">
              {labels.LBL_PAST_APPT_SUMMARY_NOTE}
            </span>
            <span className="sc-desc">
              {summaryExcerpt
                ? summaryExcerpt + "..."
                : labels.LBL_PAST_APPT_NO_SUMMARY}
            </span>
          </div>
          <div className="summary-card-right">
            <span className="cta-circle" onClick={onSidePopupOpenClick}></span>
          </div>
        </div>
      </div>

      <div className="appointment-card-bottom">
        <div className="acb-ctas">
          <button
            className="schedule-follow-up"
            onClick={redirectFollowUp}
            style={{ background: "transparent" }}
          >
            <span className="link-blue">{labels.LBL_PAST_APPT_FOLLOW_UP}</span>
          </button>
          <button className="open-chat" onClick={handleClick}>
            <img src={ChatIcon} alt="Chat with your doctor" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PastAppointmentCard;
