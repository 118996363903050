import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserPharmacyAction,
  saveUserPharmacyAction,
  getUserProfileAction,
} from "../../../redux/actions/profileAction";
import {
  sidePopupClose,
  sidePopupLoading,
  sidePopupConfirmed,
  sidePopupNotLoading,
} from "../../../redux/actions/sidePopupActions.js";
import InputMask from "react-input-mask";

import Spinner from "../../common/Spinner";

function AddPharmacyInfo() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const { userProfile, isPharmacyLoading, isPharmacySaved } = useSelector(
    (state) => state.userProfile
  );

  const [isSubmit, setSubmitted] = useState(false);
  const [values, setvalues] = useState(userProfile);
  const [errorObj, setError] = useState({});

  const labels = useSelector((state) => state.app.labels);

  useEffect(() => {
    setvalues(userProfile);
    setError({});
    setSubmitted(false);
  }, [userProfile]);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setvalues({ ...values, [name]: value });
    if (target.dataset.stateAddress) {
      if (/^[a-zA-Z]+$/.test(value) || value === "")
        setvalues({ ...values, [name]: value.toUpperCase() });
    } else setvalues({ ...values, [name]: value });
  };

  const checkPhone = () => {
    const { pharmacyPhone } = values;
    if (pharmacyPhone && pharmacyPhone.length < 12) {
      setError({ ...errorObj, phoneError: labels.MSG_INVALID_PHONE });
      return;
    }
    setError({ ...errorObj, phoneError: "" });
  };

  const onZipBlur = () => {
    const { pharmacyZip } = values;
    var re = /\d{5}(-\d{4})?/;
    if (!re.test(pharmacyZip)) {
      setError({ ...errorObj, zipError: labels.MSG_ZIP_REQ });
      return;
    }
    setError({ ...errorObj, zipError: "" });
  };

  const onStateBlur = () => {
    const { pharmacyState } = values;
    if (pharmacyState.length < 2) {
      setError({ ...errorObj, stateError: "Too short" });
      return;
    }
    setError({ ...errorObj, stateError: "" });
  };

  const savePharmacyInfo = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (!checkFormError() && validationError()) {
      dispatch(sidePopupLoading());
      let payload = {
        pharmacyName: values.pharmacyName,
        address: values.pharmacyAddress,
        city: values.pharmacyCity,
        state: values.pharmacyState,
        zip: values.pharmacyZip,
        phone: values.pharmacyPhone,
      }
      dispatch(saveUserPharmacyAction(userInfo.token, payload));
      dispatch(sidePopupNotLoading());
      //await dispatch(getUserPharmacyAction(userInfo.token));
      setTimeout(() => {
        dispatch(getUserProfileAction(userInfo.token));
      }, 2000);
    }
  };

  const checkFormError = () => {
    const { pharmacyName, pharmacyAddress, pharmacyCity, pharmacyState, pharmacyZip } = values;

    if (!pharmacyName || !pharmacyAddress || !pharmacyCity || !pharmacyState || !pharmacyZip) {
      return true;
    }
    return false;
  };

  const validationError = () => {
    if (Object.keys(errorObj).length > 0) {
      if (errorObj.phoneError) {
        return false;
      }
      if (errorObj.zipError) {
        return false;
      }
      if (errorObj.stateError) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (isPharmacySaved) {
      dispatch(sidePopupConfirmed());
      setTimeout(() => {
        dispatch(sidePopupClose());
      }, 3000);
    }
  }, [isPharmacySaved, dispatch]);

  if (isPharmacySaved)
    return (
      <div className="app-heading-1 mb-40">{labels.MSG_PHARMACY_UPDATE}</div>
    );

  return (
    <>
      <div className="app-heading-1 mb-40">Pharmacy Information</div>
      <div className="popup-form-wrap">
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="pharmacyName">
                {labels.LBL_REGISTER_PHARMACY} *
              </label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.pharmacyName && "input-invalid"
                  }`}
                  name="pharmacyName"
                  id="pharmacyName"
                  value={values.pharmacyName}
                  onChange={handleInputChange}
                />
                {isSubmit && !values.pharmacyName && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="pharmacyAddress">{labels.LBL_REGISTER_STREET} *</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.pharmacyAddress && "input-invalid"
                  }`}
                  maxLength="50"
                  name="pharmacyAddress"
                  id="pharmacyAddress"
                  value={values.pharmacyAddress}
                  onChange={handleInputChange}
                />
                {isSubmit && !values.pharmacyAddress && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-6">
            <div className="form-group mb-15">
              <label htmlFor="pharmacyCity">{labels.LBL_REGISTER_CITY} *</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.pharmacyCity && "input-invalid"
                  }`}
                  maxLength="50"
                  autoComplete="off"
                  name="pharmacyCity"
                  id="pharmacyCity"
                  value={values.pharmacyCity}
                  onChange={handleInputChange}
                />
                {isSubmit && !values.pharmacyCity && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group mb-15">
              <label htmlFor="pharmacyState">{labels.LBL_REGISTER_STATE} *</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    (errorObj.stateError || (isSubmit && !values.pharmacyState)) &&
                    "input-invalid"
                  }`}
                  maxLength="2"
                  autoComplete="off"
                  name="pharmacyState"
                  id="pharmacyState"
                  data-state-address={true}
                  value={values.pharmacyState}
                  onChange={handleInputChange}
                  onBlur={onStateBlur}
                />
                {values.pharmacyState && errorObj.stateError && (
                  <span className="error-label">{errorObj.stateError}</span>
                )}
                {isSubmit && !values.pharmacyState && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group mb-15">
              <label htmlFor="pharmacyZip">{labels.LBL_REGISTER_ZIP} *</label>
              <div className="inputwrap">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    isSubmit && !values.pharmacyZip && "input-invalid"
                  }`}
                  maxLength="50"
                  autoComplete="off"
                  name="pharmacyZip"
                  id="pharmacyZip"
                  value={values.pharmacyZip}
                  onBlur={onZipBlur}
                  onChange={handleInputChange}
                />
                {values.pharmacyZip && errorObj.zipError && (
                  <span className="error-label">{errorObj.zipError}</span>
                )}
                {isSubmit && !values.pharmacyZip && (
                  <span className="error-label">{labels.MSG_REQ}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-5">
          <div className="col-12">
            <div className="form-group mb-15">
              <label htmlFor="pharmacyPhone">{labels.LBL_REGISTER_PHONE}</label>
              <div className="inputwrap">
                <InputMask
                  type="text"
                  autoComplete="off"
                  placeholder="xxx-xxx-xxxx"
                  inputMode="tel"
                  className={`form-control form-control-lg ${
                    values.pharmacyPhone && errorObj.phoneError && "input-invalid"
                  }`}
                  name="pharmacyPhone"
                  id="pharmacyPhone"
                  mask="999-999-9999"
                  maskChar=""
                  onBlur={checkPhone}
                  value={values.pharmacyPhone}
                  onChange={handleInputChange}
                />
                {values.pharmacyPhone && errorObj.phoneError && (
                  <span className="error-label">{errorObj.phoneError}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg col-12"
          disabled={isPharmacyLoading}
          onClick={savePharmacyInfo}
        >
          {isPharmacyLoading ? <Spinner /> : "Save"}
        </button>
      </div>
    </>
  );
}

export default AddPharmacyInfo;
