const sidePopupState = {
  isSidePopupOpen: false,
  component: 0,
  content: null,
  isLoading: false,
  isConfirmed: false,
  isFailed: false,
};

export const sidePopupReducer = (state = sidePopupState, action) => {
  if (action.type === "SIDEPOPUP_OPEN") {
    return {
      isSidePopupOpen: true,
      component: action.payload.component,
      content: action.payload.content,
      isLoading: false,
      isConfirmed: false,
      isFailed: false,
    };
  }

  if (action.type === "SIDEPOPUP_CLOSE") {
    return {
      isSidePopupOpen: false,
      component: 0,
      content: null,
      isLoading: false,
      isConfirmed: false,
      isFailed: false,
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      isSidePopupOpen: false,
      component: 0,
      content: null,
      isLoading: false,
      isConfirmed: false,
      isFailed: false,
    };
  }

  if (action.type === "SIDEPOPUP_LOADING") {
    return {
      ...state,
      isLoading: true,
      isConfirmed: false,
      isFailed: false,
    };
  }

  if (action.type === "SIDEPOPUP_CONFIRMED") {
    return {
      ...state,
      isLoading: false,
      isConfirmed: true,
      isFailed: false,
    };
  }

  if (action.type === "SIDEPOPUP_FAILED") {
    return {
      ...state,
      isLoading: false,
      isConfirmed: false,
      isFailed: true,
    };
  }

  if (action.type === "SIDEPOPUP_NOT_LOADING") {
    return {
      ...state,
      isLoading: false,
    };
  }

  return state;
};
