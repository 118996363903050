import {
  getMedicalCondition,
  getMedication,
  getUserMedicalHistory,
  getUserMedicalHistoryVerified,
} from "../../requests/requestAPI";

const requestMedicalHistorySearch = () => ({
  type: "MEDICAL_HISTORY_SEARCH_REQUEST",
});

const receiveMedicalHistorySearch = (payload, flag) => ({
  type: "MEDICAL_HISTORY_SEARCH_RECEIVE",
  payload,
  flag,
});

const failureMedicalHistorySearch = (payload) => ({
  type: "MEDICAL_HISTORY_SEARCH_FAIL",
});

export const medicalHistorySearchAction = (payload, type) => {
  return async (dispatch) => {
    dispatch(requestMedicalHistorySearch());
    try {
      let data;
      if (type === 1) {
        data = await getMedicalCondition(payload);
      } else {
        data = await getMedication(payload);
      }

      if (data.ok) {
        let tempData = await data.json();

        dispatch(receiveMedicalHistorySearch(tempData, type));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      console.log(ex);
      dispatch(failureMedicalHistorySearch(JSON.parse(ex.message)));
    }
  };
};

const requestUserMedicalHistory = (token) => ({
  type: "USER_HISTORY_REQUEST",
});

const receiveUserHistory = (payload) => ({
  type: "USER_HISTORY_RECEIVE",
  payload,
});

const failureUserHistory = () => ({
  type: "USER_HISTORY_FAIL",
});

export const userHistoryAction = (token) => {
  return async (dispatch) => {
    dispatch(requestUserMedicalHistory());
    try {
      let data = await getUserMedicalHistory(token);

      if (data.ok) {
        let tempData = await data.json();

        dispatch(receiveUserHistory(tempData.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureUserHistory(JSON.parse(ex.message)));
    }
  };
};

const requestUserMedicalHistoryVerified = (token) => ({
  type: "VERIFIED_HISTORY_REQUEST",
});

const receiveUserHistoryVerified = (payload) => ({
  type: "VERIFIED_HISTORY_RECEIVE",
  payload,
});

const failureUserHistoryVerified = () => ({
  type: "VERIFIED_HISTORY_FAIL",
});
export const userVerifiedHistoryAction = (token) => {
  return async (dispatch) => {
    dispatch(requestUserMedicalHistoryVerified());
    try {
      let data = await getUserMedicalHistoryVerified(token);

      if (data.ok) {
        let tempData = await data.json();

        dispatch(receiveUserHistoryVerified(tempData.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureUserHistoryVerified(JSON.parse(ex.message)));
    }
  };
};
