import { getlabels } from "../../requests/requestAPI";
import Labels from "../../mock/Labels.json";

const requestLabels = () => ({
  type: "APP_LABEL_REQUEST",
});
const receiveLabels = (payload) => ({
  type: "APP_LABEL_RECEIVE",
  payload,
});
const failurelabels = (payload) => ({
  type: "APP_LABEL_FAIL",
  payload,
});

export const getLabelsAction = () => {
  return async (dispatch) => {
    dispatch(requestLabels());
    try {
      let data = await getlabels();
      if (data.ok) {
        let labelInfo = await data.json();
        const labels = labelInfo.value;
        var formattedData = labels.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.baseString }),
          {}
        );
        dispatch(receiveLabels({ ...Labels, ...formattedData }));
      } else {
        throw new Error();
      }
    } catch (ex) {
      dispatch(failurelabels(Labels));
    }
  };
};
