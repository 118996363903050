import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginAction } from "../../redux/actions/loginUserAction.js";
import Alert from "../common/Alert.js";
import Spinner from "../common/Spinner.js";

const requestLogin = () => ({
  type: "CLEAR_STATE",
});

const LoginForm = ({ labels = {} }) => {
  let dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const userInfo = useSelector((state) => state.user);
  const [isSubmit, setSubmitted] = useState(false);
  const [values, setvalues] = useState({ email: "", password: "" });
  const emailRef = useRef(null);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setvalues({ ...values, [name]: value });
  };

  useEffect(() => {
    // return () => {
    setvalues({ email: "", password: "" });
    dispatch(requestLogin());
    //};
  }, [dispatch]);

  useEffect(() => {
    if (userInfo.isLoginError) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [userInfo]);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (values.email && values.password) {
      dispatch(loginAction(values.email, values.password,labels.URL_GETIP));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  return (
    <div className="lg-form-wrap" id="email-login-form-main">
      <h3 className="login-heading"> {labels.LBL_LOGIN}</h3>
      {hasError && (
        <Alert
          messageTitle="ERROR:"
          message={userInfo.loginApiErrorMsg}
          cssClassName={"alert alert-danger mt-20"}
        />
      )}
      <div className="form-group mb-20">
        <label htmlFor="email">{labels.LBL_REGISTER_EMAIL}</label>
        <div className="inputwrap">
          <input
            name="email"
            type="email"
            className={`form-control form-control-lg ${isSubmit && !values.email && "input-invalid"
              }`}
            id="email"
            onChange={handleInputChange}
            value={values.email}
            required
            maxLength="250"
            disabled={userInfo.isLoading}
            ref={emailRef}
            tabIndex="0"
            onKeyPress={handleKeyPress}
          />
          {isSubmit && !values.email && (
            <span className="error-label">{labels.MSG_REQ}</span>
          )}
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="password">{labels.LBL_LOGIN_PASSWORD}</label>
        <div className="inputwrap">
          <input
            name="password"
            type="password"
            className={`form-control form-control-lg ${isSubmit && !values.password && "input-invalid"
              }`}
            id="password"
            onChange={handleInputChange}
            value={values.password}
            required
            disabled={userInfo.isLoading}
            tabIndex="0"
            onKeyPress={handleKeyPress}
          />
          {isSubmit && !values.password && (
            <span className="error-label">{labels.MSG_REQ}</span>
          )}
        </div>

        <div className="switch-cta-wrap">
          <Link tabIndex="-1" to="../forgot-password" className="link-type-1">
            <strong>{labels.LBL_LOGIN_FORGOT_PWD}</strong>
          </Link>
        </div>
      </div>

      <button
        type="submit"
        onClick={handleLogin}
        className="btn btn-primary btn-lg col-12"
        disabled={userInfo.isLoading}
        tabIndex="0"
      >
        {userInfo.isLoading ? <Spinner /> : labels.LBL_LOGIN}
      </button>
      <div className="switch-cta-wrap">
        <span className="link-type-1">{labels.LBL_NO_ACC} </span>
        <Link to="../download-radish" className="link-type-1">
          <strong>{labels.LBL_REGISTER_ONE}</strong>
        </Link>
      </div>
      <div className="switch-cta-wrap">
        <span className="link-type-1">{labels.LBL_EMPLOYER_OFFER} </span>
        <br/>
        <a href={"https://radish.health/refer-employer/"} target="_blank" className="link-type-1">
          <strong>{labels.LBL_EMPLOYER_OFFER_1}</strong>
        </a>
      </div>
    </div>
  );
};

export default LoginForm;
