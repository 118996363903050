import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SaveCardDetailsAction,
  getInsuranceProviderAction,
} from "../../redux/actions/insuranceProviderAction";
import { getUserProfileAction} from "../../redux/actions/profileAction";

const InsuranceBackPhoto = ({ backPhoto, setBackPhoto, labels = {} }) => {
  const imageRef = useRef();
  const [photo, setPhoto] = useState(backPhoto);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type.includes("image")) {
        dispatch({ type: "INVALID_CARDPHOTO_CLEAR" });
        let base64Image = await getBase64(event.target.files[0]);
        setPhoto(base64Image);
        await dispatch(
          SaveCardDetailsAction(userInfo.token, {
            cardBackPhoto: base64Image.split(",")[1],
          })
        );

       // await dispatch(getInsuranceProviderAction(userInfo.token));
        dispatch(getUserProfileAction(userInfo.token));
      } else {
        dispatch({ type: "INVALID_CARDPHOTO" });
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const openFileDialog = () => {
    imageRef.current.click();
  };

  return (
    <div className="uit-col">
      {photo && photo !== "" ? (
        <div className="uit-card-preview">
          <figure>
            <img
              src={photo}
              alt="Back of your insurance card"
              className="img-responsive"
            />
          </figure>
          <button onClick={openFileDialog} className="uit-re-upload unset-btn">
            {labels.LBL_INSURANCE_UPLOAD}
          </button>
        </div>
      ) : (
        <button
          onClick={openFileDialog}
          className="uit-upload-cta unset-btn"
        ></button>
      )}
      <span className="uit-col-label">Card Back</span>
      <input
        type="file"
        accept="image/*"
        onChange={onImageChange}
        ref={imageRef}
        id="file-upload"
      />
    </div>
  );
};
export default InsuranceBackPhoto;
