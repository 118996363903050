const ChecklistItemDescription = ({ labels, content }) => {
  const { myHFTitle, myHFDescription, accessibleVersion } = content;
  return (
    <>
      <div className="avatar-info avatar-info-2 mb-25">
        <div className="avatar-info-in">
          <div className="ai-meta">
            <span className="ai-title">{myHFTitle}</span>
          </div>
        </div>
      </div>
      <div className="center-popup-data">
        <p>{myHFDescription}</p>
        <a
          className="checklist-learn-more"
          href={accessibleVersion}
          target="_blank"
          rel="noreferrer"
        >
          {labels.LBL_LEARN_MORE}
        </a>
      </div>
    </>
  );
};

export default ChecklistItemDescription;
