import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slidersAction } from "../../redux/actions/dashboardActions.js";
import { Link, useLocation } from "react-router-dom";
import RadSlider from "./RadSlider.js";
import DashboardLoading from "./DashboardLoading.js";
import Spinner from "../common/Spinner.js";
import { logoutUser } from "../../redux/actions/logoutUser.js";

const ExploreSlide = ({ state }) => {
  function parseTitle() {
    return state.title.replace(/[^a-zA-Z ]/g, "").replace(/ /g, "-");
  }

  if (state.link)
    return (
      <div className="explore-slide">
        <a
          href={state.link}
          rel="noreferrer"
          target="_blank"
          className="explore-item"
        >
          <figure>
            <img src={state.image} alt="" className="img-responsive" />
          </figure>
          <span>{state.title}</span>
        </a>
      </div>
    );

  return (
    <div className="explore-slide">
      <Link
        to={`../explore/${parseTitle()}/1/${state.sliderId}`}
        state={{ slider: state }}
        className="explore-item"
      >
        <figure>
          <img src={state.image} alt="" className="img-responsive" />
        </figure>
        <span>{state.title}</span>
      </Link>
    </div>
  );
};

const Explore = (props) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const { sliders, isSlidersLoading, is404Error } = useSelector(
    (state) => state.dashboard
  );
  const userInfo = useSelector((state) => state.user);

  useEffect(() => {
    if (is404Error && is404Error === 401) {
      props.cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [is404Error, props, dispatch]);

  useEffect(() => {
    if (!sliders[0] && !isSlidersLoading)
      dispatch(slidersAction(userInfo.token));
  }, [userInfo, dispatch, sliders, isSlidersLoading]);

  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 300,
    arrows: true,
    dots: false,
    className: "explore-row explore-slider",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  };

  if (DashboardLoading()) return null;

  if (location.pathname !== "/dashboard" && isSlidersLoading)
    return <Spinner />;

  return (
    <>
      <div className="explore-heading-div">
        <h2 className="app-heading-1">Explore</h2>
        <div className="explore-heading-div-right">
          <Link
            to={
              location.pathname === "/dashboard"
                ? "./explore-content"
                : "../explore-content"
            }
          >
            View all
          </Link>
          <Link
            className="explore-all-arrow unset-btn"
            to={
              location.pathname === "/dashboard"
                ? "./explore-content"
                : "../explore-content"
            }
            type="button"
          ></Link>
        </div>
      </div>
      {sliders[0] && (
        <RadSlider
          stateToMap={sliders}
          CustomSlide={ExploreSlide}
          settings={settings}
        />
      )}
    </>
  );
};

export default Explore;
