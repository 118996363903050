export const sidePopupOpen = (payload) => ({
  type: "SIDEPOPUP_OPEN",
  payload: payload,
});
export const sidePopupClose = () => ({
  type: "SIDEPOPUP_CLOSE",
});
export const sidePopupLoading = () => ({
  type: "SIDEPOPUP_LOADING",
});
export const sidePopupConfirmed = () => ({
  type: "SIDEPOPUP_CONFIRMED",
});
export const sidePopupFailed = () => ({
  type: "SIDEPOPUP_FAILED",
});
export const sidePopupNotLoading = () => ({
  type: "SIDEPOPUP_NOT_LOADING",
});
