import {
  getFamilyMember,
  getUserProfile,
  saveFamilyMember,
  removeFamilyInvite,
  SaveUserProfile,
  SaveUserProfilePhoto,
  postConsent,
  getUserPharmacy,
  saveUserPharmacy,
  getStripeData,
  getCareCostData,
  getProfileService
} from "../../requests/requestAPI";
import { sendbird } from "../../requests/endpointConstant";
import SendBird from "sendbird";
import SendBirdDesk from "sendbird-desk";
import moment from "moment";

const requestUserProfile = () => ({
  type: "GET_USERPROFILE_REQUEST",
});
const receiveUserProfile = (payload) => ({
  type: "GET_USERPROFILE_RECEIVE",
  payload,
});
const failureUserProfile = (payload) => ({
  type: "GET_USERPROFILE_FAIL",
  payload,
});

export const getUserProfileAction = (token) => {
  return async (dispatch) => {
    dispatch(requestUserProfile());
    try {
      let data = await getUserProfile(token);
      if (data.ok) {
        let profileInfo = await data.json();
        dispatch(receiveUserProfile(profileInfo.value));

        sendbird.setErrorFirstCallback(true);
        sendbird.connect(profileInfo.value.patID, (err, user) => {
          if (!err) {
            sendbird.updateCurrentUserInfo(
              profileInfo.value.firstName + " " + profileInfo.value.lastName,
              profileInfo.value.photo,
              (err1, user) => {
                if (!err1) {
                  SendBirdDesk.init(SendBird);
                  SendBirdDesk.authenticate(
                    profileInfo.value.patID,
                    (res, err) => {
                      const retrievedString = localStorage.getItem("loginInfo");
                      const parsedObject = JSON.parse(retrievedString);
                      if (!err) {
                        SendBirdDesk.setCustomerCustomFields(
                          {
                            companyid: profileInfo.value.companyID,
                            companyname: profileInfo.value.companyName,
                            isdependent: parsedObject.isDependent.toString(),
                            ehrurl: `https://www.drchrono.com/patients/${profileInfo.value.patID}`,
                            primarydoctor: profileInfo.value.careDoctorName,
                            dob: profileInfo.value.dob
                              ? moment(profileInfo.value.dob).format(
                                  "MMM DD, YYYY"
                                )
                              : "",
                            phonenumber: profileInfo.value.phoneNumber,
                          },
                          (error) => {
                            // console.log("User====", error);
                          }
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        });
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureUserProfile(JSON.parse(ex.message)));
    }
  };
};

const requestSaveUserProfile = () => ({
  type: "SAVE_USERPROFILE_REQUEST",
});
const receiveSaveUserProfile = (payload) => ({
  type: "SAVE_USERPROFILE_RECEIVE",
  payload,
});
const failureSaveUserProfile = (payload) => ({
  type: "SAVE_USERPROFILE_FAIL",
  payload,
});

export const SaveUserProfileAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestSaveUserProfile());
    try {
      let data = await SaveUserProfile(token, payload);
      if (data.ok) {
        let profileInfo = await data.json();
        dispatch(receiveSaveUserProfile(profileInfo.value));
        await dispatch(getUserProfileAction(token));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureSaveUserProfile(JSON.parse(ex.message)));
    }
  };
};

const requestSaveUserProfilePhoto = () => ({
  type: "SAVE_USERPROFILE_PHOTO_REQUEST",
});
const receiveSaveUserProfilePhoto = (payload) => ({
  type: "SAVE_USERPROFILEPHOTO_RECEIVE",
  payload,
});
const failureSaveUserProfilePhoto = (payload) => ({
  type: "SAVE_USERPROFILEPHOTO_FAIL",
  payload,
});

export const SaveUserProfilePhotoAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestSaveUserProfilePhoto());
    try {
      let data = await SaveUserProfilePhoto(token, payload);
      if (data.ok) {
        let profileInfo = await data.json();
        dispatch(receiveSaveUserProfilePhoto(profileInfo.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureSaveUserProfilePhoto(JSON.parse(ex.message)));
    }
  };
};

const requestFamilyMember = () => ({
  type: "SAVE_SAVEFAMILY_REQUEST",
});
const receiveFamilyMember = (payload) => ({
  type: "SAVE_SAVEFAMILY_RECEIVE",
  payload,
});
const failureFamilyMember = (payload) => ({
  type: "SAVE_SAVEFAMILY_FAIL",
  payload,
});
export const clearFamilyMemberError = () => ({
  type: "SAVE_FAMILY_FAIL_CLEAR",
});

export const SaveFamilyMemberAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestFamilyMember());
    try {
      let data = await saveFamilyMember(token, payload);
      if (data.ok) {
        let profileInfo = await data.json();
        dispatch(receiveFamilyMember(profileInfo.value));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureFamilyMember(JSON.parse(ex.message)));
    }
  };
};

const requestGetFamilyMember = () => ({
  type: "GET_FAMILY_REQUEST",
});
const receiveGetFamilyMember = (payload) => ({
  type: "GET_FAMILY_RECEIVE",
  payload,
});
const failureGetFamilyMember = (payload) => ({
  type: "GET_FAMILY_FAIL",
  payload,
});

export const getFamilyMemberAction = (token) => {
  return async (dispatch) => {
    dispatch(requestGetFamilyMember());
    try {
      let data = await getFamilyMember(token);
      if (data.ok) {
        let newData = await data.json();
        dispatch(receiveGetFamilyMember(newData.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureGetFamilyMember(JSON.parse(ex.message)));
    }
  };
};

const requestRemoveFamilyMember = () => ({
  type: "REMOVE_FAMILY_REQUEST",
});
const receiveRemoveFamilyMember = (payload) => ({
  type: "REMOVE_FAMILY_RECEIVE",
  payload,
});
const failureRemoveFamilyMember = (payload) => ({
  type: "REMOVE_FAMILY_FAIL",
  payload,
});

export const removeFamilyMemberAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestRemoveFamilyMember());
    try {
      let data = await removeFamilyInvite(token, payload);
      if (data.ok) {
        let newData = await data.json();
        dispatch(receiveRemoveFamilyMember(newData.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureRemoveFamilyMember(JSON.parse(ex.message)));
    }
  };
};

const requestConsent = () => ({
  type: "CONSENT_REQUEST",
});
const receiveConsent = (payload) => ({
  type: "CONSENT_RECEIVE",
  payload,
});
const failureConsent = () => ({
  type: "CONSENT_FAIL",
});

export const ConsentAction = (token) => {
  return async (dispatch) => {
    dispatch(requestConsent());
    try {
      let data = await postConsent(token);
      if (data.ok) {
        let ConsentInfo = await data.json();
        dispatch(receiveConsent(ConsentInfo.value));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureConsent(JSON.parse(ex.message)));
    }
  };
};

const requestGetUserPharmacy = () => ({
  type: "GET_PHARMACY_REQUEST",
});
const receiveGetUserPharmacy = (payload) => ({
  type: "GET_PHARMACY_RECEIVE",
  payload,
});
const failureGetUserPharmacy = (payload) => ({
  type: "GET_PHARMACY_FAIL",
  payload,
});

export const getUserPharmacyAction = (token) => {
  return async (dispatch) => {
    dispatch(requestGetUserPharmacy());
    try {
      let data = await getUserPharmacy(token);
      if (data.ok) {
        let newData = await data.json();
        dispatch(receiveGetUserPharmacy(newData.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureGetUserPharmacy(JSON.parse(ex.message)));
    }
  };
};

const requestSaveUserPharmacy = () => ({
  type: "SAVE_PHARMACY_REQUEST",
});
const receiveSaveUserPharmacy = (payload) => ({
  type: "SAVE_PHARMACY_RECEIVE",
  payload,
});
const failureSaveUserPharmacy = (payload) => ({
  type: "SAVE_PHARMACY_FAIL",
  payload,
});

export const saveUserPharmacyAction = (token, payload) => {
  return async (dispatch) => {
    dispatch(requestSaveUserPharmacy());
    try {
      let data = await saveUserPharmacy(token, payload);
      if (data.ok) {
        let profileInfo = await data.json();
        dispatch(receiveSaveUserPharmacy(profileInfo.value));
      } else {
        let ab = await data.json();
        throw new Error(JSON.stringify(ab));
      }
    } catch (ex) {
      dispatch(failureSaveUserPharmacy(JSON.parse(ex.message)));
    }
  };
};

const requestStripeData = () => ({
  type: "STRIPE_REQUEST",
});
const receiveStripeData = (payload) => ({
  type: "STRIPE_RECEIVE",
  payload,
});
const failureStripeData = (payload) => ({
  type: "STRIPE_FAIL",
  payload,
});

export const getStripeAction = (token) => {
  return async (dispatch) => {
    dispatch(requestStripeData());
    try {
      let data = await getStripeData(token);
      if (data.ok) {
        let newData = await data.json();
        dispatch(receiveStripeData(newData.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureStripeData(JSON.parse(ex.message)));
    }
  };
};

const requestCareCostData = () => ({
  type: "CARE_COST_REQUEST",
});
const receiveCareCostData = (payload) => ({
  type: "CARE_COST_RECEIVE",
  payload,
});
const failureCareCostData = (payload) => ({
  type: "CARE_COST_FAIL",
  payload,
});

export const getCareCostAction = (token) => {
  return async (dispatch) => {
    dispatch(requestCareCostData());
    try {
      let data = await getCareCostData(token);
      if (data.ok) {
        let newData = await data.json();
        dispatch(receiveCareCostData(newData.value));
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureCareCostData(JSON.parse(ex.message)));
    }
  };
};

const requestProfileService = () => ({
  type: "GET_PROFILESERVICE_REQUEST",
});
const receiveProfileService = (payload) => ({
  type: "GET_PROFILESERVICE_RECEIVE",
  payload,
});
const failureProfileService = (payload) => ({
  type: "GET_PROFILESERVICE_FAIL",
  payload,
});

export const getProfileServiceAction = (token) => {
  return async (dispatch) => {
    dispatch(requestProfileService());
    try {
      let data = await getProfileService(token);
      if (data.ok) {
        let profileInfo = await data.json();
        dispatch(receiveProfileService(profileInfo.value));    
      } else {
        throw new Error(JSON.stringify({ code: data.status }));
      }
    } catch (ex) {
      dispatch(failureProfileService(JSON.parse(ex.message)));
    }
  };
};