import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/svg/radish-logo-green.svg";
import PersonalInfoIcon from "../../assets/img/svg/personal-information-icon.svg";
import HelpCenterIcon from "../../assets/img/svg/help-center-icon.svg";
import LogoutIcon from "../../assets/img/svg/logout-icon.svg";
// import SettingsIcon from "../../assets/img/svg/settings-icon.svg";
// import SupportIcon from "../../assets/img/svg/message-radish-support-icon.svg";

import { getUserProfileAction } from "../../redux/actions/profileAction";
import { unreadDocumentCountAction } from "../../redux/actions/dashboardActions";
//import CanAccess from "../helpers/CanAccess";
import { logoutUser } from "../../redux/actions/logoutUser";
// import IterateUserCredentials from "../../utils/IterateUserCredentials";

const Nav = ({ handleLogout, cookies }) => {
  const dispatch = useDispatch();
  const { token, firstName } = useSelector((state) => state.user);
  const userProfileInfo = useSelector((state) => state.userProfile);
  const { unreadDocumentsCount } = useSelector((state) => state.dashboard);
  const [openNav, setOpenNav] = useState(false);
  const accessLevel = useSelector((state) => state.user.features);
  const [openMenu, setOpenMenu] = useState(false);

  function handleClick() {
    setOpenNav(!openNav);
    setOpenMenu(false);
  }

  function handleMenuClick() {
    setOpenNav(false);
    setOpenMenu(!openMenu);
  }

  function handleMouseEnter() {
    if (openNav) setOpenNav(false);
    setOpenMenu(true);
  }

  function handleMouseLeave() {
    setOpenMenu(false);
  }

  useEffect(() => {
    if (userProfileInfo.is404Error === 401) {
      cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [userProfileInfo.is404Error, dispatch, cookies]);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileAction(token));
      dispatch(unreadDocumentCountAction(token));
    }
  }, [dispatch, token]);

  function isShow(moduleKey) {
    const selectModule = accessLevel.find(
      (next) => next.type === "MODULE" && next.key === moduleKey
    );
    if (selectModule.action === "HIDE") {
      return false;
    }
    return true;
  }

  return (
    <header className="header">
      {/* {userProfileInfo.userProfile && (
        <IterateUserCredentials
          iterateValues={{
            email: userProfileInfo.userProfile.email,
            patientID: userProfileInfo.userProfile.patID,
          }}
        />
      )} */}
      <div className="container">
        <div className="header-in">
          <div className="header-left">
            <div className="app-logo">
              <Link to="/dashboard">
                <img src={Logo} alt="radish-logo" />
              </Link>
            </div>
            <nav className={`nav-menu ${openNav && `nav-menu-show`}`}>
              <ul>
                <li>
                  <Link to="/dashboard" onClick={handleClick}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="documents" onClick={handleClick}>
                    Results{" "}
                    {unreadDocumentsCount > 0 && (
                      <span className="unread-document-count">
                        {unreadDocumentsCount}
                      </span>
                    )}
                  </Link>
                </li>
                {/* {/* <CanAccess moduleKey="MODULE-MEDICAL-SERVICE"> */}
                {isShow("MODULE-MEDICAL-SERVICE") ? (
                  <li>
                    <Link to="./appointments" onClick={handleClick}>
                      Appointments
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {/* </CanAccess>  */}
                {/* <li>
                  <a href="#">Explore</a>
                </li> */}
              </ul>
            </nav>
          </div>
          <div className="header-right">
            <div
              className="my-acc-header"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleMenuClick}
            >
              <div className="my-acc-header-top">
                <span className="my-acc-h-name">{`Hi ${firstName}`}</span>
                <figure>
                  {userProfileInfo.userProfile &&
                  userProfileInfo.userProfile.photo ? (
                    <img
                      src={userProfileInfo.userProfile.photo}
                      alt="avatar"
                      className="img-responsive avatar-img"
                    />
                  ) : (
                    <span className="avatar-text">
                      {firstName[0].toUpperCase()}
                    </span>
                  )}
                </figure>
              </div>

              <div className={`my-acc-menu ${openMenu && "my-acc-menu-show"}`}>
                <div className="my-acc-menu-in">
                  <ul>
                    <li>
                      <Link
                        to="./myprofile"
                        onClick={() => setOpenMenu(!openMenu)}
                      >
                        <figure>
                          <img
                            src={PersonalInfoIcon}
                            alt="personal-information"
                            className="img-responsive"
                          />
                        </figure>
                        <span>Personal Information</span>
                      </Link>
                    </li>
                    {/* <li>
                      <a href="#">
                        <figure>
                          <img
                            src={SettingsIcon}
                            alt="settings-icon"
                            className="img-responsive"
                          />
                        </figure>
                        <span>Settings</span>
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://help.radish.health"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <figure>
                          <img
                            src={HelpCenterIcon}
                            alt="help-center-icon"
                            className="img-responsive"
                          />
                        </figure>
                        <span>Help Center</span>
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <figure>
                          <img
                            src={SupportIcon}
                            alt="message-radish-support-icon"
                            className="img-responsive"
                          />
                        </figure>
                        <span>Message Radish Support</span>
                      </a>
                    </li> */}
                    <li>
                      <button className="unset-btn" onClick={handleLogout}>
                        <figure>
                          <img
                            src={LogoutIcon}
                            alt="logout-icon"
                            className="img-responsive"
                          />
                        </figure>
                        <span>Log Out</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <button
              className={`mobilemenu-icon unset-btn ${openNav && `open`}`}
              onClick={handleClick}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              {unreadDocumentsCount > 0 && (
                <div className="unread-document-dot"></div>
              )}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Nav;
