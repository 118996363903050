import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getFamilyMemberAction,
  SaveFamilyMemberAction,
  removeFamilyMemberAction,
  clearFamilyMemberError,
} from "../../../redux/actions/profileAction";
import {
  sidePopupClose,
  sidePopupConfirmed,
  sidePopupNotLoading,
  sidePopupLoading,
} from "../../../redux/actions/sidePopupActions";
import Spinner from "../Spinner";
import Alert from "../Alert";

const AddFamilyMemberPopup = () => {
  const [isSubmit, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [prompted, setPrompted] = useState(false);
  const userProfile = useSelector((state) => state.userProfile);

  const [values, setvalues] = useState(
    userProfile.familyForEdit ? userProfile.familyForEdit : {}
  );
  const [isAgreed, setIsAgreed] = useState(false);
  const { token } = useSelector((state) => state.user);
  const { content } = useSelector((state) => state.sidePopup);
  const labels = useSelector((state) => state.app.labels);

  const dispatch = useDispatch();

  function handleInputChange({ target }) {
    const { name, value } = target;
    setvalues({ ...values, [name]: value });
  }

  function onSaveClick(event) {
    event.preventDefault();
    setSubmitted(true);
    if (!checkFormError() && !emailError) {
      dispatch(sidePopupLoading());
      const { firstName, lastName, email, relation } = values;
      const payload = {
        firstName,
        lastName,
        email,
        relation,
        isSameInsurance: 0,
        dependentRegCodeId: userProfile.familyForEdit.dependentRegCodeId
          ? userProfile.familyForEdit.dependentRegCodeId
          : 0,
      };
      dispatch(SaveFamilyMemberAction(token, payload));
    }
  }

  useEffect(() => {
    if (userProfile && userProfile.familyMemberAdded) {
      dispatch(sidePopupConfirmed());
      setTimeout(() => {
        dispatch(getFamilyMemberAction(token));
        dispatch(sidePopupClose());
      }, 3000);
    }
  }, [dispatch, userProfile, token]);

  useEffect(() => {
    if (userProfile && userProfile.removeFamilyData) {
      dispatch(sidePopupConfirmed());
      setTimeout(() => {
        dispatch(getFamilyMemberAction(token));
        dispatch(sidePopupClose());
      }, 3000);
    }
  }, [dispatch, userProfile, token]);

  useEffect(() => {
    dispatch(clearFamilyMemberError());
  }, [dispatch]);

  useEffect(() => {
    if (userProfile.saveFamilityError) dispatch(sidePopupNotLoading());
  }, [dispatch, userProfile.saveFamilityError]);

  const onEmailBlur = () => {
    const { email } = values;
    var re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      setEmailError("Email is invalid");
      return;
    }
    setEmailError();
  };

  const checkFormError = () => {
    const { firstName, lastName, email, relation } = values;
    if (!isAgreed || !firstName || !lastName || !email || !relation) {
      return true;
    }
    return false;
  };

  function handleCheckbox() {
    setIsAgreed(!isAgreed);
  }

  function removeInvite() {
    dispatch(removeFamilyMemberAction(token, values.dependentRegCodeId));
  }

  return (
    <>
      {userProfile.familyMemberAdded ? (
        <div className="app-heading-1 mb-40">
          {content.isEdit
            ? labels.MSG_FAMILY_DETAIL_UPDATE
            : labels.MSG_FAMILY_DETAIL_SAVE}
        </div>
      ) : userProfile.removeFamilyData ? (
        <div className="app-heading-1 mb-40">
          {labels.MSG_FAMILY_DETAIL_REMOVE}
        </div>
      ) : (
        <>
          <h2 className="app-heading-1 mb-30">{content.title} FAMILY MEMBER</h2>
          <div className="popup-gen-info mb-20">
            <p>{labels.LBL_FAMILY_DETAIL_INFO}</p>
          </div>
          {userProfile.saveFamilityError && (
            <Alert
              messageTitle="ERROR:"
              message={userProfile.saveFamilityError}
              cssClassName={"alert alert-danger mt-20"}
            />
          )}

          <div className="popup-form-wrap">
            <div className="row g-3">
              <div className="col-6">
                <div className="form-group mb-15">
                  <label htmlFor="firstName">
                    {labels.LBL_FAMILY_DETAIL_FIRSTNAME}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="text"
                      maxLength="50"
                      className={`form-control form-control-lg ${
                        isSubmit && !values.firstName && "input-invalid"
                      }`}
                      onChange={handleInputChange}
                      name="firstName"
                      value={values.firstName}
                      id="firstName"
                    />
                    {isSubmit && !values.firstName && (
                      <span className="error-label">{"Required"}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-15">
                  <label htmlFor="lastName">
                    {labels.LBL_FAMILY_DETAIL_LASTNAME}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="text"
                      maxLength="50"
                      className={`form-control form-control-lg ${
                        isSubmit && !values.lastName && "input-invalid"
                      }`}
                      onChange={handleInputChange}
                      name="lastName"
                      value={values.lastName}
                      id="lastName"
                    />
                    {isSubmit && !values.lastName && (
                      <span className="error-label">{"Required"}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-3">
              <div className="col-12">
                <div className="form-group mb-15">
                  <label htmlFor="email">
                    {labels.LBL_FAMILY_DETAIL_EMAIL}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="email"
                      onBlur={onEmailBlur}
                      onChange={handleInputChange}
                      className={`form-control form-control-lg ${
                        ((values.email && emailError) ||
                          (isSubmit && !values.email)) &&
                        "input-invalid"
                      }`}
                      name="email"
                      value={values.email}
                      id="email"
                    />
                    {values.email && emailError && (
                      <span className="error-label">{"Email is invalid"}</span>
                    )}
                    {isSubmit && !values.email && (
                      <span className="error-label">{"Required"}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-3">
              <div className="col-12">
                <div className="form-group mb-25">
                  <label htmlFor="relationship">
                    {labels.LBL_FAMILY_DETAIL_RELATIONSHIP}
                  </label>
                  <div className="inputwrap">
                    <div className="row g-0 r-radio-group">
                      <div className="col-6 r-radio-group-item">
                        <input
                          type="radio"
                          className="btn-check"
                          name="relation"
                          id="option1"
                          onChange={handleInputChange}
                          autoComplete="off"
                          checked={values.relation === "Spouse"}
                          value="Spouse"
                        />
                        <label
                          className="btn btn-primary col-12 mb-0"
                          htmlFor="option1"
                        >
                          {labels.LBL_FAMILY_DETAIL_OPTION1}
                        </label>
                      </div>
                      <div className="col-6 r-radio-group-item">
                        <input
                          type="radio"
                          className="btn-check"
                          name="relation"
                          id="option2"
                          checked={values.relation === "Child"}
                          onChange={handleInputChange}
                          value="Child"
                          autoComplete="off"
                        />
                        <label
                          className="btn btn-primary col-12 mb-0"
                          htmlFor="option2"
                        >
                          {labels.LBL_FAMILY_DETAIL_OPTION2}
                        </label>
                      </div>
                    </div>
                    {isSubmit && !values.relation && (
                      <span className="error-label">Required</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-3">
              <div className="col-12">
                <div className="form-check mb-25">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="agree"
                    onChange={handleCheckbox}
                    value={isAgreed}
                    id="i-consent"
                  />
                  <label className="form-check-label" htmlFor="i-consent">
                    {labels.LBL_FAMILY_DETAIL_ICONSENT}
                  </label>
                </div>
              </div>
            </div>

            <button
              disabled={
                userProfile.isSaveFamilyLoading || prompted || !isAgreed
              }
              onClick={onSaveClick}
              className="btn btn-primary btn-lg col-12 mb-20"
            >
              {userProfile.isSaveFamilyLoading ? (
                <Spinner />
              ) : content.isEdit ? (
                labels.LBL_FAMILY_DETAIL_RESEND_INVITE
              ) : (
                labels.LBL_FAMILY_DETAIL_INVITE
              )}
            </button>

            {content.isEdit && !prompted ? (
              <button
                type="button"
                className={`btn btn-primary col-12 btn-lg btn-cancel ${
                  0 && "loading-cancel"
                }`}
                onClick={() => setPrompted(true)}
              >
                {0 ? <Spinner /> : labels.LBL_FAMILY_DETAIL_REMOVE_INVITE}
              </button>
            ) : (
              ""
            )}

            {prompted && (
              <div className="div-cancel-prompt">
                <p>{labels.MSG_FAMILY_DETAIL_REMOVE_INVITE}</p>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-cancel"
                    onClick={removeInvite}
                  >
                    {userProfile.isRemovingFamilyLoading ? <Spinner /> : "Yes"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-cancel"
                    onClick={() => setPrompted(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AddFamilyMemberPopup;
