import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { DOCUMENT_REDIRECT_URL } from "../../utils/Environment";

const DailyWorkPass = ({ dailyWorkPassInfo = {} }) => {
  const { token } = useSelector((state) => state.user);
  const [isSubmit, setSubmit] = useState(false);
  const myInputRef = useRef(null);

  const openDocument = () => {
    setSubmit(true);
    myInputRef.current.submit();
  };
  return (
    <form
      ref={myInputRef}
      name={`myform${dailyWorkPassInfo.userUID}`}
      method="post"
      action={`${DOCUMENT_REDIRECT_URL}?app=web&redirectURL=${encodeURIComponent(
        dailyWorkPassInfo.link
      )}`}
    >
      <input type="hidden" name="token" value={token}></input>
      <div
        className={`linear-cta-wrap ${
          dailyWorkPassInfo.internalStatusCode === "1" &&
          "linear-cta-wrap-success"
        }`}
      >
        {dailyWorkPassInfo && (
          <button onClick={openDocument} className="linear-cta unset-btn">
            <span className="dha-to-do-msg">
              {" "}
              {dailyWorkPassInfo.internalStatusCode === "2"
                ? "Take your Daily Health Assessment"
                : `${dailyWorkPassInfo.publicStatus}`}
            </span>
            {isSubmit ? (
              <span
                className="spinner-border spinner-workpass spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <span className="to-do-arrow"></span>
            )}
          </button>
        )}
      </div>
    </form>
  );
};

export default DailyWorkPass;
