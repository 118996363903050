import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SaveUserProfilePhotoAction } from "../../redux/actions/profileAction";
import { getUserProfileAction } from "../../redux/actions/profileAction";
import Alert from "../common/Alert";

const ProfilePhoto = ({ profilePhoto, firstName = "", lastName = "" }) => {
  const dispatch = useDispatch();
  const photo = profilePhoto;
  const userInfo = useSelector((state) => state.user);

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type.includes("image")) {
        let base64Image = await getBase64(event.target.files[0]);
        let base64ImageObj = {
          photo: base64Image.split(",")[1],
        };
        await dispatch(
          SaveUserProfilePhotoAction(userInfo.token, base64ImageObj)
        );
        await dispatch(getUserProfileAction(userInfo.token));
      } else {
        dispatch({ type: "INVALID_USERPROFILEPHOTO" });
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div className="profile-img">
      <figure>
        {photo ? (
          <img src={photo} alt="" className="img-responsive" />
        ) : (
          firstName.length > 0 &&
          lastName.length > 0 && (
            <span className="img-responsive avatar-text">
              {firstName.charAt(0)}
              {lastName.charAt(0)}
            </span>
          )
        )}
      </figure>
      <label htmlFor="file-upload" className="edit-profile-avatar-cta"></label>
      <input
        type="file"
        accept="image/*"
        style={{ visibility: "hidden" }}
        onChange={onImageChange}
        id="file-upload"
      />
    </div>
  );
};
export default ProfilePhoto;
