import parse from "html-react-parser";

const DoctorsBioModal = ({ content }) => {
  let { modalData } = content;
  return (
    <>
      <div className="avatar-info avatar-info-2 mb-25">
        <div className="avatar-info-in">
          <figure>
            <img
              src={modalData.img}
              alt={`${modalData.DrName}`}
              className="img-responsive"
            />
          </figure>
          <div className="ai-meta">
            <span className="ai-title">{modalData.DrName}</span>
            <span className="ai-position font-regular">{modalData.title}</span>
          </div>
        </div>
      </div>

      <div className="center-popup-data">
        <p>{parse(modalData.data)}</p>
      </div>
    </>
  );
};

export default DoctorsBioModal;
