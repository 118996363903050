const documentsState = {
  userDocuments: [],
  documentsTypes: [],
  vaccineTypes: [],
  vaccineManufacturers: [],
  labTypes: [],
  ddhLabStatusInfo: {
    url: "",
    token: "",
    isLabStatusAvailable: 0,
    ddhStatus: "",
  },
};

export const documentsReducer = (state = documentsState, action) => {
  if (action.type === "DOCUMENTS_LIST_REQUEST") {
    return {
      ...state,
      isDocumentsListLoading: true,
      isDocumentsError: false,
    };
  }

  if (action.type === "DOCUMENTS_LIST_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isDocumentsListLoading: false,
      isDocumentsError: false,
      userDocuments: [...payload],
    };
  }

  if (action.type === "DOCUMENTS_LIST_FAIL") {
    return {
      ...state,
      isDocumentsListLoading: false,
      isDocumentsError: true,
    };
  }

  if (action.type === "DOCUMENTS_TYPES_REQUEST") {
    return {
      ...state,
      isDocumentsTypesLoading: true,
      isDocumentsError: false,
    };
  }

  if (action.type === "DOCUMENTS_TYPES_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isDocumentsTypesLoading: false,
      isDocumentsError: false,
      documentsTypes: [...payload],
    };
  }

  if (action.type === "DOCUMENTS_TYPES_FAIL") {
    return {
      ...state,
      isDocumentsTypesLoading: false,
      isDocumentsError: true,
    };
  }

  if (action.type === "VACCINE_TYPES_REQUEST") {
    return {
      ...state,
      isVaccineTypesLoading: true,
      isDocumentsError: false,
    };
  }

  if (action.type === "VACCINE_TYPES_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isVaccineTypesLoading: false,
      isDocumentsError: false,
      vaccineTypes: [...payload],
    };
  }

  if (action.type === "VACCINE_TYPES_FAIL") {
    return {
      ...state,
      isVaccineTypesLoading: false,
      isDocumentsError: true,
    };
  }

  if (action.type === "VACCINE_MANUFACTURERS_REQUEST") {
    return {
      ...state,
      isVaccineManufacturerLoading: true,
      isDocumentsError: false,
    };
  }

  if (action.type === "VACCINE_MANUFACTURERS_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isVaccineManufacturerLoading: false,
      isDocumentsError: false,
      vaccineManufacturers: [...payload],
    };
  }

  if (action.type === "VACCINE_MANUFACTURERS_FAIL") {
    return {
      ...state,
      isVaccineManufacturerLoading: false,
      isDocumentsError: true,
    };
  }

  if (action.type === "LAB_TYPES_REQUEST") {
    return {
      ...state,
      isLabTypesLoading: true,
      isDocumentsError: false,
    };
  }

  if (action.type === "LAB_TYPES_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLabTypesLoading: false,
      isDocumentsError: false,
      labTypes: [...payload],
    };
  }

  if (action.type === "LAB_TYPES_FAIL") {
    return {
      ...state,
      isLabTypesLoading: false,
      isDocumentsError: true,
    };
  }

  if (action.type === "LAB_RESULTS_REQUEST") {
    return {
      ...state,
      isLabResultsInfoLoading: true,
      isDocumentsError: false,
    };
  }

  if (action.type === "LAB_RESULTS_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isLabResultsInfoLoading: false,
      isDocumentsError: false,
      labResultsInfo: payload,
    };
  }

  if (action.type === "LAB_RESULTS_FAIL") {
    return {
      ...state,
      isLabResultsInfoLoading: false,
      isDocumentsError: true,
    };
  }

  if (action.type === "DOCUMENTS_UPLOAD_FAILED") {
    const { payload } = action;
    return {
      ...state,
      isDocumentsUploadError: true,
      documentsErrorMessage: payload,
    };
  }

  if (action.type === "DOCUMENTS_UPLOAD_FAILED_CLEAR") {
    return {
      ...state,
      isDocumentsUploadError: false,
      documentsErrorMessage: null,
    };
  }

  if (action.type === "DDH_LABSTATUS_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isDDHLabStatusLoading: false,
      isDDHLabStatusError: true,
      is404Error: payload.code,
    };
  }

  if (action.type === "DDH_LABSTATUS_REQUEST") {
    return {
      ...state,
      isDDHLabStatusLoading: true,
      isDDHLabStatusError: false,
    };
  }

  if (action.type === "DDH_LABSTATUS_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isDDHLabStatusLoading: false,
      isDDHLabStatusError: false,
      ddhLabStatusInfo: payload,
    };
  }

  if (action.type === "UPDATE_ELABORATE_URL") {
    const { payload } = action;
    return {
      ...state,
      doc_iframe_url: payload,
    };
  }
  
  if (action.type === "LOGOUTUSER") {
    return {
      is404Error: "",
      userDocuments: [],
      documentsTypes: [],
      vaccineTypes: [],
      vaccineManufacturers: [],
      labTypes: [],
      ddhLabStatusInfo: {
        url: "",
        token: "",
        isLabStatusAvailable: 0,
        ddhStatus: "",
      },
    };
  }

  return state;
};
