import useCollapse from "react-collapsed";
import navigateToRoute from "../../utils/InternalRouting";
import { useNavigate } from "react-router-dom";

const MyToDo = ({ toDoInfo = {}, labels = {} }) => {
  const navigate = useNavigate();

  function myTodoClick(e, todo) {
    e.preventDefault();
    let parameter = {
      ...todo.webDetails,
    };
    navigateToRoute(todo.link, todo.linkType, navigate, { param: parameter });
  }

  return (
    <>
      {toDoInfo.myToDoResponses && toDoInfo.myToDoResponses.length === 1 ? (
        <SingleToDo
          toDoInfo={toDoInfo}
          myTodoClick={myTodoClick}
          labels={labels}
        />
      ) : (
        <ExpandCollapseToDo
          toDoInfo={toDoInfo}
          myTodoClick={myTodoClick}
          labels={labels}
        />
      )}
    </>
  );
};

export const SingleToDo = ({ toDoInfo, myTodoClick }) => {
  return (
    <>
      {toDoInfo.myToDoResponses.map((toDo, index) => (
        <div className="collapsible">
          <div className="linear-cta-wrap-inner" key={index}>
            <button
              onClick={(e) => myTodoClick(e, toDo)}
              className="linear-cta unset-btn"
            >
              <span className="to-do-msg">{toDo.webHeader}</span>
              <span className="to-do-arrow"></span>
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export const ExpandCollapseToDo = ({ toDoInfo, myTodoClick, labels }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <>
      {toDoInfo.myToDoResponses && toDoInfo.myToDoResponses.length > 0 ? (
        <div className="collapsible">
          <div className="unset-btn" {...getToggleProps()}>
            <div className="linear-cta-wrap-to-do">
              <button className="unset-btn" onClick={(e) => e.preventDefault()}>
                <span className="a-before"></span>
                <span>{labels.LBL_TODO_TASK}</span>
                {isExpanded ? (
                  <span className="after-expand to-do-number"></span>
                ) : (
                  <span className="to-do-number">
                    {toDoInfo.myToDoResponses.length}
                  </span>
                )}
              </button>
            </div>
          </div>
          <div {...getCollapseProps()}>
            {toDoInfo.myToDoResponses.map((toDo, index) => (
              <div className="linear-cta-wrap-inner" key={index}>
                <button
                  onClick={(e) => myTodoClick(e, toDo)}
                  className="linear-cta unset-btn"
                >
                  <span className="to-do-msg">{toDo.webHeader}</span>
                  <span className="to-do-arrow"></span>
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default MyToDo;
