import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../common/Spinner";
import {
  SaveInsuranceProviderAction,
  getInsuranceProviderAction,
} from "../../../redux/actions/insuranceProviderAction";
import { getUserProfileAction} from "../../../redux/actions/profileAction";
import {
  sidePopupLoading,
  sidePopupConfirmed,
  sidePopupClose,
} from "../../../redux/actions/sidePopupActions.js";
import InsuranceFrontPhoto from "../../profile/InsuranceFrontPhoto";
import InsuranceBackPhoto from "../../profile/InsuranceBackPhoto";
import Alert from "../../common/Alert.js";

const AddInsuranceProvider = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const insuranceInfo = useSelector((state) => state.insuranceInfo);
  const userProfileInfo = useSelector((state) => state.userProfile);
  const [values, setValues] = useState(userProfileInfo.userProfile);

  // const [errorObj, setError] = useState({});
  const [isSubmit, setSubmitted] = useState(false);
  const labels = useSelector((state) => state.app.labels);

  function handleInputChange({ target }) {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  }

  const handleCheckbox = () => {
    if (values.isSubscriberThePatient) {
      setValues({
        ...values,
        isSubscriberThePatient: 0,
      });
    } else {
      setValues({
        ...values,
        isSubscriberThePatient: 1,
      });
    }
  };

  const checkFormError = () => {
    const { insuranceCompany, insuranceIdNumber } = values;

    if (!insuranceCompany || !insuranceIdNumber) {
      return true;
    }
    return false;
  };

  const saveInsuranceProvider = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (!checkFormError()) {
      dispatch(sidePopupLoading());
      delete values.photoBack;
      delete values.photoFront;
      await dispatch(SaveInsuranceProviderAction(userInfo.token, values));
      dispatch(sidePopupConfirmed());
      setTimeout(() => {
        dispatch(sidePopupClose());
      }, 3000);
      //await dispatch(getInsuranceProviderAction(userInfo.token));
      dispatch(getUserProfileAction(userInfo.token));
    }
  };

  useEffect(() => {
    setValues(userProfileInfo.userProfile);

    setSubmitted(false);
  }, [userProfileInfo.userProfile]);

  return (
    <>
      {insuranceInfo.saveInsuranceProvider ? (
        <div className="app-heading-1 mb-40">{labels.MSG_INSURANCE_UPDATE}</div>
      ) : (
        <>
          <h2 className="app-heading-1 mb-40">{labels.LBL_INSURANCE_HEADER}</h2>
          <div className="upload-insurance-top mb-25">
            <div className="uit-row">
              <InsuranceFrontPhoto
                frontPhoto={userProfileInfo.userProfile.photoFront}
              />
              <InsuranceBackPhoto
                backPhoto={userProfileInfo.userProfile.photoBack}
              />
            </div>
          </div>
          {insuranceInfo.isInvalidCardPhoto && (
            <Alert
              messageTitle="ERROR:"
              message="Invalid file format"
              cssClassName={"alert alert-danger mt-20"}
            />
          )}
          <div className="popup-mandatory-note">Required fields*</div>
          <div className="popup-form-wrap">
            <div className="row g-3">
              <div className="col-12">
                <div className="form-group mb-15">
                  <label htmlFor="insuranceCompany">
                    {labels.LBL_INSURANCE_COMPANY}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className={`form-control form-control-lg ${
                        isSubmit && !values.insuranceCompany && "input-invalid"
                      }`}
                      name="insuranceCompany"
                      id="insuranceCompany"
                      maxLength={50}
                      onChange={handleInputChange}
                      value={values.insuranceCompany}
                    />
                    {isSubmit && !values.insuranceCompany && (
                      <span className="error-label">{labels.MSG_REQ}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12">
                <div className="form-group mb-15">
                  <label htmlFor="insuranceIdNumber">
                    {labels.LBL_INSURANCE_ID_NUMBER}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className={`form-control form-control-lg ${
                        isSubmit && !values.insuranceIdNumber && "input-invalid"
                      }`}
                      name="insuranceIdNumber"
                      id="insuranceIdNumber"
                      maxLength={50}
                      onChange={handleInputChange}
                      value={values.insuranceIdNumber}
                    />
                    {isSubmit && !values.insuranceIdNumber && (
                      <span className="error-label">{labels.MSG_REQ}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12">
                <div className="form-group mb-15">
                  <label htmlFor="insuranceGroupName">
                    {labels.LBL_INSURANCE_GROUP_NAME}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name="insuranceGroupName"
                      id="insuranceGroupName"
                      maxLength={50}
                      onChange={handleInputChange}
                      value={values.insuranceGroupName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12">
                <div className="form-group mb-15">
                  <label htmlFor="insuranceGroupNumber">
                    {labels.LBL_INSURANCE_GROUP_NUMBER}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name="insuranceGroupNumber"
                      id="insuranceGroupNumber"
                      maxLength={50}
                      onChange={handleInputChange}
                      value={values.insuranceGroupNumber}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12">
                <div className="form-group mb-15">
                  <label htmlFor="insurancePlanName">
                    {labels.LBL_INSURANCE_PLAN_NAME}
                  </label>
                  <div className="inputwrap">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name="insurancePlanName"
                      id="insurancePlanName"
                      maxLength={50}
                      onChange={handleInputChange}
                      value={values.insurancePlanName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3">
              <div className="col-12">
                <div className="form-group mb-15">
                  <div className="inputwrap insurance-checkbox">
                    <label className="check-label">
                      <input
                        type="checkbox"
                        className="form-check-input set-pass-agree"
                        name="subscriber-is-patient"
                        id="subscriber-is-patient"
                        maxLength={50}
                        onChange={handleCheckbox}
                        checked={values.isSubscriberThePatient}
                      />
                      <div>{labels.LBL_SUBSCRIBER_IS_PATIENT}</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-lg col-12"
              disabled={insuranceInfo.isSaveInsuranceProviderLoading}
              onClick={saveInsuranceProvider}
            >
              {insuranceInfo.isSaveInsuranceProviderLoading ? (
                <Spinner />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AddInsuranceProvider;
