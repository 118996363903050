import { useEffect, useRef } from "react";
import parse from "html-react-parser";

const Alert = ({ message, messageTitle, cssClassName, clearError }) => {
  const alertRef = useRef();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [alertRef]);

  return (
    <div ref={alertRef} className={cssClassName} role="alert">
      {clearError && (
        <p className="error-dismiss" onClick={clearError}>
          X
        </p>
      )}
      <strong>{messageTitle}</strong> {parse(message)}
    </div>
  );
};

export default Alert;
