import { useDispatch, useSelector } from "react-redux";
import { sidePopupOpen } from "../../redux/actions/sidePopupActions.js";
import moment from "moment";
import ProfilePhoto from "../profile/ProfilePhoto";
import Spinner from "../common/Spinner.js";
import Alert from "../common/Alert.js";

const PersonalInformation = ({ userProfile = {}, labels = {} }) => {
  const dispatch = useDispatch();
  const userProfileInfo = useSelector((state) => state.userProfile);
  const {
    isDependent,
    features: accessLevel,
    company,
  } = useSelector((state) => state.user);

  const openSideBarProfile = () => {
    const payload = {
      component: 5,
      content: userProfile,
    };
    dispatch(sidePopupOpen(payload));
    dispatch({ type: "SAVE_USERPROFILE_CLEAR" });
  };

  const clearError = () => dispatch({ type: "SAVE_USERPROFILE_CLEAR" });

  function openSidePopup() {
    const payload = {
      component: 8,
      content: {
        title: "ADD A",
        isEdit: false,
      },
    };
    dispatch(sidePopupOpen(payload));
  }

  function isShow(moduleKey) {
    const selectModule = accessLevel.find(
      (next) => next.type === "MODULE" && next.key === moduleKey
    );
    if (
      (selectModule.action === "ENABLE" || selectModule.action === "SHOW") &&
      !isDependent
    ) {
      return true;
    }
    return false;
  }

  return (
    <div className="col-12 col-xl-8 mb-40">
      <h2 className="app-heading-1">{labels.LBL_PERSONAL_INFORMATION}</h2>
      {userProfileInfo.isInvalidProfilePhoto && (
        <Alert
          message="Invalid file format"
          cssClassName={"alert alert-danger mt-20"}
          clearError={clearError}
        />
      )}
      <div className="profile-card">
        <button
          className="edit-profile-cta unset-btn"
          onClick={openSideBarProfile}
        >
          {labels.LBL_EDIT}
        </button>
        <div className="profile-card-top">
          {userProfileInfo.isSaveUserProfilePhotoLoading ? (
            <Spinner />
          ) : (
            <ProfilePhoto
              profilePhoto={userProfile.photo}
              firstName={userProfile.firstName}
              lastName={userProfile.lastName}
            />
          )}
          <div className="profile-info">
            <h3>
              {userProfile.firstName} {userProfile.lastName}{" "}
            </h3>
            {userProfileInfo.isUserProfileLoading ? (
              <Spinner />
            ) : (
              <div className="profile-info-inner">
                <div className="pii-left">
                  <p>
                    {userProfile.email}
                    <br />
                    {company}
                    <br />
                    {userProfile.phoneNumber}
                    <br />
                    DOB: {moment(userProfile.dob).format("MMM Do YYYY")}
                    <br />
                    Sex: {userProfile.gender}
                    <br />
                    My Rad-ID: {userProfile.patID}
                  </p>
                </div>
                <div className="pii-right">
                  <p>
                    {userProfile.street}
                    <br />{" "}
                    {userProfile.street2 ? (
                      <>
                        {userProfile.street2} <br />
                      </>
                    ) : null}{" "}
                    {userProfile.city}, {userProfile.state} <br />
                    {userProfile.zip}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {isShow("DEPENDENT") ? (
          <div className="profile-card-bottom">
            <button className="link-blue unset-btn" onClick={openSidePopup}>
              {labels.LBL_ADD_FAMILY}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default PersonalInformation;
