import { Helmet } from "react-helmet-async";

const renderPageTitle = (location, sliders) => {
  const pathname = location.pathname;
  let pageTitle;
  if (pathname.includes("explore/") && sliders.length) {
    let sliderId = pathname.slice(
      pathname.lastIndexOf("/") + 1,
      pathname.length
    );
    for (let i = 0; i < sliders.length; i++) {
      if (sliders[i].sliderId.toString() === sliderId) {
        pageTitle = ` - ${sliders[i].title}`;
        break;
      }
    }
  } else {
    switch (pathname.toLowerCase()) {
      case "/login":
        pageTitle = " - Login";
        break;
      case "/forgot-password":
        pageTitle = " - Forgot Password";
        break;
      case "/retrieve-registration":
        pageTitle = " - Register";
        break;
      case "/register":
        pageTitle = " - Register";
        break;
      case "/otp":
        pageTitle = " - Register";
        break;
      case "/policies/npp":
        pageTitle = " - Privacy Practices";
        break;
      case "/policies/pp":
        pageTitle = " - Privacy Policy";
        break;
      case "/policies/consent":
        pageTitle = " - Consent";
        break;
      case "/policies/tou":
        pageTitle = " - Terms of Use";
        break;
      case "/dashboard":
        pageTitle = " - Dashboard";
        break;
      case "/dashboard/bookappointment":
        pageTitle = " - Book Appointment";
        break;
      case "/dashboard/appointments":
        pageTitle = " - Appointments";
        break;
      case "/dashboard/your-care-team":
        pageTitle = " - Care Team";
        break;
      case "/dashboard/documents":
        pageTitle = " - Documents";
        break;
      case "/dashboard/myprofile":
        pageTitle = " - Profile";
        break;
      case "/dashboard/bookworkplace":
        pageTitle = " - Workplace Appointment";
        break;
      case "/dashboard/dha-result":
        pageTitle = " - Daily Health Assessment";
        break;
      default:
        pageTitle = "";
    }
  }
  return (
    <Helmet>
      <title>Radish Health{pageTitle}</title>
    </Helmet>
  );
};

export default renderPageTitle;
