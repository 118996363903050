import {
  API_URL,
  LANG,
  GET_IP_URL,
  INTERNAL_URL,
} from "../utils/Environment.js";
import {
  LOCALIZATION_API,
  LOGINAPI,
  FORGOTPASSWORD_API,
  REGISTER_API,
  SLIDER_API,
  SLIDER_API_LIST,
  GETAPPOINTMENT_API,
  SELF_REGISTRATION,
  GETPASTAPPOINTMENT_API,
  GETPASTAPPOINTMENTSUMMARY_API,
  GETAPPOINTMENTTYPE_API,
  GETSERVICES_API,
  OTP_AUTH,
  DOCUMENTS_LIST_API,
  DOCUMENTS_LAB_RESULTS_INFO,
  DOCUMENTS_TYPES_API,
  DOCUMENTS_VACCINE_TYPES_API,
  DOCUMENTS_VACCINE_MANUFACTURER_API,
  DOCUMENTS_LAB_TYPES_API,
  DOCUMENTS_UNREAD_COUNT,
  GET_SELF_DATA,
  RESET_PASSWORD,
  GETAPPOINTMENT_SLOT_API,
  VERIFY_RESET_PASSWORD,
  POSTAPPOINTMENT_BOOK_API,
  GETUSERPROFILE,
  SAVEUSERPROFILE,
  GETINSURANCEPROVIDER,
  SAVEINSURANCEPROVIDER,
  CANCEL_APPOINTMENT_API,
  GETMYTODO,
  GETWORKPASS,
  GETDOCTORSLIST,
  GETDOCTORSSPECIALITY,
  UPDATECARETEAM,
  CHANGEPASSWORD,
  ADDFAMILY,
  GETFAMILY,
  REMOVEINVITE,
  DHASTATUS,
  DHAWEBVIEW,
  DRIP_DROP_LAB_STATUS,
  HEALTHCHECK_LIST,
  HEALTHCHECK,
  MEDICAL_CONDITION_SEARCH,
  MEDICATION_SEARCH,
  USER_MEDICAL_HISTORY,
  USER_MEDICAL_HISTORY_VERIFIED,
  USER_MEDICAL_CONDITIONS,
  USER_MEDICATIONS,
  USER_CONSENT,
  POSTUPDATESUMMARYNOTESREAD,
  POSTEVENTTRACK,
  GETPHARMACY,
  SAVEPHARMACY,
  STRIPEAPI, CARECOSTAPI, PROFILESERVICEAPI
} from "./endpointConstant";
import axios from "axios";

let myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const GETrequestOptions = {
  method: "GET",
  headers: myHeaders,
};

const POSTrequestOptions = {
  method: "POST",
  headers: myHeaders,
};

export const getlabels = () => {
  const api = `${API_URL}${LOCALIZATION_API}?language=${LANG}`;
  return fetch(api, GETrequestOptions);
};

export const postLogin = async (payload, IPURL) => {
  let ip = "";
  try {
    ip = await axios.get(IPURL);
  } catch (ex) {
    ip = null;
  }
  const loginPayload = {
    ...payload,
    userIPAddress: ip && ip.data.ip ? ip.data.ip : "",
  };
  const api = `${API_URL}${LOGINAPI}`;
  return fetch(api, {
    ...POSTrequestOptions,
    body: JSON.stringify(loginPayload),
  });
};

export const postForgotPassword = (payload) => {
  const encodePayload = encodeURIComponent(payload);
  const api = `${API_URL}${FORGOTPASSWORD_API}?email=${encodePayload}`;
  return fetch(api, { ...POSTrequestOptions });
};

export const getSliders = (token) => {
  const options = {
    method: "get",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const api = `${API_URL}${SLIDER_API}`;
  return fetch(api, options);
};

export const getSlidersList = (token, payload) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: token },
    body: JSON.stringify(payload),
  };
  const api = `${API_URL}${SLIDER_API_LIST}`;
  return fetch(api, options);
};

export const postRegisterUser = (payload) => {
  const api = `${API_URL}${REGISTER_API}`;
  return fetch(api, { ...POSTrequestOptions, body: payload });
};

export const postSelfRegisterUser = (payload) => {
  const encodePayload = encodeURIComponent(payload);
  const api = `${API_URL}${SELF_REGISTRATION}?emailormobile=${encodePayload}`;
  return fetch(api, { ...POSTrequestOptions });
};

export const postOtpAuth = (payload) => {
  const api = `${API_URL}${OTP_AUTH}`;
  return fetch(api, { ...POSTrequestOptions, body: payload });
};

export const getRegData = (payload) => {
  const api = `${API_URL}${GET_SELF_DATA}${payload}`;
  return fetch(api, { GETrequestOptions });
};

export const postResetPass = (payload) => {
  const api = `${API_URL}${RESET_PASSWORD}`;
  return fetch(api, { ...POSTrequestOptions, body: payload });
};

export const postVerifyResetPass = (payload) => {
  const api = `${API_URL}${VERIFY_RESET_PASSWORD}`;
  return fetch(api, { ...POSTrequestOptions, body: payload });
};
export const getTopPastAppointment = (payload, token) => {
  const options = {
    method: "get",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const api = `${API_URL}${GETPASTAPPOINTMENTSUMMARY_API}`;
  return fetch(api, options);
};

export const postSummeryNoteReadApi = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${POSTUPDATESUMMARYNOTESREAD}?appointmentID=${payload}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
  });
};

export const getTopUpcomingAppointment = (payload, token) => {
  const options = {
    method: "get",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const api = `${API_URL}${GETAPPOINTMENT_API}?TopOffset=${payload}`;
  return fetch(api, options);
};

export const getAllUpcomingAppointments = (token) => {
  const options = {
    method: "get",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const api = `${API_URL}${GETAPPOINTMENT_API}`;
  return fetch(api, options);
};

export const getAllPastAppointments = (token) => {
  const options = {
    method: "get",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const api = `${API_URL}${GETPASTAPPOINTMENT_API}`;
  return fetch(api, options);
};

export const getBookAppointmentType = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETAPPOINTMENTTYPE_API}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: payload,
  });
};

export const bookAppointmentSlot = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${POSTAPPOINTMENT_BOOK_API}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: payload,
  });
};

export const getServices = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETSERVICES_API}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getDocumentsList = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DOCUMENTS_LIST_API}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getLabResultsInfo = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DOCUMENTS_LAB_RESULTS_INFO}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getDocumentsListSorted = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DOCUMENTS_LIST_API}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const getDocumentsTypes = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DOCUMENTS_TYPES_API}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getDocumentsUnreadCount = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DOCUMENTS_UNREAD_COUNT}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getVaccineTypes = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DOCUMENTS_VACCINE_TYPES_API}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getVaccineManufacturers = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DOCUMENTS_VACCINE_MANUFACTURER_API}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getLabTypes = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DOCUMENTS_LAB_TYPES_API}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getAppointmentSlots = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETAPPOINTMENT_SLOT_API}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const uploadDocumentOther = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  //const api = `${API_URL}${DOCUMENTS_UPLOAD_OTHER}`;
  //RD-1007: #temp solution
  const api = `${INTERNAL_URL.UPLOAD_OTHER}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const uploadDocumentLab = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  //const api = `${API_URL}${DOCUMENTS_UPLOAD_LAB}`;
  //RD-1007: #temp solution
  const api = `${INTERNAL_URL.UPLOAD_LAB}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const uploadDocumentVaccine = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  //const api = `${API_URL}${DOCUMENTS_UPLOAD_VACCINE}`;
  //RD-1007: #temp solution
  const api = `${INTERNAL_URL.UPLOAD_VACCINE}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const getUserProfile = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETUSERPROFILE}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const SaveUserProfile = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${SAVEUSERPROFILE}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const saveFamilyMember = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${ADDFAMILY}`;
  return fetch(api, {
    method: payload.dependentRegCodeId === 0 ? "POST" : "PATCH",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const getFamilyMember = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETFAMILY}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getUserPharmacy = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETPHARMACY}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const saveUserPharmacy = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${SAVEPHARMACY}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const SaveUserProfilePhoto = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  //const api = `${API_URL}${SAVEUSERPHOTO}`;
  //RD-1007: #temp solution
  const api = `${INTERNAL_URL.UPLOAD_PROFILE_IMAGE}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const cancelAppointment = (token, appointmentID) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${CANCEL_APPOINTMENT_API}?appointmentID=${appointmentID}`;
  return fetch(api, {
    method: "PATCH",
    headers: myHeader,
  });
};

export const getInsuranceProviderDetails = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETINSURANCEPROVIDER}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const SaveInsuranceProvider = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${SAVEINSURANCEPROVIDER}`;
  return fetch(api, {
    method: "PATCH",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const SaveInsuranceCardDetails = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  //const api = `${API_URL}${SAVECARDDETAILS}`;
  //RD-1007: #temp solution
  const api = `${INTERNAL_URL.UPLOAD_INSURANCE_IMAGE}`;
  return fetch(api, {
    method: "PATCH",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const getMyTodo = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETMYTODO}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getDailyUserWorkPass = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETWORKPASS}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getDoctorsList = (token, state) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETDOCTORSLIST}?stateCode=${state}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getDoctorsSpeciality = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${GETDOCTORSSPECIALITY}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const updateCareTeam = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${UPDATECARETEAM}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const changePassword = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${CHANGEPASSWORD}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const removeFamilyInvite = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${REMOVEINVITE}${payload}`;
  return fetch(api, {
    method: "DELETE",
    headers: myHeader,
  });
};

export const getDHAStatus = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${DHASTATUS}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getDHAWebview = (userUID) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  const api = `${API_URL}${DHAWEBVIEW}?userUID=${userUID}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getDDHLabStatus = (token) => {
  const options = {
    method: "get",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const api = `${API_URL}${DRIP_DROP_LAB_STATUS}`;
  return fetch(api, options);
};

export const getHealthCheckList = (token) => {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const api = `${API_URL}${HEALTHCHECK_LIST}`;
  return fetch(api, options);
};

export const postHealthCheckList = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${HEALTHCHECK}`;
  return fetch(api, {
    method: "PATCH",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const getMedicalCondition = (payload) => {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const api = `${MEDICAL_CONDITION_SEARCH}${payload}`;
  return fetch(api, options);
};

export const getMedication = (payload) => {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const api = `${MEDICATION_SEARCH}${payload}`;
  return fetch(api, options);
};

export const getUserMedicalHistory = (token) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const api = `${API_URL}${USER_MEDICAL_HISTORY}`;
  return fetch(api, options);
};

export const getUserMedicalHistoryVerified = (token) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const api = `${API_URL}${USER_MEDICAL_HISTORY_VERIFIED}`;
  return fetch(api, options);
};

export const postUserMedicalConditions = async (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${USER_MEDICAL_CONDITIONS}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const postUserMedications = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${USER_MEDICATIONS}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify(payload),
  });
};

export const deleteUserCondition = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${USER_MEDICAL_CONDITIONS}?medicalConditionId=${payload}`;
  return fetch(api, {
    method: "DELETE",
    headers: myHeader,
  });
};

export const deleteUserMedication = (token, payload) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${USER_MEDICATIONS}?medicationId=${payload}`;
  return fetch(api, {
    method: "DELETE",
    headers: myHeader,
  });
};

export const postConsent = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${USER_CONSENT}`;
  return fetch(api, {
    method: "POST",
    headers: myHeader,
  });
};

export const postEventTrack = async (token, payload) => {
  try {
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);
    const api = `${API_URL}${POSTEVENTTRACK}`;
    let data = await fetch(api, {
      method: "POST",
      headers: myHeader,
      body: JSON.stringify(payload),
    });
    if (data.ok) {
      return true;
    } else {
      return null;
    }
  } catch (ex) {
    return null;
  }
};

export const getStripeData = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${STRIPEAPI}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getCareCostData = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${CARECOSTAPI}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};

export const getProfileService = (token) => {
  let myHeader = new Headers();
  myHeader.append("Content-Type", "application/json");
  myHeader.append("Authorization", `Bearer ${token}`);
  const api = `${API_URL}${PROFILESERVICEAPI}`;
  return fetch(api, {
    method: "GET",
    headers: myHeader,
  });
};