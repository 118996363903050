import parse from "html-react-parser";
import GeneralConcernIcon from "../../../assets/img/svg/appt-type-icons/general-concern-icon.svg";
import FluShotIcon from "../../../assets/img/svg/appt-type-icons/flu-shot-icon.svg";
import AnnualPhysicalIcon from "../../../assets/img/svg/appt-type-icons/annual-physical-icon.svg";
import CovidTestIcon from "../../../assets/img/svg/appt-type-icons/covid-test-icon.svg";
import CovidBoosterShotIcon from "../../../assets/img/svg/appt-type-icons/covid-booster-shot-icon.svg";
import MentalHealthIcon from "../../../assets/img/svg/appt-type-icons/mental-health-icon.svg";
import NutritionCounselingIcon from "../../../assets/img/svg/appt-type-icons/nutrition-counseling-icon.svg";
import FollowUpIcon from "../../../assets/img/svg/appt-type-icons/follow-up-icon.svg";
import BloodWork from "../../../assets/img/svg/appt-type-icons/blood-work-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { modalOpen as modalOpenAction } from "../../../redux/actions/modalAction";
import { useNavigate, useLocation } from "react-router-dom";

const imgData = {
  "general-concern-icon.svg": GeneralConcernIcon,
  "annual-physical-icon.svg": AnnualPhysicalIcon,
  "covid-test-icon.svg": CovidTestIcon,
  "covid-booster-shot-icon.svg": CovidBoosterShotIcon,
  "mental-health-icon.svg": MentalHealthIcon,
  "nutrition-counseling-icon.svg": NutritionCounselingIcon,
  "flu-shot-icon.svg": FluShotIcon,
  "follow-up-icon.svg": FollowUpIcon,
  "BloodWorks.svg": BloodWork,
};

const ApptTypesDesciptionModal = ({ labels, content, closeModal }) => {
  const { appModalData } = content;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { features: accessLevel } = useSelector((state) => state.user);

  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "MODULE-MEDICAL-SERVICE"
  );
  const isDisableAll =
    selectModule.action === "DISABLE" || selectModule.action === "HIDE";
  const moduleLevelMsg = selectModule.featureMessage;

  const onBookClick = () => {
    closeModal();
    if (isDisableAll) {
      setTimeout(() => {
        dispatch(
          modalOpenAction({
            component: 3,
            content: {
              moduleLevelMsg: moduleLevelMsg,
            },
          })
        );
      }, 0);
    } else if (appModalData.action == "DISABLE") { 
      dispatch(
        modalOpenAction({
          component: 3,
          content: {
            moduleLevelMsg: appModalData.actionMessage,
            title: "BOOKING NOT AVAILABLE"
          },
        })
      );
    }
    else {
      dispatch({
        type: "SELECT_APP_TYPE",
        payload: appModalData.parentKey,
      });
      navigate(`/dashboard/bookappointment`);
    }
  };

  return (
    <>
      <div className="avatar-info avatar-info-2 mb-25">
        <div className="avatar-info-in">
          <figure>
            <img
              src={imgData[appModalData.img]}
              alt="general-concern-icon"
              className="img-appt-type-icon"
            />
          </figure>
          <div className="ai-meta">
            <span className="ai-title">{appModalData.title}</span>
          </div>
        </div>
      </div>
      <div className="center-popup-data">
        {parse(appModalData.description)}

        {location.pathname !== "/dashboard/bookappointment" && (
          <button
            onClick={onBookClick}
            className="btn btn-primary col-4 btn-lg"
          >
            Book Appointment
          </button>
        )}
      </div>
    </>
  );
};

export default ApptTypesDesciptionModal;
