const userProfileState = {
  saveProfileError: "",
  familyForEdit: {},
  userPharmacy: {
    pharmacyName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
  },
  isPharmacyLoading: false,
  isPharmacySaved: false,
  isPharmacyError: "",
  stripeURL: "",
  careCostData: [],
  profileService: []
};

export const profileReducer = (state = userProfileState, action) => {
  if (action.type === "GET_USERPROFILE_REQUEST") {
    return {
      ...state,
      isUserProfileLoading: true,
    };
  }

  if (action.type === "GET_USERPROFILE_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isUserProfileLoading: false,
      userProfile: { ...payload },
    };
  }

  if (action.type === "GET_USERPROFILE_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isUserProfileLoading: false,
      is404Error: payload.code,
    };
  }

  if (action.type === "SAVE_USERPROFILE_REQUEST") {
    return {
      ...state,
      isSaveUserProfileLoading: true,
      profileSaved: false,
      saveProfileError: "",
    };
  }

  if (action.type === "SAVE_USERPROFILE_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isSaveUserProfileLoading: false,
      saveUserProfile: { ...payload },
      profileSaved: true,
      saveProfileError: "",
    };
  }

  if (action.type === "SAVE_USERPROFILE_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isSaveUserProfileLoading: false,
      saveProfileError: payload.errorMessage,
    };
  }

  if (action.type === "SIDEPOPUP_CLOSE") {
    return {
      ...state,
      profileSaved: "",
      saveProfileError: "",
      familyMemberAdded: "",
      familyForEdit: {},
      removeFamilyData: false,
    };
  }

  if (action.type === "CLEAR_PROFILE_ERROR") {
    return {
      ...state,
      profileSaved: "",
      saveProfileError: "",
    };
  }
  if (action.type === "SAVE_USERPROFILE_CLEAR") {
    return {
      ...state,
      isSaveUserProfileLoading: false,
      saveUserProfile: null,
      isInvalidProfilePhoto: false,
    };
  }

  if (action.type === "SAVE_USERPROFILE_PHOTO_REQUEST") {
    return {
      ...state,
      isSaveUserProfilePhotoLoading: true,
      isInvalidProfilePhoto: false,
    };
  }

  if (action.type === "SAVE_USERPROFILEPHOTO_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isSaveUserProfilePhotoLoading: false,
      saveUserProfilePhoto: { ...payload },
      isInvalidProfilePhoto: false,
    };
  }

  if (action.type === "SAVE_USERPROFILEPHOTO_FAIL") {
    return {
      ...state,
      isSaveUserProfilePhotoLoading: false,
      isInvalidProfilePhoto: false,
    };
  }

  if (action.type === "SAVE_SAVEFAMILY_REQUEST") {
    return {
      ...state,
      isSaveFamilyLoading: true,
      familyMemberAdded: "",
      saveFamilityError: "",
    };
  }

  if (action.type === "SAVE_SAVEFAMILY_RECEIVE") {
    return {
      ...state,
      isSaveFamilyLoading: false,
      familyMemberAdded: true,
      saveFamilityError: "",
    };
  }

  if (action.type === "SAVE_SAVEFAMILY_FAIL") {
    return {
      ...state,
      isSaveFamilyLoading: false,
      saveFamilityError: action.payload.errorMessage,
    };
  }

  if (action.type === "SAVE_FAMILY_FAIL_CLEAR") {
    return {
      ...state,
      saveFamilityError: "",
    };
  }

  if (action.type === "GET_FAMILY_REQUEST") {
    return {
      ...state,
      isGetFamilyLoading: true,
    };
  }

  if (action.type === "GET_FAMILY_RECEIVE") {
    return {
      ...state,
      isGetFamilyLoading: false,
      familyData: action.payload,
    };
  }

  if (action.type === "GET_FAMILY_FAIL") {
    return {
      ...state,
      isGetFamilyLoading: false,
    };
  }

  if (action.type === "INVALID_USERPROFILEPHOTO") {
    return {
      ...state,
      isInvalidProfilePhoto: true,
    };
  }

  if (action.type === "SET_FAMILY_EDIT") {
    return {
      ...state,
      familyForEdit: action.payload,
    };
  }

  if (action.type === "LOGOUTUSER") {
    return {
      ...state,
      is404Error: "",
      saveProfileError: "",
    };
  }

  if (action.type === "LOGIN_REQUEST") {
    return {
      ...state,
      is404Error: "",
    };
  }

  if (action.type === "REMOVE_FAMILY_REQUEST") {
    return {
      ...state,
      isRemovingFamilyLoading: true,
    };
  }

  if (action.type === "REMOVE_FAMILY_RECEIVE") {
    return {
      ...state,
      isRemovingFamilyLoading: false,
      removeFamilyData: true,
    };
  }

  if (action.type === "REMOVE_FAMILY_FAIL") {
    return {
      ...state,
      isRemovingFamilyLoading: false,
    };
  }

  if (action.type === "GET_PHARMACY_REQUEST") {
    return {
      ...state,
      isPharmacyLoading: true,
      isPharmacyError: "",
    };
  }

  if (action.type === "GET_PHARMACY_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isPharmacyLoading: false,
      userPharmacy: payload,
      isPharmacyError: "",
    };
  }

  if (action.type === "GET_PHARMACY_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isPharmacyLoading: false,
      isPharmacyError: payload.errorMessage,
    };
  }

  if (action.type === "SAVE_PHARMACY_REQUEST") {
    return {
      ...state,
      isPharmacyLoading: true,
    };
  }

  if (action.type === "SAVE_PHARMACY_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isPharmacyLoading: false,
      isPharmacySaved: true,
    };
  }

  if (action.type === "SAVE_PHARMACY_FAIL") {
    const { payload } = action;
    return {
      ...state,
      isPharmacyLoading: false,
      isPharmacyError: payload.errorMessage,
    };
  }

  if (action.type === "SAVE_PHARMACY_CLEAR") {
    return {
      ...state,
      isPharmacyLoading: false,
      isPharmacySaved: false,
      isPharmacyError: "",
    };
  }

  if (action.type === "STRIPE_REQUEST") {
    const { payload } = action;
    return {
      ...state,
      stripeURL: ""
    };
  }

  if (action.type === "STRIPE_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      stripeURL: payload.stripeUrl
    };
  }

  if (action.type === "STRIPE_FAIL") {
    return {
      ...state,
      stripeURL: ""
    };
  }

  if (action.type === "CARE_COST_REQUEST") {
    const { payload } = action;
    return {
      ...state,
      careCostData: []
    };
  }

  if (action.type === "CARE_COST_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      careCostData: payload
    };
  }

  if (action.type === "CARE_COST_FAIL") {
    return {
      ...state,
      careCostData: []
    };
  }

  if (action.type === "GET_PROFILESERVICE_REQUEST") {
    const { payload } = action;
    return {
      ...state,
      profileService: []
    };
  }

  if (action.type === "GET_PROFILESERVICE_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      profileService: payload
    };
  }

  if (action.type === "GET_PROFILESERVICE_FAIL") {
    return {
      ...state,
      profileService: []
    };
  }

  return state;
};
