import { useEffect } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";

import PrivacyPractices from "../components/policies/PrivacyPractices.js";
import PrivacyPolicy from "../components/policies/PrivacyPolicy.js";
import Consent from "../components/policies/Consent.js";
import TermsOfUse from "../components/policies/TermsOfUse.js";

import Logo from "../assets/img/svg/radish-text-logo.svg";

const Policies = () => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, [location]);

  return (
    <section className="policy-section">
      <div className="container">
        <div className="policy-container">
          <div className="row">
            <div className="col-12">
              <figure className="mb-60">
                <Link to="../login">
                  <img src={Logo} alt="Radish Logo" />
                </Link>
              </figure>
            </div>
          </div>
          <div className="row">
            <Routes>
              <Route path="npp" element={<PrivacyPractices />} />
              <Route path="pp" element={<PrivacyPolicy />} />
              <Route path="consent" element={<Consent />} />
              <Route path="tou" element={<TermsOfUse />} />
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Policies;
