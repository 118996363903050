const medicalHistoryState = {
  isSearchFetching: false,
  userConditions: [],
  userMedications: [],
  userAllergies: [],
  searchTerms: [],
  userConditionsVerified: [],
  userMedicationsVerified: [],
  userAllergiesVerified: [],
};

export const medicalHistoryReducer = (state = medicalHistoryState, action) => {
  if (action.type === "MEDICAL_HISTORY_SEARCH_REQUEST") {
    return {
      ...state,
      isSearchFetching: true,
      medicalCondition: [],
    };
  }

  if (action.type === "MEDICAL_HISTORY_SEARCH_RECEIVE") {
    const { payload = [], flag } = action;

    const tempData = Object.keys(payload[2]) ? payload[2] : [];

    const finalData = () => {
      let newArr = [];

      const checkFlag = flag === 1 ? "primary_name" : "DISPLAY_NAME";
      if (tempData[checkFlag]) {
        for (let i = 0; i <= tempData[checkFlag].length - 1; i++) {
          let newObj = {};
          for (let key in tempData) {
            newObj[key] = tempData[key][i];
          }
          newArr.push(newObj);
        }
        return newArr;
      } else return [];
    };

    return {
      ...state,
      isSearchFetching: false,
      searchTerms: finalData(),
    };
  }

  if (action.type === "MEDICAL_HISTORY_SEARCH_FAIL") {
    return {
      ...state,
      isSearchFetching: false,
      isSearchError: true,
    };
  }

  if (action.type === "USER_HISTORY_REQUEST") {
    return {
      ...state,
      isUserHistoryLoading: true,
      isUserHistoryError: false,
      userConditions: [],
      userMedications: [],
      userAllergies: [],
    };
  }

  if (action.type === "USER_HISTORY_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isUserHistoryLoading: false,
      isUserHistoryError: false,
      userConditions: payload.medicalConditions,
      userMedications: payload.medications,
    };
  }

  if (action.type === "USER_HISTORY_FAIL") {
    return {
      ...state,
      isUserHistoryLoading: false,
      isUserHistoryError: true,
      userConditions: [],
      userMedications: [],
      userAllergies: [],
    };
  }

  if (action.type === "VERIFIED_HISTORY_REQUEST") {
    return {
      ...state,
      isUserVerifiedLoading: true,
      isUserVerifiedError: false,
      userConditionsVerified: [],
      userMedicationsVerified: [],
      userAllergiesVerified: [],
    };
  }

  if (action.type === "VERIFIED_HISTORY_RECEIVE") {
    const { payload } = action;
    return {
      ...state,
      isUserVerifiedLoading: false,
      isUserVerifiedError: false,
      userConditionsVerified: payload.medicalConditions,
      userMedicationsVerified: payload.medications,
      // userAllergiesVerified: payload.allergies,
    };
  }

  if (action.type === "VERIFIED_HISTORY_FAIL") {
    return {
      ...state,
      isUserVerifiedLoading: false,
      isUserVerifiedError: true,
      userConditionsVerified: [],
      userMedicationsVerified: [],
      userAllergiesVerified: [],
    };
  }

  if (action.type === "LOGOUT") {
    return {
      isSearchFetching: false,
      userConditions: [],
      userMedications: [],
      userAllergies: [],
      searchTerms: [],
      userConditionsVerified: [],
      userMedicationsVerified: [],
      userAllergiesVerified: [],
    };
  }

  return state;
};
