import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import RadSlider from "./RadSlider.js";
import DashboardLoading from "./DashboardLoading.js";

import { servicesAction } from "../../redux/actions/dashboardActions.js";
import { logoutUser } from "../../redux/actions/logoutUser.js";

import TeamHealthIcon from "../../assets/img/svg/my-health-icons/team-health-icon.svg";
import ResultsDocumentsIcon from "../../assets/img/svg/my-health-icons/results-and-documents-icon.svg";
import HomeWorkplaceIcon from "../../assets/img/svg/my-health-icons/home-workplace-icon.svg";
import InviteYourFamilyIcon from "../../assets/img/svg/my-health-icons/invite-your-family-icon.svg";
import CareTeamIcon from "../../assets/img/svg/my-health-icons/care-team-icon.svg";
import HealthChecklistIcon from "../../assets/img/svg/my-health-icons/health-checklist-icon.svg";
import medicalHistoryIcon from "../../assets/img/svg/my-health-icons/medical-history-icon.svg";

const MyHealthSlide = ({ state }) => {
  const imgData = {
    "team-health-icon.svg": TeamHealthIcon,
    "results-and-documents-icon.svg": ResultsDocumentsIcon,
    "home-workplace.png": HomeWorkplaceIcon,
    "invite-your-family.svg": InviteYourFamilyIcon,
    "your-care-team-icon.png": CareTeamIcon,
    "health-checklist-icon.svg": HealthChecklistIcon,
    "medicalHistory.png": medicalHistoryIcon,
  };

  return (
    <div className="my-health-slide">
      <Link to={state.link} className="my-health-slide-in">
        {state.serviceID === 10 ? (
          <>
            <HealthChecklistSlider
              data={state.data}
              icon={imgData[state.css]}
              shortDescription={state.shortDescription}
            />
            <span>{state.title}</span>
          </>
        ) : (
          <>
            <figure>
              <img src={imgData[state.css]} alt={state.shortDescription} />
            </figure>
            <span>{state.title}</span>
          </>
        )}
      </Link>
    </div>
  );
};

const HealthChecklistSlider = ({ data, icon, shortDescription }) => {
  let total = data.total;
  let answered = data.answered;
  let percentage = (answered / total) * 100;
  return (
    <figure className="health-checklist-progress">
      <CircularProgressbarWithChildren
        value={percentage}
        strokeWidth={8}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: "#536F57",
          trailColor: "#C3E3C7",
        })}
      >
        <div className="checklist-counter">
          <span className="checklist-complete">{answered}</span>
          <span className="checklist-slash">/</span>
          <span className="checklist-total">{total}</span>
          <img src={icon} alt={shortDescription} />
        </div>
      </CircularProgressbarWithChildren>
    </figure>
  );
};

const MyHealth = (props) => {
  let dispatch = useDispatch();
  const { services, isServicesLoading, is404Error } = useSelector(
    (state) => state.dashboard
  );
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    if (is404Error && is404Error === 401) {
      props.cookies.remove("isAuthorised");
      localStorage.clear();
      dispatch(logoutUser());
    }
  }, [is404Error, props, dispatch]);

  useEffect(() => {
    if (!services.length && !isServicesLoading) {
      dispatch(servicesAction(token));
    }
  }, [token, dispatch, services, isServicesLoading]);

  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 300,
    arrows: true,
    dots: false,
    className: "my-health-slider no-fade",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  if (DashboardLoading()) return null;

  return (
    <>
      <h2 className="app-heading-1">MY HEALTH</h2>
      <div className="my-health-slider-wrap">
        {services[0] && (
          <RadSlider
            stateToMap={services}
            CustomSlide={MyHealthSlide}
            settings={settings}
          />
        )}
      </div>
    </>
  );
};

export default MyHealth;
