import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { sidePopupClose } from "../../../redux/actions/sidePopupActions.js";
import { modalOpen as modalOpenAction } from "../../../redux/actions/modalAction";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/img/svg/radish-icon.svg";

const SummaryNotes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appointmentInfo = useSelector((state) => state.sidePopup.content);
  const { features: accessLevel } = useSelector((state) => state.user);

  let appointmentDay, appointmentDate, appointmentMonth, appointmentYear;
  if (appointmentInfo.localDate) {
    const scheduleDate = appointmentInfo.localDate.split(",");
    if (scheduleDate.length > 0) {
      let dateYear = scheduleDate[1].split(" ");
      appointmentDay = scheduleDate[0];
      appointmentDate = dateYear[2];
      appointmentMonth = dateYear[1];
      appointmentYear = dateYear[3];
    }
  }

  // const redirectFollowUp = () => {
  //   dispatch({
  //     type: "SELECT_APP_TYPE",
  //     payload: appointmentInfo.followUpParentKey,
  //   });
  //   dispatch(sidePopupClose());
  // };

  const selectModule = accessLevel.find(
    (next) => next.type === "MODULE" && next.key === "MODULE-MEDICAL-SERVICE"
  );
  const isDisableAll =
    selectModule.action === "DISABLE" || selectModule.action === "HIDE";
  const moduleLevelMsg = selectModule.featureMessage;
  const onBookClick = () => {
    dispatch(sidePopupClose());
    if (isDisableAll) {
      setTimeout(() => {
        dispatch(
          modalOpenAction({
            component: 3,
            content: {
              moduleLevelMsg: moduleLevelMsg,
            },
          })
        );
      }, 0);
    } else {
      dispatch({
        type: "SELECT_APP_TYPE",
        payload: appointmentInfo.followUpParentKey,
      });
      navigate(`/dashboard/bookappointment`, { replace: true });
    }
  };

  return (
    <>
      <h2 className="app-heading-1 mb-25">Summary Notes</h2>
      <div className="summary-notes-block">
        <div className="snb-header">
          <div className="appointment-card">
            <div className="appointment-card-top">
              <div className="act-time-title">
                <div className="act-left">
                  <span className="act-flag">
                    {appointmentInfo.appointmentTypeDisplayText}
                  </span>
                  <span className="act-title">
                    {appointmentInfo.examRoomName}
                  </span>
                </div>
                <div className="act-right">
                  <span className="act-time">
                    {appointmentInfo.localTime} {appointmentInfo.timeZone}
                  </span>
                </div>
              </div>
              <div className="act-middle">
                <div className="act-middle-left">
                  <div className="avatar-info">
                    <div className="avatar-info-in">
                      <figure>
                        <img
                          src={appointmentInfo.profilePicture || Logo}
                          alt=""
                        />
                      </figure>
                      <div className="ai-meta">
                        <span className="ai-title">
                          {appointmentInfo.doctor}
                        </span>
                        {appointmentInfo.careTeam === 1 ? (
                          <span className="ai-cta">
                            <i className="icon icon-heart"></i>YOUR CARE TEAM
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="act-middle-right">
                  {/* <span className="act-day">{appointmentDay}</span> */}
                  <span className="act-month">{appointmentMonth}</span>
                  <span className="act-day-num">{appointmentDate}</span>
                  <span className="act-month">{appointmentYear}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="snb-body">
          {/* <p><i>From your appointment {appointmentMonth} {appointmentDate}, {appointmentYear}</i></p> */}
          <p>
            {appointmentInfo.summaryNotes ||
              "There are no notes from your visit"}
          </p>
          <div className="popup-label-2">
            Thank you for using Radish Health. If you have any further questions
            or other health needs, please don't hesitate to reach out. I hope
            you have a healthy day!
          </div>
        </div>
      </div>

      {/* <div className="slide-cta-and-chat"> */}
      <div>
        {/* <div className="btn-wrap">
          <Link onClick={redirectFollowUp} to={`/dashboard/bookappointment`}>
            <span className="btn btn-primary col-12 btn-lg">
              Book a Follow-up
            </span>
          </Link>
        </div> */}
        <div className="btn-wrap">
          <button
            onClick={onBookClick}
            className="btn btn-primary col-12 btn-lg"
          >
            Book a Follow-up
          </button>
        </div>
        {/* <a href="javascript:void(0)" className="chat-cta">Chat</a> */}
      </div>
    </>
  );
};

export default SummaryNotes;
